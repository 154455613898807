import { MenuItem, Paper, TextField, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import Autosuggest from "react-autosuggest";
import Loading from "../../components/Loading";
import { getSpecialties } from "./SpecialtyUtils";

const TIMEOUT = 300;

const styles = theme => ({
  root: {
    height: 250,
    flexGrow: 1
  },
  container: {
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  suggestion: {
    display: "block"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  },
  divider: {
    height: theme.spacing.unit * 2
  },
  labelField: {
    color: theme.palette.primary.light,
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  textField: {
    border: "1px solid #E7E7E7",
    borderRadius: "3px",
    backgroundColor: "#FBFBFB",
    padding: "6px 7px"
  }
});

function renderInputComponent(inputProps) {
  const {
    classes,
    label,
    required,
    inputRef = () => {},
    ref,
    ...other
  } = inputProps;

  return (
    <TextField
      label={label}
      required={required}
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        className: classes.textField
      }}
      InputLabelProps={{
        shrink: true,
        className: classes.labelField
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { isHighlighted }) {
  return (
    <MenuItem selected={isHighlighted} component="div">
      {suggestion.name}
    </MenuItem>
  );
}

async function getSuggestions(value) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  if (inputLength === 0) {
    return [];
  }

  let suggestions = [];
  try {
    const specialties = await getSpecialties();

    suggestions = specialties.filter(specialty =>
      specialty.name.toLowerCase().includes(inputValue)
    );
  } catch (error) {
    console.log(error);
  }

  return suggestions;
}

function getSuggestionValue(suggestion) {
  return `${suggestion.name}`;
}

class SpecialtySelector extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.string
  };

  static defaultProps = {
    label: "Specialty",
    required: false
  };

  state = {
    single: "",
    suggestions: [],
    loading: false
  };

  async componentWillReceiveProps() {
    if (this.props.value) {
      const specialties = await getSpecialties();
      const currentSpecialty = specialties.find(
        specialty => specialty.uid === this.props.value
      );

      if (currentSpecialty) {
        this.setState({ single: currentSpecialty.name });
      }
    }
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(async () => {
      this.setState({ loading: true });
      const suggestions = await getSuggestions(value);
      this.setState({ suggestions });
      this.setState({ loading: false });
    }, TIMEOUT);
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleChange = name => (event, { newValue }) => {
    this.setState({
      [name]: newValue
    });
  };

  handleSuggestionSelected = (event, { suggestion }) => {
    this.props.onChange(suggestion);
    this.setState({ suggestions: [] });
  };

  render() {
    const { classes, label, required } = this.props;
    const { loading } = this.state;

    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      onSuggestionSelected: this.handleSuggestionSelected,
      getSuggestionValue,
      renderSuggestion
    };

    return (
      <Fragment>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            label,
            required,
            classes,
            value: this.state.single,
            onChange: this.handleChange("single")
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion
          }}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
        {loading && (
          <div style={{ marginTop: 5 }}>
            <Loading />
          </div>
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(SpecialtySelector);
