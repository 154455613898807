import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router";
import {
  withStyles,
  List,
  ListItem,
  Divider,
  ListItemText,
  Collapse
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import logo from "../../images/medici-logo.svg";
import { version } from "./../../../package.json";

const styles = theme => ({
  toolbar: Object.assign(theme.mixins.toolbar, {
    background: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    boxShadow:
      "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)"
  }),
  logo: {
    width: "115px",
    marginLeft: "25px"
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4
  },
  fullHeight: {
    height: "100%"
  },
  scrollBox: {
    overflowX: "hidden",
    overflowY: "auto",
    height: "calc(100vh - 100px)"
  },
  version: {
    textAlign: "center",
    padding: theme.spacing.unit
  }
});

class Menu extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    role: PropTypes.string,
    onMenuItemClick: PropTypes.func,
    router: PropTypes.object.isRequired
  };

  state = {
    isEngagementOpen: false,
    isSystemOpen: false
  };

  onClick = url => {
    this.props.router.push(url);
    if (this.props.onMenuItemClick) this.props.onMenuItemClick();
  };

  componentWillMount() {
    const activeRoute = this.props.router.location.pathname.split("/")[1];

    if (activeRoute) {
      if (
        ["activation-code", "invite-incentives", "notifications"].includes(
          activeRoute
        )
      ) {
        this.setState({ isEngagementOpen: true });
      }
      if (
        [
          "admins",
          "settings",
          "scenarios",
          "ehr-management",
          "practices",
          "upgrade-version"
        ].includes(activeRoute)
      ) {
        this.setState({ isSystemOpen: true });
      }
    }
  }

  render() {
    const { classes, router, role } = this.props;
    const activeItem = router.location.pathname.split("/")[1];
    return (
      <div className={classes.fullHeight}>
        <div className={classes.toolbar}>
          <a href="#" onClick={() => this.onClick("/practitioners")}>
            <img src={logo} alt="Medici" className={classes.logo} />
          </a>
        </div>
        <Divider />
        <div className={classes.scrollBox}>
          <List>
            <ListItem
              button
              disabled={!['admin'].includes(role)}
              onClick={() => this.onClick("/patients")}
              selected={activeItem === "patients"}
            >
              <ListItemText primary="Patients" />
            </ListItem>
            <ListItem
              button
              disabled={!['admin', 'sales'].includes(role)}
              onClick={() => this.onClick("/practitioners")}
              selected={activeItem === "practitioners" || !activeItem}
            >
              <ListItemText primary="Doctors" />
            </ListItem>
            <ListItem
              button
              disabled={!['admin'].includes(role)}
              onClick={() => this.onClick("/consultations")}
              selected={activeItem === "consultations"}
            >
              <ListItemText primary="Consultations" />
            </ListItem>
            <ListItem
              button
              onClick={() =>
                this.setState({
                  isEngagementOpen: !this.state.isEngagementOpen
                })
              }
            >
              <ListItemText primary="Engagement" />
              {this.state.isEngagementOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={this.state.isEngagementOpen}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItem
                  button
                  disabled={!['admin', 'sales'].includes(role)}
                  className={classes.nested}
                  onClick={() => this.onClick("/activation-code")}
                  selected={activeItem === "activation-code"}
                >
                  <ListItemText primary="Activation Codes" />
                </ListItem>
                <ListItem
                  button
                  disabled={!['admin'].includes(role)}
                  className={classes.nested}
                  onClick={() => this.onClick("/invite-incentives")}
                  selected={activeItem === "invite-incentives"}
                >
                  <ListItemText primary="Invite Incentives" />
                </ListItem>
                <ListItem
                  button
                  disabled={!['admin'].includes(role)}
                  className={classes.nested}
                  onClick={() => this.onClick("/notifications")}
                  selected={activeItem === "notifications"}
                >
                  <ListItemText primary="Notifications" />
                </ListItem>
                <ListItem
                  button
                  disabled={!['admin'].includes(role)}
                  className={classes.nested}
                  onClick={() => this.onClick("/networks")}
                  selected={activeItem === "network"}
                >
                  <ListItemText primary="Network" />
                </ListItem>
              </List>
            </Collapse>
            <ListItem
              button
              onClick={() =>
                this.setState({ isSystemOpen: !this.state.isSystemOpen })
              }
            >
              <ListItemText primary="System" />
              {this.state.isSystemOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.isSystemOpen} timeout="auto" unmountOnExit>
              <ListItem
                button
                disabled={!['admin'].includes(role)}
                className={classes.nested}
                onClick={() => this.onClick("/admins")}
                selected={activeItem === "admins"}
              >
                <ListItemText primary="Admins" />
              </ListItem>
              <ListItem
                button
                disabled={!['admin'].includes(role)}
                className={classes.nested}
                onClick={() => this.onClick("/settings")}
                selected={activeItem === "settings"}
              >
                <ListItemText primary="Settings" />
              </ListItem>
              <ListItem
                button
                disabled={!['admin'].includes(role)}
                className={classes.nested}
                onClick={() => this.onClick("/scenarios")}
                selected={activeItem === "scenarios"}
              >
                <ListItemText primary="Event Log" />
              </ListItem>
              <ListItem
                button
                disabled={!['admin'].includes(role)}
                className={classes.nested}
                onClick={() => this.onClick("/ehr-management")}
                selected={activeItem === "ehr-management"}
              >
                <ListItemText primary="EHR Management" />
              </ListItem>
              <ListItem
                button
                disabled={!['admin', 'sales'].includes(role)}
                className={classes.nested}
                onClick={() => this.onClick("/practices")}
                selected={activeItem === "practices"}
              >
                <ListItemText primary="Practices" />
              </ListItem>
            </Collapse>
          </List>
        </div>
        <div className={classes.version} id="version">
          Medici Admin v{version}
        </div>
      </div>
    );
  }
}

const MenuWithRouter = withRouter(Menu);

export default withStyles(styles)(MenuWithRouter);
