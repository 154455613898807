import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
  Typography,
  withStyles
} from "@material-ui/core";
import SHA512 from "crypto-js/sha512";
import PropTypes from "prop-types";
import React from "react";
import { deletePractitioner } from "../../components/practitioners/PractitionerUtils";
import { mediciFetch, getApiUrl, forceLogoff } from "../../components/utils/NetworkUtils";

const styles = ({ palette }) => ({
  title: {
    color: palette.primary.light
  },
  line: {
    backgroundColor: palette.primary.light,
    height: 2,
    width: "100%",
    marginBottom: 23
  },
  deleteContainer: {
    height: "173px",
    border: `1px solid ${palette.error.main}`,
    borderRadius: "4px",
    padding: 24
  },
  passwordText: {
    color: "#6D7278",
    fontSize: 16,
    marginBottom: 18
  },
  labelField: {
    color: palette.primary.light,
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  textField: {
    border: "1px solid #E7E7E7",
    borderRadius: "3px",
    backgroundColor: "#FBFBFB",
    padding: "6px 7px"
  },
  deleteButton: {
    color: palette.error.main,
    borderColor: palette.error.main
  },
  deleteTitle: {
    color: palette.common.black,
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5
  },
  deleteText: {
    color: "#6D7278",
    fontSize: 14,
    marginBottom: 20
  }
});

class PractitionerSettings extends React.PureComponent {
  static propTypes = {
    practitioner: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    classes: PropTypes.object,
    role: PropTypes.string,
    save: PropTypes.func.isRequired,
    setNotifySuccess: PropTypes.func.isRequired,
    setNotifyWarning: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    const { practitioner } = this.props;
    this.state = {
      demo: practitioner.demo,
      password: ""
    };
  }

  handleSwitch = name => event => {
    this.setState({
      [name]: event.target.checked
    });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleSave = async () => {
    this.setState({ loading: true });
    const { practitioner } = this.props;
    practitioner.demo = this.state.demo;
    await this.props.save(practitioner);
    this.setState({ loading: false });
  };

  handleSavePassword = async () => {
    if (this.state.password !== "") {
      this.setState({ loading: true });
      const { practitioner } = this.props;
      practitioner.password = SHA512(this.state.password).toString();
      await this.props.save(practitioner);
      this.setState({ loading: false });
    }
  };

  handleResetPassword = async () => {
    this.setState({ loading: true });

    try {
      const { email } = this.props.practitioner;
      await mediciFetch(`${getApiUrl()}/practitioners/password`, {
        method: "POST",
        body: JSON.stringify({ email })
      });
      this.props.setNotifySuccess(
        "A link to reset the password has been sent to the doctor email address."
      );
    } catch (error) {
      console.log(error);
      this.props.setNotifyWarning("Error sending email...");
    }

    this.setState({ loading: false });
  };

  handleDelete = async () => {
    if (confirm("Are you sure you want to delete the Doctor?")) {
      this.setState({ loading: true });

      try {
        const { practitioner } = this.props;
        await deletePractitioner(practitioner);
        this.props.router.push("/practitioners");
      } catch (error) {
        console.log(error);
      }

      this.setState({ loading: false });
    }
  };

  handleLogoff = async () => {
    if (confirm('Are you sure you want to force logoff?')) {
      this.setState({ loading: true });

      try {
        const { practitioner } = this.props;
        await forceLogoff(practitioner.uid, 'pr');
        this.props.setNotifySuccess(
          "Force logoff successful."
        );
      } catch (error) {
        console.log(error);
        this.props.setNotifyWarning('Error forcing logoff.');
      }

      this.setState({ loading: false });
    }
  }

  render() {
    const { classes, role } = this.props;

    if (role === 'sales') {
      return (
        <Grid container>
          <Typography variant="h6" className={classes.title}>
            Password
          </Typography>
          <div className={classes.line} />
          <Grid container style={{ marginTop: 16 }}>
            <Button
              color="primary"
              variant="contained"
              disabled={this.state.loading}
              onClick={this.handleResetPassword}
            >
              Reset Password
            </Button>
          </Grid>
        </Grid>
      )
    }

    return (
      <Grid container>
        <Grid container direction="row">
          <Grid item xs={6} style={{ paddingRight: 20 }}>
            <Typography variant="h6" className={classes.title}>
              Settings
            </Typography>
            <div className={classes.line} />

            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    id="demoSwitch"
                    checked={this.state.demo}
                    onChange={this.handleSwitch("demo")}
                  />
                }
                label="Demo Account"
              />
            </FormGroup>
            <Grid container justify='flex-end'>
              <Button
                variant="outlined"
                className={classes.deleteButton}
                disabled={this.state.loading}
                onClick={this.handleLogoff}
              >
                Force Logoff
              </Button>
              <Button
                color="primary"
                variant="contained"
                style={{ marginLeft: "16px" }}
                disabled={this.state.loading}
                onClick={this.handleSave}
              >
                Save
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: 20 }}>
            <Typography variant="h6" className={classes.title}>
              Password
            </Typography>
            <div className={classes.line} />
            <div className={classes.passwordText}>
              Type in a new password for the user. This will change their
              password immediately.
            </div>
            <TextField
              label="New Password"
              value={this.state.password}
              onChange={this.handleChange("password")}
              InputLabelProps={{
                shrink: true,
                className: classes.labelField
              }}
              InputProps={{ className: classes.textField }}
              fullWidth
            />
            <Grid container style={{ marginTop: 16 }}>
              <Button
                color="primary"
                variant="contained"
                style={{ marginLeft: "auto" }}
                disabled={this.state.loading}
                onClick={this.handleSavePassword}
              >
                Update Password
              </Button>
            </Grid>
            <Grid container style={{ marginTop: 16 }}>
              <Button
                color="primary"
                variant="contained"
                style={{ marginLeft: "auto" }}
                disabled={this.state.loading}
                onClick={this.handleResetPassword}
              >
                Reset Password
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={6} style={{ paddingRight: 20 }}>
            <Grid item xs={12} className={classes.deleteContainer}>
              <div className={classes.deleteTitle}>Delete Provider</div>
              <div className={classes.deleteText}>
                Are you sure you want to delete the Doctor?
              </div>
              <Button
                variant="outlined"
                className={classes.deleteButton}
                onClick={this.handleDelete}
                disabled={this.state.loading}
              >
                DELETE PROVIDER
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(PractitionerSettings);
