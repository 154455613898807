import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import {
  Button, FormControl, Input, InputAdornment, InputLabel,
  MenuItem,
  TextField,
  withStyles
} from '@material-ui/core';

import LocalStorage from '../utils/LocalStorage';
import { resetTOTP } from './LoginUtils';
import { ENVS, fetchGupiEnvs } from '../utils/NetworkUtils';

const styles = theme => ({
  loginForm: {
    maxWidth: '450px',
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    padding: theme.spacing.unit * 3,
    margin: theme.spacing.unit * 2,
    borderRadius: 10,
  },
  title: {
    textAlign: 'center',
  },
  formItem: {
    margin: `${theme.spacing.unit}px 0`
  },
  actions: {
    textAlign: 'center',
    marginTop: theme.spacing.unit * 2
  }
});

class LoginForm extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onLogin: PropTypes.func.isRequired,
    showVerificationField: PropTypes.bool,
  };

  state = {
    login: '',
    password: '',
    verificationCode: '',
    env: LocalStorage.get('env', 'dev'),
    coreApi: LocalStorage.get('coreApi', ''),
    allEnvs: [],
  };

  async componentDidMount() {
    const allEnvs = [];
    Object.keys(ENVS).forEach((env) => {
      allEnvs.push({ key: env, coreApi:ENVS[env] });
    })

    if (isDevelopment) {
      const gupiEnvs = (await fetchGupiEnvs()).environments;
      gupiEnvs.forEach((env) => {
        allEnvs.push({ key: `GUPI - ${env.name}`, coreApi: `https://${env.api.host}` });
      });
    }
    allEnvs.push({ key: 'local', coreApi: '' });

    await this.setState({ allEnvs });
  }

  logIn = (e) => {
    e.preventDefault();
    this.props.onLogin(this.state.login, this.state.password, this.state.verificationCode);
  };

  onChangeEnv = (event) => {
    const { value } = event.target;
    this.setState({ env: value });
    LocalStorage.set('env', value);
    if (value !== 'local') {
      LocalStorage.set('coreApi', this.state.allEnvs.find(i => i.key === value).coreApi);
    }
  };

  onChangeLocalEnv = (event) => {
    const { value } = event.target;
    this.setState({ coreApi: value });
    LocalStorage.set('coreApi', value);
  };

  onChange(propName, event) {
    this.setState({
      [propName]: event.target.value,
    });
  }

  resetTOTP = () => {
    LocalStorage.set('useTOTPAuth', false);

    resetTOTP('6e49d6ae-a6cd-4597-b0bb-b1f84620ee59')
      .then((response) => {
        console.log(response);
      });
  };

  render() {
    const { classes } = this.props;
    let verificationField = null;
    if (this.props.showVerificationField === true) {
      verificationField = (
        <FormControl className={classes.formItem} fullWidth>
          <InputLabel htmlFor='verification'>Verification</InputLabel>
          <Input
            id='verification'
            autoComplete='off'
            value={this.state.verificationCode}
            onChange={this.onChange.bind(this, 'verificationCode')}
            endAdornment={
              <InputAdornment position='end'>
                <span data-tip="The verification code is an extra security feature that requires users to not only use their password but also a code that is randomly generated by the Google Authenticator app.<br /><br />If you have already setup Google Authenticator then you can open the app, find the Medici code and enter it here.<br /><br />If you are having issues logging in please contact tech@medici.md.">?</span>
                <ReactTooltip place="right" type="dark" html effect="solid" />
              </InputAdornment>
            }
          />
        </FormControl>
      );
    }

    return (
      <form className={classes.loginForm} onSubmit={this.logIn}>
        <h2 className={classes.title}>Web Admin</h2>
        <div style={{ background: '#FFF' }}>
          <TextField
            className={classes.formItem}
            id='login'
            label='Email'
            value={this.state.login}
            onChange={this.onChange.bind(this, 'login')}
            fullWidth
          />
          <TextField
            className={classes.formItem}
            id='password'
            label='Password'
            value={this.state.password}
            onChange={this.onChange.bind(this, 'password')}
            type='password'
            fullWidth
          />
          {isDevelopment ? (
            <TextField
              select
              className={classes.formItem}
              label='Environment'
              value={this.state.env}
              onChange={this.onChangeEnv}
              fullWidth
            >
              {this.state.allEnvs.map(env => (
                <MenuItem key={env.key} value={env.key}>{env.key}</MenuItem>
              ))}
            </TextField>
          ) : null}
          { this.state.env === 'local' ? (
            <TextField
              className={classes.formItem}
              label='Core API URL'
              value={this.state.coreApi}
              onChange={this.onChangeLocalEnv}
              fullWidth
            >

            </TextField>
          ) : null }
          {verificationField}
        </div>
        <div className={classes.actions}>
          <Button variant='contained' color='secondary' onClick={this.logIn} type='submit'>Login</Button>
        </div>
      </form>
    );
  }
}

export default withStyles(styles)(LoginForm);
