import {
  AppBar,
  Grid,
  Tab,
  Tabs,
  Typography,
  withStyles
} from "@material-ui/core";
import Settings from "@material-ui/icons/Settings";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import Loading from "../../components/Loading";
import {
  setNotifyHidden,
  setNotifySuccess,
  setNotifyWarning
} from "../../components/notification/NotifyUtils";
import {
  getPractitionerByID,
  getPractitionerStatisticByID,
  savePractitioner,
  sendToDosespot,
  refreshDosespotStatus,
} from "../../components/practitioners/PractitionerUtils";
import { setPractitionerSpecialties } from "../../components/specialties/SpecialtyUtils";
import UserConsults from "../Patients/UserConsults";
import UserCalls from "../Patients/UserCalls";
import PractitionerColleagues from "./PractitionerColleagues";
import PractitionerFiles from "./PractitionerFiles";
import PractitionerHeader from "./PractitionerHeader";
import PractitionerInfo from "./PractitionerInfo";
import PractitionerPayment from "./PractitionerPayment";
import PractitionerSettings from "./PractitionerSettings";
import { errorToString } from '../../utils/helpers';

const styles = ({ palette }) => ({
  appBar: {
    backgroundColor: palette.primary.light
  },
  settings: {
    color: palette.common.white
  }
});

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class PractitionerProfile extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }),
    router: PropTypes.object.isRequired,
    user: PropTypes.object,
    setNotifyHidden: PropTypes.func.isRequired,
    setNotifySuccess: PropTypes.func.isRequired,
    setNotifyWarning: PropTypes.func.isRequired
  };

  state = {
    value: 0,
    loading: true,
    practitioner: null,
    statistic: {
      completedConsultationCount: { 30: 0, 60: 0, 90: 0 },
      monthRevenue: {
        currentMonth: { amount: 0, currency: "USD", positive: false },
        previousMonth: { amount: 0, currency: "USD", positive: false }
      },
      patientContactCount: 0,
      practitionerContactCount: 0
    },
    error: null
  };

  async componentDidMount() {
    await this.loadData(this.props.params.id);
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.params.id !== this.props.params.id) {
      await this.loadData(nextProps.params.id);
    }
  }

  loadData = async uid => {
    this.setState({ loading: true });

    try {
      let practitioner = await getPractitionerByID(uid);
      const statistic = await getPractitionerStatisticByID(practitioner.uid);
      practitioner = await setPractitionerSpecialties(practitioner);

      this.setState({ practitioner, statistic });
    } catch (error) {
      this.setState({ error });
    }

    this.setState({ loading: false });
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleSave = async practitioner => {
    if (!this.state.practitioner) {
      return;
    }

    this.props.setNotifyHidden();

    try {
      const resp = await savePractitioner(
        Object.assign({ uid: this.state.practitioner.uid }, practitioner)
      );
      this.setState({ practitioner: resp });
      this.props.setNotifySuccess("Doctor Saved");
    } catch (error) {
      this.setState({ error });
      this.props.setNotifyWarning(errorToString(error));
    }
  };

  onCopyInviteCode = async () => {
    if ("clipboard" in window.navigator) {
      try {
        await window.navigator.clipboard.writeText(
          this.state.practitioner.inviteCode
        );
        this.props.setNotifySuccess("Copied!");
      } catch (e) {
        this.props.setNotifyWarning(e.message);
      }
    }
  };

  onEditPractice = uid => {
    this.props.router.push(`/practices/${uid}`);
  };

  onSendToDosespot = async () => {
    this.props.setNotifyHidden();
    try {
      await sendToDosespot(this.state.practitioner.uid);
      this.props.setNotifySuccess('Credentials sent!');
      await this.loadData(this.state.practitioner.uid);
    } catch (e) {
      this.props.setNotifyWarning(e.message);
    }
  };

  onRefreshDosespotStatus = async () => {
    this.props.setNotifyHidden();
    try {
      await refreshDosespotStatus(this.state.practitioner.uid);
      this.props.setNotifySuccess('Dosespot status refreshed!');
      await this.loadData(this.state.practitioner.uid);
    } catch (e) {
      this.props.setNotifyWarning(e.message);
    }
  };

  render() {
    const { classes, router, user } = this.props;
    const { practitioner, statistic, value, loading, error } = this.state;

    if (loading) {
      return <Loading />;
    }

    if (!practitioner) {
      return <div>{error.message}</div>;
    }

    return (
      practitioner && (
        <div>
          <PractitionerHeader
            practitioner={practitioner}
            statistic={statistic}
          />
          <AppBar
            position="static"
            style={{ marginTop: 15 }}
            className={classes.appBar}
          >
            <Grid container direction="row">
              <Tabs value={value} onChange={this.handleChange}>
                <Tab style={{ minWidth: 125 }} label="Info" />
                <Tab style={{ minWidth: 125 }} label="Consults" disabled={!['admin'].includes(user.subRole || 'admin')} />
                <Tab style={{ minWidth: 125 }} label="Calls" />
                <Tab style={{ minWidth: 125 }} label="Patients" disabled={!['admin'].includes(user.subRole || 'admin')} />
                <Tab style={{ minWidth: 125 }} label="Colleagues" />
                <Tab style={{ minWidth: 125 }} label="Payment" />
                <Tab style={{ minWidth: 125 }} label="Files" />
                <Tab
                  style={{ minWidth: 92 }}
                  icon={<Settings className={classes.settings} />}
                />
              </Tabs>
            </Grid>
          </AppBar>
          {value === 0 && (
            <TabContainer>
              <PractitionerInfo
                practitioner={practitioner}
                save={this.handleSave}
                onCopyInviteCode={this.onCopyInviteCode}
                onEditPractice={this.onEditPractice}
                onSendDosespot={this.onSendToDosespot}
                onRefreshDosespot={this.onRefreshDosespotStatus}
                onError={this.props.setNotifyWarning}
              />
            </TabContainer>
          )}
          {value === 1 && (
            <UserConsults
              role="practitioner"
              uid={practitioner.uid}
              onViewConsult={uid => router.push(`/consultations/${uid}`)}
              setNotifyWarning={this.props.setNotifyWarning}
            />
          )}
          {value === 2 && (
            <UserCalls
              role="practitioner"
              uid={practitioner.uid}
              onViewCall={uid => router.push(`/practitioners/${practitioner.uid}/calls/${uid}`)}
              setNotifyWarning={this.props.setNotifyWarning}
            />
          )}
          {value === 3 && (
            <TabContainer>
              <PractitionerColleagues
                setNotifyWarning={this.props.setNotifyWarning}
                practitioner={practitioner}
                router={router}
                role="patient"
              />
            </TabContainer>
          )}
          {value === 4 && (
            <TabContainer>
              <PractitionerColleagues
                setNotifyWarning={this.props.setNotifyWarning}
                practitioner={practitioner}
                router={router}
              />
            </TabContainer>
          )}
          {value === 5 && (
            <TabContainer>
              <PractitionerPayment
                setNotifyWarning={this.props.setNotifyWarning}
                practitioner={practitioner}
                save={this.handleSave}
                router={router}
              />
            </TabContainer>
          )}
          {value === 6 && (
            <TabContainer>
              <PractitionerFiles prUid={practitioner.uid} />
            </TabContainer>
          )}
          {value === 7 && (
            <TabContainer>
              <PractitionerSettings
                practitioner={practitioner}
                router={router}
                role={user.subRole || 'admin'}
                save={this.handleSave}
                setNotifySuccess={this.props.setNotifySuccess}
                setNotifyWarning={this.props.setNotifyWarning}
              />
            </TabContainer>
          )}
        </div>
      )
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setNotifySuccess: title => {
      dispatch(setNotifySuccess(title));
    },
    setNotifyWarning: title => {
      dispatch(setNotifyWarning(title));
    },
    setNotifyHidden: () => {
      dispatch(setNotifyHidden());
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PractitionerProfile));
