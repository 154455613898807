import { getApiUrl, mediciFetch } from "./NetworkUtils";

let getRecurlyPublicKeyMemo = null;
async function getRecurlyPublicKey() {
  if (getRecurlyPublicKeyMemo) {
    return getRecurlyPublicKeyMemo;
  }

  const response = await mediciFetch(`${getApiUrl()}/mgmt/appParams`, {
    method: "get"
  });

  getRecurlyPublicKeyMemo = response.recurlyPublicKey;

  return getRecurlyPublicKeyMemo;
}

async function setRecurly() {
  const publicKey = await getRecurlyPublicKey();

  recurly.configure({
    publicKey,
    fields: {
      // affects all fields
      all: {
        style: {
          fontSize: "14px",
          invalid: {
            fontColor: "red"
          }
        }
      },
      number: {
        style: {
          placeholder: {
            content: "1234 5678 8765 4321"
          }
        },
        // Format the card number
        format: true
      },
      month: {
        // Display a month select on mobile devices
        inputType: "mobileSelect",
        style: {
          placeholder: {
            content: "MM"
          }
        }
      },
      year: {
        style: {
          placeholder: {
            content: "YYYY"
          }
        }
      },
      cvv: {
        style: {
          placeholder: {
            content: "123"
          }
        }
      }
    }
  });
}

/**
 * Create Recurly Account for Medici PR
 * @param uid
 * @returns {Promise.<{ recurlyAccountUrl: string, }>}
 */
async function createRecurlyAccount(uid) {
  return mediciFetch(
    `${getApiUrl()}/practitioners/${uid}/recurly`,
    {
      method: "post"
    }
  );
}

async function getToken(form) {
  return new Promise((resolve, reject) => {
    recurly.token(form, (error, token) => {
      if (error) {
        return reject(error);
      }

      resolve(token && token.id);
    });
  });
}

async function updatePrice(country, postalCode, plan, quantity, coupon) {
  //   await this.recurly();
  await setRecurly();
  const pricing = recurly.Pricing();
  const address = {
    country: country.toUpperCase(),
    postal_code: postalCode
  };

  return new Promise((resolve, reject) => {
    pricing
      .plan(plan, { quantity })
      .address(address)
      .coupon(coupon)
      .catch(error => reject(error))
      .done(price => resolve(price));
  });
}

async function getSubscription(userUid) {
  return mediciFetch(`${getApiUrl()}/practitioners/${userUid}/subscription`);
}

async function createSubscription(
  plan,
  tokenId,
  coupon,
  price,
  userUid,
  practiceUid
) {
  const subscription = await mediciFetch(
    `${getApiUrl()}/practitioners/${userUid}/subscription`,
    {
      method: "post",
      body: JSON.stringify({
        plan: "premium",
        tokenId,
        coupon,
        practiceUid,
        price: price ? price * 100 : null
      })
    }
  );
  return subscription;
}

export default {
  getToken,
  updatePrice,
  getSubscription,
  createSubscription,
  setRecurly,
  createRecurlyAccount
};
