import {
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Modal,
  withStyles,
  CardActions
} from "@material-ui/core";
import { DatePicker } from "material-ui-pickers";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import moment from "moment";
import * as helpers from "../../utils/helpers";

import { getSpecialties } from "../../components/specialties/SpecialtyUtils";
import { getPracticesByPractitionerID } from "../../components/practitioners/PractitionerUtils";

import PractitionerAccount from "./PractitionerAccount";
import PractitionerSMS from "./PractitionerSMS";
import EditBiography from "./EditBiography";
import SpecialtySelector from "../../components/specialties/SpecialtySelector";

const styles = ({ palette, spacing }) => ({
  title: {
    color: palette.primary.light
  },
  line: {
    backgroundColor: palette.primary.light,
    height: 2,
    width: "100%",
    marginBottom: 23
  },
  container: {
    marginBottom: 18
  },
  labelField: {
    color: palette.primary.light,
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  textField: {
    border: "1px solid #E7E7E7",
    borderRadius: "3px",
    backgroundColor: "#FBFBFB",
    padding: "6px 7px"
  },
  practiceCard: {
    minWidth: "300px",
    minHeight: "100px",
    color: "#556979",
    marginRight: spacing.unit * 4,
    marginBottom: spacing.unit * 4,
    cursor: "pointer"
  },
  cardTitle: {
    fontSize: "16px",
    fontWeight: "bold"
  },
  cardDesc: {
    fontSize: "14px",
    lineHeight: "20px"
  },
  addWrap: {
    width: "300px",
    height: "100px",
    marginRight: spacing.unit * 4,
    marginBottom: spacing.unit * 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px dashed #D8D8D8",
    color: "#D8D8D8",
    cursor: "pointer"
  }
});

const titles = [
  {
    label: "None",
    value: ""
  },
  {
    label: "Doctor",
    value: "Dr."
  }
];

const countries = [
  {
    label: "United States",
    value: "us"
  },
  {
    label: "Namibia",
    value: "na"
  },
  {
    label: "South Africa",
    value: "za"
  }
];

function getDefaultBiographyModel() {
  return {
    summary: "",
    body: "",
    start: moment().format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
    current: false
  };
}

class PractitionerInfo extends React.Component {
  static propTypes = {
    practitioner: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    save: PropTypes.func.isRequired,
    onCopyInviteCode: PropTypes.func,
    onEditPractice: PropTypes.func,
    onSendDosespot: PropTypes.func,
    onRefreshDosespot: PropTypes.func,
    onError: PropTypes.func,
    isCreate: PropTypes.bool
  };

  static defaultProps = {
    onCopyInviteCode: () => {},
    isCreate: false
  };

  constructor(props) {
    super(props);

    const { practitioner } = this.props;
    this.state = {
      title: practitioner.title,
      firstname: practitioner.firstname,
      lastname: practitioner.lastname,
      email: practitioner.email,
      phone: practitioner.phone,
      birthdate: practitioner.birthdate,
      country: practitioner.country,
      location: practitioner.location,

      specialties: practitioner.specialties,
      npiNumber: practitioner.npiNumber,
      deaNumber: practitioner.deaNumber,
      registrationId: practitioner.registrationId,
      practiceNumber: practitioner.practiceNumber,
      vetLicenseState: "",
      vetLicenseNumber: "",
      biography: {
        summary: practitioner.biography.summary,
        welcomeMessage: practitioner.biography.welcomeMessage,
        education: practitioner.biography.education || [],
        experiences: practitioner.biography.experiences || []
      },
      loading: false,
      addressHelper: helpers.locationToAddressLine(practitioner.location),
      allSpecialties: [],
      practices: [],
      isEditBiography: false,
      modal: {
        type: "",
        index: null,
        model: getDefaultBiographyModel()
      }
    };

    if (practitioner.stateTaxonomyLicense) {
      const stateTaxonomyLicense = practitioner.stateTaxonomyLicense.split(":");
      this.state.vetLicenseState = stateTaxonomyLicense[0];
      this.state.vetLicenseNumber = stateTaxonomyLicense[2];
    }
  }

  get isVeterinarian() {
    // specialties are guids, need to get names
    if (!this.state.allSpecialties.length) return [];

    const specialityValues = this.state.specialties.map(uid => {
      const spec = this.state.allSpecialties.find(
        curSpecialty => curSpecialty.uid === uid
      );
      return {
        id: uid,
        title: spec ? spec.name : ""
      };
    });

    return specialityValues.some(item => item.title === "Veterinarian");
  }

  componentDidMount = () => {
    setTimeout(() => {
      if (window.google !== undefined) {
        this.searchBox = new google.maps.places.SearchBox(this.addressInput);
        this.searchBox.addListener("places_changed", this.placesChanged);
      }
    }, 1000);
  };

  placesChanged = () => {
    if (this.searchBox.getPlaces() && this.searchBox.getPlaces().length > 0) {
      const place = this.searchBox.getPlaces()[0];

      const newLocation = {
        street: "",
        streetNumber: "",
        additionalInfos: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        geopoint: "",
        lat: "",
        lng: ""
      };

      newLocation.lat = `${place.geometry.location.lat()}`;
      newLocation.lng = `${place.geometry.location.lng()}`;
      newLocation.geopoint = `${newLocation.lat},${newLocation.lng}`;
      newLocation.formatted_address = place.formatted_address;

      place.address_components.forEach(component => {
        const typesString = component.types.join(", ");

        if (typesString.includes("route")) {
          newLocation.street = component.short_name;
        }

        if (typesString.includes("street_number")) {
          newLocation.streetNumber = component.short_name;
        }

        if (typesString.includes("sublocality_level_1")) {
          newLocation.additionalInfos = component.short_name;
        }

        if (
          typesString.includes("administrative_area_level_2") &&
          !newLocation.city
        ) {
          newLocation.city = component.short_name;
        }

        if (typesString.includes("locality")) {
          newLocation.city = component.short_name;
        }

        if (typesString.includes("administrative_area_level_1")) {
          newLocation.state = component.short_name;
        }

        if (typesString.includes("country")) {
          newLocation.country = component.short_name.toLowerCase();
        }

        if (
          typesString.includes("postal_code") &&
          !typesString.includes("postal_code_suffix")
        ) {
          newLocation.postalCode = component.short_name;
        }
      });

      this.setState({
        country: newLocation.country,
        location: newLocation,
        addressHelper: helpers.locationToAddressLine(newLocation),
      });
    }
  };

  handleChange = name => event => {
    const { value } = event.target;
    this.setState({
      [name]: value
    });
    if (name === "addressHelper") {
      const regexp = new RegExp(" [0-9]+|[0-9]+ ", "g");
      if (regexp.test(value)) {
        this.handleChangeLocation("street")({
          target: { value: value.replace(regexp, "").trim() }
        });
        this.handleChangeLocation("streetNumber")({
          target: { value: value.match(regexp)[0].trim() }
        });
      } else {
        this.handleChangeLocation("street")(event);
        this.handleChangeLocation("streetNumber")({ target: { value: "" } });
      }
    }
  };

  handleChangeLocation = name => event => {
    const { location } = this.state;
    location[name] = event.target.value;

    this.setState({ location });
  };

  handleChangeSpecialties = specialty => {
    this.setState({
      specialties: [specialty.uid],
      taxonomyCode: specialty.taxonomyCode
    });
  };

  handleChangeBiography = name => event => {
    const { biography } = this.state;
    biography[name] = event.target.value;

    this.setState({ biography });
  };

  save = async () => {
    this.setState({ loading: true });
    const pr = {
      title: this.state.title,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      email: this.state.email,
      phone: this.state.phone,
      birthdate: this.state.birthdate,
      country: this.state.country,
      location: this.state.location
    };
    try {
      pr.location.geopoint = await helpers.getGeopoint(
        helpers.locationToString(this.state.location)
      );
    } catch (e) {
      if (e.message === "ZERO_RESULTS") {
        try {
          const { city, state, postalCode } = this.state.location;
          pr.location.geopoint = await helpers.getGeopoint(
            `${city}, ${state}, ${postalCode}`
          );
        } catch (error) {
          this.props.onError(
            "We couldn't find that address. Please double-check it and try again."
          );
          this.setState({ loading: false });
          return;
        }
      }
    }
    await this.props.save(pr);
    this.setState({ loading: false });
  };

  saveProfessional = async () => {
    this.setState({ loading: true });
    const pr = {
      // TODO: 'title' can be removed after GHOST-945 fix
      title: this.state.title,
      specialties: this.state.specialties,
      npiNumber: this.state.npiNumber,
      deaNumber: this.state.deaNumber,
      registrationId: this.state.registrationId,
      practiceNumber: this.state.practiceNumber,
      biography: this.state.biography
    };

    if (this.isVeterinarian) {
      const { vetLicenseState, vetLicenseNumber } = this.state;
      if (vetLicenseState && vetLicenseNumber) {
        pr.stateTaxonomyLicense = `${vetLicenseState.toUpperCase()}:174M:${vetLicenseNumber}`;
      } else {
        pr.stateTaxonomyLicense = "";
      }
    }

    await this.props.save(pr);
    this.setState({ loading: false });
  };

  saveCreate = async () => {
    this.setState({ loading: true });

    const pr = {
      title: this.state.title,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      email: this.state.email,
      phone: helpers.parsePhone(this.state.phone),
      birthdate: this.state.birthdate,
      country: this.state.country,
      location: this.state.location,

      specialties: this.state.specialties,
      npiNumber: this.state.npiNumber,
      deaNumber: this.state.deaNumber,
      registrationId: this.state.registrationId,
      practiceNumber: this.state.practiceNumber
    };

    if (this.isVeterinarian) {
      const { vetLicenseState, vetLicenseNumber } = this.state;
      if (vetLicenseState && vetLicenseNumber) {
        pr.stateTaxonomyLicense = `${vetLicenseState.toUpperCase()}:174M:${vetLicenseNumber}`;
      } else {
        pr.stateTaxonomyLicense = "";
      }
    }

    try {
      pr.location.geopoint = await helpers.getGeopoint(
        helpers.locationToString(this.state.location)
      );
    } catch (e) {
      if (e.message === "ZERO_RESULTS") {
        try {
          const { city, state, postalCode } = this.state.location;
          pr.location.geopoint = await helpers.getGeopoint(
            `${city}, ${state}, ${postalCode}`
          );
        } catch (error) {
          this.props.onError(
            "We couldn't find that address. Please double-check it and try again."
          );
          this.setState({ loading: false });
          return;
        }
      }
    }

    await this.props.save(pr);
    this.setState({ loading: false });
  };

  openEditBiography = (type, index, value) => () => {
    this.setState({
      isEditBiography: true,
      modal: {
        type,
        index,
        model: value || getDefaultBiographyModel()
      }
    });
  };

  handleModalClose = () => {
    this.setState({
      isEditBiography: false
    });
  };

  onBiographySave = (model, type, index) => {
    const value = this.state.biography[type];
    if (typeof index !== "undefined") {
      value[index] = model;
    } else {
      value.push(model);
    }

    this.setState({
      biography: { ...this.state.biography, [type]: value },
      isEditBiography: false
    });
  };

  onBiographyDelete = (type, index) => {
    const value = this.state.biography[type];
    value.splice(index, 1);
    this.setState({
      biography: { ...this.state.biography, [type]: [...value] },
      isEditBiography: false
    });
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      addressHelper: helpers.locationToAddressLine(nextProps.practitioner.location)
    });
  }

  componentWillMount() {
    getSpecialties()
      .then(res => {
        this.setState({ allSpecialties: res });
      })
      .catch(e => console.log(e));

    if (this.props.practitioner.uid) {
      getPracticesByPractitionerID(this.props.practitioner.uid)
        .then(practices => {
          this.setState({ practices });
        })
        .catch(e => console.log(e.message));
    }
  }

  render() {
    const { classes, isCreate } = this.props;

    const { firstname, lastname, email, phone, taxonomyCode } = this.state;
    const validForm =
      firstname.length > 0 && lastname.length > 0 && email.length > 0 && (!isCreate || helpers.validatePhone(phone));

    return (
      <Grid container>
        <Grid item xs={9}>
          <Typography variant="h6" className={classes.title}>
            Profile
          </Typography>
          <div className={classes.line} />
          <form noValidate autoComplete="off">
            <Grid container>
              <Grid
                className={classes.container}
                container
                direction="row"
                justify="space-between"
              >
                <TextField
                  select
                  label="Title"
                  value={this.state.title}
                  onChange={this.handleChange("title")}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ flex: "0.5 0 auto", marginRight: 10 }}
                >
                  {titles.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  error={this.state.firstname.length === 0}
                  required
                  label="First Name"
                  value={this.state.firstname}
                  onChange={this.handleChange("firstname")}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ flex: "1 0 auto", marginRight: 10 }}
                />

                <TextField
                  error={this.state.lastname.length === 0}
                  required
                  label="Last Name"
                  value={this.state.lastname}
                  onChange={this.handleChange("lastname")}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ flex: "1 0 auto" }}
                />
              </Grid>
              <Grid
                className={classes.container}
                container
                direction="row"
                justify="space-between"
              >
                <TextField
                  error={this.state.email.length === 0}
                  required
                  label="Email"
                  type="email"
                  autoComplete="email"
                  value={this.state.email}
                  onChange={this.handleChange("email")}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ flex: "1 0 auto", marginRight: 10 }}
                />

                <TextField
                  error={isCreate && !helpers.validatePhone(this.state.phone)}
                  required={isCreate}
                  label="Phone"
                  value={this.state.phone}
                  onChange={this.handleChange("phone")}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ flex: "0.5 0 auto", marginRight: 10 }}
                />

                <DatePicker
                  label="DOB"
                  value={this.state.birthdate ? this.state.birthdate : null}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ flex: "0.5 0 auto" }}
                  onChange={date =>
                    this.handleChange("birthdate")({
                      target: { value: date.format("YYYY-MM-DD") }
                    })
                  }
                  maxDate={moment().subtract(18, 'years')}
                  format="MM/DD/YYYY"
                />
              </Grid>

              <Grid className={classes.container} container>
                <TextField
                  id="addressHelper"
                  label="Address"
                  placeholder=""
                  value={this.state.addressHelper}
                  onChange={this.handleChange("addressHelper")}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ flex: "0.5 0 auto" }}
                  inputRef={el => {
                    this.addressInput = el;
                  }}
                />
              </Grid>
              <Grid className={classes.container} container>
                <TextField
                  label="Address 2"
                  value={this.state.location.additionalInfos}
                  onChange={this.handleChangeLocation("additionalInfos")}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{
                    className: classes.textField
                  }}
                  style={{ flex: "0.5 0 auto" }}
                />
              </Grid>
              <Grid
                className={classes.container}
                style={{ flexWrap: "unset" }}
                container
                direction="row"
                justify="space-between"
              >
                <TextField
                  label="City"
                  value={this.state.location.city}
                  onChange={this.handleChangeLocation("city")}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ flex: "1 0 auto", marginRight: 10 }}
                />

                <TextField
                  label="State"
                  value={this.state.location.state}
                  onChange={this.handleChangeLocation("state")}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ flex: "1 0 auto", marginRight: 10 }}
                />

                <TextField
                  label={
                    this.state.country === "us" ? "Zip Code" : "Postal Code"
                  }
                  value={this.state.location.postalCode}
                  onChange={this.handleChangeLocation("postalCode")}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ flex: "0.5 0 auto", marginRight: 10 }}
                />

                <TextField
                  id="country"
                  select
                  label="Country"
                  value={this.state.country}
                  onChange={this.handleChange("country")}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ flex: "1 0 auto" }}
                >
                  {countries.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {!isCreate && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.save}
                  style={{ marginLeft: "auto", minWidth: 90 }}
                  disabled={this.state.loading || !validForm}
                >
                  Save
                </Button>
              )}
            </Grid>
          </form>
          <Typography variant="h6" className={classes.title}>
            Professional
          </Typography>
          <div className={classes.line} />
          <Grid
            className={classes.container}
            container
            direction="row"
            justify="space-between"
          >
            <Grid style={{ flex: "1 0 auto", marginRight: 10 }}>
              <SpecialtySelector
                value={this.state.specialties[0] || ""}
                label={`Speciality${taxonomyCode ? ` - ${taxonomyCode}` : ""}`}
                onChange={this.handleChangeSpecialties}
                required={true}
              />
            </Grid>
            {this.state.country === "us"
              ? [
                  <TextField
                    key="npi"
                    label="NPI"
                    value={this.state.npiNumber}
                    onChange={this.handleChange("npiNumber")}
                    InputLabelProps={{
                      shrink: true,
                      className: classes.labelField
                    }}
                    InputProps={{ className: classes.textField }}
                    style={{ flex: "0.5 0 auto", marginRight: 10 }}
                  />,
                  <TextField
                    key="dea"
                    label="DEA Number"
                    value={this.state.deaNumber}
                    onChange={this.handleChange("deaNumber")}
                    InputLabelProps={{
                      shrink: true,
                      className: classes.labelField
                    }}
                    InputProps={{ className: classes.textField }}
                    style={{ flex: "0.5 0 auto" }}
                  />
                ]
              : [
                  <TextField
                    key="registrationID"
                    label="Registration ID"
                    value={this.state.registrationId}
                    onChange={this.handleChange("registrationId")}
                    InputLabelProps={{
                      shrink: true,
                      className: classes.labelField
                    }}
                    InputProps={{ className: classes.textField }}
                    style={{ flex: "0.5 0 auto", marginRight: 10 }}
                  />,
                  <TextField
                    key="practiceNumber"
                    label="Practice Number"
                    value={this.state.practiceNumber}
                    onChange={this.handleChange("practiceNumber")}
                    InputLabelProps={{
                      shrink: true,
                      className: classes.labelField
                    }}
                    InputProps={{ className: classes.textField }}
                    style={{ flex: "0.5 0 auto" }}
                  />
                ]}
          </Grid>
          {this.isVeterinarian && (
            <Grid className={classes.container} container>
              <TextField
                key="vetLicenseNumber"
                label="Vet License Number"
                value={this.state.vetLicenseNumber}
                onChange={this.handleChange("vetLicenseNumber")}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labelField
                }}
                InputProps={{ className: classes.textField }}
                style={{ flex: "0.5 0 auto", marginRight: 10 }}
              />
              <TextField
                key="vetLicenseState"
                label="Licensing State"
                value={this.state.vetLicenseState}
                onChange={this.handleChange("vetLicenseState")}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labelField
                }}
                InputProps={{ className: classes.textField }}
                style={{ flex: "0.5 0 auto" }}
              />
            </Grid>
          )}
          {isCreate ? (
            <Grid className={classes.container} container>
              <Button
                variant="contained"
                color="primary"
                onClick={this.saveCreate}
                style={{ marginLeft: "auto", minWidth: 90 }}
                disabled={this.state.loading || !validForm}
              >
                Save
              </Button>
            </Grid>
          ) : (
            <Fragment>
              <Grid className={classes.container} container>
                <Typography variant="subtitle2" className={classes.title}>
                  BIOGRAPHY
                </Typography>
              </Grid>
              <Grid className={classes.container} container>
                <TextField
                  label="Summary"
                  value={this.state.biography.summary}
                  onChange={this.handleChangeBiography("summary")}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  fullWidth
                />
              </Grid>
              <Grid className={classes.container} container>
                <Typography variant="subtitle2" className={classes.title}>
                  PRACTICES
                </Typography>
              </Grid>
              <Grid className={classes.container} container>
                {this.state.practices.map((practice, index) => (
                  <Card key={index} className={classes.practiceCard}>
                    <CardContent>
                      <div className={classes.cardTitle}>{practice.name}</div>
                      {practice.location ? (
                        <div className={classes.cardDesc}>
                          {helpers.locationToString(practice.location)}
                        </div>
                      ) : null}
                    </CardContent>
                    <CardActions>
                      <Button
                        color="primary"
                        onClick={() => this.props.onEditPractice(practice.uid)}
                      >
                        Edit
                      </Button>
                    </CardActions>
                  </Card>
                ))}
              </Grid>
              <Grid className={classes.container} container>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.saveProfessional}
                  style={{ marginLeft: "auto", minWidth: 90 }}
                  disabled={this.state.loading}
                >
                  Save
                </Button>
              </Grid>
            </Fragment>
          )}
        </Grid>
        {!isCreate && (
          <Grid item xs={3}>
            <PractitionerAccount
              practitioner={this.props.practitioner}
              save={this.props.save}
              onCopyInviteCode={this.props.onCopyInviteCode}
              onSendDosespot={this.props.onSendDosespot}
              onRefreshDosespot={this.props.onRefreshDosespot}
            />
            <PractitionerSMS
              practitioner={this.props.practitioner}
              save={this.props.save}
              onError={this.props.onError}
            />
          </Grid>
        )}
        <Modal
          onClose={this.handleModalClose}
          open={this.state.isEditBiography}
        >
          <EditBiography
            data={this.state.modal}
            onSave={this.onBiographySave}
            onClose={this.handleModalClose}
            onDelete={this.onBiographyDelete}
          />
        </Modal>
      </Grid>
    );
  }
}

export default withStyles(styles)(PractitionerInfo);
