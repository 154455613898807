import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { getPatientByID } from '../patients/PatientUtils';
import { getPracticeByID } from '../utils/PracticeUtils';
import { getPractitionerByID } from '../practitioners/PractitionerUtils';
import { getSpecialtyByID } from '../specialties/SpecialtyUtils';
import { getConsultationByID, sendConsultationReceipt, getConsultationDetailsByID } from './ConsultationUtils';
import './ConsultationForm.css';

export default class ConsultationForm extends React.Component {
  static propTypes = {
    params: PropTypes.shape({ id: PropTypes.string }),
    onCancel: PropTypes.func,
    setNotifySuccess: PropTypes.func,
    setNotifyWarning: PropTypes.func,
    router: PropTypes.object.isRequired,
  };

  static defaultProps = {
    onCancel: () => { },
    setNotifySuccess: () => {},
    setNotifyWarning: () => {},
  };

  state = {
    displaySpecialty: false,
    displayPractitioner: false,
    displayPractitionerPractice: false,
    displayPricingAndPayments: false,
    displayReviews: false,
    displayStats: false,
    displayInvoice: false,
    specialtyName: '',
    practitionerName: '',
    patientName: '',
    practiceName: '',
    sendTo: '',
    consultation: {
      audioCalls: [],
      callsTotalDuration: 0,
      dateTime: '',
      dateTimeEnd: '',
      patientId: '',
      practitionerId: '',
      reviews: [],
      terminated: false,
      messages: [],
      totalNbOfMessages: 0,
      uid: '',
      charges: [],
      specialityId: '',
      practiceId: '',
    },
  };

  sendReceipt = (e) => {
    e.preventDefault();
    sendConsultationReceipt(this.state.consultation.uid, [this.state.sendTo])
      .then(res => this.props.setNotifySuccess(`You have emailed an invoice to [${res.recipients.join(',')}]`))
      .catch((error) => {
        console.log('Error sending receipt: ', error);
        this.props.setNotifyWarning('Email failed to send');
      });
  };

  cancel = (e) => {
    e.preventDefault();
    this.props.onCancel();
  };

  toggle = (propName) => {
    this.setState({
      [propName]: !this.state[propName],
    });
  };

  componentDidMount() {
    this.setup(this.props.params.id);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.params.id !== nextProps.params.id) {
      this.setup(nextProps.params.id);
    }
  }

  setup(consultationId) {
    getConsultationByID(consultationId)
      .then((consultation) => {
        this.setState({ consultation });
        this.getConsultDetails(consultation.uid);
        this.getSpecialty(consultation.specialtyId);
        this.getPractitioner(consultation.practitionerId);
        this.getPatient(consultation.patientId);
        if (consultation.practiceId) {
          this.getPractice(consultation.practiceId);
        }
      })
      .catch((ex) => {
        console.log('Error retrieving consult details', ex);
        this.props.setNotifyWarning('Error retrieving Consultation');
        this.props.router.replace('/consultations');
      });
  }

  getConsultDetails(consultId) {
    getConsultationDetailsByID(consultId)
      .then((details) => {
        this.setState({ consultation: Object.assign({}, this.state.consultation, details) });
      })
      .catch((ex) => {
        console.log('Error retrieving consult details', ex);
      });
  }

  getSpecialty(uid) {
    getSpecialtyByID(uid)
      .then((specialty) => {
        this.setState({ specialtyName: specialty.name });
      })
      .catch((ex) => {
        console.log('Error retrieving specialty', ex);
      });
  }

  getPractitioner(uid) {
    getPractitionerByID(uid)
      .then((practitioner) => {
        this.setState({
          practitionerName: `${practitioner.firstname} ${practitioner.lastname}`,
        });
      })
      .catch((ex) => {
        console.log('Error retrieving practitioner', ex);
      });
  }

  getPatient(uid) {
    getPatientByID(uid)
      .then((patient) => {
        this.setState({
          patientName: `${patient.firstname} ${patient.lastname}`,
        });
      })
      .catch((ex) => {
        console.log('Error retrieving patient', ex);
      });
  }

  getPractice(uid) {
    getPracticeByID(uid)
      .then((practice) => {
        this.setState({ practiceName: practice.name });
      })
      .catch((ex) => {
        console.log('Error retrieving practice', ex);
      });
  }

  returnRow(caption, key, value) { // eslint-disable-line
    return (
      <div className="form-fieldset">
        <label htmlFor={key} className="form-label">
          {caption}
        </label>
        {typeof value === 'boolean' ? (
          <div className="form-freeContainer">
            <input readOnly type="checkbox" checked={value} />{' '}
            <label>Is {key}</label>
          </div>
        ) : (
            <input id={key} readOnly className="form-input" value={value} />
          )}
      </div>
    );
  }

  returnFullCaption(caption) { // eslint-disable-line
    return (
      <div className="form-fieldset">
        <label className="form-label-full">{caption}</label>
      </div>
    );
  }

  render() {
    const timeToRespond = {};
    const { totalNbOfMessages, practitionerNbMessages } = this.state.consultation;

    if (this.state.consultation.messages) {
      this.state.consultation.messages.forEach((text, index) => {
        timeToRespond[text.senderId] = timeToRespond[text.senderId] || [];

        if (this.state.consultation.messages[index + 1]) {
          const nextMessage = moment(this.state.consultation.messages[index + 1].dateTime);
          const currentMessage = moment(text.dateTime);
          timeToRespond[text.senderId].push(nextMessage.diff(currentMessage, 'seconds'));
        }
      });
    }


    const reviews = [];
    if (this.state.consultation.reviews) {
      this.state.consultation.reviews.forEach((review) => {
        const user1 = review.reviewerId === this.state.consultation.patientId ? 'Patient' : 'Practitioner';
        const user2 = review.reviewerId === this.state.consultation.patientId ? 'Practitioner' : 'Patient';
        reviews.push(this.returnFullCaption(`${user1} gave ${user2} ${review.stars} stars`));
      });
    }

    const paymentInfo = [];
    if (this.state.consultation.charges) {
      this.state.consultation.charges.forEach((charge, index) => {
        paymentInfo.push(<div className="charges-list" key={index}>
          <h5>Transaction #{index + 1}</h5>
          {this.returnRow('Billed', 'price', `${charge.price / 100} ${charge.currency}`)}
          {charge.amountPaidWithFreeCredit ?
            this.returnRow(
              'From free credit',
              'amountPaidWithFreeCredit',
              `${charge.amountPaidWithFreeCredit / 100} ${charge.currency}`,
            ) : null}
          {charge.stripeBalanceTransactionid ? <h5>Stripe Transaction</h5> : null}
          {charge.stripeBalanceTransactionid ?
            <a target="_blank" rel="noreferrer noopener"
               href={`https://dashboard.stripe.com/payments/${charge.stripeBalanceTransactionid}`}>
              {charge.stripeBalanceTransactionid}
            </a> : null}
        </div>);
      });
    }

    const callInfo = [];
    if (this.state.consultation.audioCalls) {
      this.state.consultation.audioCalls.forEach((call, index) => {
        const callTitle = call.startedByPatient ? ' started by Patient' : ' started by Practitioner';
        callInfo.push(<div className="call-info" key={index}>
          <h5>
            Call #{index + 1} {callTitle}
          </h5>
          {this.returnRow('Started On', 'dateStarted', moment(new Date(call.dateStarted)).format('YYYY-MM-DD [@] h:mm a'))}
          {this.returnRow('Ended On', 'dateEnded', moment(new Date(call.dateEnded)).format('YYYY-MM-DD [@] h:mm a'))}
          {this.returnRow('Ending Reason', 'endingReason', call.endingReason)}
        </div>);
      });
    }

    return (
      <section id="consultationForm" className="consultationForm">
        <form id="formConsultation" ref="formConsultation" className="form">
          {this.returnRow('ID', 'uid', this.state.consultation.uid)}
          {this.returnRow('Status', 'terminated', this.state.consultation.terminated)}
          {this.returnRow('Start Date', 'uid', moment(new Date(this.state.consultation.dateTime)).format('YYYY-MM-DD [@] h:mm a'))}
          {this.returnRow('End Date', 'uid', moment(new Date(this.state.consultation.dateTimeEnd)).format('YYYY-MM-DD [@] h:mm a'))}

          <div className="toggle">
            <h4
              className="toggleContainer"
              onClick={this.toggle.bind(this, 'displaySpecialty')}
            >
              <span className="containerArrow">
                {this.state.displaySpecialty ? '▼' : '▶'}
              </span>{' '}
              Specialty
            </h4>
            <div className={this.state.displaySpecialty ? '' : 'hide'}>
              {this.returnRow('ID', 'specialtyId', this.state.consultation.specialtyId)}
              {this.returnRow('Name', 'specialtyName', this.state.specialtyName)}
            </div>
          </div>

          <div className="toggle">
            <h4
              className="toggleContainer"
              onClick={this.toggle.bind(this, 'displayPractitioner')}
            >
              <span className="containerArrow">
                {this.state.displayPractitioner ? '▼' : '▶'}
              </span>{' '}
              Practitioner
            </h4>
            <div className={this.state.displayPractitioner ? '' : 'hide'}>
              {this.returnRow(
                'ID',
                'practitionerId',
                this.state.consultation.practitionerId,
              )}
              {this.returnRow(
                'Name',
                'practitionerName',
                this.state.practitionerName,
              )}
            </div>
          </div>

          <div className="toggle">
            <h4
              className="toggleContainer"
              onClick={this.toggle.bind(this, 'displayPractitionerPractice')}
            >
              <span className="containerArrow">
                {this.state.displayPractitionerPractice ? '▼' : '▶'}
              </span>{' '}
              Practitioner Practice
            </h4>
            <div
              className={this.state.displayPractitionerPractice ? '' : 'hide'}
            >
              {this.returnRow(
                'ID',
                'practiceId',
                this.state.consultation.practiceId,
              )}
              {this.returnRow('Name', 'practiceName', this.state.practiceName)}
            </div>
          </div>

          <div className="toggle">
            <h4
              className="toggleContainer"
              onClick={this.toggle.bind(this, 'displayPatient')}
            >
              <span className="containerArrow">
                {this.state.displayPatient ? '▼' : '▶'}
              </span>{' '}
              Patient
            </h4>
            <div className={this.state.displayPatient ? '' : 'hide'}>
              {this.returnRow(
                'ID',
                'patientId',
                this.state.consultation.patientId,
              )}
              {this.returnRow('Name', 'patientName', this.state.patientName)}
            </div>
          </div>

          <div className="toggle" id="billing">
            <h4
              className="toggleContainer"
              onClick={this.toggle.bind(this, 'displayPricingAndPayments')}
            >
              <span className="containerArrow">
                {this.state.displayPricingAndPayments ? '▼' : '▶'}
              </span>{' '}
              Billing
            </h4>
            <div className={this.state.displayPricingAndPayments ? '' : 'hide'}>
              <h5>Total billed:</h5>
              {this.state.consultation.payment ?
                `${this.state.consultation.payment.price / 100} ${this.state.consultation.payment.currency}` : '0' }
              <h5>Payments Info</h5>
              {paymentInfo}
            </div>
          </div>

          <div className="toggle">
            <h4
              className="toggleContainer"
              onClick={this.toggle.bind(this, 'displayReviews')}
            >
              <span className="containerArrow">
                {this.state.displayReviews ? '▼' : '▶'}
              </span>{' '}
              Reviews
            </h4>
            <div className={this.state.displayReviews ? '' : 'hide'}>
              {reviews}
            </div>
          </div>

          <div className="toggle" id="stats">
            <h4
              className="toggleContainer"
              onClick={this.toggle.bind(this, 'displayStats')}
            >
              <span className="containerArrow">
                {this.state.displayStats ? '▼' : '▶'}
              </span>{' '}
              Stats
            </h4>
            <div className={this.state.displayStats ? '' : 'hide'}>
              <h5>Messages</h5>
              {this.returnRow('Total', 'totalNbOfMessages', totalNbOfMessages)}
              {this.returnRow('Patient Sent', 'patientNbMessages', totalNbOfMessages - practitionerNbMessages)}
              {this.returnRow('Practitioner Sent', 'practitionerNbMessages', practitionerNbMessages)}
              <h5>Calls</h5>
              {this.returnRow('Total Duration (seconds)', 'callsTotalDuration', this.state.consultation.callsTotalDuration)}
              {callInfo}
            </div>
          </div>

          <div className="toggle" id="invoice">
            <h4
              className="toggleContainer"
              onClick={this.toggle.bind(this, 'displayInvoice')}
            >
              <span className="containerArrow">
                {this.state.displayInvoice ? '▼' : '▶'}
              </span>{' '}
              Invoice
            </h4>
            <div className={this.state.displayInvoice ? '' : 'hide'}>
              {this.returnRow('Invoice', 'invoice', this.state.consultation.charges.map(charge => charge.invoice).join(','))}
              <div className="form-fieldset">
                <label htmlFor="sendTo" className="form-label">
                  Send To:
                </label>
                <input id="sendTo" className="form-input"
                       value={this.state.sendTo}
                       onChange={e => this.setState({ sendTo: e.target.value })} />
                &nbsp;&nbsp;
                <button className="actionButton" onClick={this.sendReceipt}>Send</button>
              </div>
            </div>
          </div>

          <div className="form-options">
            <button className="form-button actionButton" onClick={this.cancel}>
              Close
            </button>
          </div>
        </form>
      </section>
    );
  }
}
