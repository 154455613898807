import { connect } from "react-redux";
import { setNotifyWarning } from "../../../components/notification/NotifyUtils";
import PracticeForm from "./PracticeForm";

function mapDispatchToProps(dispatch) {
  return {
    setNotifyWarning: title => {
      dispatch(setNotifyWarning(title));
    }
  };
}

export default connect(
  null,
  mapDispatchToProps
)(PracticeForm);
