import { connect } from "react-redux";
import {
  setNotifySuccess,
  setNotifyWarning
} from "../../notification/NotifyUtils";
import CallDetail from "./CallDetail";

function mapDispatchToProps(dispatch) {
  return {
    setNotifySuccess: title => {
      dispatch(setNotifySuccess(title));
    },
    setNotifyWarning: title => {
      dispatch(setNotifyWarning(title));
    }
  };
}

export default connect(
  null,
  mapDispatchToProps
)(CallDetail);
