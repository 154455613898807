import SHA512 from 'crypto-js/sha512';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, TextField } from '@material-ui/core';
import CountrySelector from '../countrySelector/CountrySelector';
import LanguageSelector from '../countrySelector/LanguageSelector';
import SearchBox from '../google/searchBox';
import List from '../listManager/List';
import { setNotifyInfo, setNotifyWarning } from '../notification/NotifyUtils';
import PracticeSelector from '../practices/PracticeSelector';
import Amount from '../pricing/Amount';
import CurrencySelector from '../pricing/CurrencySelector';
import SubscriptionPlanSelector from '../pricing/SubscriptionPlanSelector';
import PricingModel from '../pricing/PricingModel';
import { getSpecialties } from '../specialties/SpecialtyUtils';
import {
  getPracticesByPractitionerID,
  getPractitionerByID,
  getSubscriptionStatus,
  cancelSubscription,
  getSubscriptionHistory,
} from './PractitionerUtils';
import { getApiUrl } from '../utils/NetworkUtils';
import * as helpers from './../../utils/helpers';
import TitleSelector from '../titleSelector/TitleSelector';
import './PractitionerForm.css';

import types from '../../utils/types';
import BiographyManager from './../biographyManager/BiographyManager';

class PractitionerForm extends React.Component {
  static propTypes = {
    router: PropTypes.object,
    cancelInvitePatients: PropTypes.func,
    invitePatients: PropTypes.func,
    invitePatientsSave: PropTypes.func,
    cancelUploadAttachments: PropTypes.func,
    uploadAttachmentsSave: PropTypes.func,
    onCancel: PropTypes.func,
    onDelete: PropTypes.func,
    onSave: PropTypes.func,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    practitioner: types.Practitioner,
    practitionerPractices: PropTypes.arrayOf(PropTypes.shape({
      canPrescribe: PropTypes.bool,
      creationDate: PropTypes.string,
      defaultPrice: PropTypes.shape({
        amount: PropTypes.number,
        currency: PropTypes.string,
        dollars: PropTypes.number,
        type: PropTypes.string,
      }),
      location: PropTypes.shape({
        city: PropTypes.string,
        country: PropTypes.string,
        name: PropTypes.string,
        postalCode: PropTypes.string,
        state: PropTypes.string,
        street: PropTypes.string,
        streetNumber: PropTypes.string,
      }),
      managerId: PropTypes.string,
      managerReceivesMoney: PropTypes.bool,
      name: PropTypes.string,
      noPrFallback: PropTypes.bool,
      uid: PropTypes.string,
    })),
    title: PropTypes.string,
    setNotifyInfo: PropTypes.func,
    setNotifyWarning: PropTypes.func,
  };

  getDefaultPractitioner = () => ({
    uid: '',
    email: '',
    phone: '',
    score: '',
    demo: false,
    location: {
      name: '',
      streetNumber: '',
      street: '',
      additionalInfos: '',
      country: '',
      state: '',
      city: '',
      postalCode: '',
    },
    firstname: '',
    lastname: '',
    specialties: [],
    biography: {
      summary: '',
      baseline: '',
      education: [],
      experiences: [],
      welcomeMessage: '',
    },
    photo: '',
    available: true,
    verifiedPractitioner: false,
    inviteCode: '',
    deaNumber: '',
    npiNumber: '',
    birthdate: '',
    incentiveAmount: '',
    creditPerPatient: '',
    pricingModel: {},
    currency: 'USD',
    language: 'en',
    registrationId: '',
    practiceNumber: '',
    vetLicenseNumber: '',
    vetLicenseState: '',
  });

  state = {
    specialtySelectedValue: '0',
    practiceSelectedValue: { name: ' ' },
    educationValue: '',
    experienceValue: '',
    practitionerImage: {
      file: undefined,
      uploaded: false,
      image: '',
    },
    password: '',
    // address : "",
    practitioner: this.getDefaultPractitioner(),
    subscription: { plan: 'basic' },
    subscriptionHistory: [],
    specialties: [],
    practitionerPractices: [],
    forceUpdate: false,
    isCancelSubscription: false,
    errors: {
      firstname: false,
      lastname: false,
      email: false,
    }
  };

  pricingModelChanged = false;

  get specialityValues() {
    // specialties are guids, need to get names
    if (!this.state.specialties.length) return [];

    return this.state.practitioner.specialties.map(uid => ({
      id: uid,
      title: this.state.specialties.find(curSpecialty => curSpecialty.uid === uid).name,
    }));
  }

  customValidation = () => {
    let valid = true;
    let msg = '';
    let firstname = false;
    let lastname = false;
    let email = false;

    if (!this.state.practitioner.firstname) {
      firstname = true;
      valid = false;
    }
    if (!this.state.practitioner.lastname) {
      lastname = true;
      valid = false;
    }
    if (!this.state.practitioner.email) {
      email = true;
      valid = false;
    }
    if (!helpers.validateEmail(this.state.practitioner.email)) {
      email = true;
      valid = false;
    }

    if (this.state.practitioner.specialties.length === 0) {
      valid = false;
      msg = 'Please select at least one specialty';
    }

    if (!this.state.practitioner.uid) {
      if (
        !this.state.practitioner.location.street ||
        !this.state.practitioner.location.streetNumber ||
        !this.state.practitioner.location.city ||
        !this.state.practitioner.location.state ||
        !this.state.practitioner.location.postalCode
      ) {
        valid = false;
        msg = 'Please enter a valid address';
      }
    }

    if (msg) {
      this.props.setNotifyWarning(msg);
    } else if (!valid) {
      this.props.setNotifyWarning('Form has error. Please check all fields');
    }
    if (!valid) {
      this.setState({
        errors: {
          firstname,
          lastname,
          email,
        }
      });
    }

    return valid;
  };

  save = async (e) => {
    e.preventDefault();

    if (this.customValidation()) {
      if (this.state.isCancelSubscription && this.state.subscription.status !== 'canceled') {
        try {
          await cancelSubscription(this.state.practitioner.uid);
        } catch (error) {
          console.error(error);
          this.props.setNotifyWarning('Error canceling subscription');
          return;
        }
      }
      const practitioner = Object.assign({}, this.state.practitioner);
      delete practitioner.stats;
      delete practitioner.prettyName;
      if (!this.pricingModelChanged) {
        delete practitioner.pricingModel;
      }

      if (!this.state.titleChanged) {
        delete practitioner.title;
      }

      if (this.specialityValues.some(item => item.title === 'Veterinarian')) {
        if (practitioner.vetLicenseState && practitioner.vetLicenseNumber) {
          practitioner.stateTaxonomyLicense = `${practitioner.vetLicenseState.toUpperCase()}:174M:${practitioner.vetLicenseNumber}`;
        } else {
          practitioner.stateTaxonomyLicense = ""
        }
      } else {
        delete practitioner.stateTaxonomyLicense;
      }
      delete practitioner.vetLicenseNumber;
      delete practitioner.vetLicenseState;

      delete practitioner.incentiveAmount;
      delete practitioner.creditPerPatient;

      const data = {
        practitioner: Object.assign({}, practitioner),
        practitionerImage: Object.assign({}, this.state.practitionerImage, {
          uid: this.state.practitioner.uid,
        }),
        practitionerPractices: this.state.practitionerPractices.map(_practice => _practice.uid),
      };

      if (this.state.password !== '') {
        data.practitioner.password = SHA512(this.state.password).toString();
      }

      this.props.onSave(data, this.state.forceUpdate);
    }
  };

  cancel = (e) => {
    e.preventDefault();
    this.props.onCancel();
  };

  deleteItem = (e) => {
    e.preventDefault();

    if (confirm('Delete Doctor?')) {
      this.props.onDelete(this.state.practitioner);
    }
  };

  onChange(propName, event) {
    this.setState({
      [propName]: event.target.value,
    });
  }

  onForceUpdateChange = (e) => {
    this.onChange('forceUpdate', { target: { value: e.target.checked } });
  };

  onPractitionerChange(propName, event) {
    const value = event.target.value;

    const p = this.state.practitioner;
    p[propName] = value;
    if (this.state.errors[propName]) {
      this.setState({
        practitioner: p,
        errors: Object.assign(this.state.errors, { [propName]: false }),
      })
    } else {
      this.setState({ practitioner: p });
    }
  }

  onDemoChange = (e) => {
    this.onPractitionerChange('demo', { target: { value: e.target.checked } });
  };

  onCanPrescribeChange = (e) => {
    this.onPractitionerChange('canPrescribe', {
      target: { value: e.target.checked },
    });
  };

  onCreditChange(propName, event) {
    const p = this.state.practitioner;
    p[propName] = event.target.value * 100;
    this.setState({ practitioner: p });
  }

  onLocationChange(propName, event) {
    const p = Object.assign({}, this.state.practitioner);
    p.location[propName] = event.target.value;
    this.setState({ practitioner: p });
  }

  onBiographyChange(propName, event) {
    const p = Object.assign({}, this.state.practitioner);
    p.biography[propName] = event.target.value;
    this.setState({ practitioner: p });
  }

  setPractitionerPricingModel = (pricingModel) => {
    this.setState({
      practitioner: Object.assign({}, this.state.practitioner, {
        pricingModel,
      }),
    });
    this.pricingModelChanged = true;
  };

  changeIncentivePerPatient = (amount) => {
    this.setState({
      practitioner: Object.assign({}, this.state.practitioner, {
        incentivePerPatient: {
          amount,
          currency: this.state.practitioner.currency,
        },
      }),
    });
  };

  changeIncentivePool = (amount) => {
    this.setState({
      practitioner: Object.assign({}, this.state.practitioner, {
        incentivePool: { amount, currency: this.state.practitioner.currency },
      }),
    });
  };

  clickHandlerAvailable = (e) => {
    this.onPractitionerChange('available', {
      target: { value: e.target.checked },
    });
  };

  clickHandlerVerified = (e) => {
    this.onPractitionerChange('verifiedPractitioner', {
      target: { value: e.target.checked },
    });
  };

  onSpecialtyChange(event) {
    this.setState({
      specialtySelectedValue: event.target.value,
    });
  }
  onSpecialtyDelete = (listItem) => {
    let uid = null;

    this.state.specialties.forEach((_specialty) => {
      if (_specialty.uid === listItem.id && !_specialty.targetSpecialtyId) {
        uid = _specialty.uid;
      }
    });

    const vs = this.state.practitioner.specialties.slice();
    const i = vs.indexOf(uid);
    if (i !== -1) {
      vs.splice(i, 1);
    }

    this.onPractitionerChange('specialties', { target: { value: vs } });
  };

  onSpecialtyAdd = () => {
    if (this.state.specialtySelectedValue) {
      const specs = this.state.practitioner.specialties;

      // convert name to uid and update state
      this.state.specialties.forEach((_specialty) => {
        if (
          _specialty.name === this.state.specialtySelectedValue &&
          !_specialty.targetSpecialtyId
        ) {
          specs.push(_specialty.uid);
        }
      });

      this.onPractitionerChange('specialties', { target: { value: specs } });
      this.setState({
        specialtySelectedValue: '0',
      });
    }
  };

  onPracticeAutoCompleteSelect = (e) => {
    this.setState({
      practiceSelectedValue: e,
    });
  };

  onPracticeDelete = (listItem) => {
    const prPractices = this.state.practitionerPractices;
    prPractices.forEach((_practice, index) => {
      if (listItem.id === _practice.name) {
        prPractices.splice(index, 1);
      }
    });

    this.setState({ practitionerPractices: prPractices });
  };

  onPracticeAdd = () => {
    if (
      this.state.practiceSelectedValue.name &&
      this.state.practiceSelectedValue.name !== ' '
    ) {
      const prPractices = this.state.practitionerPractices;
      prPractices.push(this.state.practiceSelectedValue);

      this.setState({
        practitionerPractices: prPractices,
        practiceSelectedValue: { name: ' ' },
      });
    }
  };

  onDefaultCurrencyChange = (event) => {
    this.setState({
      practitioner: Object.assign({}, this.state.practitioner, {
        currency: event.target.value,
      }),
    });
  };

  onSubscriptionPlanChange = (event) => {
    this.setState({
      subscription: Object.assign({}, this.state.subscription, {
        plan: event.target.value,
      }),
    });
  };

  onToggleCancelSubscription = () => {
    this.setState({
      isCancelSubscription: !this.state.isCancelSubscription,
    });
  };

  onEducationChange(event) {
    this.setState({
      educationValue: event.target.value,
    });
  }

  onEducationDelete = (index) => {
    const value = [...this.state.practitioner.biography.education];
    value.splice(index, 1);
    this.onBiographyChange('education', { target: { value } });
  };

  onEducationAdd = (education) => {
    const value = [...this.state.practitioner.biography.education, education];
    this.onBiographyChange('education', { target: { value } });
  };

  onEducationEdit = (education) => {
    const value = [...this.state.practitioner.biography.education];
    value[education.index] = education;
    this.onBiographyChange('education', { target: { value } });
  };

  onExperiencesChange(event) {
    this.setState({
      experienceValue: event.target.value,
    });
  }

  onExperiencesDelete = (index) => {
    const value = [...this.state.practitioner.biography.experiences];
    value.splice(index, 1);
    this.onBiographyChange('experiences', { target: { value } });
  };

  onExperiencesAdd = (experience) => {
    const value = [...this.state.practitioner.biography.experiences, experience];
    this.onBiographyChange('experiences', { target: { value } });
  };

  onExperiencesEdit = (experience) => {
    const value = [...this.state.practitioner.biography.experiences];
    value[experience.index] = experience;
    this.onBiographyChange('experiences', { target: { value } });
  };

  trim = value => value.trim();

  profilePictureHandler = (e) => {
    e.preventDefault();

    const _practitionerImage = Object.assign({}, this.state.practitionerImage);
    _practitionerImage.file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = (ev) => {
      _practitionerImage.image = ev.target.result;
      _practitionerImage.uploaded = true;
      this.setState({
        practitionerImage: _practitionerImage,
      });
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  addressSelected = (location) => {
    this.onPractitionerChange('location', {
      target: {
        value: Object.assign(this.state.practitioner.location, location),
      },
    });
    this.setState({ address: this.buildAddressString(location) });
  };

  onChangeTitle = (title) => {
    this.setState({
      practitioner: Object.assign({}, this.state.practitioner, { title }),
      titleChanged: true,
    });
  };

  onChangeCountry = (country) => {
    this.setState({
      practitioner: Object.assign({}, this.state.practitioner, { country }),
    });
  };

  onChangeLanguage = (language) => {
    this.setState({
      patient: Object.assign({}, this.state.practitioner, { language }),
    });
  };

  buildAddressString(location) { // eslint-disable-line
    let address = '';
    if (location !== undefined) {
      const {
        streetNumber = '',
        street = '',
        city = '',
        state = '',
        postalCode = '',
      } = location;

      address = `${streetNumber} ${street} ${city} ${state} ${postalCode}`.trim();
    }
    return address;
  }

  syncSubscription = (e) => {
    e.preventDefault();
    getSubscriptionStatus(this.state.practitioner.uid, true)
      .then((subscription) => {
        this.setState({ subscription });
        this.props.setNotifyInfo('Subscription synchronized');
      })
      .catch((error) => {
        console.error(error);
        this.props.setNotifyWarning('Error synchronizing subscription');
      });
  };

  componentWillMount() {
    const { id } = this.props.params;
    if (id === 'create') {
      getSpecialties()
        .then((specialties) => {
          this.setState({ specialties });
        })
        .catch((e) => {
          console.error(e);
          this.props.setNotifyWarning(e.message);
          this.props.router.replace('/practitioners-old');
        });
    } else {
      Promise.all([
        getPracticesByPractitionerID(id),
        getPractitionerByID(id),
        getSubscriptionStatus(id),
        getSubscriptionHistory(id),
        getSpecialties(),
      ])
        .then((response) => {
          const address = this.buildAddressString(response[1].location);
          const biography = {
            education: response[1].biography.education || [],
            experiences: response[1].biography.experiences || [],
          };
          const stateTaxonomyLicense = {
            vetLicenseState: '',
            vetLicenseNumber: '',
          };
          if (response[1].stateTaxonomyLicense) {
            stateTaxonomyLicense.vetLicenseState = response[1].stateTaxonomyLicense.split(':')[0];
            stateTaxonomyLicense.vetLicenseNumber = response[1].stateTaxonomyLicense.split(':')[2];
          }
          this.setState({
            address,
            practitioner: Object.assign(
              this.state.practitioner,
              response[1],
              { biography },
              stateTaxonomyLicense,
            ),
            practitionerPractices: response[0],
            subscription: response[2],
            specialties: response[4],
            isCancelSubscription: response[2].status === 'canceled',
            subscriptionHistory: response[3].expiredSubscriptions,
          });
        })
        .catch((e) => {
          console.error(e);
          this.props.setNotifyWarning(e.message);
          this.props.router.replace('/practitioners-old');
        });
    }
  }

  render() {
    const rand = new Date().getTime();

    const specialtyValues = this.specialityValues;

    const specialtyControl = (
      <select
        className="form-select"
        value={this.state.specialtySelectedValue}
        onChange={this.onSpecialtyChange.bind(this)}
      >
        <option key="0" value="0" disabled>
          Select a Specialty
        </option>
        {this.state.specialties.map((_specialty, index) => (
          <option key={index + 1} value={_specialty.name}>
            {_specialty.name}
          </option>
        ))}
      </select>
    );

    const practiceValues = [];
    this.state.practitionerPractices.forEach((value) => {
      practiceValues.push(value.name);
    });
    const practiceControl = (
      <PracticeSelector
        onSelect={this.onPracticeAutoCompleteSelect}
        defaultValue={this.state.practiceSelectedValue.name}
        className="form-input"
      />
    );

    if (
      this.state.practitioner.uid &&
      this.state.practitionerImage.image === ''
    ) {
      this.state.practitionerImage.image = `${getApiUrl()}/practitioners/${this.state.practitioner.uid}/picture?w=200&i=${rand}`; // eslint-disable-line
    }

    let practitionerFormOptions = null;
    if (this.state.practitioner.uid) {
      practitionerFormOptions = (
        <div className="form-options">
          <Button variant='contained'
                  color='primary'
                  onClick={() => this.props.router.push(`/practitioners-old/invite/${this.state.practitioner.uid}`)}>
            Invite Patients
          </Button>
          <Button variant='contained'
                  color='primary'
                  onClick={() => this.props.router.push(`/practitioners-old/upload/${this.state.practitioner.uid}`)}>
            Upload Document
          </Button>
        </div>
      );
    }

    const pricingModel = this.state.practitioner.pricingModel;

    return (
      <form id="formPractitioner" className="form" noValidate>
        <h2>{this.state.practitioner.uid ? 'Edit' : 'Create'}</h2>
        <div className="form-freeFieldset">
          <label htmlFor="photo" className="form-label">
            Photo
          </label>
          <div className="form-freeContainer">
            <img
              className={
                this.state.practitionerImage.image
                  ? 'form-profileImage'
                  : 'hide'
              }
              src={this.state.practitionerImage.image}
            />
            <br />
            <input
              type="file"
              name="file"
              accept=".jpg,.jpeg,.png,.gif"
              onChange={this.profilePictureHandler}
            />
          </div>
        </div>
        <div className="form-fieldset">
          <label htmlFor="country" className="form-label">
            Country
          </label>
          <CountrySelector
            country={this.state.practitioner.country}
            onCountryChanged={this.onChangeCountry}
            id="country"
          />
        </div>
        <div className="form-fieldset">
          <label htmlFor="language" className="form-label">
            Language
          </label>
          <LanguageSelector
            language={this.state.practitioner.language}
            onLanguageChanged={this.onChangeLanguage}
            id="language"
          />
        </div>
        <div className="form-fieldset">
          <label htmlFor="title" className="form-label">
            Title
          </label>
          <TitleSelector
            title={this.state.practitioner.title}
            onTitleChanged={this.onChangeTitle}
            id="title"
          />
        </div>
        <div className="form-fieldset">
          <label htmlFor="firstName" className="form-label">
            First Name *
          </label>
          <TextField
            id="firstName"
            value={this.state.practitioner.firstname}
            onChange={this.onPractitionerChange.bind(this, 'firstname')}
            error={this.state.errors.firstname}
            helperText={this.state.errors.firstname ? 'Please fill out this field' : ''}
            required
            fullWidth
          />
        </div>
        <div className="form-fieldset">
          <label htmlFor="lastName" className="form-label">
            Last Name *
          </label>
          <TextField
            id="lastName"
            value={this.state.practitioner.lastname}
            onChange={this.onPractitionerChange.bind(this, 'lastname')}
            error={this.state.errors.lastname}
            helperText={this.state.errors.lastname ? 'Please fill out this field' : ''}
            required
            fullWidth
          />
        </div>
        <div className="form-fieldset">
          <label htmlFor="mediciId" className="form-label">
            Medici ID
          </label>
          <span>{this.state.practitioner.uid}</span>
        </div>
        <div className="form-fieldset">
          <label htmlFor="birthdate" className="form-label">
            Date of Birth
          </label>
          <input
            type="date"
            id="birthdate"
            className="form-input"
            value={this.state.practitioner.birthdate}
            onChange={this.onPractitionerChange.bind(this, 'birthdate')}
          />
        </div>
        <div className="form-fieldset">
          <label htmlFor="phone" className="form-label">
            Phone
          </label>
          <input
            type="tel"
            id="phone"
            className="form-input"
            value={this.state.practitioner.phone}
            onChange={this.onPractitionerChange.bind(this, 'phone')}
          />
        </div>
        <div className="form-fieldset">
          <label htmlFor="email" className="form-label">
            Email *
          </label>
          <TextField
            id="email"
            type="email"
            placeholder="abc@xzy.com"
            value={this.state.practitioner.email}
            onChange={this.onPractitionerChange.bind(this, 'email')}
            error={this.state.errors.email}
            helperText={this.state.errors.email ? 'Please enter a valid email address' : ''}
            required
            fullWidth
          />
        </div>
        <div className="form-fieldset">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            id="password"
            className="form-input"
            placeholder="Only change to reset password"
            value={this.state.password}
            onChange={this.onChange.bind(this, 'password')}
          />
        </div>
        <div className="form-fieldset">
          <label htmlFor="inviteCode" className="form-label">
            Invite Code
          </label>
          <input
            id="inviteCode"
            className="form-input"
            value={this.state.practitioner.inviteCode}
            onChange={this.onPractitionerChange.bind(this, 'inviteCode')}
          />
        </div>
        { this.state.practitioner.country === 'us' ? (
          <div className="form-fieldset">
            <label htmlFor="NPI" className="form-label">
              National Provider Identifier
            </label>
            <input
              id="NPI"
              className="form-input"
              value={this.state.practitioner.npiNumber}
              onChange={this.onPractitionerChange.bind(this, 'npiNumber')}
            />
          </div>
        ) : null }
        { this.state.practitioner.country === 'us' ? (
          <div className="form-fieldset">
            <label htmlFor="DEA" className="form-label">
              DEA number
            </label>
            <input
              id="DEA"
              className="form-input"
              value={this.state.practitioner.deaNumber}
              onChange={this.onPractitionerChange.bind(this, 'deaNumber')}
            />
          </div>
        ) : null }
        { specialtyValues.some(item => item.title === 'Veterinarian') ? ([
          <div className="form-fieldset" key='field_1'>
            <label htmlFor="vetLicenseNumber" className="form-label">
              Vet License Number
            </label>
            <input
              id="vetLicenseNumber"
              className="form-input"
              placeholder="ex. 1605 or VET.11241 or 1027-A"
              value={this.state.practitioner.vetLicenseNumber}
              onChange={this.onPractitionerChange.bind(this, 'vetLicenseNumber')}
            />
          </div>,
          <div className="form-fieldset" key='field_2'>
            <label htmlFor="vetLicenseState" className="form-label">
              Licensing State
            </label>
            <input
              id="vetLicenseState"
              className="form-input"
              placeholder="CA or NY"
              value={this.state.practitioner.vetLicenseState}
              onChange={this.onPractitionerChange.bind(this, 'vetLicenseState')}
            />
          </div>,
        ]) : null}
        { this.state.practitioner.country === 'za' ? (
          <div className="form-fieldset">
            <label htmlFor="registrationId" className="form-label">
              Registration ID
            </label>
            <input
              id="registrationId"
              className="form-input"
              value={this.state.practitioner.registrationId}
              onChange={this.onPractitionerChange.bind(this, 'registrationId')}
            />
          </div>
        ) : null }
        { this.state.practitioner.country === 'za' ? (
          <div className="form-fieldset">
            <label htmlFor="practiceNumber" className="form-label">
              Practice Number
            </label>
            <input
              id="practiceNumber"
              className="form-input"
              value={this.state.practitioner.practiceNumber}
              onChange={this.onPractitionerChange.bind(this, 'practiceNumber')}
            />
          </div>
          ) : null }
        <div className="form-fieldset">
          <label htmlFor="available" className="form-label">
            Available
          </label>
          <div className="form-container isAvailable">
            <input
              type="checkbox"
              checked={this.state.practitioner.available}
              onChange={this.clickHandlerAvailable}
            />
            <label htmlFor="available"> Is Available</label>
          </div>
        </div>
        <div className="form-fieldset">
          <label htmlFor="verifiedPractitioner" className="form-label">
            Verified
          </label>
          <div className="form-container isAvailable">
            <input
              type="checkbox"
              checked={this.state.practitioner.verifiedPractitioner}
              onChange={this.clickHandlerVerified}
            />
            <label htmlFor="verifiedPractitioner"> Is Verified</label>
          </div>
        </div>
        <div className="form-fieldset">
          <label htmlFor="demo" className="form-label">
            Demo
          </label>
          <div className="form-container isDemo">
            <input
              type="checkbox"
              checked={this.state.practitioner.demo}
              onChange={this.onDemoChange}
            />
            <label htmlFor="demo"> Is Demo/Sales account</label>
          </div>
        </div>

        <div className="form-fieldset">
          <label htmlFor="canPrescribe" className="form-label">
            e-Prescribe
          </label>
          <div className="form-container isDemo">
            <input
              type="checkbox"
              checked={this.state.practitioner.canPrescribe}
              onChange={this.onCanPrescribeChange}
            />
            <label htmlFor="canPrescribe"> Can Prescribe</label>
          </div>
        </div>

        { this.state.practitioner.country === 'us' ? (
          <div className="form-fieldset">
            <label htmlFor="dosespotStatus" className="form-label">
              DoseSpot Status
            </label>
            <div className="form-container">
              {this.state.practitioner.dosespotStatus === 0 ? 'Has not submitted credentials' : null}
              {this.state.practitioner.dosespotStatus === 1 ? 'Pending Action from DoseSpot' : null}
              {this.state.practitioner.dosespotStatus === 2 ? 'Ready to e-Prescribe' : null}
            </div>
          </div>
        ) : null }

        <div className="form-freeFieldset">
          <label htmlFor="specialty" className="form-label">
            Specialty *
          </label>
          <div className="form-freeContainer">
            <List
              values={specialtyValues}
              formControl={specialtyControl}
              onAdd={this.onSpecialtyAdd}
              onDelete={this.onSpecialtyDelete}
            />
          </div>
        </div>
        <div className="form-freeFieldset">
          <label htmlFor="practices" className="form-label">
            Practices
          </label>
          <div className="form-freeContainer">
            <List
              values={practiceValues}
              formControl={practiceControl}
              onAdd={this.onPracticeAdd}
              onDelete={this.onPracticeDelete}
            />
          </div>
        </div>

        <h3>Subscription</h3>
        <div className="form-fieldset">
          <label htmlFor="subscriptionPlan" className="form-label">
            Subscription plan
          </label>
          <SubscriptionPlanSelector
            disabled
            plan={this.state.subscription.plan}
            onPlanChange={this.onSubscriptionPlanChange}
          />
        </div>
        {this.state.subscription.plan === 'premium' ? [
          <div className="form-fieldset" key="0">
            <label className="form-label">
              Activated at
            </label>
            <div className="form-container">
              <span>{moment(this.state.subscription.activatedAt).format('YYYY-MM-DD [@] h:mm a')}</span>
            </div>
          </div>,
          <div className="form-fieldset" key="1">
            <label className="form-label">
              Period end date
            </label>
            <div className="form-container">
              <span>{moment(this.state.subscription.periodEndsAt).format('YYYY-MM-DD [@] h:mm a')}</span>
            </div>
          </div>,
          <div className="form-fieldset" key="2">
            <label className="form-label">
              Status
            </label>
            <div className="form-container">
              <span>{this.state.subscription.subscriptionCancelRequested ? 'Cancellation Pending' : this.state.subscription.status}</span>
            </div>
          </div>,
          <div className="form-fieldset" key="3">
            <label htmlFor="cancel-subscription" className="form-label">
              Cancel
            </label>
            <div className="form-container">
              <input
                type="checkbox"
                id="cancel-subscription"
                checked={this.state.isCancelSubscription}
                onChange={this.onToggleCancelSubscription}
                disabled={this.state.subscription.status === 'canceled'}
              />
            </div>
          </div>,
        ] : null}

        {this.state.subscriptionHistory.length ?
          this.state.subscriptionHistory.map(((item, index) => (
            <div className="form-componentContainer" key={index}>
              <h5>Previous subscription #{index + 1}</h5>
              <div className="form-fieldset">
                <label className="form-label">
                  Activated at
                </label>
                <div className="form-container">
                  <span>{moment(item.activatedAt).format('YYYY-MM-DD [@] h:mm a')}</span>
                </div>
              </div>
              <div className="form-fieldset">
                <label className="form-label">
                  Expired at
                </label>
                <div className="form-container">
                  <span>{moment(item.expiredAt).format('YYYY-MM-DD [@] h:mm a')}</span>
                </div>
              </div>
            </div>
          ))) : <p>Subscription history is empty</p> }

        { this.state.practitioner.uid ? (
          <div className="form-options">
            <button className="form-button actionButton" onClick={this.syncSubscription}>Sync subscription</button>
          </div>) : null }

        <h3> Pricing Information</h3>
        <div className="form-fieldset">
          <label htmlFor="lastName" className="form-label">
            Default currency *
          </label>
          <CurrencySelector
            currency={this.state.practitioner.currency}
            onCurrencyChange={this.onDefaultCurrencyChange}
          />
        </div>
        <div className="form-fieldset">
          <Amount
            label="Incentive Amount"
            amount={
              this.state.practitioner.incentivePool
                ? this.state.practitioner.incentivePool.amount
                : 0
            }
            currency={
              this.state.practitioner.incentivePool
                ? this.state.practitioner.incentivePool.currency
                : this.state.practitioner.currency
            }
            defaultCurrency={this.state.practitioner.currency}
            lockCurrency
            onAmountChange={this.changeIncentivePool}
          />
        </div>
        <div className="form-fieldset">
          <Amount
            label="Credit Per Patient"
            amount={
              this.state.practitioner.incentivePerPatient
                ? this.state.practitioner.incentivePerPatient.amount
                : 0
            }
            currency={
              this.state.practitioner.incentivePerPatient
                ? this.state.practitioner.incentivePerPatient.currency
                : this.state.practitioner.currency
            }
            defaultCurrency={this.state.practitioner.currency}
            lockCurrency
            onAmountChange={this.changeIncentivePerPatient}
          />
        </div>
        <div>Payment Model</div>
        <PricingModel
          pricingModel={pricingModel}
          onPricingModelChange={this.setPractitionerPricingModel}
          lockCurrency
          defaultCurrency={this.state.practitioner.currency}
        />

        <h3>Location Information</h3>
        <div className="form-fieldset">
          <label htmlFor="location_Address" className="form-label">
            {this.state.practitioner.uid ? 'Address' : 'Address *'}
          </label>
          <SearchBox
            className="form-input"
            required={!this.state.practitioner.uid}
            address={this.state.address}
            onSelect={this.addressSelected}
          />
        </div>
        <div className="form-fieldset">
          <label htmlFor="location_AdditionalInfos" className="form-label">
            Additional Info
          </label>
          <input
            id="location_AdditionalInfos"
            className="form-input"
            value={this.state.practitioner.location.additionalInfos}
            onChange={this.onLocationChange.bind(this, 'additionalInfos')}
          />
        </div>

        <h3>Biography Information</h3>
        <div className="form-freeFieldset">
          <label htmlFor="biography_Summary" className="form-label">
            Summary
          </label>
          <textarea
            id="biography_Summary"
            className="biography_Summary form-textArea"
            value={this.state.practitioner.biography.summary}
            onChange={this.onBiographyChange.bind(this, 'summary')}
          />
        </div>
        <div className="form-fieldset">
          <label htmlFor="biography_Baseline" className="form-label">
            Baseline
          </label>
          <input
            id="biography_Baseline"
            className="form-input"
            value={this.state.practitioner.biography.baseline}
            onChange={this.onBiographyChange.bind(this, 'baseline')}
          />
        </div>
        <div className="form-freeFieldset">
          <label htmlFor="education" className="form-label">
            Education
          </label>
          <div className="form-freeContainer">
            <BiographyManager
              entries={this.state.practitioner.biography.education}
              onAdd={this.onEducationAdd}
              onEdit={this.onEducationEdit}
              onDelete={this.onEducationDelete}
            />
          </div>
        </div>
        <div className="form-freeFieldset">
          <label htmlFor="experiences" className="form-label">
            Experiences
          </label>
          <div className="form-freeContainer">
            <BiographyManager
              entries={this.state.practitioner.biography.experiences}
              onAdd={this.onExperiencesAdd}
              onEdit={this.onExperiencesEdit}
              onDelete={this.onExperiencesDelete}
              bioType="experience"
            />
          </div>
        </div>
        <div className="form-freeFieldset">
          <label htmlFor="biography_welcomeMessage" className="form-label">
            Welcome Message
          </label>
          <textarea
            id="biography_welcomeMessage"
            className="biography_welcomeMessage form-textArea"
            value={this.state.practitioner.biography.welcomeMessage}
            onChange={this.onBiographyChange.bind(this, 'welcomeMessage')}
          />
        </div>

        <div className="form-fieldset">
          <label htmlFor="forceUpdate" className="form-label">
            Force Update
          </label>
          <div className="form-container checkbox">
            <input
              type="checkbox"
              id="forceUpdate"
              checked={this.state.forceUpdate}
              onChange={this.onForceUpdateChange}
            />
            <label htmlFor="forceUpdate">
              {' '}
              Ignore errors returned from API
            </label>
          </div>
        </div>

        <div className="form-options">
          <Button variant='contained'
                  color='primary'
                  onClick={this.save}>Save</Button>
          {this.state.practitioner.uid ? (
            <Button variant='contained'
                    color='secondary'
                    onClick={this.deleteItem}>Delete</Button>
          ) : null }
          <Button variant='contained'
                  onClick={this.cancel}>Cancel</Button>
        </div>
        {practitionerFormOptions}
      </form>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setNotifyInfo: (title) => {
      dispatch(setNotifyInfo(title));
    },
    setNotifyWarning: (title) => {
      dispatch(setNotifyWarning(title));
    },
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(PractitionerForm);
