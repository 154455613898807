import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined';

const styles = ({ spacing, palette }) => ({
  accountWrap: {
    border: '1px solid #E5E5E5',
    backgroundColor: '#F6F6F6',
    boxShadow: '0 2px 7px 0 rgba(0,0,0,0.5)',
    margin: spacing.unit * 3,
    padding: spacing.unit * 3,
  },
  accountLabelField: {
    color: palette.text.secondary,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  accountTextField: {
    padding: `0 ${spacing.unit}px`,
    border: '1px solid #E7E7E7',
    borderRadius: '3px',
    background: '#FBFBFB!important',
    '&::after': {
      display: 'none',
    },
    '&::before': {
      display: 'none',
    },
    '&> div > div:focus': {
      background: '#FBFBFB',
    },
  },
  accountRow: {
    marginTop: spacing.unit * 3,
  },
  copyIcon: {
    padding: spacing.unit,
  },
  copyIconSvg: {
    fontSize: '16px',
  }
});

class PatientAccount extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    patient: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    onCopyInviteCode: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      status: props.patient.status,
      inviteCode: props.patient.inviteCode,
    };
  }

  onSave = () => {

  };

  render() {
    const { classes, onCopyInviteCode } = this.props;
    return (
      <Grid container className={classes.accountWrap} direction='column'>
        <Typography variant='h6'>Account</Typography>
        <Grid className={classes.accountRow} container direction='row'>
          <Typography>Status:&nbsp;</Typography>
          <Typography variant='subtitle2' style={{ textTransform: 'capitalize' }}>{this.state.status}</Typography>
        </Grid>
        <TextField
          className={classes.accountRow}
          label="Invite Code"
          value={this.state.inviteCode}
          InputLabelProps={{
            shrink: true,
            className: classes.accountLabelField
          }}
          InputProps={{
            className: classes.accountTextField,
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  className={classes.copyIcon}
                  aria-label="Copy Invite Code"
                  onClick={onCopyInviteCode}
                >
                  <FileCopyOutlined className={classes.copyIconSvg} />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Button color='primary' variant='contained' style={{ marginLeft: 'auto' }}
                className={classes.accountRow} onClick={this.onSave}>Save</Button>
      </Grid>
    )
  }
}

export default withStyles(styles)(PatientAccount);
