import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, InputAdornment, TextField, Typography, withStyles,
  Table, TableHead, TableBody, TableRow, TableCell, Button,
  IconButton, Menu, MenuItem,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import moment from 'moment';

import { shortUid } from '../../utils/helpers';
import UserAvatar from '../../components/user-avatar/UserAvatar';
import ContactsDialog from '../../components/ContactsDialog';

const styles = theme => ({
  title: {
    color: theme.palette.primary.light,
  },
  table: {

  }
});

class PatientProviders extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    providers: PropTypes.array.isRequired,
    uid: PropTypes.string.isRequired,
    router: PropTypes.object.isRequired,
    onProviderRemove: PropTypes.func.isRequired,
    onProviderUpdate: PropTypes.func.isRequired,
    setNotifySuccess: PropTypes.func.isRequired,
    setNotifyWarning: PropTypes.func.isRequired,
  };

  state = {
    search: '',
    isOpen: false,
    anchorEl: null,
    activeContact: null,
    openContactsDialog: false,
  };

  onSearch = (e) => {
    const { value } = e.target;

    this.setState({ search: value });
  };

  copyUid = async (uid) => {
    if ("clipboard" in window.navigator) {
      try {
        await window.navigator.clipboard.writeText(uid);
        this.props.setNotifySuccess("Copied!");
      } catch (e) {
        this.props.setNotifyWarning(e.message);
      }
    }
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      isOpen: false,
    });
  };

  handleMenu = (contact) => (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      isOpen: true,
      activeContact: contact,
    });
  };

  handleRemove = () => {
    this.handleClose();
    this.props.onProviderRemove(this.state.activeContact.id);
  };

  openContactsDialog = () =>
    this.setState({ openContactsDialog: true });

  handleCloseContactsDialog = (isHasChanges) => {
    if (isHasChanges) {
      this.props.onProviderUpdate();
    }
    this.setState({ openContactsDialog: false })
  };

  render() {
    const { classes, providers, router } = this.props;
    const { search, isOpen, anchorEl, openContactsDialog } = this.state;

    return (
      <Grid container direction='row'>
        <Grid container direction='row' justify='space-between' alignItems='center'>
          <Typography variant="h6" className={classes.title}>
            All Providers
          </Typography>
          <TextField
            style={{ width: '275px' }}
            label='Search Providers'
            value={search}
            onChange={this.onSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Last Interaction</TableCell>
              <TableCell>Specialty</TableCell>
              <TableCell>Medici ID</TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {providers
              .filter(contact => new RegExp(search.toLowerCase()).test(contact.prettyName.toLowerCase()))
              .map((contact, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Grid container alignItems='center'>
                      <UserAvatar uid={contact.userUid} photoVersion={contact.photoVersion} size={24}/>
                      <Typography style={{ marginLeft: 10 }} variant='subtitle2'>{contact.prettyName}</Typography>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    {contact.lastActivityDatetime ?
                      moment(contact.lastActivityDatetime).format('YYYY-MM-DD [@] h:mm a') : ''}
                  </TableCell>
                  <TableCell>{contact.specialtyName}</TableCell>
                  <TableCell
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.copyUid(contact.userUid)}
                  >{shortUid(contact.userUid)}</TableCell>
                  <TableCell numeric>
                    <Button color='secondary' onClick={() => router.push(`/practitioners/${contact.userUid}`)}>View</Button>
                    <IconButton aria-haspopup="true"
                                onClick={this.handleMenu(contact)}
                                color="inherit">
                      <MoreIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
        <div style={{ padding: '18px 0' }}>
          <Button variant='contained' color='primary' onClick={this.openContactsDialog}>
            Add Provider
          </Button>
        </div>
        <Menu
          id="menu-table"
          anchorReference='anchorPosition'
          anchorPosition={{
            top: anchorEl ? anchorEl.getBoundingClientRect().bottom - 18 : 0,
            left: anchorEl ? anchorEl.getBoundingClientRect().left : 0
          }}
          open={isOpen}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleRemove}>Remove</MenuItem>
        </Menu>
        <ContactsDialog
          uid={openContactsDialog && this.props.uid}
          handleClose={this.handleCloseContactsDialog}
          isPractitioner={false}
        />
      </Grid>
    )
  }
}

export default withStyles(styles)(PatientProviders);
