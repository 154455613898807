import moment from "moment";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { debounce } from "underscore";
import {
  withStyles,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Button,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TablePagination,
  TableSortLabel,
  Paper
} from '@material-ui/core';
import { FilterList } from "@material-ui/icons";

import { advancedSearchConsultations } from "../components/consultations/ConsultationUtils";
import { getSpecialties } from '../components/specialties/SpecialtyUtils';
import {
  setNotifySuccess,
  setNotifyHidden,
  setNotifyWarning
} from "../components/notification/NotifyUtils";
import ConsultationFilter from './../components/consultations/ConsultationFilter';
import Loading from "../components/Loading";

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 150
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  },
  actionsCell: {
    textAlign: "right"
  },
});

function createDefaultFilter() {
  return {
    deleted: false,
    order: {
      type: "dateTime",
      direction: "desc"
    }
  };
}

class ConsultationsPage extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    setNotifySuccess: PropTypes.func.isRequired,
    setNotifyHidden: PropTypes.func.isRequired,
    setNotifyWarning: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
    classes: PropTypes.object
  };

  state = {
    searchValue: "",
    searchResults: [],
    pagination: {
      total: 0,
      limit: 10,
      page: 0
    },
    searchHeaders: ["Start", "End", "Patient", "Practitioner"],
    consultation: {
      audioCalls: [],
      callsTotalDuration: 0,
      dateTime: "",
      dateTimeEnd: "",
      patientId: "",
      practitionerId: "",
      reviews: [],
      terminated: false,
      messages: [],
      totalNbOfMessages: 0,
      uid: "",
      charges: []
    },
    onCancel: () => {},
    isShowFilter: false,
    filter: createDefaultFilter(),
    isLoading: false,
    specialties: [],
  };

  lastSearchResultTs = null;

  debouncedSearch = debounce(searchData => {
    const currentSearchResultTs = Date.now();
    this.lastSearchResultTs = currentSearchResultTs;
    this.setState({ isLoading: true });
    advancedSearchConsultations(searchData)
      .then(response => {
        if (
          !this.lastSearchResultTs ||
          currentSearchResultTs === this.lastSearchResultTs
        ) {
          const { pagination } = this.state;
          pagination.total = response.pagination.total;
          this.setState({
            pagination,
            searchResults: this.mapResults(response.content),
            isLoading: false
          });
        }
      })
      .catch(() => {
        this.props.setNotifyWarning("Error loading search results");
        this.setState({ isLoading: false });
      });
  }, 100);

  onSearch = () => {
    const { filter, pagination } = this.state;

    const searchData = {
      size: pagination.limit,
      offset: pagination.limit * pagination.page
    };

    const orderBy = `${filter.order.type},${filter.order.direction}`;

    Object.keys(filter).forEach(key => {
      if (filter[key]) {
        searchData[key] = filter[key];
      }
    });

    delete searchData.order;
    searchData.orderBy = orderBy;

    this.debouncedSearch(searchData);
  };

  mapResults = results =>
    results.map(result => ({
      Start: moment(new Date(result.dateStart)).format("YYYY-MM-DD [@] h:mm a"),
      End: result.dateEnd
        ? moment(new Date(result.dateEnd)).format("YYYY-MM-DD [@] h:mm a")
        : "-",
      Patient: `${result.patientPrettyName}`,
      Practitioner: `${result.practitionerPrettyName}`,
      uid: result.uid
    }));

  onEdit = uid => {
    this.props.setNotifyHidden();

    this.props.router.push(`/consultations/${uid}`);
  };

  onCancel = () => {
    this.props.router.goBack();
  };

  handleChangePage = (e, page) => {
    const { pagination } = this.state;
    pagination.page = page;
    this.setState({ pagination }, this.onSearch);
  };

  handleChangeRowsPerPage = e => {
    const { pagination } = this.state;
    pagination.limit = e.target.value;
    this.setState({ pagination }, this.onSearch);
  };

  onClickFilter = () => {
    if (this.state.isShowFilter) {
      this.setState(
        {
          isShowFilter: false,
          filter: createDefaultFilter()
        },
        this.onSearch
      );
    } else {
      this.setState({ isShowFilter: true });
    }
  };

  handleChangeOrder = name => () => {
    const { order } = this.state.filter;

    if (order.type === name) {
      order.direction = order.direction === "desc" ? "asc" : "desc";
    } else {
      order.type = name;
      order.direction = "desc";
    }

    this.setState({
      filter: {
        ...this.state.filter,
        terminated: name === 'dateTimeEnd',
        order,
      }
    }, this.onSearch);
  };

  onUpdateFilter = filter => {
    this.setState({
      filter: { ...this.state.filter, ...filter },
    }, this.onSearch);
  };

  componentWillMount() {
    this.onSearch();
    getSpecialties()
      .then(specialties => {
        this.setState({ specialties });
      })
      .catch(e => this.props.setNotifyWarning(e.message));
  }

  render() {
    const { classes } = this.props;
    const { pagination, specialties } = this.state;

    return (
      <Grid container direction="column">
        <Typography variant="h5">Consultations</Typography>
          {!this.props.children && (
            <Grid container direction='row' justify='flex-end' style={{ margin: '24px 0' }}>
              <Tooltip title="Filter list">
                {this.state.isShowFilter ? (
                  <Button
                    variant='fab'
                    color='primary'
                    id="filter-button"
                    aria-label="Filter list"
                    onClick={this.onClickFilter}
                  >
                    <FilterList />
                  </Button>
                ): (
                  <IconButton
                    id="filter-button"
                    aria-label="Filter list"
                    onClick={this.onClickFilter}
                  >
                    <FilterList />
                  </IconButton>
                )}
              </Tooltip>
            </Grid>
          )}
        {!this.props.children && (
          <Grid container direction="column">
            {this.state.isShowFilter && (
            <Paper style={{ padding: '0 18px', marginBottom: 18, height: 388 }}>
              <ConsultationFilter
                onFilter={this.onUpdateFilter}
                specialties={specialties}
                terminated={this.state.filter.terminated} />
              </Paper>
            )}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={this.state.filter.order.type === "dateTime"}
                      direction={this.state.filter.order.direction}
                      onClick={this.handleChangeOrder("dateTime")}
                    >
                      Start
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={this.state.filter.order.type === "dateTimeEnd"}
                      direction={this.state.filter.order.direction}
                      onClick={this.handleChangeOrder("dateTimeEnd")}
                    >
                      End
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Patient</TableCell>
                  <TableCell>Doctor</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.isLoading && (
                  <TableRow>
                    <TableCell colSpan={5} style={{ height: "50vh" }}>
                      <Loading size={50} />
                    </TableCell>
                  </TableRow>
                )}
                {!this.state.isLoading &&
                  this.state.searchResults.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.Start}</TableCell>
                      <TableCell>{item.End}</TableCell>
                      <TableCell>{item.Patient}</TableCell>
                      <TableCell>{item.Practitioner}</TableCell>
                      <TableCell className={classes.actionsCell}>
                        <Button
                          color="secondary"
                          onClick={() => this.onEdit(item.uid)}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              count={pagination.total}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              page={pagination.page}
              rowsPerPage={pagination.limit}
              rowsPerPageOptions={[10, 25, 50, 100]}
            />
          </Grid>
        )}
        {this.props.children &&
          React.cloneElement(this.props.children, {
            onSave: this.onSave,
            onCancel: this.onCancel,
            onDelete: this.onDelete,
            setNotifySuccess: this.props.setNotifySuccess,
            setNotifyWarning: this.props.setNotifyWarning,
            router: this.props.router
          })}
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setNotifySuccess: title => {
      dispatch(setNotifySuccess(title));
    },
    setNotifyWarning: title => {
      dispatch(setNotifyWarning(title));
    },
    setNotifyHidden: () => {
      dispatch(setNotifyHidden());
    }
  };
}

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(withRouter(ConsultationsPage)));
