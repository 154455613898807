import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  withStyles,
  Button,
  TextField
} from '@material-ui/core';
import SHA512 from 'crypto-js/sha512';

const styles = theme => ({
  title: {
    color: theme.palette.primary.light,
  },
  line: {
    backgroundColor: theme.palette.primary.light,
    height: 2,
    width: "100%",
    marginBottom: theme.spacing.unit * 4,
  },
  labelField: {
    color: theme.palette.primary.light,
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  textField: {
    border: "1px solid #E7E7E7",
    borderRadius: "3px",
    backgroundColor: "#FBFBFB",
    padding: "6px 7px"
  },
  deleteContainer: {
    height: "173px",
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: "4px",
    padding: 24
  },
  deleteButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
  },
  deleteTitle: {
    color: theme.palette.common.black,
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5
  },
  deleteText: {
    color: "#6D7278",
    fontSize: 14,
    marginBottom: 20
  },
});

class PatientSettings extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    patient: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onLogoff: PropTypes.func.isRequired,
  };

  state = {
    demo: this.props.patient.demo,
    password: '',
  };

  handleChangeSwitch = name => event => {
    this.setState({
      [name]: event.target.checked
    })
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    })
  };

  changePassword = () => {
    this.props.onSave({
      password: SHA512(this.state.password).toString(),
    });
    this.setState({ password: '' });
  };

  saveSettings = () => {
    this.props.onSave({
      demo: this.state.demo,
    })
  };

  handleDelete = () => {
    this.props.onDelete();
  };

  handleLogoff = () => {
    this.props.onLogoff();
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container direction='row'>
        <Grid container direction='row' justify='space-between'>
          <Grid item xs={5}>
            <Typography variant='h6' className={classes.title}>Settings</Typography>
            <div className={classes.line} />
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.demo}
                    onChange={this.handleChangeSwitch('demo')}
                  />
                }
                label="Demo Account"
              />
            </div>
            <Grid direction='row' container justify='flex-end'>
              <Button
                variant="outlined"
                className={classes.deleteButton}
                disabled={this.state.loading}
                onClick={this.handleLogoff}
              >
                Force Logoff
              </Button>
              <Button variant='contained'
                      style={{ marginLeft: "16px" }}
                      color='primary'
                      onClick={this.saveSettings}
              >Save</Button>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Typography variant='h6' className={classes.title}>Password</Typography>
            <div className={classes.line} />
            <Typography variant='body1' style={{ marginBottom: 18 }}>
              Type in a new password for the user. This will change their password immediately.
            </Typography>
            <TextField
              label='New Password'
              value={this.state.password}
              onChange={this.handleChange('password')}
              fullWidth
              InputLabelProps={{
                shrink: true,
                className: classes.labelField
              }}
              InputProps={{ className: classes.textField }}
              style={{ marginBottom: 18 }}
            />
            <Button variant='contained'
                    color='primary'
                    style={{ marginLeft: 'auto' }}
                    onClick={this.changePassword}
                    disabled={!this.state.password}
            >Update Password</Button>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={6} style={{ paddingTop: 20 }}>
            <Grid item xs={12} className={classes.deleteContainer}>
              <div className={classes.deleteTitle}>Delete Patient</div>
              <div className={classes.deleteText}>
                Are you sure you want to delete the Patient?
              </div>
              <Button
                variant="outlined"
                className={classes.deleteButton}
                onClick={this.handleDelete}
              >
                DELETE PATIENT
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(PatientSettings);
