import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withStyles, Grid, Typography, TextField, Button } from '@material-ui/core';

import { getEtlUrl, createEHRInstance } from './EHRManagementUtils';
import {
  setNotifyHidden,
  setNotifySuccess,
  setNotifyWarning,
} from '../../components/notification/NotifyUtils';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    marginTop: theme.spacing.unit * 3,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    maxWidth: 400,
    width: '100%',
  },
  actions: {
    marginTop: theme.spacing.unit * 3,
  },
  button: {
    marginRight: theme.spacing.unit * 3,
  },
});

class CreateEHRInstance extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    setNotifyHidden: PropTypes.func.isRequired,
    setNotifyWarning: PropTypes.func.isRequired,
    setNotifySuccess: PropTypes.func.isRequired,
  };

  state = {
    etlUrl: '',
    siteKey: '',
    title: '',
  };

  onChange = (e) => {
    const { id, value } = e.target;
    this.setState({ [id]: value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    createEHRInstance(this.state.etlUrl, this.state.siteKey, this.state.title)
      .then(() => {
        this.props.setNotifySuccess('Request send successfully');
        this.props.router.goBack();
      }).catch(() => {
        this.props.setNotifyWarning('Something went wrong! Please try again.');
      });
  };

  componentWillMount() {
    getEtlUrl()
      .then((res) => {
        this.setState({
          etlUrl: res.endPoint,
        });
      });
  }

  render() {
    const { classes, router } = this.props;

    return (
      <Grid container direction='column' className='pageContainer'>
        <Typography variant='h5'>EHR Management</Typography>
        <Typography variant='subtitle1'>Link new EHR</Typography>
        <form className={classes.container} onSubmit={this.onSubmit}>
          <TextField
            className={classes.textField}
            id='siteKey'
            label='Site Service Key'
            helperText='Ellkay Site Service Key'
            value={this.state.siteKey}
            onChange={this.onChange}
            required
          />
          <TextField
            className={classes.textField}
            id='title'
            label='Description'
            helperText='Describe which clinic this EHR installation is for'
            value={this.state.title}
            onChange={this.onChange}
            required
          />
          <div className={classes.actions}>
            <Button className={classes.button} onClick={router.goBack}>Back</Button>
            <Button className={classes.button} variant='contained' color='primary' type='submit'>Link</Button>
          </div>
        </form>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setNotifySuccess: (title) => {
      dispatch(setNotifySuccess(title));
    },
    setNotifyWarning: (title) => {
      dispatch(setNotifyWarning(title));
    },
    setNotifyHidden: () => {
      dispatch(setNotifyHidden());
    },
  };
}

export default connect(null, mapDispatchToProps)(withRouter(withStyles(styles)(CreateEHRInstance)));
