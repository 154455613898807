import {
  mediciFetch,
  getApiUrl,
  serialize,
  trimObjectValues,
  mediciFetchBinaryData
} from "./../utils/NetworkUtils";

/**
 * Search practitioners free text
 * @param searchValue value to search
 * @returns {Promise.<T>}
 */
export function searchPractitioners(
  searchValue,
  offset = 0,
  size = 10,
  showDeleted
) {
  const params = [`size=${size}`, `offset=${offset}`];
  if (showDeleted !== undefined) {
    params.push(`deleted=${showDeleted}`);
  }
  return mediciFetch(
    `${getApiUrl()}/practitioners/search?q=${encodeURIComponent(
      searchValue
    )}&${params.join("&")}`,
    {
      method: "get"
    }
  );
}

/**
 * Search practitioners between dates
 * @returns {Promise.<T>}
 */
export function searchPractitionersBetweenDates(fromDate, toDate) {
  return mediciFetch(
    `${getApiUrl()}/practitioners/search?creationDateFrom=${fromDate}&creationDateTo=${toDate}`,
    {
      method: "get"
    }
  );
}

/**
 * Search practitioners filter
 * @param filter object of filter criteria
 * @returns {Promise.<T>}
 */
export function advancedSearchPractitioners(filter) {
  return mediciFetch(
    `${getApiUrl()}/practitioners/search?${serialize(filter)}`,
    {
      method: "get"
    }
  );
}

/**
 * Get practitioners by id
 * @param id practitioner id
 * @returns {Promise.<T>}
 */
export function getPractitionerByID(id) {
  return mediciFetch(`${getApiUrl()}/practitioners/${id}`, {
    method: "get"
  });
}

export function getPractitionerStatisticByID(id) {
  return mediciFetch(`${getApiUrl()}/practitioners/${id}/statistic`, {
    method: "get"
  });
}

/**
 * Get practices that Pracitioner belong to
 * @param Pracitioner ID
 * @returns {Promise.<T>}
 */
export function getPracticesByPractitionerID(practitionerID) {
  return mediciFetch(
    `${getApiUrl()}/practitioners/${practitionerID}/practices`,
    {
      method: "get"
    }
  );
}

/**
 * Loop practitioners and find by UID
 * @param practitioners data array to loop through
 * @param uid ID of practictioner to find
 * @returns {Promise.<T>}
 */
export function findByUID(practitioners, uid) {
  return practitioners.find(r => r.uid === uid);
}

/**
 * Save practitioner
 * @param practitioner object to save
 * @returns {Promise.<T>}
 */
export function savePractitioner(practitioner, forceUpdate) {
  let queryParams = "";
  trimObjectValues(practitioner);

  if (forceUpdate) {
    queryParams = "?force=true";
  }

  if (practitioner.uid) {
    // update
    return mediciFetch(
      `${getApiUrl()}/practitioners/${practitioner.uid}${queryParams}`,
      {
        method: "post",
        body: JSON.stringify(practitioner)
      }
    );
  }
  // insert
  return mediciFetch(`${getApiUrl()}/practitioners/register${queryParams}`, {
    method: "post",
    body: JSON.stringify(practitioner)
  });
}

/**
 * Save practitioner profile picture
 * @param practitionerImage practitioner data
 * @returns {Promise.<T>}
 */
export function savePractitionerImage(practitionerImage) {
  const fd = new FormData();
  fd.append("file", practitionerImage.file);

  return mediciFetch(
    `${getApiUrl()}/practitioners/${practitionerImage.uid}/picture`,
    {
      method: "post",
      body: fd
    }
  );
}

/**
 * Save practices for Practitioner
 * @param practices list of practice objects to save
 * @returns {Promise.<T>}
 */
export function savePracticesForPractitioner(practices, practitionerID) {
  return mediciFetch(
    `${getApiUrl()}/practitioners/${practitionerID}/practices`,
    {
      method: "post",
      body: JSON.stringify(practices)
    }
  );
}

/**
 * Delete practitioner
 * @param practitioner object to delete
 * @returns {Promise.<T>}
 */
export function deletePractitioner(practitioner) {
  return mediciFetch(`${getApiUrl()}/practitioners/${practitioner.uid}`, {
    method: "delete"
  });
}

/**
 * Save csv bulk file
 * @param File csv file
 * @returns {Promise.<T>}
 */
export function saveBulkCSVFile(practitionerUID, bulkCSVFile, type, smsSetups) {
  const fd = new FormData();
  fd.append("file", bulkCSVFile);
  fd.append("type", type);
  fd.append("smsSetups", JSON.stringify(smsSetups));

  return mediciFetch(
    `${getApiUrl()}/practitioners/${practitionerUID}/patientUploads`,
    {
      method: "post",
      body: fd
    }
  );
}

/**
 * Save csv bulk file new, with customized imported columns
 * @returns {Promise.<T>}
 */
export function saveBulkCSVFileNew(practitionerUID, fileUid, smsSetups, sendToAllPatients = false) {
  const fd = {
    patientUploadUid: fileUid,
    smsSetups,
    sendToAllPatients,
  };

  return mediciFetch(
    `${getApiUrl()}/practitioners/${practitionerUID}/patientUploads/${fileUid}/send`,
    {
      method: "post",
      body: JSON.stringify(fd),
    }
  );
}

/**
 * @returns {Promise.<{ patientUploadUid:string }>}
 */
export function savePatientsUploadFile(practitionerUID, CSVFile, type) {
  const fd = new FormData();
  fd.append("file", CSVFile);
  fd.append("type", type);

  return mediciFetch(
    `${getApiUrl()}/practitioners/${practitionerUID}/patientUploads`,
    {
      method: "put",
      body: fd
    }
  );
}

/**
 * @returns {Promise.<{ patientUploadUid:string }>}
 */
export function savePatientsUploadFileFinal(practitionerUid, fileUid, fields) {
  const fd = {
    patientUploadUid: fileUid,
    fields,
  };

  return mediciFetch(
    `${getApiUrl()}/practitioners/${practitionerUid}/patientUploads/${fileUid}`,
    {
      method: "post",
      body: JSON.stringify(fd),
    }
  );
}

/**
 * @returns {Promise.<{ fields:[], importColumns:[], }>}
 */
export function processPatientsUploadFile(practitionerUID, fileUid) {
  return mediciFetch(
    `${getApiUrl()}/practitioners/${practitionerUID}/patientUploads/${fileUid}`,
    {
      method: "get",
    }
  );
}

/**
 * Get history of all patient list uploads
 * @param practitionerUID
 * @returns {Promise.<T>}
 */
export function getUploadsHistory(practitionerUID) {
  return mediciFetch(
    `${getApiUrl()}/practitioners/${practitionerUID}/patientUploads`,
    {
      method: "get"
    }
  );
}

export function getAttachments(prUid) {
  return mediciFetch(`${getApiUrl()}/practitioners/${prUid}/documents`, {
    method: "get"
  });
}

/**
 * Save csv bulk file
 * @param File csv file
 * @returns {Promise.<T>}
 */
export function uploadAttachment(practitionerUID, fileAttachment) {
  const fd = new FormData();
  fd.append("file", fileAttachment);

  return mediciFetch(
    `${getApiUrl()}/practitioners/${practitionerUID}/documents`,
    {
      method: "post",
      body: fd
    }
  );
}

/**
 * match up specialty name with ID and get practitioner
 * @param specialtiesList list of specialties (uid,name)
 * @returns {Promise.<T>}
 */

export function getReferralPractitioners(
  referralPractitioners,
  specialtiesList
) {
  return new Promise((resolve, reject) => {
    referralPractitioners.forEach(practitioner => {
      specialtiesList.forEach(e => {
        if (practitioner.specialtyID === e.uid) {
          practitioner.specialty = e.name;
        }
      });
    });

    const callsToMake = [];
    referralPractitioners.forEach(practitioner => {
      callsToMake.push(
        getPractitionerByID.bind(this, practitioner.practitionerID)()
      );
    });

    Promise.all(callsToMake)
      .then(response => {
        response.forEach((v, i) => {
          referralPractitioners[i].practitioner = `${v.firstname} ${
            v.lastname
          }`;
        });

        resolve(referralPractitioners);
      })
      .catch(() => {
        reject(new Error("Error loading Treating Doctors"));
      });
  });
}

/**
 * Get PR contacts by uid
 * @param uid
 * @return {Promise}
 */
export function getPractitionerContacts(uid) {
  return mediciFetch(
    `${getApiUrl()}/practitioners/${uid}/contacts?showAll=true`
  );
}

/**
 * Get Current Subscription
 * @param uid
 * @return {Promise}
 */
export function getSubscriptionStatus(uid, sync = false) {
  return mediciFetch(
    `${getApiUrl()}/practitioners/${uid}/subscription?sync=${sync}`
  );
}

/**
 * Get All Active Subscriptions
 * @param uid
 * @return {Promise}
 */
export function getAllActiveSubscriptions(uid, sync = false) {
  return mediciFetch(
    `${getApiUrl()}/practitioners/${uid}/subscriptions?sync=${sync}`
  );
}

/**
 * Cancel Subscription
 * @param uid
 * @return {Promise}
 */
export function cancelSubscription(uid) {
  return mediciFetch(
    `${getApiUrl()}/practitioners/${uid}/subscription/cancel`,
    {
      method: "put"
    }
  );
}

/**
 * Get Subscriptions history
 * @param uid
 * @return {Promise}
 */
export function getSubscriptionHistory(uid) {
  return mediciFetch(
    `${getApiUrl()}/practitioners/${uid}/subscription/history`,
    {
      method: "get"
    }
  );
}

export function getDeliveryReport(practitionerUid, reportUid) {
  return mediciFetchBinaryData(
    `${getApiUrl()}/practitioners/${practitionerUid}/patientUploads/${reportUid}/report`
  );
}

export function getUploadStats(practitionerUid, reportUid) {
  return mediciFetch(
    `${getApiUrl()}/practitioners/${practitionerUid}/patientUploads/${reportUid}/stats`
  );
}

export function deletePractitionerContact(prUid, contactId) {
  return mediciFetch(
    `${getApiUrl()}/practitioners/${prUid}/contacts/${contactId}`,
    {
      method: "delete"
    }
  );
}

export function findByNPI(npi) {
  return mediciFetch(
    `${getApiUrl()}/practitioners/opensearch/npi/${npi}`, {
      method: 'get',
    },
  );
}

export function findByRegistrationId(registrationId) {
  return mediciFetch(
    `${getApiUrl()}/practitioners/opensearch.v3?registrationId=${registrationId}`, {
      method: 'get',
    },
  );
}

export function sendToDosespot(prUid) {
  return mediciFetch(`${getApiUrl()}/practitioners/${prUid}/dosespot/forceRegistration`, {
    method: 'post',
  })
}

export function refreshDosespotStatus(prUid) {
  return mediciFetch(`${getApiUrl()}/practitioners/${prUid}/dosespot/validation/status`, {
    method: 'post',
  })
}
