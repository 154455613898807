import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import {
  withStyles,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  Drawer
} from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";

import Menu from "./menu/Menu";
import Notify from "./notification/Notify";
import DoctorsList from "./../pages/Doctors/DoctorsList";
import ProfileMenu from "./ProfileMenu/ProfileMenu";
import { logoutUser, logoutOk } from "./login/LoginUtils";
import LocalStorage from "./utils/LocalStorage";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: "flex",
    paddingTop: '64px',
  },
  drawer: {
    [theme.breakpoints.up("lg")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("lg")]: {
      display: "none"
    }
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "flex-end"
    }
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3
  },
  logo: {
    width: "72px"
  }
});

class Main extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    children: PropTypes.object,
    router: PropTypes.object,
    user: PropTypes.object,
    logoutOk: PropTypes.func
  };

  state = {
    mobileOpen: false
  };

  componentDidMount() {
    if (!this.props.user || !this.props.user.uid) {
      this.onLogout();
    }
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  onLogout = async () => {
    try {
      await logoutUser();
    } catch (error) {
      console.log(error);
    }

    this.props.logoutOk();
    LocalStorage.remove("loginCredentials");
    this.props.router.push("/login");
  };

  render() {
    const { classes, router, user } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" color="primary" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="Open Menu"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <ProfileMenu onLogout={this.onLogout} router={router} user={user} />
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden lgUp implementation="css">
            <Drawer
              variant="temporary"
              anchor="left"
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper
              }}
            >
              <Menu
                router={this.props.router}
                onMenuItemClick={this.handleDrawerToggle}
                role={(user && user.subRole) || 'admin'}
              />
            </Drawer>
          </Hidden>
          <Hidden mdDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper
              }}
              variant="permanent"
              open
            >
              <Menu
                router={this.props.router}
                role={(user && user.subRole) || 'admin'}
              />
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Notify />
          {this.props.children || <DoctorsList />}
        </main>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    logged: state.login.logged,
    user: state.login.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logoutOk: () => {
      dispatch(logoutOk());
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Main)));
