import React from "react";
import PropTypes from "prop-types";
import SearchAdvancedFilter from "./SearchAdvancedFilter";
import SearchConsultationsFilter from "./SearchConsultationsFilter";
import "./SearchForm.css";

const TIMEOUT = 500;

export default class SearchForm extends React.Component {
  static propTypes = {
    advanced: PropTypes.string,
    onSearch: PropTypes.func.isRequired
  };

  state = {
    searchValue: "",
    advancedFormIsOpen: false
  };

  searchChange = e => {
    e.preventDefault();
    this.setState({ searchValue: e.target.value }, () => this.search({}));
  };

  search = data => {
    let searchData = {};

    if (this.props.advanced) {
      searchData.searchValue = searchData.q = this.state.searchValue; // eslint-disable-line
      // Assume we are hiding deleted entries if no delete value present
      if (this.props.advanced === "SearchAdvancedFilter") {
        searchData.deleted = data.deleted === undefined ? false : data.deleted;
        searchData.creationDateFrom = data.creationDateFrom;
        searchData.creationDateTo = data.creationDateTo;
        searchData.nbConsultationsGreaterThan = data.nbConsultationsGreaterThan;
        searchData.nbConsultationsLowerThan = data.nbConsultationsLowerThan;
        searchData.nbConsultationsOrder = data.nbConsultationsOrder;
      }

      if (this.props.advanced === "SearchConsultationsFilter") {
        if (data) {
          searchData = data;
        }
        if (this.state.searchValue) {
          searchData.q = this.state.searchValue;
        }
      }
    } else {
      searchData = this.state.searchValue;
    }

    this.debouncedSearch(searchData);
  };

  debouncedSearch = searchData => {
    if (this.timeoutId) clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => this.props.onSearch(searchData), TIMEOUT);
  };

  toggleAdvancedForm = () => {
    this.setState({
      advancedFormIsOpen: !this.state.advancedFormIsOpen
    });
  };

  handleKeyPress = target => {
    if (target.charCode === 13) {
      target.preventDefault();
      this.search();
    }
  };

  render() {
    let advancedForm = null;
    if (this.props.advanced && this.state.advancedFormIsOpen) {
      switch (this.props.advanced) {
        case "SearchAdvancedFilter":
          advancedForm = <SearchAdvancedFilter onUpdate={this.search} />;
          break;
        case "SearchConsultationsFilter":
          advancedForm = <SearchConsultationsFilter onUpdate={this.search} />;
          break;
        default:
          break;
      }
    }

    return (
      <form className="searchForm">
        <div className="searchForm-fieldset">
          <label htmlFor="search" className="searchForm-label">
            Search
          </label>

          <div className="searchForm-inputContainer">
            <input
              id="search"
              placeholder="Search..."
              ref="search" // eslint-disable-line
              className="searchForm-input"
              onChange={this.searchChange}
              onKeyPress={this.handleKeyPress}
              value={this.searchValue}
            />
            <span
              onClick={this.toggleAdvancedForm}
              className={
                this.props.advanced ? "searchForm-toggleAdvancedForm" : "hide"
              }
            >
              {this.state.advancedFormIsOpen ? "▲" : "▼"}
            </span>
          </div>
        </div>
        {advancedForm}
      </form>
    );
  }
}
