import { mediciFetch, getApiUrl } from './../../components/utils/NetworkUtils';

export function getEtlUrl() {
  return mediciFetch(`${getApiUrl()}/discovery/etl`);
}

export function getEHRInstances(url) {
  return mediciFetch(`${url}/ehrs/instances`, { method: 'get' }, false, true);
}

export function createEHRInstance(url, siteKey, title) {
  return mediciFetch(`${url}/ehrs/instances`, {
    method: 'post',
    body: JSON.stringify({
      siteKey,
      title,
    }),
  }, false, true);
}

export function getLinkedPractitioners(sourceKey) {
  return mediciFetch(`${getApiUrl()}/ehrs/instances/${sourceKey}/linkedPractitioners`);
}

export function getMatchingEhrPhysicianByNpi(url, sourceKey, npi) {
  return mediciFetch(`${url}/ehrs/instances/${sourceKey}/ehrPhysicians?npi=${npi}`, {
    method: 'get',
  }, false, true);
}

export function manuallyLinkPractitioner(sourceKey, prUid, physicianId, physicianName) {
  return mediciFetch(`${getApiUrl()}/ehrs/instances/${sourceKey}/linkedPractitioners`, {
    method: 'post',
    body: JSON.stringify({
      physicianId,
      physicianName,
      practitionerUid: prUid,
    }),
  });
}

export function unlinkPractitioner(sourceKey, practitionerUid) {
  return mediciFetch(`${getApiUrl()}/ehrs/instances/${sourceKey}/linkedPractitioners/${practitionerUid}`, {
    method: 'delete',
  });
}

export function startSync(url, sourceKey) {
  return mediciFetch(`${url}/ehrs/instances/${sourceKey}/start`, { method: 'put' }, false, true);
}

export function pauseSync(url, sourceKey) {
  return mediciFetch(`${url}/ehrs/instances/${sourceKey}/pause`, { method: 'put' }, false, true);
}

export function updateTitle(url, sourceKey, title) {
  return mediciFetch(`${url}/ehrs/instances/${sourceKey}/title`, {
    method: 'put',
    body: JSON.stringify({ title }),
  }, false, true);
}
