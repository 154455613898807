import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
  Button,
  Checkbox, FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Table, TableHead, TableBody,
  TableRow, TableCell,
  withStyles
} from '@material-ui/core';
import { DateTimePicker } from 'material-ui-pickers';
import _ from 'underscore';

import {
  setNotifyHidden,
  setNotifySuccess,
  setNotifyWarning
} from '../../components/notification/NotifyUtils';
import Amount from '../../components/pricing/Amount';
import ListManager from '../../components/listManager/List';
import DictionaryManager from '../../components/dictionaryManager/DictionaryManager';
import GroupSelector from './../../components/activationCode/GroupSelector';
import PracticeSelector from './../../components/practices/PracticeSelector';
import PractitionerSelector from './../../components/practitioners/PractitionerSelector';
import {
  deleteActivationCode,
  getActivationCodeByCode,
  getBrokerGroups,
  getCompanyGroups,
  saveActivationCode,
  saveBrokerGroup,
  saveCompanyGroup,
  updateActivationCode,
  getActivationCodeUsersByCode,
} from '../../components/activationCode/ActivationCodeUtils';
import { convertToUTC, formatDateForPicker } from '../../components/utils/DateUtils';
import { getPracticeByID } from '../../components/utils/PracticeUtils';
import { getPractitionerByID } from '../../components/practitioners/PractitionerUtils';
import { getPatientByID } from '../../components/patients/PatientUtils';
import Loading from '../../components/Loading';

const styles = theme => ({
  button: {
    margin: `0 ${theme.spacing.unit * 3}px`,
  },
  usage: {
    width: '100%',
    textAlign: 'center',
  },
});

class ActivationCodeForm extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    setNotifyHidden: PropTypes.func.isRequired,
    setNotifyWarning: PropTypes.func.isRequired,
    setNotifySuccess: PropTypes.func.isRequired,
  };

  state = {
    activationCode: {
      code: '',
      dateStart: formatDateForPicker(new Date()),
      dateEnd: formatDateForPicker(new Date().setMonth(new Date().getMonth() + 1)),
      maxQuantity: 0,
      description: '',
      creditAmount: 0,
      creditCurrency: 'USD',
      userSpecific: false,
      preloadedPracticeIds: [],
      preloadedPractitionerIds: [],
      companyGroupId: '',
      brokerGroupId: '',
      benefitPackageName: '',
      benefitAdminContact: '',
      doubleLink: false,
      trackProperties: {},
      topOfCarouselPractitionerUid: null,
    },
    companyGroups: [],
    brokerGroups: [],
    practiceData: [],
    practitionerData: [],
    practiceSelectedValue: {},
    practitionerSelectedValue: {},
    loading: false,
  };

  saveTrackProperty = (trackProperty) => {
    if (trackProperty) {
      const pc = this.state.activationCode;
      pc.trackProperties = Object.assign({}, pc.trackProperties, trackProperty);
      this.setState({ activationCode: pc });
    }
  };

  deleteTrackProperty = (key) => {
    if (key) {
      const pc = this.state.activationCode;
      delete pc.trackProperties[key];
      this.setState({ activationCode: pc });
    }
  };

  onActivationChange = (key, event) => {
    const activationCode = this.state.activationCode;
    activationCode[key] = event.target.value;
    this.setState({ activationCode });
  };

  onActivationCodeChange = (code, event) => {
    const p = this.state.activationCode;
    p.code = event.target.value;
    this.setState({ activationCode: p });
  };

  onDateChange = (name) => (value) => {
    this.setState({
      activationCode: { ...this.state.activationCode, [name]: value }
    })
  }

  onOfferCreditChange = (creditAmount, event) => {
    const p = this.state.activationCode;
    let numVal = event.target.value;
    if (numVal[numVal.length - 1] !== '.' && isNaN(numVal[numVal.length - 1])) {
      numVal = numVal.slice(0, -1);
    }
    p.creditAmount = numVal;
    this.setState({ activationCode: p });
  };

  onMaxQuantityChange = (maxQuantity, event) => {
    const p = this.state.activationCode;
    p.maxQuantity = parseInt(event.target.value, 10);
    this.setState({ activationCode: p });
  };

  onDescriptionChange = (description, event) => {
    const p = this.state.activationCode;
    p.description = event.target.value;
    this.setState({ activationCode: p });
  };

  amountChanged = (amount) => {
    this.setState({
      activationCode: Object.assign({}, this.state.activationCode, {
        creditAmount: amount,
      }),
    });
  };

  currencyChanged = (creditCurrency) => {
    this.setState({
      activationCode: Object.assign({}, this.state.activationCode, {
        creditCurrency,
      }),
    });
  };

  // Practices
  onPracticeAutoCompleteSelect = (e) => {
    this.setState({
      practiceSelectedValue: e,
    });
  };

  onPracticeDelete = (listItem) => {
    const practiceData = this.state.practiceData;
    practiceData.forEach((_practice, index) => {
      if (listItem.id === _practice.name) {
        practiceData.splice(index, 1);
      }
    });

    this.setState({ practiceData });
  };

  onPracticeAdd = () => {
    if (
      this.state.practiceSelectedValue.name &&
      this.state.practiceSelectedValue.name !== ' '
    ) {
      const practiceData = this.state.practiceData;
      practiceData.push(this.state.practiceSelectedValue);

      this.setState({
        practiceSelectedValue: { name: ' ' },
        practiceData,
      });
    }
  };

  // Practitioners
  onPractitionerAutoCompleteSelect = (e) => {
    this.setState({
      practitionerSelectedValue: Object.assign({}, e.user, {
        title: `${e.user.firstname} ${e.user.lastname} (${e.user.email})`,
      }),
    });
  };

  onPractitionerDelete = (practitioner) => {
    const practitionerData = this.state.practitionerData;
    practitionerData.forEach((_practitioner, index) => {
      if (practitioner.id === _practitioner.uid) {
        practitionerData.splice(index, 1);
      }
    });

    this.setState({ practitionerData });
  };

  onPractitionerSelect = (practitioner) => {
    const currentSelected = this.state.activationCode.topOfCarouselPractitionerUid;
    if (currentSelected === practitioner) {
      this.setState({
        activationCode: { ...this.state.activationCode, topOfCarouselPractitionerUid: null }
      });
    } else {
      this.setState({
        activationCode: { ...this.state.activationCode, topOfCarouselPractitionerUid: practitioner }
      });
    }
  };

  onPractitionerAdd = () => {
    if (
      this.state.practitionerSelectedValue.title &&
      this.state.practitionerSelectedValue.title !== ' '
    ) {
      const practitionerData = this.state.practitionerData;
      practitionerData.push(this.state.practitionerSelectedValue);

      this.setState({
        practitionerSelectedValue: { title: ' ' },
        practitionerData,
      });
    }
  };

  // Company Groups
  onCompanyGroupSelect = (e) => {
    this.setState({
      activationCode: Object.assign({}, this.state.activationCode, {
        companyGroupId: e.uid,
      }),
    });
  };

  getCompanyGroupName = (uid) => {
    let groupName = '';

    this.state.companyGroups.forEach((company) => {
      if (company.uid === uid) {
        groupName = company.name;
      }
    });

    return groupName;
  };

  // Broker Groups
  onBrokerGroupSelect = (e) => {
    this.setState({
      activationCode: Object.assign({}, this.state.activationCode, {
        brokerGroupId: e.uid,
      }),
    });
  };

  getBrokerGroupName = (uid) => {
    let groupName = '';

    this.state.brokerGroups.forEach((broker) => {
      if (broker.uid === uid) {
        groupName = broker.name;
      }
    });

    return groupName;
  };

  clickHandlerDoubleLink = (e) => {
    this.onActivationChange('doubleLink', {
      target: { value: e.target.checked },
    });
  };

  clickHandlerUserSpecific = (e) => {
    this.onActivationChange('userSpecific', {
      target: { value: e.target.checked },
    });
  };

  save = (e) => {
    if (this.refs.formActivationCode.checkValidity()) {
      e.preventDefault();

      const activationCodeToSave = Object.assign(
        {},
        this.state.activationCode,
        {
          dateStart: convertToUTC(this.state.activationCode.dateStart),
          dateEnd: convertToUTC(this.state.activationCode.dateEnd),
          preloadedPracticeIds: _.pluck(this.state.practiceData, 'uid'),
          preloadedPractitionerIds: _.pluck(this.state.practitionerData, 'uid'),
        },
      );

      if (this.props.params.id !== 'create') {
        this.update(activationCodeToSave);
      } else {
        this.create(activationCodeToSave);
      }
    }
  };

  update = (activationCode) => {
    updateActivationCode(activationCode)
      .then(() => {
        this.props.setNotifySuccess('Activation code saved');
        this.props.router.replace('/activation-code');
      })
      .catch((e) => {
        this.props.setNotifyWarning(e.message);
      });
  };

  create = (activationCode) => {
    saveActivationCode(activationCode)
      .then(() => {
        this.props.setNotifySuccess('Activation code saved');
        this.props.router.replace('/activation-code');
      })
      .catch((e) => {
        this.props.setNotifyWarning(e.message);
      });
  };

  onCompanyGroupSave = async (groupName) => {
    try {
      await saveCompanyGroup(groupName);
      const companyGroups = await getCompanyGroups();
      this.setState({
        companyGroups: companyGroups.filter(item => item.name),
      })
    } catch (e) {
      this.props.setNotifyWarning(e.message);
    }
  };

  onBrokerGroupSave = async (groupName) => {
    try {
      await saveBrokerGroup(groupName);
      const brokerGroups = await getBrokerGroups();
      this.setState({
        brokerGroups: brokerGroups.filter(item => item.name),
      })
    } catch (e) {
      this.props.setNotifyWarning(e.message);
    }
  };

  cancel = (e) => {
    e.preventDefault();
    this.props.router.replace('/activation-code');
  };

  deleteItem = (e) => {
    e.preventDefault();
    if (confirm('Delete activation code?')) {
      deleteActivationCode(this.props.params.id)
        .then(() => {
          this.props.setNotifySuccess('Activation code deleted');
          this.props.router.replace('/activation-code');
        })
        .catch((error) => {
          this.props.setNotifyWarning(error.message);
        });
    }
  };

  getCodeUsers = (e) => {
    e.preventDefault();

    this.setState({ loadingUsage: true });

    getActivationCodeUsersByCode(this.props.params.id)
      .then(response =>
        Promise.all(response.patientsList.map(patientId => getPatientByID(patientId))))
      .then((activationCodePatients) => {
        this.setState({
          activationCodePatients,
          showUsage: true,
          loadingUsage: false,
        });
      })
      .catch((error) => {
        this.setState({ loadingUsage: false });
        this.props.setNotifyWarning(error.message);
      });
  };

  componentWillMount() {
    this.setState({ loading: true });

    Promise.all([
      getCompanyGroups(),
      getBrokerGroups(),
    ]).then((result) => {
      this.setState({
        companyGroups: result[0].filter(item => item.name),
        brokerGroups: result[1].filter(item => item.name),
      })
    });

    if (this.props.params.id !== 'create') {
      let _activationCode;
      getActivationCodeByCode(this.props.params.id)
        .then(activationCode => {
          _activationCode = activationCode;
          this.setState({
            activationCode: Object.assign({}, activationCode, {
              dateStart: formatDateForPicker(activationCode.dateStart),
              dateEnd: formatDateForPicker(activationCode.dateEnd),
            }),
          });

          if (
            activationCode.preloadedPracticeIds &&
            activationCode.preloadedPracticeIds.length > 0
          ) {
            // Get practice IDs and get practice objects
            return new Promise((resolve) => {
              const resolved = [];
              const failed = [];
              activationCode.preloadedPracticeIds.forEach((praticeId) => {
                getPracticeByID(praticeId)
                  .then((practice) => {
                    resolved.push(practice);
                    if (
                      resolved.length + failed.length ===
                      activationCode.preloadedPracticeIds.length
                    ) { resolve({ resolved, failed }); }
                  })
                  .catch(() => {
                    failed.push({});
                    if (
                      resolved.length + failed.length ===
                      activationCode.preloadedPracticeIds.length
                    ) { resolve({ resolved, failed }); }
                  });
              });
            });
          }
        })
        .then((_practiceData = {}) => {
          this.setState({
            practiceData: _practiceData.resolved || [],
          });

          if (
            _activationCode.preloadedPractitionerIds &&
            _activationCode.preloadedPractitionerIds.length > 0
          ) {
            // Get practitioner IDs and get PR objects
            return new Promise((resolve) => {
              const resolved = [];
              const failed = [];
              _activationCode.preloadedPractitionerIds.forEach((prId) => {
                getPractitionerByID(prId)
                  .then((practitioner) => {
                    resolved.push(practitioner);
                    if (
                      resolved.length + failed.length ===
                      _activationCode.preloadedPractitionerIds.length
                    ) { resolve({ resolved, failed }); }
                  })
                  .catch(() => {
                    failed.push({});
                    if (
                      resolved.length + failed.length ===
                      _activationCode.preloadedPractitionerIds.length
                    ) { resolve({ resolved, failed }); }
                  });
              });
            });
          }
        })
        .then((_practitionerData = {}) => {
          this.setState({
            practitionerData: _practitionerData.resolved || [],
            loading: false,
          });
        })
        .catch(e => {
          this.props.setNotifyWarning(e.message);
          this.setState({ loading: false })
        })
    } else {
      this.setState({ loading: false })
    }
  }

  renderActivationCodePatientsRows = () =>
    this.state.activationCodePatients.map(patient => (
      <TableRow key={patient.uid}>
        <TableCell>{patient.firstname}</TableCell>
        <TableCell>{patient.lastname}</TableCell>
        <TableCell>{patient.email}</TableCell>
        <TableCell>{patient.phone}</TableCell>
      </TableRow>
    ));

  renderUsage = () => {
    const { classes } = this.props;
    if (this.props.params.id !== 'create') {
      if (this.state.loadingUsage) {
        return (
          <Button variant="contained" disabled>
            Loading Usage
          </Button>
        );
      }

      if (this.state.showUsage) {
        return (
          <div className={classes.usage}>
            <Typography variant="h6">Usage</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.renderActivationCodePatientsRows()}
              </TableBody>
            </Table>
          </div>
        );
      }

      return (
        <Button
          color="primary"
          onClick={this.getCodeUsers}
        >
          Show Usage
        </Button>
      );
    }
  };

  render() {
    const { classes } = this.props;
    // Practice setup
    const practiceControl = (
      <PracticeSelector
        onSelect={this.onPracticeAutoCompleteSelect}
        defaultValue={this.state.practiceSelectedValue.name}
        className="form-input"
      />
    );
    const practiceValues = [];
    this.state.practiceData.forEach((value) => {
      practiceValues.push(value.name);
    });

    // Practitioner Setup
    const practitionerControl = (
      <PractitionerSelector
        onSelect={this.onPractitionerAutoCompleteSelect}
        defaultValue={this.state.practitionerSelectedValue.title}
      />
    );
    const practitionerValues = [];
    this.state.practitionerData.forEach((value) => {
      if (value) {
        practitionerValues.push({
          id: value.uid,
          title: `${value.firstname} ${value.lastname} (${value.email})`,
        });
      }
    });

    if (this.state.loading) {
      return (
        <Grid container direction='column'>
          <Typography variant='h5'>Activation Codes</Typography>
          <Loading size={50} />
        </Grid>
      )
    }

    return (
      <Grid container direction='column'>
        <Typography variant='h5'>Activation Codes</Typography>
        <form id="formActivationCode" ref="formActivationCode" className="form">
          <h2>{this.props.params.id === 'create' ? 'Create' : 'Edit'}</h2>

          <TextField
            id="code"
            label="Code"
            value={this.state.activationCode.code}
            onChange={this.onActivationCodeChange.bind(this, 'code')}
            readOnly={this.props.params.id !== 'create'}
            required
            fullWidth
          />
          <DateTimePicker
            id="start"
            label='Start'
            clearable
            emptyLabel='Any'
            value={this.state.activationCode.dateStart}
            onChange={this.onDateChange('dateStart')}
            format="MM/DD/YYYY - HH:mm:ss"
            fullWidth
          />
          <DateTimePicker
            id='end'
            label='End'
            clearable
            emptyLabel='Any'
            value={this.state.activationCode.dateEnd}
            onChange={this.onDateChange('dateEnd')}
            format="MM/DD/YYYY - HH:mm:ss"
            fullWidth
          />
          <Amount
            label="Amount *"
            amount={this.state.activationCode.creditAmount}
            currency={this.state.activationCode.creditCurrency}
            onAmountChange={this.amountChanged}
            onCurrencyChange={this.currencyChanged}
          />
          <TextField
            id="quantity"
            label="Max Quantity"
            type="number"
            value={this.state.activationCode.maxQuantity}
            onChange={this.onMaxQuantityChange.bind(this, 'maxQuantity')}
            required
            fullWidth
          />
          <TextField
            id="benefitPackageName"
            label="Benefit Package Name"
            value={this.state.activationCode.benefitPackageName}
            onChange={this.onActivationChange.bind(this, 'benefitPackageName')}
            required
            fullWidth
          />
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.activationCode.userSpecific}
                  onChange={this.clickHandlerUserSpecific}
                  value={this.state.activationCode.userSpecific}
                />
              }
              label="Coupon can only be used once"
            />
          </FormControl>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.activationCode.doubleLink}
                  onChange={this.clickHandlerDoubleLink}
                  value={this.state.activationCode.doubleLink}
                />
              }
              label="Immediately link PR to PA and PA to PR"
            />
          </FormControl>
          <TextField
            id="description"
            label="Description"
            value={this.state.activationCode.description}
            onChange={this.onDescriptionChange.bind(this, 'description')}
            multiline
            fullWidth
          />

          <div className="form-freeFieldset">
            <label htmlFor="practices" className="form-label">
              Practices
            </label>
            <div className="form-freeContainer">
              <ListManager
                values={practiceValues}
                formControl={practiceControl}
                onAdd={this.onPracticeAdd}
                onDelete={this.onPracticeDelete}
              />
            </div>
          </div>

          <div className="form-freeFieldset">
            <label htmlFor="practitioners" className="form-label">
              Practitioners
            </label>
            <div className="form-freeContainer">
              <ListManager
                values={practitionerValues}
                formControl={practitionerControl}
                onAdd={this.onPractitionerAdd}
                onDelete={this.onPractitionerDelete}
                onSelect={this.onPractitionerSelect}
                selected={this.state.activationCode.topOfCarouselPractitionerUid}
              />
            </div>
          </div>

          <div className="form-freeFieldset">
            <label htmlFor="companyGroups" className="form-label">
              Company Groups
            </label>
            <div className="form-flexColFieldset">
              <label className="tip">
                To add a new Company Group type in the name and click Create
              </label>
              <GroupSelector
                values={this.state.companyGroups}
                onSelect={this.onCompanyGroupSelect}
                onSave={this.onCompanyGroupSave}
                defaultValue={this.getCompanyGroupName(this.state.activationCode.companyGroupId)}
              />
            </div>
          </div>

          <div className="form-freeFieldset">
            <label htmlFor="brokerGroups" className="form-label">
              Broker Groups
            </label>
            <div className="form-flexColFieldset">
              <label className="tip">
                To add a new Broker Group type in the name and click Create
              </label>
              <GroupSelector
                values={this.state.brokerGroups}
                onSelect={this.onBrokerGroupSelect}
                onSave={this.onBrokerGroupSave}
                defaultValue={this.getBrokerGroupName(this.state.activationCode.brokerGroupId)}
              />
            </div>
          </div>

          <TextField
            id="benefitAdminContact"
            label="Benefit Admin Contact"
            value={this.state.activationCode.benefitAdminContact}
            onChange={this.onActivationChange.bind(this, 'benefitAdminContact')}
            fullWidth
          />

          <br />
          <h3>Tracking Information</h3>
          <div className="form-freeFieldset">
            <label className="form-label">Properties</label>
            <DictionaryManager
              values={this.state.activationCode.trackProperties}
              onDeleteProperty={this.deleteTrackProperty}
              onSaveProperty={this.saveTrackProperty}
            />
          </div>

          {this.renderUsage()}

          <br />
          <br />
          <Grid id="form-options" container justify="center" alignContent="center">
            <Button
              className={classes.button}
              color="primary"
              variant="raised"
              onClick={this.save}>Save</Button>
            <Button
              className={classes.button}
              onClick={this.cancel}>Cancel</Button>
            { this.props.params.id !== 'create' ? (
              <Button
                className={classes.button}
                variant="raised"
                onClick={this.deleteItem}>Delete</Button>
            ) : null }
          </Grid>
        </form>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setNotifySuccess: (title) => {
      dispatch(setNotifySuccess(title));
    },
    setNotifyWarning: (title) => {
      dispatch(setNotifyWarning(title));
    },
    setNotifyHidden: () => {
      dispatch(setNotifyHidden());
    },
  };
}

export default connect(null, mapDispatchToProps)(withRouter(withStyles(styles)(ActivationCodeForm)));
