const styles = ({ spacing, palette }) => ({
  form: {
    marginLeft: spacing.unit,
    marginRight: spacing.unit,
    marginBottom: spacing.unit,
    display: "flex",
    flexDirection: "column"
  },
  input: {
    marginTop: spacing.unit
  },
  button: {
    marginTop: spacing.unit * 2,
    marginLeft: "auto",
    marginRight: "auto"
  },
  subscriptionStatus: {
    fontSize: 18,
    fontWeight: 500,
    marginLeft: "auto",
    textTransform: "capitalize",
    color: palette.text.secondary,
    "&> span.active": {
      color: palette.secondary.main
    }
  },
  subscriptionDate: {
    color: palette.primary.light,
    fontSize: 14,
    marginTop: 6
  },
  subscriptionQuantity: {
    color: palette.primary.light,
    fontSize: 16,
    fontWeight: 500
  },
  subscriptionPrice: {
    color: palette.primary.light,
    textTransform: "capitalize",
    fontSize: 18,
    fontWeight: 500
  },
  actionIcon: {
    color: palette.primary.light,
    padding: 0,
    marginLeft: 8,
    marginRight: 8
  },
  label: {
    padding: '3px 4px',
    borderRadius: '6px',
    background: '#21CE99',
    color: '#fff',
    textTransform: 'uppercase',
    fontSize: '8px',
    fontWeight: 900,
  },
  payouts: {
    marginTop: '36px',
  },
});

export default styles;
