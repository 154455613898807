import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Button, TableRow, TableCell } from '@material-ui/core';

export default class AddRow extends React.Component {
  static propTypes = {
    doSave: PropTypes.func.isRequired,
    doCancel: PropTypes.func.isRequired,
    keyIndex: PropTypes.number,
  };

  state = {
    key: '',
    value: '',
  };

  onChange = (propName, event) => {
    const s = this.state;
    s[propName] = event.target.value;
    this.setState(s);
  };

  onSave = () => {
    if (this.state.key) {
      const item = {};
      item[this.state.key] = this.state.value;
      this.props.doSave(item);
    }
  };

  render() {
    return (
      <TableRow key={`td${this.props.keyIndex}`} className="dictionary-entry">
        <TableCell>
          <TextField
            label="Name"
            value={this.state.key}
            onChange={this.onChange.bind(this, 'key')}
          />
        </TableCell>
        <TableCell>
          <TextField
            label="Value"
            value={this.state.value}
            onChange={this.onChange.bind(this, 'value')}
          />
        </TableCell>
        <TableCell>
          <Button
            color='primary'
            style={{ marginRight: 12 }}
            onClick={this.onSave}>save</Button>
          <Button
            onClick={this.props.doCancel}>cancel</Button>
        </TableCell>
      </TableRow>
    );
  }
}
