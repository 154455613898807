import { mediciFetch, getApiUrl } from "./../utils/NetworkUtils";

const ACTION_GET_SPECIALTIES_SUCCESS = "ACTION_GET_SPECIALTIES_SUCCESS";
const ACTION_GET_SPECIALTIES_FAILURE = "ACTION_GET_SPECIALTIES_FAILURE";

/**
 * Reducer that manages the states for specialist data
 */
export function SpecialtyReducer(state = { specialtiesList: [] }, action) {
  switch (action.type) {
    case ACTION_GET_SPECIALTIES_SUCCESS:
      return Object.assign({}, state, {
        specialtiesList: action.specialtiesList
      });
    case ACTION_GET_SPECIALTIES_FAILURE:
      return Object.assign({}, state, { specialtiesList: [] });
    default:
      return state;
  }
}

/**
 * Create a "success" action
 */
export function fetchSpecialtySuccess(specialtiesList) {
  return {
    type: ACTION_GET_SPECIALTIES_SUCCESS,
    specialtiesList
  };
}

/**
 * Create a "failed" action
 */
export function fetchSpecialtyFailure() {
  return {
    type: ACTION_GET_SPECIALTIES_FAILURE
  };
}
/**
 * Delete specialty
 * @param practitioner object to save
 * @returns {Promise.<T>}
 */
export function deleteSpecialty(specialtyID) {
  return mediciFetch(`${getApiUrl()}/specialties/${specialtyID}`, {
    method: "delete"
  });
}

/**
 * Save specialty
 * @param practitioner object to save
 * @returns {Promise.<T>}
 */
export function saveSpecialty(specialty) {
  if (specialty.uid) {
    // update
    return mediciFetch(`${getApiUrl()}/specialties/${specialty.uid}`, {
      method: "post",
      body: JSON.stringify(specialty)
    });
  }
  // insert
  return mediciFetch(`${getApiUrl()}/specialties`, {
    method: "post",
    body: JSON.stringify(specialty)
  });
}

/**
 * Get specialty by ID
 * @returns {Promise.<T>}
 */
export function getSpecialtyByID(uid) {
  return mediciFetch(`${getApiUrl()}/specialties/${uid}`, {
    method: "get"
  });
}

/**
 * Get list of unique medici specialties
 * @returns {Promise.<T>}
 */
let getSpecialtiesMemo = null;
export async function getSpecialties() {
  if (getSpecialtiesMemo) {
    return getSpecialtiesMemo;
  }

  const response = mediciFetch(`${getApiUrl()}/specialties`, {
    method: "get"
  });

  getSpecialtiesMemo = response;
  return getSpecialtiesMemo;
}

export async function setPractitionerSpecialties(practitioner) {
  const specialties = await getSpecialties();

  const specialtyValues = [];
  practitioner.specialties.forEach(specialtyUid => {
    specialties.forEach(specialty => {
      if (specialty.uid === specialtyUid) {
        specialtyValues.push(specialty.name);
      }
    });
  });
  const specialtyName = specialtyValues.join(", ");

  return Object.assign({}, practitioner, { specialtyName });
}
