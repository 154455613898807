import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import moment from 'moment';

import { getIncentiveReport } from './InviteIncentivesUtils';

export default class InviteIncentivesReport extends React.Component {
  static propTypes = {
    router: PropTypes.object,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  };

  state = {
    incentive: {
      uid: '',
      startDate: '',
      endDate: '',
      type: '',
      amount: '',
      currency: '',
      referrals: [],
    },
  };

  componentDidMount() {
    getIncentiveReport(this.props.params.id)
      .then((incentive) => {
        this.setState({ incentive });
      });
  }

  render() {
    return (
      <Grid container direction='column' className='pageContainer'>
        <Typography variant='h6' gutterBottom>
          Incentive Report For {this.state.incentive.uid}
        </Typography>
        <Typography variant='subtitle1'><b>Incentive Details</b></Typography>
        <Typography variant='body1'>
          <b>Start Date: </b>{moment(this.state.incentive.startDate).format('YYYY-MM-DD hh:mm a')}
          </Typography>
        <Typography variant='body1'>
          <b>End Date: </b>{this.state.incentive.endDate ? moment(this.state.incentive.endDate).format('YYYY-MM-DD hh:mm a') : 'Active'}
        </Typography>
        <Typography variant='body1'><b>Type: </b>{this.state.incentive.type}</Typography>
        <Typography variant='body1'><b>Amount: </b>{this.state.incentive.amount / 100} {this.state.incentive.currency}</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Inviting Doctor</TableCell>
              <TableCell>Inviting Doctor</TableCell>
              <TableCell>Link</TableCell>
              <TableCell>Invited Doctor</TableCell>
              <TableCell>Invited Doctor</TableCell>
              <TableCell>Paid Consult</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.incentive.referrals && this.state.incentive.referrals.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.uid}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.link}</TableCell>
                <TableCell>{item.invitedUid}</TableCell>
                <TableCell>{item.invitedName}</TableCell>
                <TableCell>{item.consultationUid ? 'Yes' : 'No'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    );
  }
}
