import { Button, Grid, withStyles } from "@material-ui/core";
import { FileCopyOutlined } from "@material-ui/icons";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import ClipboardJS from "clipboard";
import PropTypes from "prop-types";
import React from "react";
import UserAvatar from "../../components/user-avatar/UserAvatar";
import * as helpers from "../../utils/helpers";

const styles = ({ palette, spacing }) => ({
  container: {
    minHeight: 162,
    width: 503,
    borderRadius: 3,
    backgroundColor: "#F6F6F6"
  },
  img: {
    height: "100%",
    width: "100%",
    borderRadius: "3px 0 0 3px",
    paddingRight: 16
  },
  name: {
    color: palette.text.secondary,
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 8,
    marginBottom: 1
  },
  specialty: {
    color: palette.text.secondary,
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 12
  },
  location: {
    color: palette.text.secondary,
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 16
  },
  phone: {
    color: palette.text.secondary,
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 6
  },
  email: {
    color: palette.text.secondary,
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 9
  },
  date: {
    color: "#999999",
    fontSize: 12
  },
  uidContainer: {
    marginLeft: "auto",
    marginRight: 2,
    padding: 4,
    minHeight: 0
  },
  uid: {
    color: "#999999",
    fontSize: 10,
    marginRight: 2
  },
  uidIcon: {
    color: "#999999",
    fontSize: 10
  },
  wrap: {
    padding: spacing.unit * 2
  },
  label: {
    fontSize: 10,
    fontWeight: 500,
    color: palette.text.secondary,
    opacity: 0.9,
    letterSpacing: 0.5,
    marginRight: spacing.unit
  },
  bar: {
    fontSize: 10,
    fontWeight: 500,
    color: palette.common.white,
    backgroundColor: palette.text.secondary,
    textAlign: "right",
    paddingRight: spacing.unit / 2,
    minWidth: spacing.unit * 3
  },
  divider: {
    width: 1,
    backgroundColor: "#F3F3F3"
  },
  statsTitle: {
    fontSize: 11,
    fontWeight: 500,
    color: palette.text.secondary,
    textTransform: "uppercase",
    marginBottom: spacing.unit
  },
  statsData: {
    color: palette.text.secondary,
    fontSize: 21,
    letterSpacing: 0.5,
    lineHeight: "24px"
  },
  statsHint: {
    color: palette.text.secondary,
    opacity: 0.6,
    fontSize: 10,
    fontWeight: 500,
    lineHeight: "11px",
    letterSpacing: 0.5
  },
  block: {}
});

class PractitionerHeader extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    practitioner: PropTypes.object.isRequired,
    statistic: PropTypes.object,
    actions: PropTypes.bool,
    style: PropTypes.object
  };

  static defaultProps = {
    actions: true
  };

  componentDidMount() {
    return new ClipboardJS(`.${this.props.classes.uidContainer}`);
  }

  render() {
    const { practitioner, statistic, classes, actions, style } = this.props;
    const location = helpers.locationToString(practitioner.location);
    const { uid, role } = practitioner;

    return (
      <Grid container direction="row">
        <Grid
          container
          direction="row"
          className={classes.container}
          style={style}
        >
          <Grid item style={{ width: "36%" }}>
            <UserAvatar
              uid={uid}
              role={`${role}s`}
              className={classes.img}
              size={0}
              enableUpload={actions && true}
            />
          </Grid>
          <Grid item style={{ width: "64%" }}>
            <div className={classes.name}>
              <Grid container direction="row" alignItems="center">
                {helpers.renderUserName(practitioner)}
                {practitioner.verifiedPractitioner && (
                  <VerifiedUser color="secondary" style={{ marginLeft: 10 }} />
                )}
              </Grid>
            </div>
            <div className={classes.specialty}>
              {practitioner.specialtyName}{" "}
            </div>
            <div className={classes.location}>
              {location}{" "}
              <span className={`flag-icon flag-icon-${practitioner.country}`} />
            </div>
            <div className={classes.phone}>{practitioner.phone}</div>
            <div className={classes.email}>{practitioner.email}</div>
            <Grid container direction="row" alignItems="center">
              <div className={classes.date}>
                Joined{" "}
                {helpers.renderDate(practitioner.creationDate, "MMMM DD, YYYY")}
              </div>
              {actions && (
                <Button
                  className={classes.uidContainer}
                  data-clipboard-text={uid}
                >
                  <Grid container direction="row" alignItems="center">
                    <span className={classes.uid}>{helpers.shortUid(uid)}</span>
                    <FileCopyOutlined className={classes.uidIcon} />
                  </Grid>
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>

        {statistic && (
          <React.Fragment>
            <Grid item className={classes.wrap} xs={2}>
              <Grid
                container
                direction="column"
                justify="space-between"
                style={{ height: "100%" }}
              >
                <div className={classes.block}>
                  <div className={classes.statsTitle}>Consults</div>
                  <Grid
                    container
                    direction="row"
                    style={{ marginBottom: 6, flexWrap: "nowrap" }}
                  >
                    <div className={classes.label}>30D</div>
                    <div
                      className={classes.bar}
                      style={{
                        width: `${(statistic.completedConsultationCount["30"] *
                          100) /
                          statistic.completedConsultationCount["90"]}%`
                      }}
                    >
                      {statistic.completedConsultationCount["30"]}
                    </div>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    style={{ marginBottom: 6, flexWrap: "nowrap" }}
                  >
                    <div className={classes.label}>60D</div>
                    <div
                      className={classes.bar}
                      style={{
                        width: `${(statistic.completedConsultationCount["60"] *
                          100) /
                          statistic.completedConsultationCount["90"]}%`
                      }}
                    >
                      {statistic.completedConsultationCount["60"]}
                    </div>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    style={{ marginBottom: 6, flexWrap: "nowrap" }}
                  >
                    <div className={classes.label}>90D</div>
                    <div
                      className={classes.bar}
                      style={{
                        width: `${(statistic.completedConsultationCount["90"] *
                          100) /
                          statistic.completedConsultationCount["90"]}%`
                      }}
                    >
                      {statistic.completedConsultationCount["90"]}
                    </div>
                  </Grid>
                </div>
                <div className={classes.block}>
                  <div className={classes.statsTitle}>Revenue</div>
                  <Grid container direction="row">
                    <div style={{ marginRight: 6 }}>
                      <div
                        className={classes.statsData}
                      >{`${helpers.getCurrencySymbol(
                        statistic.monthRevenue.currentMonth.currency
                      )}${statistic.monthRevenue.currentMonth.amount /
                        100}`}</div>
                      <div className={classes.statsHint}>Current</div>
                    </div>
                    <div
                      className={classes.divider}
                      style={{ height: 25, width: 2 }}
                    />
                    <div style={{ marginLeft: 6 }}>
                      <div
                        className={classes.statsData}
                      >{`${helpers.getCurrencySymbol(
                        statistic.monthRevenue.previousMonth.currency
                      )}${statistic.monthRevenue.previousMonth.amount /
                        100}`}</div>
                      <div className={classes.statsHint}>Last Month</div>
                    </div>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container style={{ height: "100%" }}>
                <div className={classes.divider} style={{ height: "100%" }} />
              </Grid>
            </Grid>
            <Grid item className={classes.wrap} xs={1}>
              <Grid
                container
                direction="column"
                justify="space-between"
                style={{ height: "100%" }}
              >
                <div>
                  <div className={classes.statsTitle}>Patients</div>
                  <div className={classes.statsData}>
                    {statistic.patientContactCount}
                  </div>
                  <div className={classes.statsHint}>All Time</div>
                </div>
                <div>
                  <div className={classes.statsTitle}>Colleagues</div>
                  <div className={classes.statsData}>
                    {statistic.practitionerContactCount}
                  </div>
                  <div className={classes.statsHint}>All Time</div>
                </div>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container style={{ height: "100%" }}>
                <div className={classes.divider} style={{ height: "100%" }} />
              </Grid>
            </Grid>

            {practitioner.applicationVersionMap && (
              <Grid item className={classes.wrap} xs={3}>
                <Grid
                  container
                  direction="column"
                  justify="space-between"
                  style={{ height: "100%" }}
                >
                  <div className={classes.statsTitle}>Version</div>
                  <div>
                    <div className={classes.statsData} style={{ fontSize: 16 }}>
                      iOS
                    </div>
                    <div className={classes.statsHint}>
                      {practitioner.applicationVersionMap.ios || "----"}
                    </div>
                  </div>
                  <div>
                    <div className={classes.statsData} style={{ fontSize: 16 }}>
                      Android
                    </div>
                    <div className={classes.statsHint}>
                      {practitioner.applicationVersionMap.android || "----"}
                    </div>
                  </div>
                  <div>
                    <div className={classes.statsData} style={{ fontSize: 16 }}>
                      WebApp
                    </div>
                    <div className={classes.statsHint}>
                      {practitioner.applicationVersionMap.webapp || "----"}
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
          </React.Fragment>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(PractitionerHeader);
