import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  withStyles, Grid, InputAdornment, TextField, Typography,
  Table, TableBody, TableRow, TableCell, TablePagination, Button,
} from '@material-ui/core';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { debounce } from 'underscore';
import SearchIcon from '@material-ui/icons/Search';

import { advancedSearchPatients } from '../../components/patients/PatientUtils';
import UserAvatar from '../../components/user-avatar/UserAvatar';
import Loading from '../../components/Loading';
import { setNotifyHidden, setNotifyWarning } from '../../components/notification/NotifyUtils';

const styles = theme => ({
  marginX: {
    margin: `0 ${theme.spacing.unit}px`,
  },
  name: {
    fontSize: '17px',
  },
  hint: {
    fontSize: '12px',
    color: '#999',
  },
  flagSA: {
    margin: `0 ${theme.spacing.unit}px`,
    width: '16px',
  },
  demoLabel: {
    position: 'absolute',
    top: '-4px',
    left: '-12px',
    padding: '2px 4px',
    borderRadius: '0 0 3px 3px',
    background: '#21CE99',
    color: '#fff',
    textTransform: 'uppercase',
    fontSize: '7px',
    fontWeight: 900,
  },
  infoItem: {
    margin: theme.spacing.unit,
    whiteSpace: 'nowrap',
  },
  loadingWrap: {
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    background: 'rgba(255, 255, 255, 0.75)',
    zIndex: 1,
  },
  newPatient: {
    marginLeft: 'auto',
    marginBottom: 'auto',
  },
  container: {
    margin: `${theme.spacing.unit * 2}px 0`,
  },
});

class PatientsList extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    router: PropTypes.object,
    setNotifyHidden: PropTypes.func.isRequired,
    setNotifyWarning: PropTypes.func.isRequired,
  };

  state = {
    patients: [],
    search: {
      value: '',
    },
    pagination: {
      total: 0,
      limit: 10,
      page: 0,
    },
    loading: false,
  };

  search = debounce(() => {
    this.retrievePatients();
  }, 500);

  onSearch = e => {
    const { value } = e.target;
    this.setState({ search: { value }}, this.search);
  };

  retrievePatients = () => {
    const { limit, page } = this.state.pagination;
    this.setState({ loading: true });
    this.props.setNotifyHidden();
    advancedSearchPatients({ q: this.state.search.value, size: limit, offset: limit * page })
      .then(res => {
        this.setState({
          patients: res.content,
          pagination: {
            ...this.state.pagination,
            total: res.pagination.total,
          },
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({ loading: false});
        this.props.setNotifyWarning(e.message);
      });
  };

  handleChangeRowsPerPage = (e) => {
    const { pagination } = this.state;
    this.setState({ pagination: { ...pagination, limit: e.target.value } }, this.retrievePatients);
  };

  handleChangePage = (e, page) => {
    const { pagination } = this.state;
    this.setState({ pagination: { ...pagination, page } }, this.retrievePatients);
  };

  componentWillMount() {
    this.retrievePatients();
  }

  render() {
    const { pagination } = this.state;
    const { classes, router } = this.props;
    return (
      <Grid container direction='column'>
        {this.state.loading && (
          <div className={classes.loadingWrap}>
            <Loading/>
          </div>
        )}
        <Typography variant='h5'>Patients</Typography>
        <Grid container direction='column' style={{ margin: '30px 0' }}>
          <TextField
            style={{ width: '275px' }}
            label='Search'
            value={this.state.search.value}
            onChange={this.onSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Table>
          <TableBody>
            {this.state.patients.map((patient, index) => (
              <TableRow key={index} style={{ cursor: 'pointer' }} onClick={() => router.push(`/patients/${patient.user.uid}`)}>
                <TableCell>
                  <Grid container direction='row' alignItems='center' wrap='nowrap' style={{ padding: '8px 0 8px 12px', position: 'relative' }}>
                    {patient.user.demo ? (<span className={classes.demoLabel}>demo</span>) : null}
                    <UserAvatar uid={patient.user.uid} photoVersion={patient.user.photoVersion} role='patients'/>
                    <Grid className={classes.marginX}>
                      <div className={classes.name}>
                        {patient.user.firstname} {patient.user.lastname}
                      </div>
                      <div className={classes.hint}>
                        {patient.user.birthdate ? `DOB ${moment(patient.user.birthdate).format('MMM D, YYYY')}` : null }
                        {patient.user.country === 'za' ? <span className={`${classes.flagSA} flag-icon flag-icon-za`} /> : null }
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid>
                    <div className={classes.infoItem}>{patient.user.email}</div>
                    <div className={classes.infoItem}>{patient.user.phone}</div>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid>
                    <div className={classes.infoItem}>{patient.practitionerContactCount} Providers</div>
                    <div className={classes.infoItem}>{patient.consultationCount} Consults</div>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          count={pagination.total}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          page={pagination.page}
          rowsPerPage={pagination.limit}
          rowsPerPageOptions={[10, 25, 50, 100]}
        />
        <Grid direction='row' container className={classes.container}>
          <Button
            className={classes.newPatient}
            variant='outlined'
            color='primary'
            onClick={() => router.push('/patients/create')}>New Patient</Button>
        </Grid>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setNotifyWarning: title => {
      dispatch(setNotifyWarning(title));
    },
    setNotifyHidden: () => {
      dispatch(setNotifyHidden());
    },
  };
}


export default connect(null, mapDispatchToProps)(withStyles(styles)(withRouter(PatientsList)));
