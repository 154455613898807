import React from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
} from '@material-ui/core';

import AutoComplete from "../autocomplete/AutoComplete";
import { searchPractitioners } from "./PractitionerUtils";

import "./PractitionerSelector.css";
/**
 * PractitionerSelector
 */
class PractitionerSelector extends React.Component {
  static propTypes = {
    onSelect: PropTypes.func,
    onClear: PropTypes.func,
    defaultValue: PropTypes.any,
    className: PropTypes.any
  };

  state = {
    showDeleted: false
  };

  onSearch = e =>
    searchPractitioners(e.toString().trim(), 0, 10, this.state.showDeleted).then(r =>
      r.content.map(d => {
        d.uid = d.user.uid;
        d.title = `${d.user.firstname} ${d.user.lastname}`;
        d.subTitle = d.user.email;
        return d;
      })
    );

  showDeleted = e => {
    this.setState({
      showDeleted: e.target.checked
    });
  };

  render() {
    return (
      <Grid container direction="row" justify="space-between">
        <Grid item style={{ width: '45%' }}>
          <AutoComplete
            onSearch={this.onSearch}
            onSelect={this.props.onSelect}
            onClear={this.props.onClear}
            defaultValue={this.props.defaultValue}
            className={this.props.className}
          />
        </Grid>
        <Grid item style={{ width: '45%'}}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.showDeleted}
                  onChange={this.showDeleted}
                  value={this.state.showDeleted}
                />
              }
              label="Show Deleted"
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

export default PractitionerSelector;
