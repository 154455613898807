import { mediciFetch, getApiUrl, serialize } from './../utils/NetworkUtils';

/**
 * Get list of practices
 * @returns {Promise.<T>}
 */
export function getPractices() {
  return mediciFetch(`${getApiUrl()}/practices`, {
    method: 'get',
  });
}

/**
 * Get practice by id
 * @param id practice id
 * @returns {Promise.<T>}
 */
export function getPracticeByID(id) {
  return mediciFetch(`${getApiUrl()}/practices/${id}`, {
    method: 'get',
  });
}

/**
 * Get list of PRs that belong to practice
 * @returns {Promise.<T>}
 */
export function getPRsForPractice(practiceID) {
  return mediciFetch(
    `${getApiUrl()}/practices/${practiceID}/practitioners`,
    {
      method: "get"
    }
  );
}

/**
 * Search for practices
 * @returns {Promise.<T>}
 */
export function searchPractices(filter) {
  return mediciFetch(
    `${getApiUrl()}/practices/search?${serialize(filter)}`,
    {
      method: 'get',
    },
  );
}

/**
 * Delete practice
 * @param practice ID to delete
 * @returns {Promise.<T>}
 */
export function deletePractice(practiceID) {
  return mediciFetch(`${getApiUrl()}/practices/${practiceID}`, {
    method: 'delete',
  });
}

/**
 * Delete practice picture
 * @param practice ID for practice picture deletion
 * @returns {Promise.<T>}
 */
export function deletePracticePicture(practiceID) {
  return mediciFetch(`${getApiUrl()}/practices/${practiceID}/picture`, {
    method: 'delete',
  });
}

/**
 * Delete PR from practice
 * @param practice ID to delete PR from
 * @param practitioners list of PRs to delete
 * @returns {Promise.<T>}
 */
export function deletePractitionerFromPractice(practiceID, practitioners) {
  return mediciFetch(
    `${getApiUrl()}/practices/${practiceID}/practitioners`,
    {
      method: "delete",
      body: JSON.stringify(practitioners)
    }
  );
}

/**
 * Save practice
 * @param practice object to save
 * @returns {Promise.<T>}
 */
export function savePractice(practice) {
  if (practice.uid) {
    // update
    return mediciFetch(`${getApiUrl()}/practices/${practice.uid}`, {
      method: 'post',
      body: JSON.stringify(practice),
    });
  }
  // insert
  return mediciFetch(`${getApiUrl()}/practices`, {
    method: 'post',
    body: JSON.stringify(practice),
  });
}

/**
 * Enforce practice payments
 * @param practiceUid string
 * @param enforcePayment boolean
 * @returns {Promise.<T>}
 */
export function enforcePracticePayments(practiceUid, enforcePayment) {
  return mediciFetch(`${getApiUrl()}/practices/${practiceUid}/enforcePayment`, {
    method: 'put',
    body: JSON.stringify({ enforcePayment }),
  });
}

/**
 * Save practice  picture
 * @param practiceImage practice logo
 * @returns {Promise.<T>}
 */
export function savePracticeImage(practiceImage) {
  const fd = new FormData();
  fd.append("file", practiceImage.file);

  return mediciFetch(
    `${getApiUrl()}/practices/${practiceImage.uid}/picture`,
    {
      method: "post",
      body: fd
    }
  );
}

/**
 * Save practitioners to practice
 * @param practiceID Practice ID to save PRs to
 * @param practitioners list of PRs to save
 * @returns {Promise.<T>}
 */
export function savePractitionertoPractice(practiceID, practitioners) {
  return mediciFetch(
    `${getApiUrl()}/practices/${practiceID}/practitioners`,
    {
      method: "post",
      body: JSON.stringify(practitioners)
    }
  );
}
