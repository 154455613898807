import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import React from "react";

export default class InvitePatientsHistory extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    currentUid: PropTypes.string,
    history: PropTypes.array.isRequired,
    stats: PropTypes.object.isRequired,
    isDialogOpen: PropTypes.bool.isRequired,
    onDialogClose: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired
  };

  render() {
    const {
      classes,
      currentUid,
      history,
      stats,
      isDialogOpen,
      onDialogClose,
      handleCancel
    } = this.props;

    const currentScenario = history.find(
      scenario => scenario.uuid === currentUid
    );

    return (
      <Dialog
        onClose={onDialogClose}
        open={isDialogOpen}
        style={{ width: "100%" }}
        maxWidth="md"
      >
        <AppBar position="fixed" style={{ position: "relative" }}>
          <Toolbar className={classes.appBar}>
            <IconButton
              color="inherit"
              onClick={onDialogClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Grid>
              <Typography variant="h6" color="inherit" style={{ flex: 1 }}>
                Stats
              </Typography>
            </Grid>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <Grid container style={{ margin: "10px 0px" }}>
            <Grid>Validation</Grid>

            <Table>
              <TableBody>
                <TableRow>
                  <TableCell variant="head">Records Uploaded</TableCell>
                  <TableCell>{stats.nbRecordsUploaded}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Users Passed Validation</TableCell>
                  <TableCell>{stats.nbUsersPassValidation}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">
                    Patients Already Tried to Upload
                  </TableCell>
                  <TableCell>{stats.nbPatientsAlreadyTriedToUpload}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">InApp Invites Sent</TableCell>
                  <TableCell>{stats.nbInAppInvitesSent}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>

          {currentScenario &&
            currentScenario.smsStates &&
            currentScenario.smsStates.map(sms => (
              <Grid container key={sms.orderId} style={{ margin: "10px 0px" }}>
                <Grid>
                  <Grid>SMS Invite #{sms.orderId}</Grid>
                  {sms.overriddenTemplate && (
                    <Grid style={{ marginTop: 5, fontSize: 14 }}>
                      {sms.overriddenTemplate}
                    </Grid>
                  )}
                </Grid>

                {stats.smsStatsMap[sms.orderId] && (
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell variant="head">SMS Sending Status</TableCell>
                        <TableCell>
                          {sms.sent ? "Sent" : "Not sent"} -{" "}
                          {sms.cancelled ? (
                            "Canceled"
                          ) : (
                            <Button
                              size="small"
                              onClick={() => handleCancel(sms)}
                              variant="outlined"
                              className={classes.deleteButton}
                            >
                              Cancel
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell variant="head">SMS Send Time</TableCell>
                        <TableCell>{sms.sendingDateTime}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell variant="head">
                          SMS Invites Scheduled
                        </TableCell>
                        <TableCell>
                          {
                            stats.smsStatsMap[sms.orderId]
                              .nbScheduledSmsAccepted
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="head">
                          Scheduled Sms Delivered
                        </TableCell>
                        <TableCell>
                          {
                            stats.smsStatsMap[sms.orderId]
                              .nbScheduledSmsDelivered
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="head">
                          Scheduled Sms Failed
                        </TableCell>
                        <TableCell>
                          {stats.smsStatsMap[sms.orderId].nbScheduledSmsFailed}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="head">
                          Scheduled Sms Undelivered
                        </TableCell>
                        <TableCell>
                          {
                            stats.smsStatsMap[sms.orderId]
                              .nbScheduledSmsUndelivered
                          }
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                )}
              </Grid>
            ))}
        </DialogContent>
      </Dialog>
    );
  }
}
