import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import {
  withStyles,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Toolbar,
  Tooltip,
  IconButton,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TablePagination,
} from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import moment from 'moment';

import { getScenarios } from './ScenarioUtils';

import Loading from '../../components/Loading';

const styles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

class ScenarioManagement extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  };

  state = {
    scenarios: [],
    count: 0,
    loading: false,
    isShowFilter: false,
    state: '',
    scenarioName: '',
    page: 0,
    perPage: 10,
  };

  handleChange = (e) => {
    const { value, name } = e.target;
    this.setState({
      [name]: value,
      page: 0,
    }, this.fetchScenarios);
  };

  onClickFilter = () => {
    if (this.state.isShowFilter) {
      this.setState({
        isShowFilter: false,
        state: '',
        scenarioName: '',
      }, this.fetchScenarios);
    } else {
      this.setState({ isShowFilter: true });
    }
  };

  fetchScenarios = () => {
    this.setState({ loading: true });
    const { scenarioName, state, perPage, page } = this.state;
    getScenarios(scenarioName, state, perPage, perPage * page)
      .then((result) => {
        this.setState({
          scenarios: result.content,
          count: result.pagination.total,
          loading: false,
        })
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  handleChangePage = (e, page) => {
    this.setState({ page }, this.fetchScenarios);
  };

  handleChangeRowsPerPage = (e) => {
    this.setState({ perPage: e.target.value }, this.fetchScenarios);
  };

  componentWillMount() {
    this.fetchScenarios();
  }

  render() {
    const { classes, router } = this.props;
    if (this.state.loading) {
      return (
        <Grid container direction='column'>
          <Typography variant='h5'>Scenario Management</Typography>
          <div style={{ height: '50vh' }}>
            <Loading size={50} />
          </div>
        </Grid>
      );
    }
    return (
      <Grid container direction='column'>
        <Typography variant='h5'>Scenario Management</Typography>
        <Toolbar className={classes.root}>
          <div className={classes.title}>
            <Typography variant='subtitle1'>Scenarios</Typography>
          </div>
          <div className={classes.spacer} />
          <div className={classes.actions}>
            <Tooltip title="Filter list">
              <IconButton aria-label="Filter list" onClick={this.onClickFilter}>
                <FilterList />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>UID</TableCell>
              <TableCell>Started at</TableCell>
              <TableCell>Last Activity</TableCell>
              {this.state.isShowFilter ? (
                <TableCell>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="state">State</InputLabel>
                    <Select
                      className={classes.selectEmpty}
                      value={this.state.state}
                      onChange={this.handleChange}
                      inputProps={{
                        name: 'state',
                        id: 'state',
                      }}
                    >
                      <MenuItem value="">
                        <em>ANY</em>
                      </MenuItem>
                      <MenuItem value='QUEUED'>QUEUED</MenuItem>
                      <MenuItem value='SUSPENDED'>SUSPENDED</MenuItem>
                      <MenuItem value='FINISHED'>FINISHED</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              ) : (<TableCell>State</TableCell>)}
              {this.state.isShowFilter ? (
                <TableCell>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="state">Scenario Name</InputLabel>
                    <Select
                      className={classes.selectEmpty}
                      value={this.state.scenarioName}
                      onChange={this.handleChange}
                      inputProps={{
                        name: 'scenarioName',
                        id: 'scenarioName',
                      }}
                    >
                      <MenuItem value="">
                        <em>ANY</em>
                      </MenuItem>
                      <MenuItem value='GenericPaymentScenario'>GenericPaymentScenario</MenuItem>
                      <MenuItem value='UploadPatients'>UploadPatients</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              ) : (<TableCell>Scenario Name</TableCell>)}
              <TableCell>Current Step</TableCell>
              <TableCell>Hostname</TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.scenarios.length ? this.state.scenarios.map((scenario, index) => (
              <TableRow key={index}>
                <TableCell>{scenario.uid}</TableCell>
                <TableCell>{moment(scenario.startedAt).format('YYYY-MM-DD [@] h:mm a')}</TableCell>
                <TableCell>{moment(scenario.lastActivity).format('YYYY-MM-DD [@] h:mm a')}</TableCell>
                <TableCell>{scenario.state}</TableCell>
                <TableCell>{scenario.scenarioName}</TableCell>
                <TableCell>{scenario.currentStep}</TableCell>
                <TableCell>{scenario.hostname}</TableCell>
                <TableCell>
                  <Button color='secondary'
                          onClick={() => router.push(`/scenarios/${scenario.uid}`)}
                  >Details</Button>
                </TableCell>
              </TableRow>
            )) : null}
          </TableBody>
        </Table>
        <TablePagination
          count={this.state.count}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          page={this.state.page}
          rowsPerPage={this.state.perPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(ScenarioManagement));
