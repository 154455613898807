import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router';
import { withStyles, Typography, Button, Grid, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import moment from 'moment';

import { getEHRInstances, getEtlUrl } from './EHRManagementUtils';

const styles = () => ({
  button: {
    marginLeft: 'auto',
  },
  wide: {
    width: '50%',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
});

class EHRManagement extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  };

  state = {
    ehrInstances: [],
  };

  componentWillMount() {
    getEtlUrl()
      .then((etlRes) => {
        getEHRInstances(etlRes.endPoint)
          .then((result) => {
            this.setState({
              ehrInstances: result.ehrInstances,
            });
          })
          .catch(e => console.error(e));
      })
      .catch(e => console.error(e));
  }

  render() {
    const { classes, router } = this.props;
    return (
      <Grid container direction='column' className='pageContainer'>
        <Typography variant='h5'>EHR Management</Typography>
        <Button variant='contained' color='secondary' className={classes.button}
          onClick={() => router.push('/ehr-management/create')}>
          Link New EHR
        </Button>
        <Typography variant='subtitle1'>Linked EHRs</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell/>
              <TableCell>Site Service Key</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Status</TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.ehrInstances && this.state.ehrInstances.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <div className={classes.noWrap}>Created at: {moment(item.createdAt).format('YYYY-MM-DD [@] h:mm a')}</div>
                  <div className={classes.noWrap}>Updated at: {moment(item.updatedAt).format('YYYY-MM-DD [@] h:mm a')}</div>
                </TableCell>
                <TableCell>{item.siteKey}</TableCell>
                <TableCell className={classes.wide}>{item.title}</TableCell>
                <TableCell>
                  <div className={classes.noWrap}>Is Started: {item.syncStarted ? 'True' : 'False'}</div>
                  <div className={classes.noWrap}>Is Paused: {item.syncPaused ? 'True' : 'False'}</div>
                  <div className={classes.noWrap}>Is Error: {item.hasError ? 'True' : 'False'}</div>
                </TableCell>
                <TableCell><Link to={`/ehr-management/details/${item.sourceKey}`}>Details</Link></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(EHRManagement));
