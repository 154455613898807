import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter} from 'react-router';
import { debounce } from 'underscore';
import {
  withStyles, Button, Grid, Typography,
  Table, TableHead, TableBody, TableRow, TableCell, TablePagination, TextField, InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import {
  setNotifyHidden,
  setNotifySuccess,
  setNotifyWarning
} from '../../components/notification/NotifyUtils';
import Patients from './Patients';
import { getActivationCodes } from '../../components/activationCode/ActivationCodeUtils';

import { convertToFriendlyDateTime } from '../../components/utils/DateUtils';
import Loading from '../../components/Loading';

const styles = theme => ({
  button: {
    margin: `0px ${theme.spacing.unit}px`,
  },
  actionsCell: {
    textAlign: 'right',
  },
  loadingWrap: {
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    background: 'rgba(255, 255, 255, 0.75)',
    zIndex: 1,
  },
});

class ActivationCodePage extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    setNotifyHidden: PropTypes.func.isRequired,
    setNotifyWarning: PropTypes.func.isRequired,
    setNotifySuccess: PropTypes.func.isRequired,
  };

  state = {
    codes: [],
    count: 0,
    searchValue: '',
    limit: 25,
    page: 0,
    loading: false,
  };

  retrieveActivationCodes = async () => {
    const { searchValue, limit, page } = this.state;
    const value = searchValue.toUpperCase();
    this.setState({ loading: true });
    try {
      const result = await getActivationCodes({ code: value }, limit, limit * page);
      this.setState({
        count: result.pagination.total,
        codes: result.content,
        loading: false,
      });
    } catch (e) {
      this.setState({ loading: false });
      this.props.setNotifyWarning(e.message);
    }
  };

  onSearch = debounce(() => {
    this.retrieveActivationCodes();
  }, 500);

  handleSearch = (e) => {
    const { value } = e.target;
    this.setState({ searchValue: value }, this.onSearch);
  };

  handleChangeRowsPerPage = (e) => {
    this.setState({
      limit: e.target.value,
      page: 0,
    }, this.retrieveActivationCodes);
  };

  handleChangePage = (e, page) => {
    this.setState({ page }, this.retrieveActivationCodes);
  };

  componentWillMount() {
    this.retrieveActivationCodes();
  }

  render() {
    const { classes, router } = this.props;

    return (
      <Grid container direction='column'>
        {this.state.loading && (
          <div className={classes.loadingWrap}>
            <Loading/>
          </div>
        )}
        <Typography variant='h5'>Activation Codes</Typography>
        <Grid container direction='row' justify='space-between' style={{ padding: '12px 0' }} alignItems='center'>
          <TextField
            label='Search'
            value={this.state.searchValue}
            onChange={this.handleSearch}
            style={{ width: '275px' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button variant='contained' color='secondary' className={classes.button}
                  onClick={() => router.push('/activation-code/create')}>
            Add Activation Code
          </Button>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell numeric>Patients</TableCell>
              <TableCell>Start</TableCell>
              <TableCell>End</TableCell>
              <TableCell>CUR</TableCell>
              <TableCell numeric>Credit</TableCell>
              <TableCell>Description</TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.codes.map((code, index) => (
              <TableRow key={index}>
                <TableCell>
                  <a href={`https://medicipa.page.link/?apn=com.medici&efr=1&isi=1064907624&ibi=com.medici&link=https://app.medici.md/%23/patients/settings/activation/${code.code}`}
                    target='_blank'
                    rel='noopener noreferrer'>
                  {code.code}
                  </a>
                </TableCell>
                <TableCell numeric>
                  <Patients codeUid={code.uid}>Show</Patients>
                </TableCell>
                <TableCell>{convertToFriendlyDateTime(code.dateStart)}</TableCell>
                <TableCell>{convertToFriendlyDateTime(code.dateEnd)}</TableCell>
                <TableCell>{code.creditCurrency}</TableCell>
                <TableCell numeric>{code.creditAmount / 100}</TableCell>
                <TableCell>{code.description}</TableCell>
                <TableCell className={classes.actionsCell}>
                  <Button color='secondary' onClick={() => router.push(`/activation-code/${code.uid}`)}>
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          count={this.state.count}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          page={this.state.page}
          rowsPerPage={this.state.limit}
          rowsPerPageOptions={[10, 25, 50, 100]}
        />
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setNotifySuccess: (title) => {
      dispatch(setNotifySuccess(title));
    },
    setNotifyWarning: (title) => {
      dispatch(setNotifyWarning(title));
    },
    setNotifyHidden: () => {
      dispatch(setNotifyHidden());
    },
  };
}

export default connect(null, mapDispatchToProps)(withRouter(withStyles(styles)(ActivationCodePage)))
