import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, ListItem, Button, Checkbox } from '@material-ui/core';

const styles = theme => ({
  deleteBtn: {
    margin: `0 ${theme.spacing.unit}px`,
    color: theme.palette.error.main,
    "&:hover": {
      background: "rgba(255,87,87,0.08)"
    }
  }
});

class CustomRow extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    keyIndex: PropTypes.number,
    data: PropTypes.object,
    checked: PropTypes.bool,
    doEdit: PropTypes.func.isRequired,
    doDelete: PropTypes.func.isRequired,
    doSelect: PropTypes.func,
  };

  render() {
    const { classes } = this.props;
    return (
      <ListItem key={`li${this.props.keyIndex}`} className="list-entry">
        {this.props.doSelect ? (
          <Checkbox
            checked={this.props.checked}
            onChange={this.props.doSelect.bind(this, this.props.data.id)}
          />
          ) : null
        }
        <span className="listItem">{this.props.data.title}</span>
        <span className="linkContainer">
          {this.props.doEdit ? (
            <label
              className="list-entry-link"
              onClick={this.props.doEdit.bind(this, this.props.data.id)}
            >
              edit
            </label>
          ) : null}
          <Button
            className={classes.deleteBtn}
            onClick={this.props.doDelete.bind(this, this.props.data)}
          >
            delete
          </Button>
        </span>
      </ListItem>
    );
  }
}

export default withStyles(styles)(CustomRow);
