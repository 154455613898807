import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Typography,
  withStyles
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import PaymentIcon from "@material-ui/icons/Payment";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import Loading from "../../components/Loading";
import {
  cancelSubscription,
  getSubscriptionHistory,
  getSubscriptionStatus,
  getAllActiveSubscriptions
} from "../../components/practitioners/PractitionerUtils";
import SubscriptionUtils from "../../components/utils/SubscriptionUtils";
import * as helpers from "../../utils/helpers";

const styles = ({ palette, spacing }) => ({
  container: {
    border: "1px solid #E5E5E5",
    backgroundColor: "#F6F6F6",
    padding: 24
  },
  labelField: {
    color: palette.primary.light,
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  textField: {
    border: "1px solid #E7E7E7",
    borderRadius: "3px",
    backgroundColor: "#FBFBFB",
    padding: "6px 7px"
  },
  title: {
    color: palette.primary.light
  },
  line: {
    backgroundColor: palette.primary.light,
    height: 2,
    width: "100%",
    marginBottom: 23
  },
  subscriptionDetails: {
    color: palette.primary.light,
    fontSize: 16,
    fontWeight: 500
  },
  subscriptionPrice: {
    color: palette.primary.light,
    textTransform: "capitalize",
    fontSize: 18,
    fontWeight: 500
  },
  subscriptionDate: {
    color: palette.primary.light,
    fontSize: 14,
    marginTop: 6
  },
  subscriptionStatus: {
    fontSize: 18,
    fontWeight: 500,
    marginLeft: "auto",
    textTransform: "capitalize",
    color: palette.text.secondary,
    "&> span.active": {
      color: palette.secondary.main
    }
  },
  cancelButton: {
    color: palette.error.main,
    borderColor: palette.error.main,
    marginTop: spacing.unit,
    marginLeft: "auto"
  }
});

class PractitionerPayment extends React.Component {
  static propTypes = {
    setNotifyWarning: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
    practitioner: PropTypes.object.isRequired,
    classes: PropTypes.object,
    save: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    const { practitioner } = this.props;

    this.state = {
      subscription: { plan: "basic", recurlyAccountUrl: null },
      subscriptionHistory: { expiredSubscriptions: [] },
      subscriptions: [{ plan: "basic", recurlyAccountUrl: null, isPractice: false }],
      pricingModel: practitioner.pricingModel,
      loading: true
    };
  }

  async componentDidMount() {
    await this.loadData(null, false);
  }

  loadData = async (_, sync = true) => {
    const { practitioner } = this.props;
    this.setState({ loading: true });

    try {
      const subscription = await getSubscriptionStatus(practitioner.uid, sync);
      const subscriptionHistory = await getSubscriptionHistory(
        practitioner.uid
      );
      const subscriptions = await getAllActiveSubscriptions(practitioner.uid, sync);
      this.setState({ subscription, subscriptionHistory, subscriptions });
    } catch (error) {
      this.props.setNotifyWarning(helpers.errorToString(error));
      console.log(error);
    }

    this.setState({ loading: false });
  };

  handleChange = name => event => {
    const { pricingModel } = this.state;
    pricingModel[name] = event.target.value;

    if (name === "amount") {
      pricingModel.amount = event.target.value * 100;
      if (this.props.practitioner.subscriptionPlan === 'premium') {
        if (pricingModel.amount > 0) {
          pricingModel.type = 'ppa';
        } else {
          pricingModel.type = 'free';
        }
      } else {
        pricingModel.type = 'free';
      }
    }

    this.setState({ ...pricingModel });
  };

  handleSave = async () => {
    this.setState({ loading: true });

    const { practitioner } = this.props;
    const { pricingModel } = this.state;
    practitioner.pricingModel = pricingModel;
    practitioner.currency = pricingModel.currency;

    await this.props.save(practitioner);
    this.setState({ loading: false });
  };

  handleCancel = async () => {
    if (confirm("Are you sure you want to cancel the Subscription?")) {
      const { practitioner } = this.props;

      this.setState({ loading: true });

      try {
        await cancelSubscription(practitioner.uid);
        await this.loadData();
      } catch (error) {
        this.props.setNotifyWarning(helpers.errorToString(error));
      }

      this.setState({ loading: false });
    }
  };

  openBillingInfo = async () => {
    const { practitioner } = this.props;
    const { subscription } = this.state;

    try {
      if (!subscription.recurlyAccountUrl) {
        const recurlyProfile = await SubscriptionUtils.createRecurlyAccount(practitioner.uid);
        subscription.recurlyAccountUrl = recurlyProfile.recurlyAccountUrl;
        await this.setState({ ...subscription });
      }
      const win = window.open(subscription.recurlyAccountUrl, '_blank');
      if (win !== null) {
        win.focus();
      }
    } catch (error) {
      this.props.setNotifyWarning('unexpected reply from server');
    }
  };

  openPractice = practiceUid => {
    this.props.router.push(`/practices/${practiceUid}`);
  };

  renderSubscription = (subscription, i=0) => {
    const { classes } = this.props;

    if (subscription.plan === "basic") {
      return (
        <Paper style={{ padding: 24, marginBottom: 32 }} id="subscription" key={i}>
          <div className={classes.subscriptionPrice}>Basic</div>
          <div className={classes.subscriptionDetails}>
            {subscription.isPractice && (
              <span>
                Practice -
                <Button  onClick={() => this.openPractice(subscription.practiceUid)} color="primary">&quot;{subscription.practiceName}&quot;</Button>
              </span>
            )}
            {!subscription.isPractice && (
              <span>Individual</span>
            )}
          </div>
        </Paper>
      );
    }

    return (
      <Paper style={{ padding: 24, marginBottom: 32 }} id="subscription" key={i}>
        <Grid container direction="row">
          <div className={classes.subscriptionPrice}>
            {subscription.plan} - ${subscription.price / 100}/mo.
          </div>
          <div className={classes.subscriptionStatus}>
            {subscription.subscriptionCancelRequested ? (
              "Cancellation Pending"
            ) : (
              <span className={subscription.status}>{subscription.status}</span>
            )}
          </div>
        </Grid>
        <div className={classes.subscriptionDetails}>
          {subscription.isPractice && (
            <span>
              Practice -
              <Button  onClick={() => this.openPractice(subscription.practiceUid)} color="primary">&quot;{subscription.practiceName}&quot;</Button>
            </span>
          )}
          {!subscription.isPractice && (
            <span>Individual</span>
          )}
        </div>
        <Grid container direction="row" alignItems="center">
          <div className={classes.subscriptionDate}>
            Started {helpers.renderDate(subscription.activatedAt, "MM/DD/YYYY")}
          </div>
          {subscription.subscriptionCancelRequested && (
            <Button
              variant="outlined"
              className={classes.cancelButton}
              onClick={this.handleCancel}
            >
              Cancel
            </Button>
          )}
        </Grid>
      </Paper>
    );
  };

  renderAllSubscription = () => {
    const { subscriptions } = this.state;

    return (
      <Fragment>
        {subscriptions.map((subscription, i) => this.renderSubscription(subscription, i))}
      </Fragment>
    );
  }

  renderSubscriptionHistory = () => {
    const { classes } = this.props;
    const { subscriptionHistory } = this.state;

    if (subscriptionHistory.expiredSubscriptions.length === 0) {
      return (
        <Paper
          style={{ padding: 24, marginBottom: 32 }}
          id="subscriptionHistory"
        >
          <div className={classes.subscriptionPrice}>
            Subscription history is empty
          </div>
        </Paper>
      );
    }

    return (
      <Fragment>
        {subscriptionHistory.expiredSubscriptions.map(history => (
          <Paper
            style={{ padding: 24, marginBottom: 32, width: 340 }}
            key={history.externalUid}
            id={`subscriptionHistory-${history.externalUid}`}
          >
            <div className={classes.subscriptionPrice}>Premium</div>
            <Grid container direction="row">
              <div className={classes.subscriptionDate}>
                Started {helpers.renderDate(history.activatedAt, "MM/DD/YYYY")}
              </div>
              <div
                className={classes.subscriptionDate}
                style={{ marginLeft: "auto" }}
              >
                Ended {helpers.renderDate(history.expiredAt, "MM/DD/YYYY")}
              </div>
            </Grid>
          </Paper>
        ))}
      </Fragment>
    );
  };

  render() {
    const { classes, practitioner } = this.props;
    const { pricingModel, loading } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <Grid container>
        <Grid container direction="row">
          <Grid item xs={6} style={{ marginRight: "auto" }}>
            <Grid container direction="row" alignContent="center">
              <Typography variant="h6" className={classes.title}>
                Subscription
              </Typography>
              <IconButton
                style={{ marginLeft: "auto", padding: 0 }}
                className={classes.title}
                onClick={this.openBillingInfo}
              >
                <PaymentIcon />
              </IconButton>
              <IconButton
                style={{ marginLeft: "8px", padding: 0 }}
                className={classes.title}
                onClick={this.loadData}
              >
                <RefreshIcon />
              </IconButton>
            </Grid>
            <div className={classes.line} />
            {this.renderAllSubscription()}
            <Grid />
            <Typography variant="subtitle1" className={classes.title}>
              Subscription History of {practitioner.prettyName}
            </Typography>
            <div className={classes.line} />
            {this.renderSubscriptionHistory()}
          </Grid>
          <Grid item xs={5}>
            <div className={classes.container}>
              <Typography variant="h6" className={classes.title}>
                Pricing
              </Typography>
              <Grid container direction="row" style={{ marginTop: 20 }}>
                <TextField
                  type="number"
                  label="Amount per Consult"
                  value={pricingModel.amount / 100}
                  onChange={this.handleChange("amount")}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ width: "55%" }}
                />
                <TextField
                  select
                  label="Currency"
                  value={pricingModel.currency}
                  onChange={this.handleChange("currency")}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ width: "40%", marginLeft: "auto" }}
                >
                  {["USD", "ZAR", "NAD"].map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid container direction="row" style={{ marginTop: 20 }}>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: "auto", marginTop: 16 }}
                  disabled={loading}
                  onClick={this.handleSave}
                >
                  Save
                </Button>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(PractitionerPayment);
