import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, TextField, Button, Grid, Typography,
  FormControlLabel, Checkbox,
} from '@material-ui/core';
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 60,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
  container: {
    marginBottom: 18
  },
  button: {
    marginRight: theme.spacing.unit * 2
  },
  deleteBtn: {
    marginRight: 'auto',
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    }
  }
});

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

class EditBiography extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    data: PropTypes.shape({
      type: PropTypes.string,
      model: PropTypes.object,
      index: PropTypes.number,
    })
  };

  constructor(props) {
    super(props);

    const { data } = this.props;

    this.state = {
      type: data.type,
      index: data.index,
      model: data.model,
      error: {
        summary: false,
        body: false,
        start: false,
        end: false,
      }
    }
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      model: { ...this.state.model, [id]: value },
      error: { ...this.state.error, [id]: false },
    })
  };

  handleChangeCurrent = (e) => {
    const { checked } = e.target;
    this.setState({
      model: { ...this.state.model, current: checked },
    })
  };

  handleChangeDate = name => date => {
    this.handleChange({ target : { id: name, value: date.format('YYYY-MM-DD')} });
  };

  handleClose = () => {
    this.props.onClose();
  };

  validate = () => {
    let isValid = true;
    const { summary, body, start, end, current } = this.state.model;
    const error = {
      summary: false,
      body: false,
      start: false,
      end: false,
    };

    if (!summary) {
      error.summary = true;
      isValid = false;
    }

    if (!body) {
      error.body = true;
      isValid = false;
    }

    if (!moment(start).isValid() || moment(start).isAfter(moment())) {
      error.start = true;
      isValid = false;
    }

    if (!current && (!moment(end).isValid() || moment(end).isAfter(moment()) || moment(end).isBefore(moment(start)))) {
      error.end = true;
      isValid = false;
    }

    this.setState({ error });

    return isValid;
  };

  handleSave = () => {
    if (!this.validate()) return;
    const { type, index, model } = this.state;
    this.props.onSave(model, type, index);
  };

  handleDelete = () => {
    const { type, index } = this.state;
    this.props.onDelete(type, index);
  };

  render() {
    const { classes } = this.props;
    return (
      <div style={getModalStyle()} className={classes.paper}>
        <Grid className={classes.container} container>
          <Typography variant='h6'>{typeof this.state.index === 'undefined' ? 'Add' : 'Edit'} {this.state.type === 'education' ? 'Education' : 'Experience'}</Typography>
        </Grid>
        <form noValidate autoComplete='off'>
          <Grid className={classes.container} container>
            <TextField
              id='summary'
              label={this.state.type === 'education' ? 'School/University' : 'Company/Organization'}
              variant='filled'
              value={this.state.model.summary}
              onChange={this.handleChange}
              error={this.state.error.summary}
              helperText={this.state.error.summary && 'This field is required'}
              required
              fullWidth
            />
          </Grid>
          <Grid className={classes.container} container>
            <TextField
              id='body'
              label={this.state.type === 'education' ? 'Degree' : 'Job Title'}
              variant='filled'
              value={this.state.model.body}
              onChange={this.handleChange}
              error={this.state.error.body}
              helperText={this.state.error.body && 'This field is required'}
              required
              fullWidth
            />
          </Grid>
          <Grid className={classes.container} container
                direction="row"
                justify="space-between">
            <DatePicker
              id='start'
              label='From'
              value={this.state.model.start}
              onChange={this.handleChangeDate('start')}
              error={this.state.error.start}
              helperText={this.state.error.start && 'Please enter a valid date'}
              format='MM/DD/YYYY'
              variant='filled'
            />
            {this.state.model.current ? (
              <TextField
                label='To'
                value='Present'
                variant='filled'
                readOnly
              />
            ) : (
              <DatePicker
                id='end'
                label='To'
                value={this.state.model.end}
                onChange={this.handleChangeDate('end')}
                error={this.state.error.end}
                helperText={this.state.error.end && 'Please enter a valid date'}
                format='MM/DD/YYYY'
                variant='filled'
              />
            )}
          </Grid>
          {this.state.type === 'experiences' && (
            <Grid className={classes.container} container>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.model.current}
                    onChange={this.handleChangeCurrent}
                    value="current"
                  />
                }
                label="Currently work/volunteer here"
              />
            </Grid>
          )}
          <Grid className={classes.container} container
                direction="row"
                justify="flex-end">
            {typeof this.state.index !== 'undefined' &&
              <Button variant='contained' className={classes.deleteBtn} onClick={this.handleDelete}>Delete</Button>}
            <Button className={classes.button} onClick={this.handleClose}>Cancel</Button>
            <Button variant='contained' color='primary' onClick={this.handleSave}>Save</Button>
          </Grid>
        </form>
      </div>
    )
  }
}

export default withStyles(styles)(EditBiography);
