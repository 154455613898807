import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Grid, Typography, withStyles, Table, TableRow, TableCell, Button } from '@material-ui/core';
import moment from 'moment';
import ReactJson from 'react-json-view';

import { getScenarioByUid, resumeScenario } from './ScenarioUtils';

import Loading from '../../components/Loading';
import {
  setNotifyHidden,
  setNotifySuccess,
  setNotifyWarning
} from '../../components/notification/NotifyUtils';

const styles = theme => ({
  valignTop: {
    verticalAlign: 'top',
    paddingTop: '8px',
  },
  container: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
});

class ScenarioDetails extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    params: PropTypes.shape({
      uid: PropTypes.string.isRequired,
    }),
    setNotifyHidden: PropTypes.func.isRequired,
    setNotifyWarning: PropTypes.func.isRequired,
    setNotifySuccess: PropTypes.func.isRequired,
  };

  state = {
    scenario: null,
  };

  onResume = async () => {
    try {
      const resp = await resumeScenario(this.props.params.uid);
      this.props.setNotifySuccess(resp.message);
      const scenario = await getScenarioByUid(this.props.params.uid);
      this.setState({ scenario });
    } catch (e) {
      this.props.setNotifyWarning(e.message);
    }
  };

  componentWillMount() {
    getScenarioByUid(this.props.params.uid)
      .then((scenario) => {
        this.setState({ scenario });
      })
      .catch(() => {
        this.props.router.replace('/scenarios');
      });
  }

  render() {
    const { classes, router } = this.props;
    const { scenario } = this.state;
    return (
      <Grid container direction='column' className='pageContainer'>
        <Typography variant='h5'>Scenario Management</Typography>
        <Typography variant='subtitle1'>Details</Typography>
        {scenario ? (
          <Table>
            <TableRow>
              <TableCell variant='head'>UID</TableCell>
              <TableCell>{scenario.uid}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Scenario Name</TableCell>
              <TableCell>{scenario.scenarioName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Started at</TableCell>
              <TableCell>{moment(scenario.startedAt).format('YYYY-MM-DD [@] h:mm a')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Last Activity</TableCell>
              <TableCell>{moment(scenario.lastActivity).format('YYYY-MM-DD [@] h:mm a')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>State</TableCell>
              <TableCell>
                {scenario.state}
                {scenario.state === 'SUSPENDED' ? (
                  <Button variant='contained' color='secondary'
                          style={{ marginLeft: '12px' }}
                          onClick={this.onResume}>
                    Resume
                  </Button>
                ) : null}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Current Step</TableCell>
              <TableCell>{scenario.currentStep}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Hostname</TableCell>
              <TableCell>{scenario.hostname}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head' className={classes.valignTop}>Description</TableCell>
              <TableCell>{scenario.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head' className={classes.valignTop}>Storage Info</TableCell>
              <TableCell>
                <ReactJson src={scenario.storage} collapsed={true} enableClipboard={false}/>
              </TableCell>
            </TableRow>
          </Table>
        ) : (
          <div style={{ height: '50vh' }}>
            <Loading size={50} />
          </div>
        )}
        <div className={classes.container}>
          <Button variant='contained' color='secondary' onClick={() => router.replace('/scenarios')} >
            Back
          </Button>
        </div>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setNotifySuccess: (title) => {
      dispatch(setNotifySuccess(title));
    },
    setNotifyWarning: (title) => {
      dispatch(setNotifyWarning(title));
    },
    setNotifyHidden: () => {
      dispatch(setNotifyHidden());
    },
  };
}

export default connect(null, mapDispatchToProps)(withRouter(withStyles(styles)(ScenarioDetails)));
