import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

export default class AddressAutoComplete extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    helperText: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.bool,
    onChange: PropTypes.func,
    id: PropTypes.string,
    InputLabelProps: PropTypes.object
  };

  static defaultProps = {
    id: "addressAutocompleteField"
  };

  getDefaultAddress = () => ({
    city: "",
    country: "",
    name: "",
    postalCode: "",
    state: "",
    street: "",
    streetNumber: ""
  });

  componentWillMount() {
    this.setState({ value: this.props.value || "" });
  }

  componentDidMount() {
    const input = document.getElementById(this.props.id);
    const options = {
      types: ["address"]
    };
    const geo = new window.google.maps.places.Autocomplete(input, options);
    geo.addListener("place_changed", this.placesChanged.bind(this, geo));
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ value: nextProps.value });
    }
  }

  placesChanged(geo) {
    const place = geo.getPlace();
    if (place) {
      const address = Object.assign(
        {},
        this.processAddress(place.address_components, place.geometry.location.lat(), place.geometry.location.lng())
      );
      this.props.onChange(address);
    }
  }

  processAddress = (addressComponents, lat, lng) => {
    const components = {};
    const address = this.getDefaultAddress();

    // Convert googles format into a simple location object
    if (addressComponents) {
      addressComponents.forEach(v1 => {
        v1.types.forEach(v2 => {
          components[v2] = v1.long_name;
        });
      });

      address.city = components.locality || components.sublocality || "";
      address.country = components.country || "";
      address.name = `${components.street_number || ""} ${components.route ||
        ""}`;
      address.postalCode = components.postal_code || "";
      address.state = components.administrative_area_level_1 || "";
      address.street = components.route || "";
      address.streetNumber = components.street_number || "";
    }

    if (lat && lng) {
      address.geopoint = `${lat},${lng}`;
    }

    return address;
  };

  _handleChange = event => {
    const { value } = event.target;
    this.setState({ value });
    if (!value) {
      this.props.onChange(this.getDefaultAddress());
    }
  };

  render() {
    return (
      <TextField
        id={this.props.id}
        label={this.props.label}
        helperText={this.props.helperText}
        value={this.state.value}
        onChange={this._handleChange}
        required={this.props.required}
        error={this.props.error}
        fullWidth
        InputLabelProps={this.props.InputLabelProps}
      />
    );
  }
}
