import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './Notify.css';
import { NOTIFY_HIDDEN, clearNotify } from './NotifyUtils';

class Notify extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    status: PropTypes.string,
    clearNotify: PropTypes.func.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.status !== NOTIFY_HIDDEN) {
      this.closeNotification(4000);
    }
  }

  timeoutID = 0;
  closeNotification(timeout) {
    timeout = timeout || 0;
    if (this.timeoutID) {
      clearTimeout(this.timeoutID);
      this.timeoutID = 0;
    }
    this.timeoutID = setTimeout(() => {
      this.props.clearNotify();
    }, timeout);
  }

  forceCloseNotification() {
    if (this.timeoutID) {
      clearTimeout(this.timeoutID);
      this.timeoutID = 0;
    }
    this.props.clearNotify();
  }

  render() {
    return (
      <div id="notify" className={this.props.status}>
        <label className="notifyTitle">{this.props.title}</label>
        <label
          className="notifyClose"
          onClick={this.forceCloseNotification.bind(this)}
        >
          x
        </label>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    title: state.notify.title,
    status: state.notify.status,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearNotify: () => {
      dispatch(clearNotify());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notify);
