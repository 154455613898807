import React from 'react';
import PropTypes from 'prop-types';

export default class SubscriptionPlanSelector extends React.Component {
  static propTypes = {
    plan: PropTypes.string,
    onPlanChange: PropTypes.func,
    disabled: PropTypes.bool,
  };

  render() {
    return (
      <select
        id="subscriptionPlan"
        className="form-select"
        value={this.props.plan}
        onChange={this.props.onPlanChange}
        disabled={this.props.disabled}
      >
        <option value="basic"> Basic </option>
        <option value="premium"> Premium </option>
      </select>
    );
  }
}
