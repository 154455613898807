import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  Typography,
  DialogContent,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  DialogActions,
  Button,
  FormHelperText,
  Avatar,
} from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PhotoIcon from '@material-ui/icons/Photo';
import { DatePicker } from 'material-ui-pickers';
import Loading from '../Loading';

const styles = theme => ({
  appBar: {
    position: "relative",
    minWidth: "500px",
    marginBottom: theme.spacing.unit * 2,
  },
  addButton: {
    marginLeft: "20px"
  },
  searchButton: {
    marginTop: theme.spacing.unit,
    marginLeft: "auto"
  },
  flex: {
    flex: 1
  },
  textField: {
    margin: `${theme.spacing.unit}px 0`,
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
  },
  container: {
    padding: theme.spacing.unit * 2,
  },
  image: {
    width: 150,
    height: 150,
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

function getDefaultState() {
  return {
    pet: {
      uid: '',
      name: '',
      typeCode: '',
      genderCode: '',
      breed: '',
      dateOfBirth: '',
    },
    errors: {
      name: false,
      typeCode: false,
      genderCode: false,
      dateOfBirth: false,
    },
    imageUrl: null,
    image: null,
  }
}

class PetDialog extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    pet: PropTypes.object,
    isLoading: PropTypes.bool,
  };

  state = getDefaultState();

  handleBack(isHasChanges) {
    this.props.handleClose(isHasChanges);
  }

  handleChange = (field) => (event) => {
    this.setState({
      pet: { ...this.state.pet, [field]: event.target.value },
      errors: { ...this.state.errors, [field]: false },
    });
  };

  handleSave = () => {
    if (!this.props.isLoading && this.validate()) {
      this.props.handleClose(this.state.pet, this.state.image);
    }
  };

  validate = () => {
    const { errors } = getDefaultState();
    let isValid = true;

    if (!this.state.pet.name.trim()) {
      errors.name = true;
      isValid = false;
    }

    if (!this.state.pet.typeCode) {
      errors.typeCode = true;
      isValid = false;
    }

    if (!this.state.pet.genderCode) {
      errors.genderCode = true;
      isValid = false;
    }

    if (!this.state.pet.dateOfBirth) {
      errors.dateOfBirth = true;
      isValid = false;
    }

    this.setState({ errors });
    return isValid;
  };

  handleClickUpload = () => {
    this.inputEl.click();
  };

  handleFileUpload = (event) => {
    if (event.target.files.length) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        this.setState({
          imageUrl: e.target.result,
          image: file,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      if (this.props.pet) {
        const { uid, name, typeCode, genderCode, breed, dateOfBirth, imageUrl } = this.props.pet;
        this.setState({ ...getDefaultState(), pet: {
            uid, name, typeCode, genderCode, breed, dateOfBirth,
          },
          imageUrl,
        });
      } else {
        this.setState(getDefaultState());
      }
    }
  }

  render() {
    const { classes, isLoading } = this.props;
    const { pet, errors, imageUrl } = this.state;

    return (
      <Dialog
        open={this.props.isOpen}
        onClose={() => this.handleBack(false)}
        TransitionComponent={Transition}
        maxWidth="lg"
      >
        <AppBar className={classes.appBar} position="fixed">
          <Toolbar className={classes.toolbar}>
            <IconButton
              id="backHeader"
              color="inherit"
              onClick={() => this.handleBack(false)}
              aria-label="Close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              Pet Profile
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <Grid className={classes.container} container direction='column' alignContent='center'>
            <Avatar src={imageUrl} className={classes.image}>
              <PhotoIcon />
            </Avatar>
            <Button color='primary' style={{ margin: 12 }} onClick={this.handleClickUpload}>
              Upload
            </Button>
          </Grid>
          <TextField
            className={classes.textField}
            label='Pet Name'
            value={pet.name}
            onChange={this.handleChange('name')}
            error={errors.name}
            helperText={errors.name ? 'This field is required' : ''}
            required
            fullWidth
          />
          <FormControl className={classes.textField} fullWidth error={errors.typeCode}>
            <InputLabel>Type</InputLabel>
            <Select value={pet.typeCode} onChange={this.handleChange('typeCode')}>
              <MenuItem value='cat'>Cat</MenuItem>
              <MenuItem value='dog'>Dog</MenuItem>
              <MenuItem value='horse'>Horse</MenuItem>
              <MenuItem value='cow'>Cow</MenuItem>
              <MenuItem value='bird'>Bird</MenuItem>
              <MenuItem value='pig'>Pig</MenuItem>
              <MenuItem value='other'>Other Pet</MenuItem>
            </Select>
            {errors.typeCode && (
              <FormHelperText>This field is required</FormHelperText>
            )}
          </FormControl>
          <TextField
            className={classes.textField}
            label='Breed'
            placeholder='Optional'
            value={this.state.breed}
            onChange={this.handleChange('breed')}
            fullWidth
          />
          <Grid container justify='space-between' className={classes.textField}>
            <DatePicker
              label="DoB"
              id="dateOfBirth"
              value={pet.dateOfBirth ? pet.dateOfBirth : null}
              InputLabelProps={{ shrink: true }}
              style={{ width: 180, marginRight: 10 }}
              onChange={date =>
                this.handleChange("dateOfBirth")({
                  target: { value: date.format('YYYY-MM-DD') }
                })
              }
              format="MM/DD/YYYY"
              disableFuture
            />
            <FormControl style={{ width: 180 }} error={errors.genderCode}>
              <InputLabel>Sex</InputLabel>
              <Select value={pet.genderCode} onChange={this.handleChange('genderCode')}>
                <MenuItem value='male'>Male</MenuItem>
                <MenuItem value='female'>Female</MenuItem>
                <MenuItem value='neutered_male'>Neutered Male</MenuItem>
                <MenuItem value='spayed_female'>Spayed Female</MenuItem>
                <MenuItem value='unknown'>Unknown</MenuItem>
              </Select>
              {errors.genderCode && (
                <FormHelperText>This field is required</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained'
                  color='primary'
                  onClick={this.handleSave}>
            {isLoading ? <Loading size={24} /> : pet.uid ? 'Save' : 'Add'}
          </Button>
        </DialogActions>
        <input
          ref={c => { this.inputEl = c; }}
          type='file'
          accept='image/*'
          onChange={this.handleFileUpload}
          hidden
        />
      </Dialog>
    );
  }
}

export default withStyles(styles)(PetDialog);
