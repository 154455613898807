import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, MenuItem, TextField, Typography, withStyles } from '@material-ui/core';
import { DatePicker } from 'material-ui-pickers';

import PatientAccount from './PatientAccount'

import * as helpers from '../../utils/helpers';

const countries = [
  {
    label: "United States",
    value: "us"
  },
  {
    label: "Namibia",
    value: "na"
  },
  {
    label: "South Africa",
    value: "za"
  }
];

const languages = [
  { value: 'en', label: 'English' },
  { value: 'es', label: 'Spanish' },
  { value: 'af', label: 'Afrikaans' },
  { value: 'xh', label: 'Xhosa' },
  { value: 'zu', label: 'Zulu' },
];

const styles = ({ palette }) => ({
  title: {
    color: palette.primary.light
  },
  line: {
    backgroundColor: palette.primary.light,
    height: 2,
    width: "100%",
    marginBottom: 23
  },
  container: {
    marginBottom: 18
  },
  labelField: {
    color: palette.primary.light,
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  textField: {
    border: "1px solid #E7E7E7",
    borderRadius: "3px",
    backgroundColor: "#FBFBFB",
    padding: "6px 7px"
  },
});

class PatientInfo extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    patient: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    onCopyInviteCode: PropTypes.func,
    onError: PropTypes.func,
    isCreate: PropTypes.bool,
  };

  static defaultProps = {
    onCopyInviteCode: () => {},
    isCreate: false
  };

  constructor(props) {
    super(props);

    const { patient } = this.props;
    this.state = {
      firstname: patient.firstname,
      lastname: patient.lastname,
      email: patient.email,
      phone: patient.phone,
      birthdate: patient.birthdate,
      gender: patient.gender,
      country: patient.country,
      location: patient.location,
      addressHelper: helpers.locationToString(patient.location),
      language: patient.language,
      loading: false,
    };
  }

  handleChange = name => event => {
    let { value } = event.target;
    if (name === 'gender' && value) value = parseInt(value, 10);
    this.setState({
      [name]: value
    });

    if(name === 'addressHelper') {
      const regexp = new RegExp(' [0-9]+|[0-9]+ ', 'g');
      if (regexp.test(value)) {
        this.handleChangeLocation('street')({ target: { value: value.replace(regexp, '').trim() } });
        this.handleChangeLocation('streetNumber')({ target: { value: value.match(regexp)[0].trim() } });
      } else {
        this.handleChangeLocation('street')(event);
        this.handleChangeLocation('streetNumber')({ target: { value: '' } });
      }
    }
  };

  handleChangeLocation = name => event => {
    const { location } = this.state;
    location[name] = event.target.value;

    this.setState({ location });
  };

  placesChanged = () => {
    if (this.searchBox.getPlaces() && this.searchBox.getPlaces().length > 0) {
      const place = this.searchBox.getPlaces()[0];

      const newLocation = {
        street: "",
        streetNumber: "",
        additionalInfos: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        geopoint: "",
        lat: "",
        lng: ""
      };

      newLocation.lat = `${place.geometry.location.lat()}`;
      newLocation.lng = `${place.geometry.location.lng()}`;
      newLocation.geopoint = `${newLocation.lat},${newLocation.lng}`;
      newLocation.formatted_address = place.formatted_address;

      place.address_components.forEach(component => {
        const typesString = component.types.join(", ");

        if (typesString.includes("route")) {
          newLocation.street = component.short_name;
        }

        if (typesString.includes("street_number")) {
          newLocation.streetNumber = component.short_name;
        }

        if (typesString.includes("sublocality_level_1")) {
          newLocation.additionalInfos = component.short_name;
        }

        if (
          typesString.includes("administrative_area_level_2") &&
          !newLocation.city
        ) {
          newLocation.city = component.short_name;
        }

        if (typesString.includes("locality")) {
          newLocation.city = component.short_name;
        }

        if (typesString.includes("administrative_area_level_1")) {
          newLocation.state = component.short_name;
        }

        if (typesString.includes("country")) {
          newLocation.country = component.short_name;
        }

        if (
          typesString.includes("postal_code") &&
          !typesString.includes("postal_code_suffix")
        ) {
          newLocation.postalCode = component.short_name;
        }
      });

      this.setState({
        location: newLocation,
        addressHelper: helpers.locationToString(newLocation)
      });
    }
  };

  save = async () => {
    const profile = {
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      email: this.state.email,
      phone: this.state.phone,
      birthdate: this.state.birthdate,
      gender: this.state.gender,
      country: this.state.country,
      location: this.state.location,
      language: this.state.language,
    };
    try {
      profile.location.geopoint = await helpers.getGeopoint(helpers.locationToString(this.state.location));
    } catch (e) {
      if (e.message === 'ZERO_RESULTS') {
        try {
          const { city, state, postalCode} = this.state.location;
          profile.location.geopoint = await helpers.getGeopoint(`${city}, ${state}, ${postalCode}`);
        } catch (error) {
          this.props.onError('We couldn\'t find that address. Please double-check it and try again.');
          return;
        }
      }
    }

    this.props.onSave(profile);
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ addressHelper: helpers.locationToString(nextProps.patient.location) });
  }

  componentDidMount = () => {
    setTimeout(() => {
      if (window.google !== undefined) {
        this.searchBox = new google.maps.places.SearchBox(this.addressInput);
        this.searchBox.addListener("places_changed", this.placesChanged);
      }
    }, 1000);
  };

  render() {
    const { classes, isCreate } = this.props;
    const { firstname, lastname, email } = this.state;
    const validForm = firstname && lastname && email;

    return (
      <Grid container direction='row'>
        <Grid item xs={9}>
          <Typography variant="h6" className={classes.title}>
            Profile
          </Typography>
          <div className={classes.line} />
          <form noValidate autoComplete="off">
            <Grid container direction='column'>
              <Grid
                className={classes.container}
                container
                direction="row"
                justify="space-between"
              >
                <TextField
                  error={!this.state.firstname}
                  required
                  label="First Name"
                  value={this.state.firstname}
                  onChange={this.handleChange("firstname")}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ flex: "1 0 auto", marginRight: 10 }}
                />

                <TextField
                  error={!this.state.lastname}
                  required
                  label="Last Name"
                  value={this.state.lastname}
                  onChange={this.handleChange("lastname")}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ flex: "1 0 auto" }}
                />
              </Grid>
              <Grid
                className={classes.container}
                container
                direction="row"
                justify="space-between"
              >
                <TextField
                  error={!this.state.email}
                  required
                  label="Email"
                  type="email"
                  autoComplete="email"
                  value={this.state.email}
                  onChange={this.handleChange("email")}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ flex: "1 0 auto", marginRight: 10 }}
                />

                <TextField
                  label="Phone"
                  value={this.state.phone}
                  onChange={this.handleChange("phone")}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ flex: "1 0 auto" }}
                />
              </Grid>
              <Grid
                className={classes.container}
                container
                direction="row"
              >
                <DatePicker
                  label="DOB"
                  value={this.state.birthdate ? this.state.birthdate : null}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ width: '160px', marginRight: 10 }}
                  onChange={date =>
                    this.handleChange("birthdate")({
                      target: { value: date.format('YYYY-MM-DD') }
                    })
                  }
                  format="MM/DD/YYYY"
                />
                <TextField
                  select
                  label="Gender"
                  value={this.state.gender}
                  onChange={this.handleChange("gender")}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ minWidth: '160px', marginRight: 10 }}
                >
                  <MenuItem value=''>Not Specified</MenuItem>
                  <MenuItem value={0}>Male</MenuItem>
                  <MenuItem value={1}>Female</MenuItem>
                </TextField>
              </Grid>
              <Grid className={classes.container} container
                    direction="row"
                    justify="space-between"
              >
                <TextField
                  id="addressHelper"
                  label="Address"
                  placeholder=''
                  value={this.state.addressHelper}
                  onChange={this.handleChange("addressHelper")}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ flex: "0.5 0 auto", marginRight: 10 }}
                  inputRef={el => {
                    this.addressInput = el;
                  }}
                />
                <TextField
                  label="Address 2"
                  value={this.state.location.additionalInfos}
                  onChange={this.handleChangeLocation("additionalInfos")}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{
                    className: classes.textField
                  }}
                  style={{ flex: "0.5 0 auto" }}
                />
              </Grid>
              <Grid
                className={classes.container}
                style={{ flexWrap: "unset" }}
                container
                direction="row"
                justify="space-between"
              >
                <TextField
                  label="City"
                  value={this.state.location.city}
                  onChange={this.handleChangeLocation("city")}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ flex: "1 0 auto", marginRight: 10 }}
                />

                <TextField
                  label="State"
                  value={this.state.location.state}
                  onChange={this.handleChangeLocation("state")}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ flex: "0.5 0 auto", marginRight: 10 }}
                />

                <TextField
                  label={
                    this.state.country === "us" ? "Zip Code" : "Postal Code"
                  }
                  value={this.state.location.postalCode}
                  onChange={this.handleChangeLocation("postalCode")}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ flex: "0.5 0 auto", marginRight: 10 }}
                />
              </Grid>
              <Grid className={classes.container} container direction='row'>
                <TextField
                  id="country"
                  select
                  label="Country"
                  value={this.state.country}
                  onChange={this.handleChange("country")}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ minWidth: 160, marginRight: 10 }}
                >
                  {countries.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="language"
                  select
                  label="Default Language"
                  value={this.state.language}
                  onChange={this.handleChange("language")}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ minWidth: 160 }}
                >
                  {languages.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                onClick={this.save}
                style={{ marginLeft: "auto", minWidth: 90 }}
                disabled={this.state.loading || !validForm}
              >
                {isCreate ? 'Create' : 'Save'}
              </Button>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={3}>
          { !isCreate && (
            <PatientAccount
              patient={this.props.patient}
              onSave={this.props.onSave}
              onCopyInviteCode={this.props.onCopyInviteCode} />
          )}
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(PatientInfo);
