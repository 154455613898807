import { routerReducer } from 'react-router-redux';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { LoginReducer } from './components/login/LoginUtils';
import { NotificationReducer } from './components/notification/NotifyUtils';
import { SpecialtyReducer } from './components/specialties/SpecialtyUtils';
import { LanguageReducer } from './components/utils/LanguageList';
import { PractitionerReducer } from './components/practitioners/PractitionersStore';
import LocalStorage from './components/utils/LocalStorage';

const reducers = combineReducers({
  routing: routerReducer,
  login: LoginReducer,
  specialty: SpecialtyReducer,
  language: LanguageReducer,
  notify: NotificationReducer,
  practitioner: PractitionerReducer,
});

const localStorageMiddleware = ({ getState }) => next => (action) => {
  const result = next(action);
  LocalStorage.set('applicationState', getState());
  return result;
};

const store = createStore(
  reducers,
  LocalStorage.get('applicationState', {}),
  applyMiddleware(localStorageMiddleware),
);

window.store = store;

export default store;
