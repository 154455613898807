import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  List,
} from '@material-ui/core';

import CustomRow from './CustomRow';
import EditCustomRow from './EditCustomRow';

import './List.css';

/**
 * List manager
 * @param title Title header for list
 * @param values Object data {id, title}
 */
class ListManager extends React.Component { // eslint-disable-line
  static propTypes = {
    onDelete: PropTypes.func,
    onAdd: PropTypes.func,
    onEdit: PropTypes.func,
    onSelect: PropTypes.func,
    selected: PropTypes.string,
    values: PropTypes.array,
    formControl: PropTypes.any,
  }

  updateItems = (e) => {
    const values = this.state.values;

    values.forEach((value) => {
      if (!e.id) {
        // no uid passed in, update all
        value = Object.assign(value, e);
      } else if (value.id === e.id) {
        value = Object.assign(value, e);
      }
    });

    this.setState({
      values,
    });
  };

  doDelete = (listItem) => {
    if (listItem) {
      this.props.onDelete(listItem);
    }
  };

  doAdd = (e) => {
    e.preventDefault();

    this.props.onAdd();
  };

  doEdit = (listItemID) => {
    // update form
    this.updateItems({
      edit: false,
    });

    this.updateItems({
      id: listItemID,
      edit: true,
    });
  };

  doSaveEdit = (listItemID) => {
    if (listItemID) {
      this.props.onEdit(listItemID);
    }

    this.doCancel(listItemID);
  };

  doCancel = (listItemID) => {
    this.updateItems({
      id: listItemID,
      edit: false,
    });
  };

  doSelect = (listItemID) => {
    if (listItemID) {
      this.props.onSelect(listItemID);
    }
  };

  processData = (values) => {
    // check if string array passed in, convert to object array and set ID to value
    values = values.map((value) => {
      if (typeof value === 'string') {
        value = { id: value, title: value };
      }
      return value;
    });
    return values;
  };

  componentWillMount() {
    this.setState({
      values: this.processData(this.props.values),
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      values: this.processData(nextProps.values),
    });
  }

  render() {
    return (
      <Grid container direction="column">
        <Grid container direction="row">
          <Grid item style={{ flexGrow: 1 }}>
            {this.props.formControl}
          </Grid>
          <Grid item style={{ padding: '0 12px' }}>
            <Button
              color="secondary"
              onClick={this.doAdd}>Add</Button>
          </Grid>
        </Grid>
        <List>
          {this.state.values.map((item, index) => {
            if (item.edit) {
              return (
                <EditCustomRow
                  key={`row${index}`}
                  keyIndex={index + 1}
                  data={item}
                  doSaveEdit={this.doSaveEdit}
                  doCancel={this.doCancel}
                />
              );
            }
            return (
              <CustomRow
                key={`row${index}`}
                keyIndex={index + 1}
                data={item}
                doEdit={this.props.onEdit ? this.doEdit : null}
                doDelete={this.doDelete}
                doSelect={this.props.onSelect ? this.doSelect : null}
                checked={this.props.selected === item.id}
              />
            );
          })}
        </List>
      </Grid>
    );
  }
}
ListManager.defaultProps = { values: [] };

export default ListManager;
