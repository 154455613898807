import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, Grid, Typography,
  TextField, Button, IconButton, MenuItem, Menu, InputAdornment,
  Table, TableHead, TableBody, TableRow, TableCell, TablePagination,
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import moment from 'moment';
import {
  parseCardExpiry,
  validateCardNumber,
  validateCardExpiry,
  validateCardCVC,
  parseCardType,
} from 'payform';

import { advancedSearchConsultations } from '../../components/consultations/ConsultationUtils';
import { getUserParams } from '../../components/utils/SettingUtils';
import { setStripeKey, createCard } from '../../components/utils/PaymentUtils';
import { getCurrencySymbol, renderPaymentStatus } from '../../utils/helpers';

function isExpiring(method) {
  return moment({ month: method.card.expMonth - 1, year: method.card.expYear})
    .isBefore(moment().endOf('month'));
}

function isExpired(method) {
  return moment({ month: method.card.expMonth, year: method.card.expYear})
  .isBefore(moment().endOf('month'));
}

const styles = (theme) => ({
  title: {
    color: theme.palette.primary.light,
  },
  accountWrap: {
    border: '1px solid #E5E5E5',
    backgroundColor: '#F6F6F6',
    boxShadow: '0 2px 7px 0 rgba(0,0,0,0.5)',
    padding: theme.spacing.unit * 3,
  },
  line: {
    backgroundColor: theme.palette.primary.light,
    height: 2,
    width: "100%",
    marginBottom: theme.spacing.unit * 4,
  },
  container: {
    marginBottom: theme.spacing.unit * 3,
  },
  labelField: {
    color: theme.palette.primary.light,
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  textField: {
    border: "1px solid #E7E7E7",
    borderRadius: "3px",
    backgroundColor: "#FBFBFB",
    padding: "6px 7px"
  },
  cardWrap: {
    boxShadow: '0 1px 4px 0 rgba(0,0,0,0.5)',
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 4,
  },
});

class PatientPayment extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    patient: PropTypes.object.isRequired,
    onAddPaymentMethod: PropTypes.func.isRequired,
    onRemovePaymentMethod: PropTypes.func.isRequired,
    onSetPaymentMethodAsDefault: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    setNotifySuccess: PropTypes.func.isRequired,
    setNotifyWarning: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    let amount = "";
    if (props.patient.freeCredits && props.patient.freeCredits[props.patient.currency]) {
      amount = (props.patient.freeCredits[props.patient.currency].amount / 100);
    }

    this.state = {
      consults: [],
      pagination: {
        total: 0,
        limit: 10,
        page: 0,
      },
      methodUid: null,
      anchorEl: null,
      isOpen: false,
      card: {
        number: '',
        exp: '',
        cvv: '',
      },
      error: {
        number: false,
        exp: false,
        cvv: false,
      },
      amount,
    };
  }


  handleChange = (key) => (e) => {
    this.setState({
      card: { ...this.state.card, [key]: e.target.value },
      error: { ...this.state.error, [key]: false },
    })
  };

  handleChangeFreeCredits = (e) => {
    const { value } = e.target;
    this.setState({ amount: value });
  };

  handleMenu = (methodUid) => (event) => {
    this.setState({
      methodUid,
      anchorEl: event.currentTarget,
      isOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      isOpen: false,
    });
  };

  handleChangeRowsPerPage = (e) => {
    const { pagination } = this.state;
    this.setState({ pagination: { ...pagination, limit: e.target.value } }, this.fetchConsultations);
  };

  handleChangePage = (e, page) => {
    const { pagination } = this.state;
    this.setState({ pagination: { ...pagination, page } }, this.fetchConsultations);
  };

  fetchConsultations = () => {
    const { page, limit } = this.state.pagination;
    advancedSearchConsultations({
      patientUid: this.props.patient.uid,
      terminated: true,
      size: limit,
      offset: limit * page,
    })
      .then(result => {
        this.setState({
          consults: result.content,
          pagination: {
            page,
            limit,
            total: result.pagination.total,
          }
        })
      })
      .catch(e => this.props.setNotifyWarning(e.message));
  };

  removePaymentMethod = () => {
    this.handleClose();
    this.props.onRemovePaymentMethod(this.state.methodUid);
  };

  setAsDefault = () => {
    this.handleClose();
    this.props.onSetPaymentMethodAsDefault(this.state.methodUid);
  };

  addPaymentMethod = () => {
    if (this.validateCard()) {
      const { month, year} = parseCardExpiry(this.state.card.exp);
      const card = {
        number: this.state.card.number,
        cvc: this.state.card.cvv,
        exp_month: month,
        exp_year: year,
      };
      createCard(card)
        .then(token => {
          const body = {
            stripeToken: token.id,
            card: { id: token.card.id },
            provider: 'stripe',
            favorite: true,
          };
          this.props.onAddPaymentMethod(body);
        })
        .catch(e => this.props.setNotifyWarning(e));
      this.setState({
        card: {
          number: '',
          exp: '',
          cvv: '',
        }
      })
    }
  };

  validateCard = () => {
    let valid = true;
    let number = false;
    let exp = false;
    let cvv = false;
    const cardType = parseCardType(this.state.card.number);
    const { month, year } = parseCardExpiry(this.state.card.exp);

    if (!validateCardNumber(this.state.card.number)) {
      valid = false;
      number = true;
    }

    if (!validateCardExpiry(month, year)) {
      valid = false;
      exp = true;
    }

    if (!validateCardCVC(this.state.card.cvv, cardType)) {
      valid = false;
      cvv = true;
    }

    this.setState({
      error: { number, exp, cvv },
    });
    return valid;
  };

  saveFreeCredits = () => {
    const { currency } = this.props.patient;
    let { freeCredits } = this.props.patient;

    if (!freeCredits) {
      freeCredits = {};
      freeCredits[currency] = { amount: 0 };
    }

    if (!freeCredits[currency]) {
      freeCredits[currency] = { amount: 0 };
    }

    freeCredits[currency].amount = this.state.amount * 100;

    this.props.onSave({ freeCredits });
  };

  componentWillMount() {
    this.fetchConsultations();
    getUserParams(this.props.patient.uid, 'patients')
      .then((res) => {
        if (res.stripeKey) {
          setStripeKey(res.stripeKey);
        }
      })
      .catch(() => {});
  }

  render() {
    const { classes, patient } = this.props;
    const { isOpen, anchorEl, pagination } = this.state;

    return (
      <Grid container direction='row'>
        <Grid container direction='row' justify='space-between'>
          <Grid item xs={6}>
            <Typography variant='h6' className={classes.title}>Payment Types</Typography>
            <div className={classes.line} />
            <div className={classes.cardWrap}>
              <Grid container direction='row' justify='space-between'>
                <Typography variant='h6'>Medici Credits</Typography>
              </Grid>
              <Grid container direction='row' justify='space-between'>
                <div>
                  <TextField label='Free Credits'
                             value={this.state.amount}
                             onChange={this.handleChangeFreeCredits}
                             InputProps={{
                               startAdornment: <InputAdornment position="start">{getCurrencySymbol(patient.currency)}</InputAdornment>,
                             }}
                  />
                </div>
                <Button variant='contained' color='primary' onClick={this.saveFreeCredits}>Save</Button>
              </Grid>
            </div>
            {patient.paymentMethods.map(method => (
              <div key={method.uid} className={classes.cardWrap}>
                <Grid container direction='row' justify='space-between'>
                  <Typography variant='h6'>{method.card.brand}</Typography>
                  {method.favorite && <Typography variant='h6' style={{ color: '#20CE9A' }}>Default</Typography>}
                  {isExpired(method) && <Typography variant='h6' style={{ color: '#D0021B' }}>Expired</Typography>}
                  {isExpiring(method) && <Typography variant='h6' style={{ color: '#D0021B' }}>Expiring</Typography>}
                </Grid>
                <Grid container direction='row' justify='space-between'>
                  <div>**** **** **** {method.card.last4} {method.card.expMonth}/{method.card.expYear}</div>
                  <IconButton aria-haspopup="true"
                              onClick={this.handleMenu(method.uid)}
                              color="inherit">
                    <MoreIcon />
                  </IconButton>
                </Grid>
              </div>
            ))}
          </Grid>
          <Grid item xs={4}>
            <Grid container className={classes.accountWrap} direction='column'>
              <Typography variant='h6' style={{ marginBottom: 18 }}>Add Payment Type</Typography>
              <Grid container className={classes.container}>
                <TextField
                  label='Card Number'
                  value={this.state.card.number}
                  onChange={this.handleChange('number')}
                  error={this.state.error.number}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                />
              </Grid>
              <Grid
                className={classes.container}
                container
                direction="row"
                justify="space-between"
              >
                <TextField
                  label="Expiration"
                  value={this.state.card.exp}
                  onChange={this.handleChange('exp')}
                  error={this.state.error.exp}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ width: '45%' }}
                />
                <TextField
                  label="CVV"
                  value={this.state.card.cvv}
                  onChange={this.handleChange('cvv')}
                  error={this.state.error.cvv}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ width: '45%' }}
                />
              </Grid>
              <Button variant='contained'
                      color='primary'
                      style={{ marginLeft: 'auto' }}
                      onClick={this.addPaymentMethod}>
                ADD
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction='column'>
          <Typography variant='h6' className={classes.title}>Payments</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell numeric>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.consults.map(consult => (
                <TableRow key={consult.uid}>
                  <TableCell>
                    <Typography variant='subtitle2'>
                      {moment(consult.dateStart).format('MM/DD/YYYY')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {consult.consultationChargeSummary && (
                      <Typography variant='subtitle2'>
                        {getCurrencySymbol(consult.consultationChargeSummary.currency)} {consult.consultationChargeSummary.amount / 100}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell numeric>
                    {renderPaymentStatus(consult.paymentStatus)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            count={pagination.total}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            page={pagination.page}
            rowsPerPage={pagination.limit}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </Grid>
        <Menu
          id="menu-method"
          anchorReference='anchorPosition'
          anchorPosition={{
            top: anchorEl ? anchorEl.getBoundingClientRect().bottom - 18 : 0,
            left: anchorEl ? anchorEl.getBoundingClientRect().left : 0
          }}
          open={isOpen}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.removePaymentMethod}>Remove</MenuItem>
          <MenuItem onClick={this.setAsDefault}>Set as default</MenuItem>
        </Menu>
      </Grid>
    );
  }
}

export default withStyles(styles)(PatientPayment);
