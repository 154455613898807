import React from 'react';
import PropTypes from 'prop-types';

export default class EditCustomRow extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    doSaveEdit: PropTypes.func.isRequired,
    doCancel: PropTypes.func.isRequired,
    keyIndex: PropTypes.number,
  };

  state = {
    id: 0,
    title: '',
  };

  onChange = (propName, event) => {
    const s = this.state;
    s[propName] = event.target.value;
    this.setState(s);
  };

  onSave = () => {
    const item = Object.assign({}, this.state);
    delete item.edit;
    this.props.doSaveEdit(item);
  };

  componentWillMount = () => {
    if (this.props.data) {
      this.setState(Object.assign({}, this.props.data));
    }
  };

  render() {
    return (
      <li key={this.props.keyIndex} className="list-entry">
        <input
          className="list-edit"
          value={this.state.title}
          onChange={this.onChange.bind(this, 'title')}
        />
        <span className="linkContainer">
          <label className="list-entry-link" onClick={this.onSave}>
            save
          </label>
          <label
            className="list-entry-link"
            onClick={this.props.doCancel.bind(this, this.state.id)}
          >
            cancel
          </label>
        </span>
      </li>
    );
  }
}
