import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import Loader from "react-loader";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { debounce } from "underscore";
import { Grid, Typography } from "@material-ui/core";
import ContactsDialog from "../components/ContactsDialog";
import { PageContent } from "../components/containers/PageContent";
import {
  setNotifyHidden,
  setNotifySuccess,
  setNotifyWarning
} from "../components/notification/NotifyUtils";
import {
  advancedSearchPractitioners,
  deletePractitioner,
  savePracticesForPractitioner,
  savePractitioner,
  savePractitionerImage,
  uploadAttachment
} from "../components/practitioners/PractitionerUtils";
import ProfileDialog from "../components/ProfileDialog";
import SearchForm from "../components/search/SearchForm";
import SearchResults from "../components/search/SearchResultsDynamic";
import "./css/PractitionersPage.css";
import * as helpers from "../utils/helpers";

class PractitionersPage extends React.Component {
  static propTypes = {
    setNotifyHidden: PropTypes.func.isRequired,
    setNotifyWarning: PropTypes.func.isRequired,
    setNotifySuccess: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
    children: PropTypes.node
  };

  state = {
    showForm: !!this.props.children,
    showInvite: false,
    showUpload: false,
    searchValue: "",
    searchResults: [],
    searchHeaders: [
      "Name",
      "Email",
      "Invite Code",
      { name: "Consultations", align: "center" },
      "Date Created"
    ],
    currentPractitionerUid: "",
    practitionerPractices: [],
    loaded: true,
    showDeleted: false,
    viewPractitioner: null,
    viewPractitionerContacts: null
  };

  lastSearchResultTs = null;

  debouncedSearch = debounce(searchData => {
    const currentSearchResultTs = Date.now();
    this.lastSearchResultTs = currentSearchResultTs;
    advancedSearchPractitioners(searchData)
      .then(response => {
        if (
          !this.lastSearchResultTs ||
          currentSearchResultTs === this.lastSearchResultTs
        ) {
          this.setState({
            searchResults: this.mapResults(response)
          });
        }
      })
      .catch(() => {
        this.props.setNotifyWarning("Error loading search results");
      });
  }, 100);

  onSearch = searchData => {
    if (!searchData) return;

    if (searchData.searchValue !== undefined) {
      this.setState({
        searchValue: searchData.searchValue,
        showDeleted: searchData.deleted
      });
      delete searchData.searchValue;
    }

    this.debouncedSearch(searchData);
  };

  mapResults = results =>
    results.map(result => ({
      Name: `${result.user.firstname} ${result.user.lastname}`,
      Email: result.user.email,
      "Invite Code": result.user.inviteCode,
      Consultations: result.consultationCount,
      "Date Created": moment(new Date(result.user.creationDate)).format(
        "YYYY-MM-DD"
      ),
      uid: result.user.uid
    }));

  onEdit = uid => {
    this.setState({ currentPractitionerUid: uid });
    this.props.setNotifyHidden();
    this.props.router.push(`/practitioners-old/${uid}`);
  };

  onSave = (e, forceUpdate) => {
    this.setState({ loaded: false });
    let practitionerID;
    e.practitioner.firstname = e.practitioner.firstname.trim();
    e.practitioner.lastname = e.practitioner.lastname.trim();
    let saveChain = savePractitioner(e.practitioner, forceUpdate);

    saveChain = saveChain.then(_practitioner => {
      practitionerID = _practitioner.uid || _practitioner.practitionerId;
      return savePracticesForPractitioner(
        e.practitionerPractices,
        practitionerID
      );
    });

    if (e.practitionerImage.uploaded) {
      saveChain = saveChain.then(() => {
        e.practitionerImage.uid = practitionerID;
        return savePractitionerImage(e.practitionerImage);
      });
    }

    saveChain
      .then(() => {
        this.onSearch(this.state.searchData);
        this.setState({ loaded: true, showForm: false });
        this.props.setNotifySuccess("Doctor saved");
        this.props.router.goBack();
      })
      .catch(ex => {
        this.setState({
          loaded: true,
          showForm: true,
          practitioner: Object.assign(
            {},
            this.state.practitioner,
            e.practitioner
          )
        });

        this.props.setNotifyWarning(helpers.errorToString(ex));
      });
  };

  onCancel = () => {
    this.props.router.replace("/practitioners-old");
  };

  onDelete = practitioner => {
    this.setState({ loaded: false });

    deletePractitioner(practitioner)
      .then(() => {
        this.setState({
          showForm: false,
          loaded: true
        });
        this.props.setNotifySuccess("Doctor deleted");
        this.onSearch({
          q: this.state.searchValue,
          deleted: this.state.showDeleted
        });
        this.props.router.goBack();
      })
      .catch(() => {
        this.setState({
          loaded: true,
          showForm: true,
          practitioner
        });
        this.props.setNotifyWarning("Error deleting Doctor");
      });
  };

  createPractitioner = () => {
    this.setState({ loaded: false });

    this.props.setNotifyHidden();
    this.setState({
      showForm: true,
      loaded: true,
      practitioner: {},
      practitionerPractices: []
    });
    this.props.router.push("/practitioners-old/create");
  };

  cancelUploadAttachments = e => {
    e.preventDefault();

    this.props.router.goBack();
    this.setState({
      showForm: true
    });
  };

  uploadAttachmentsSave = (e, uid) => {
    this.setState({ loaded: false });

    uploadAttachment(uid, e)
      .then(() => {
        this.props.router.goBack();
        this.setState({
          loaded: true,
          showForm: true
        });
        this.props.setNotifySuccess("Document uploaded");
        this.props.router.goBack();
      })
      .catch(ex => {
        this.setState({
          loaded: true
        });
        this.props.setNotifyWarning(ex.message);
      });
  };

  handleViewPractitioner = practitionerUid =>
    this.setState({ viewPractitioner: practitionerUid });

  handleViewPractitionerContacts = () => {
    const { viewPractitioner } = this.state;

    this.setState({
      viewPractitioner: null,
      viewPractitionerContacts: viewPractitioner
    });
  };

  handleCloseProfileDialog = shouldEdit => {
    if (shouldEdit) {
      this.props.router.push(
        `/practitioners-old/${this.state.viewPractitioner}`
      );
    }

    this.setState({ viewPractitioner: null });
  };

  handleCloseContactsDialog = forceClose => {
    const { viewPractitionerContacts } = this.state;
    if (!forceClose) {
      this.setState({
        viewPractitioner: viewPractitionerContacts,
        viewPractitionerContacts: null
      });
    } else {
      this.setState({
        viewPractitionerContacts: null
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      showForm: !!nextProps.children,
      loaded: true
    });

    // this.props.setNotifyHidden();
  }

  render() {
    return (
      <Loader loaded={this.state.loaded}>
        <Grid container direction="column">
          <Typography variant="h5">Doctors</Typography>
          {!this.props.children && (
            <div>
              <PageContent className="pageContent">
                <button
                  className="actionButton add"
                  onClick={this.createPractitioner}
                >
                  Add Doctor
                </button>
                <ProfileDialog
                  uid={this.state.viewPractitioner}
                  handleClose={this.handleCloseProfileDialog}
                  handleOpenContacts={this.handleViewPractitionerContacts}
                  isPractitioner={true}
                />
                <ContactsDialog
                  uid={this.state.viewPractitionerContacts}
                  handleClose={this.handleCloseContactsDialog}
                  isPractitioner={true}
                />
                <SearchForm
                  onSearch={this.onSearch}
                  searchValue={this.state.searchValue}
                  advanced="SearchAdvancedFilter"
                />
                <SearchResults
                  searchResults={this.state.searchResults}
                  searchHeaders={this.state.searchHeaders}
                  onView={this.handleViewPractitioner}
                  onEdit={this.onEdit}
                />
              </PageContent>
            </div>
          )}
          {this.props.children &&
            React.cloneElement(this.props.children, {
              router: this.props.router,
              practitionerPractices: this.state.practitionerPractices,
              onSave: this.onSave,
              onCancel: this.onCancel,
              onDelete: this.onDelete,
              invitePatientsSave: this.invitePatientsSave,
              cancelInvitePatients: this.cancelInvitePatients,
              uploadAttachmentsSave: this.uploadAttachmentsSave,
              cancelUploadAttachments: this.cancelUploadAttachments,
              setNotifyWarning: this.props.setNotifyWarning,
              setNotifySuccess: this.props.setNotifySuccess
            })}
        </Grid>
      </Loader>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setNotifySuccess: title => {
      dispatch(setNotifySuccess(title));
    },
    setNotifyWarning: title => {
      dispatch(setNotifyWarning(title));
    },
    setNotifyHidden: () => {
      dispatch(setNotifyHidden());
    }
  };
}

// wrapping page to inject router into its props
const PractitionersPageWithRouter = withRouter(PractitionersPage);

export default connect(
  null,
  mapDispatchToProps
)(PractitionersPageWithRouter);
