import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hashHistory } from 'react-router';
import { withStyles } from '@material-ui/core';

import AuthenticateCodeForm from '../components/login/AuthenticateCodeForm';
import LoginForm from '../components/login/LoginForm';
import { loginKo, loginOk, loginUser, getUser } from '../components/login/LoginUtils';
import { getAdminByID } from '../components/admins/AdminUtils';
import NotifyPanel from '../components/notification/NotifyPanel';
import { NOTIFY_GRAY } from '../components/notification/NotifyUtils';
import LocalStorage from '../components/utils/LocalStorage';
import logo from '../images/logo.png';

const styles = () => ({
  loginPage: {
    minHeight: '50vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: 200,
    marginTop: 60,
    marginBottom: 40,
  }
});

class LoginPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    loginKo: PropTypes.func.isRequired,
    loginOk: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
  };

  login = '';
  password = '';
  state = {
    currentForm: 'loginForm',
    showVerificationField: LocalStorage.get('useTOTPAuth', false),
    notificationMessage: undefined,
    token: '',
  };

  onLogin = (login, password, code) => {
    loginUser(login, password, code)
      .then((credentials) => {
        if (credentials.needToCreateTotp) {
          const error = new Error(credentials.message);
          error.needToCreateTotp = true;
          error.token = credentials.token;
          throw error;
        } else if (credentials.totpRequired) {
          const error = new Error('Please enter your verification code.');
          error.totpRequired = true;
          throw error;
        } else {
          LocalStorage.set('loginCredentials', credentials);
          return credentials;
        }
      })
      .then(credentials => {
        this.props.loginOk(credentials, null);
        return getAdminByID(credentials.uid)
      })
      .then(user => {
        this.props.getUser(user);
        hashHistory.push('/');
      })
      .catch((e) => {
        LocalStorage.remove('loginCredentials');

        if (e.needToCreateTotp) {
          this.login = login;
          this.password = password;
          this.setState({
            currentForm: 'authenticateCodeForm',
            token: e.token,
          });
        } else if (e.totpRequired) {
          // HTTP 403
          LocalStorage.set('useTOTPAuth', true);
          this.setState({
            currentForm: 'loginForm',
            showVerificationField: true,
            notificationMessage: 'Please enter your verification code.',
          });
        } else {
          this.props.loginKo();
          this.setState({
            currentForm: 'loginForm',
            notificationMessage: e.message,
          });
        }
      });
  };

  onVerificationLogin = (verificationCode) => {
    this.onLogin(this.login, this.password, verificationCode);
  };

  render() {
    const { classes } = this.props;
    let form = null;
    if (this.state.currentForm === 'autoLogin') {
      form = (
        <form className="loginForm">
          <p>Please wait while we try to log you in..</p>
        </form>
      );
    } else if (this.state.currentForm === 'loginForm') {
      form = (
        <LoginForm
          onLogin={this.onLogin}
          showVerificationField={this.state.showVerificationField}
        />
      );
    } else if (this.state.currentForm === 'authenticateCodeForm') {
      form = (
        <AuthenticateCodeForm
          token={this.state.token}
          onVerificationLogin={this.onVerificationLogin} />
      );
    }

    let notifyPanel = null;
    if (this.state.notificationMessage) {
      notifyPanel = (
        <NotifyPanel
          status={NOTIFY_GRAY}
          title={this.state.notificationMessage}
        />
      );
    }

    return (
      <section className={classes.loginPage}>
        <div className={classes.logo}>
          <img src={logo} alt="Medici logo" style={{ width: '100%' }} />
        </div>
        {notifyPanel}
        {form}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    logged: state.login.logged,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginOk: (credentials) => {
      dispatch(loginOk(credentials));
    },
    getUser: (user) => {
      dispatch(getUser(user));
    },
    loginKo: () => {
      dispatch(loginKo());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoginPage));
