import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, withStyles } from '@material-ui/core';
import ClipboardJS from "clipboard";
import { FileCopyOutlined } from '@material-ui/icons';

import * as helpers from '../../utils/helpers';
import UserAvatar from '../../components/user-avatar/UserAvatar';

const styles = theme => ({
  container: {
    minHeight: 162,
    width: 503,
    borderRadius: 3,
    backgroundColor: "#F6F6F6"
  },
  img: {
    height: "100%",
    width: "100%",
    borderRadius: "3px 0 0 3px",
    paddingRight: 16
  },
  name: {
    color: theme.palette.text.secondary,
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 8,
    marginBottom: 1,
  },
  phone: {
    color: theme.palette.text.secondary,
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 6
  },
  email: {
    color: theme.palette.text.secondary,
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 9
  },
  location: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 600,
    marginBottom: theme.spacing.unit,
    marginTop: 'auto',
  },
  date: {
    color: "#999999",
    fontSize: 12
  },
  uidContainer: {
    marginLeft: "auto",
    marginRight: 2,
    padding: 4,
    minHeight: 0
  },
  uid: {
    color: "#999999",
    fontSize: 10,
    marginRight: 2
  },
  uidIcon: {
    color: "#999999",
    fontSize: 10
  },
  countWrap: {
    padding: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 8,
  },
  label: {
    color: '#9B9B9B',
    fontSize: '11px',
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    lineHeight: '13px',
    textTransform: 'uppercase',
  },
  count: {
    color: '#556979',
    fontSize: '55px',
    letterSpacing: '1.3px',
    lineHeight: '64px',
  },
  hint: {
    color: '#9B9B9B',
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    lineHeight: '11px',
  },
  wrap: {
    padding: theme.spacing.unit * 2
  },
  divider: {
    width: 1,
    backgroundColor: "#F3F3F3"
  },
  statsTitle: {
    fontSize: 11,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    textTransform: "uppercase",
    marginBottom: theme.spacing.unit
  },
  statsData: {
    color: theme.palette.text.secondary,
    fontSize: 21,
    letterSpacing: 0.5,
    lineHeight: "24px"
  },
  statsHint: {
    color: theme.palette.text.secondary,
    opacity: 0.6,
    fontSize: 10,
    fontWeight: 500,
    lineHeight: "11px",
    letterSpacing: 0.5
  },
});

class PatientHeader extends React.Component {
  static propTypes = {
    patient: PropTypes.object.isRequired,
    providersCount: PropTypes.number.isRequired,
    consultCount: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
  };

  componentDidMount() {
    return new ClipboardJS(`.${this.props.classes.uidContainer}`);
  }

  getCountryPrettyName = (countryCode) => {
    switch(countryCode) {
      case 'na':
        return 'Namibia';
      case 'za':
        return 'South Africa';
      default:
        return 'United States';
    }
  }

  render() {
    const { classes, patient, providersCount, consultCount } = this.props;

    return (
      <Grid container direction="row">
        <Grid container direction="row" className={classes.container}>
          <Grid item style={{ width: "36%" }}>
            <UserAvatar
              uid={patient.uid}
              className={classes.img}
              role="patients"
              size={0}
              enableUpload={true}
            />
          </Grid>
          <Grid
            style={{ width: "64%" }}
            container
            justify="flex-start"
            direction="column"
          >
            <div className={classes.name}>
              <Grid container direction="row" alignItems="center">
                {`${patient.firstname} ${patient.lastname}`}
              </Grid>
            </div>
            <div className={classes.phone}>{patient.phone}</div>
            <div className={classes.email}>{patient.email}</div>
            <div className={classes.location}>
              {this.getCountryPrettyName(patient.country)}{" "}
              <span className={`flag-icon flag-icon-${patient.country}`} />
            </div>
            <Grid container direction="row" alignItems="center">
              <div className={classes.date}>
                Joined{" "}
                {helpers.renderDate(patient.creationDate, "MMMM DD, YYYY")}
              </div>
              <Button
                className={classes.uidContainer}
                data-clipboard-text={patient.uid}
              >
                <Grid container direction="row" alignItems="center">
                  <span className={classes.uid}>
                    {helpers.shortUid(patient.uid)}
                  </span>
                  <FileCopyOutlined className={classes.uidIcon} />
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item direction="column" className={classes.countWrap}>
          <div className={classes.label}>Consults</div>
          <div className={classes.count}>{consultCount}</div>
          <div className={classes.hint}>All Time</div>
        </Grid>
        <Grid item direction="column" className={classes.countWrap}>
          <div className={classes.label}>Providers</div>
          <div className={classes.count}>{providersCount}</div>
          <div className={classes.hint}>All Time</div>
        </Grid>

        <Grid item>
          <Grid container style={{ height: "100%" }}>
            <div className={classes.divider} style={{ height: "100%" }} />
          </Grid>
        </Grid>

        {patient.applicationVersionMap && (
          <Grid item className={classes.wrap} xs={2}>
            <Grid
              container
              direction="column"
              justify="space-between"
              style={{ height: "100%" }}
            >
              <div className={classes.statsTitle}>Version</div>
              <div>
                <div className={classes.statsData} style={{ fontSize: 16 }}>
                  iOS
                </div>
                <div className={classes.statsHint}>
                  {patient.applicationVersionMap.ios || "----"}
                </div>
              </div>
              <div>
                <div className={classes.statsData} style={{ fontSize: 16 }}>
                  Android
                </div>
                <div className={classes.statsHint}>
                  {patient.applicationVersionMap.android || "----"}
                </div>
              </div>
              <div>
                <div className={classes.statsData} style={{ fontSize: 16 }}>
                  WebApp
                </div>
                <div className={classes.statsHint}>
                  {patient.applicationVersionMap.webapp || "----"}
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(PatientHeader);
