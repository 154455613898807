import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  withStyles, Grid, Typography, Paper,
  Table, TableBody, TableCell, TableRow,
  TextField, Button, InputAdornment, TablePagination, Tooltip, IconButton,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import VerifiedIcon from '@material-ui/icons/VerifiedUserOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import { debounce } from 'underscore';
import moment from 'moment';

import {
  setNotifyHidden,
  setNotifyWarning
} from '../../components/notification/NotifyUtils';
import { setDoctors, setSearchValue, setPagination, setFilter, resetState } from '../../components/practitioners/PractitionersStore';
import { advancedSearchPractitioners } from './../../components/practitioners/PractitionerUtils';
import { getSpecialties } from '../../components/specialties/SpecialtyUtils';

import UserAvatar from './../../components/user-avatar/UserAvatar';
import Loading from '../../components/Loading';
import DoctorsFilter from './DoctorsFilter';

const styles = theme => ({
  marginX: {
    margin: `0 ${theme.spacing.unit}px`,
  },
  actions: {
    color: theme.palette.text.secondary
  },
  name: {
    fontSize: '17px',
  },
  hint: {
    fontSize: '12px',
    color: '#999',
  },
  verified: {
    height: theme.spacing.unit * 2,
    color: '#21CE99',
  },
  flagSA: {
    margin: `0 ${theme.spacing.unit}px`,
    width: '16px',
  },
  demoLabel: {
    position: 'absolute',
    top: '-4px',
    left: '-12px',
    padding: '2px 4px',
    borderRadius: '0 0 3px 3px',
    background: '#21CE99',
    color: '#fff',
    textTransform: 'uppercase',
    fontSize: '7px',
    fontWeight: 900,
  },
  infoItem: {
    margin: theme.spacing.unit,
    whiteSpace: 'nowrap',
  },
  newDoctor: {
    marginLeft: 'auto',
    marginBottom: 'auto',
  },
  container: {
    margin: `${theme.spacing.unit * 2}px 0`,
  },
  loadingWrap: {
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    background: 'rgba(255, 255, 255, 0.75)',
    zIndex: 1,
  },
  nothingFound: {
    padding: '75px 0',
    textAlign: 'center',
    color: '#BFBFBF',
    fontSize: '13px',
    fontWeight: 'bold',
    borderTop: '1px solid #D8D8D8',
    borderBottom: '1px solid #D8D8D8',
  },
  label: {
    padding: '3px 4px',
    borderRadius: '6px',
    background: '#21CE99',
    color: '#fff',
    textTransform: 'uppercase',
    fontSize: '8px',
    fontWeight: 900,
  },
  hiConsult: {
    display: 'inline-block',
    marginRight: theme.spacing.unit,
    width: theme.spacing.unit,
    height: theme.spacing.unit,
    borderRadius: '50%',
    backgroundColor: '#2B94F1',
  },
  lowConsult: {
    opacity: '0.6',
  },
  consultLabel: {
    position: 'absolute',
    left: '-4px',
  },
  legend: {
    padding: theme.spacing.unit * 2,
    border: '1px solid #979797',
    backgroundColor: '#F4F4F4',
    borderRadius: '3px',
  },
  legendTitle: {
    color: '#747474',
    fontSize: '11px',
    fontWeight: 'bold',
    marginBottom: theme.spacing.unit,
  },
  legendItem: {
    fontSize: '11px',
    color: '#747474',
    marginRight: theme.spacing.unit,
  },
});

class DoctorsList extends React.Component {
  static propTypes = {
    doctors: PropTypes.array.isRequired,
    search: PropTypes.shape({ value: PropTypes.string }),
    pagination: PropTypes.shape({
      total: PropTypes.number,
      limit: PropTypes.number,
      page: PropTypes.number,
    }),
    isShowFilter: PropTypes.bool,
    filter: PropTypes.object,
    classes: PropTypes.object,
    router: PropTypes.object,
    setNotifyHidden: PropTypes.func.isRequired,
    setNotifyWarning: PropTypes.func.isRequired,
    setDoctors: PropTypes.func.isRequired,
    setSearchValue: PropTypes.func.isRequired,
    setPagination: PropTypes.func.isRequired,
    setFilter: PropTypes.func.isRequired,
    resetState: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    specialties: [],
  };

  search = debounce(() => {
    this.retrieveDoctors();
  }, 500);

  onSearch = e => {
    const { value } = e.target;
    this.props.setSearchValue(value);
    if (value.length === 0 || value.length > 2) {
      this.search();
    }
  };

  retrieveDoctors = () => {
    const { pagination, search } = this.props;
    this.props.setNotifyHidden();
    this.setState({ loading: true });
    const filter = {
      q: search.value,
      size: pagination.limit,
      offset: pagination.limit * pagination.page,
      ...this.props.filter,
    };
    advancedSearchPractitioners(filter)
      .then(res => {
        this.props.setDoctors(res.content);
        this.props.setPagination({ ...pagination, total: res.pagination.total });
        this.setState({ loading: false });
      })
      .catch(() => {
        this.props.setNotifyWarning('Error loading search results');
        this.setState({ loading: false });
      });
  };

  handleChangeRowsPerPage = (e) => {
    const { pagination } = this.props;
    this.props.setPagination({ ...pagination, limit: e.target.value });
  };

  handleChangePage = (e, page) => {
    const { pagination } = this.props;
    this.props.setPagination({ ...pagination, page });
  };

  onClickFilter = async () => {
    if (this.props.isShowFilter) {
      await this.props.setFilter(false);
      await this.retrieveDoctors;
    } else {
      await this.props.setFilter(true);
    }
  };

  handleFilter = async (filter) => {
    await this.props.setFilter(true, filter);
    await this.retrieveDoctors();
  };

  componentWillMount() {
    this.retrieveDoctors();
    getSpecialties()
      .then(specialties => {
        this.setState({ specialties });
      })
      .catch(e => this.props.setNotifyWarning(e.message));
  }

  componentDidUpdate(prevProps) {
    if (this.props.pagination.page !== prevProps.pagination.page ||
      this.props.pagination.limit !== prevProps.pagination.limit) {
      this.retrieveDoctors();
    }
  }

  render() {
    const { classes, router, doctors, search, pagination } = this.props;

    return (
      <Grid container direction='column'>
        {this.state.loading && (
          <div className={classes.loadingWrap}>
            <Loading/>
          </div>
        )}
        <Typography variant='h5'>Doctors</Typography>
        <Grid container direction='row' style={{ margin: '24px 0' }} justify='space-between'>
          <TextField
            style={{ width: '275px' }}
            label='Search'
            value={search.value}
            onChange={this.onSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <div className={classes.actions}>
            <Tooltip title="Filter list">
              {this.props.isShowFilter ? (
                <Button
                  variant='fab'
                  color='primary'
                  id="filter-button"
                  aria-label="Filter list"
                  onClick={this.onClickFilter}
                >
                  <FilterListIcon />
                </Button>
              ): (
                <IconButton
                  id="filter-button"
                  aria-label="Filter list"
                  onClick={this.onClickFilter}
                >
                  <FilterListIcon />
                </IconButton>
              )}
            </Tooltip>
          </div>
        </Grid>
        {this.props.isShowFilter && (
          <Paper style={{ padding: '0 18px', marginBottom: 18 }}>
            <DoctorsFilter
              filter={this.props.filter}
              specialties={this.state.specialties}
              updateList={this.handleFilter}
            />
          </Paper>
        )}
        <Table>
          <TableBody>
            {doctors.map((doctor, index) => (
              <TableRow key={index} onClick={() => this.props.router.push(`/practitioners/${doctor.user.uid}`)} style={{ cursor: "pointer" }}>
                <TableCell>
                  <Grid container direction='row' alignItems='center' wrap='nowrap' style={{ padding: '8px 0 8px 12px', position: 'relative' }}>
                    {doctor.user.demo ? (<span className={classes.demoLabel}>demo</span>) : null}
                    {doctor.terminatedConsultationCount >= 1 && doctor.terminatedConsultationCount <= 10 ?
                      (<span className={`${classes.hiConsult} ${classes.lowConsult} ${classes.consultLabel}`}/>) : null
                    }
                    {doctor.terminatedConsultationCount > 10 && (
                      <span className={`${classes.hiConsult} ${classes.consultLabel}`} />
                    )}
                    <UserAvatar uid={doctor.user.uid} photoVersion={doctor.user.photoVersion}/>
                    <Grid className={classes.marginX}>
                      <div className={classes.name}>
                        {doctor.user.firstname} {doctor.user.lastname} {doctor.user.verifiedPractitioner ? (
                        <VerifiedIcon className={classes.verified} />
                      ) : null}
                      </div>
                      <div className={classes.hint}>
                        Joined {moment(doctor.user.creationDate).format('MMM D, YYYY')}
                        {doctor.user.country === 'za' ? <span className={`${classes.flagSA} flag-icon flag-icon-za`} /> : null }
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid>
                    <div className={classes.infoItem}>{doctor.user.email}</div>
                    <div className={classes.infoItem}>{doctor.user.phone}</div>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid>
                    <div className={classes.infoItem}>{doctor.patientContactCount} Patients</div>
                    <div className={classes.infoItem}>{doctor.practitionerContactCount} Colleagues</div>
                  </Grid>
                </TableCell>
                <TableCell numeric>
                  <Grid>
                    <div className={classes.infoItem}>
                      {!doctor.consultationRevenue || doctor.consultationRevenue < 10000 ? '$' :
                        doctor.consultationRevenue && doctor.consultationRevenue > 50000 ? '$$$' : '$$'}
                    </div>
                    <div className={classes.infoItem}>
                      {doctor.subscriptionPlan !== 'basic' && (<span className={classes.label}>Subscribed</span>)}
                    </div>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
            {!this.state.loading && search.value.length > 2 && !doctors.length ? (
              <div className={classes.nothingFound}>
                Ah shucks. There are no results to display.
              </div>
            ) : null}
          </TableBody>
        </Table>
        <TablePagination
          count={pagination.total}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          page={pagination.page}
          rowsPerPage={pagination.limit}
          rowsPerPageOptions={[10, 25, 50, 100]}
        />
        <Grid direction='row' container className={classes.container}>
          <div className={classes.legend}>
            <div className={classes.legendTitle}>30-DAY USAGE</div>
            <Grid container direction='row'>
              <div className={classes.legendItem}>
                <span className={`${classes.hiConsult} ${classes.lowConsult}`}/> &gt;= 1 consult
              </div>
              <div className={classes.legendItem}>
                <span className={classes.hiConsult}/>&gt; 10 consults
              </div>
            </Grid>
          </div>
          <Button
            className={classes.newDoctor}
            variant='outlined'
            color='primary'
            onClick={() => router.push('/practitioners/create')}>New Doctor</Button>
        </Grid>
      </Grid>
    )
  }
}

function mapStateToProps(state) {
  return {
    doctors: state.practitioner.doctors || [],
    search: state.practitioner.search || { value: "" },
    pagination: state.practitioner.pagination || {
      total: 0,
      limit: 10,
      page: 0
    },
    isShowFilter: state.practitioner.isShowFilter,
    filter: state.practitioner.filter,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setNotifyWarning: title => {
      dispatch(setNotifyWarning(title));
    },
    setNotifyHidden: () => {
      dispatch(setNotifyHidden());
    },
    setDoctors: doctors => {
      dispatch(setDoctors(doctors));
    },
    setSearchValue: value => dispatch(setSearchValue(value)),
    setPagination: pagination => dispatch(setPagination(pagination)),
    setFilter: (isShowFilter, filter = {}) => dispatch(setFilter(isShowFilter, filter)),
    resetState: () => dispatch(resetState()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(DoctorsList)));
