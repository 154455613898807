import { hashHistory } from 'react-router';
import { version } from '../../../package.json';
import LocalStorage from './LocalStorage';

let isRefreshingToken = false;
let refreshPromise = null;

export const ENVS = {
  dev: 'https://api-dev.medi.ci',
  rc: 'https://api-dev.medi.ci',
  stage: 'https://api-demo.medi.ci',
};

export function getApiUrl() {
  if (isDevelopment) {
    const env = LocalStorage.get('env', 'dev');
    const coreApi = LocalStorage.get('coreApi', ENVS[env]);
    return coreApi || ENVS[env];
  }

  return window.apiUrl;
}

function addCredentials(options, isUseJWT) {
  const loginCredentials = LocalStorage.get('loginCredentials');
  if (loginCredentials && loginCredentials.authToken) {
    const { authToken, token } = loginCredentials;
    return Object.assign({}, options, {
      credentials: 'include',
      headers: {
        'x-app-version': version,
        'X-Auth-Client-Id': '74bb3d27dfee4d9d895d80dd0a5107b8',
        Authorization: `Bearer ${isUseJWT ? token : authToken}`,
      },
    });
  }
  return Object.assign({}, options, { credentials: 'include' });
}

async function refreshAuthToken() {
  const optionsWithCredentials = addCredentials({ method: 'post' });
  const loginCredentials = LocalStorage.get('loginCredentials', {});

  const url = `${getApiUrl()}/oauth/token?grant_type=refresh_token&refresh_token=${loginCredentials.refreshToken}`;
  const response = await fetch(url, optionsWithCredentials);
  const body = response.json();

  const credentials = { uid: loginCredentials.uid };
  credentials.authToken = response.headers.get('WWW-Authenticate');
  credentials.refreshToken = response.headers.get('WWW-Refresh');
  credentials.token = body.token;

  return LocalStorage.set('loginCredentials', credentials);
}

export async function mediciFetch(url, options, isLogin = false, isUseJWT = false) {
  const optionsWithCredentials = addCredentials(options, isUseJWT);

  if (refreshPromise) {
    await refreshPromise;
  }

  const response = await fetch(url, optionsWithCredentials);

  if (isLogin) {
    const authToken = response.headers.get('WWW-Authenticate');
    const refreshToken = response.headers.get('WWW-Refresh');
    if (authToken && response.ok) {
      const body = await response.json();
      return Object.assign({}, body, { authToken, refreshToken });
    }
  }

  if (response.ok) {
    if (response.status === 204) {
      return '{ "status": 204 }';
    }

    const body = await response.json();
    return body;
  }

  if (response.status === 401) {
    if (response.headers.get('X-Auth-MustRefresh')) {
      if (!isRefreshingToken) {
        isRefreshingToken = true;
        refreshPromise = refreshAuthToken();
        await refreshPromise;
        isRefreshingToken = false;
      } else {
        await refreshPromise;
      }

      const newResponse = await mediciFetch(url, options);
      return newResponse;
    }

    refreshPromise = null;
    localStorage.clear();
    hashHistory.push('/login');
  }

  const error = await response.json();
  throw error;
}

export function mediciFetchText(url, options) {
  const optionsWithCredentials = addCredentials(options);
  return fetch(url, optionsWithCredentials).then(response =>
    new Promise((resolve, reject) => {
      if (response.ok) resolve(response.text());
      else {
        response.text().then((error) => {
          if (response.status === 401) {
            hashHistory.push('/login');
          }
          reject(error);
        });
      }
    }));
}

export function mediciFetchBinaryData(url, options) {
  const optionsWithCredentials = addCredentials(options);
  return fetch(url, optionsWithCredentials).then(response =>
    new Promise((resolve, reject) => {
      if (response.ok) resolve(response.blob());
      else {
        response.text().then((error) => {
          if (response.status === 401) {
            hashHistory.push('/login');
          }
          reject(error);
        });
      }
    }));
}

export function serialize(obj) {
  const str = [];
  Object.keys(obj).forEach((p) => {
    if (obj[(p)]) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  });

  return str.join('&');
}

export function trimObjectValues(obj) {
  Object.keys(obj).forEach(
    (k) => { obj[k] = (typeof obj[k] === 'string') ? obj[k].trim() : obj[k]; }
  );
}

export async function fetchGupiEnvs() {
  try {
    const response = await fetch('https://env.medi.ci/');
    const body = response.json();
    return body;
  } catch (error) {
    console.error(error);
  }
  return { environments: [] };
}

export async function forceLogoff(uid, type) {
  return mediciFetch(
    `${getApiUrl()}/${type === 'pa' ? 'patients' : 'practitioners'}/${uid}/tokens`,
    {
      method: "delete"
    }
  );
}
