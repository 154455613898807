import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import './SearchForm.css';

export default class SearchConsultationsFilter extends React.Component {
  static propTypes = {
    onUpdate: PropTypes.func.isRequired,
  };

  state = {
    filterByDate: false,
    dateStart: '',
    dateEnd: '',
    patientUid: '',
    practitionerUid: '',
    order: 'desc',
  };

  onChange = (propName, e) => {
    this.setState({
      [propName]: e.target.value,
    });
  };

  onDateChange = (propName, e) => {
    this.setState({
      [propName]: e.format('YYYY-MM-DD'),
    });
  };

  onDateClick = (e) => {
    this.setState({
      filterByDate: e.target.checked,
    });
  };

  onOrderByConsultClick = (e) => {
    this.setState({
      order: e.target.checked ? 'asc' : 'desc',
    });
  };

  onSearch = (e) => {
    e.preventDefault();

    const searchData = Object.assign({}, this.state);

    if (!searchData.filterByDate) {
      delete searchData.dateStart;
      delete searchData.dateEnd;
    }

    delete searchData.filterByDate;

    this.props.onUpdate(searchData);
  };

  render() {
    return (
      <div className="searchForm-fieldset searchForm-advanced">
        <div className="searchForm-fieldset searchForm-advanced-container">
          <label className="searchForm-advanced-caption">Date</label>
          <div className="searchForm-advanced-valueContainer">
            <div className="searchForm-advanced-valueRow">
              <input
                id="filterByDate"
                type="checkbox"
                checked={this.state.filterByDate}
                onClick={this.onDateClick}
              />{' '}
              &nbsp;<label htmlFor="filterByDate">
                Filter by date
              </label>
            </div>
            <div
              className={
                this.state.filterByDate
                  ? 'searchForm-advanced-valueRow'
                  : 'searchForm-advanced-valueRow disabled'
              }
            >
              <Datetime
                inputProps={{ placeholder: 'mm/dd/yyyy' }}
                value={this.state.dateStart}
                onChange={this.onDateChange.bind(this, 'dateStart')}
              />
              <span>&nbsp;to&nbsp;</span>
              <Datetime
                inputProps={{ placeholder: 'mm/dd/yyyy' }}
                value={this.state.dateEnd}
                onChange={this.onDateChange.bind(this, 'dateEnd')}
              />
            </div>
          </div>
        </div>
        <br />

        <div className="searchForm-fieldset searchForm-advanced-container">
          <label className="searchForm-advanced-caption">Patient Info</label>
          <div className="searchForm-advanced-valueContainer">
            <div className="searchForm-advanced-valueRow">
              <input
                className="sibling"
                placeholder="UID"
                value={this.state.patientUid}
                onChange={this.onChange.bind(this, 'patientUid')}
              />
            </div>
          </div>
        </div>

        <div className="searchForm-fieldset searchForm-advanced-container">
          <label className="searchForm-advanced-caption">
            Practitioner Info
          </label>
          <div className="searchForm-advanced-valueContainer">
            <div className="searchForm-advanced-valueRow">
              <input
                className="sibling"
                placeholder="UID"
                value={this.state.practitionerUid}
                onChange={this.onChange.bind(this, 'practitionerUid')}
              />
            </div>
          </div>
        </div>

        <div className="searchForm-fieldset searchForm-advanced-container">
          <label className="searchForm-advanced-caption" />
          <div className="searchForm-advanced-valueContainer">
            <div className="searchForm-advanced-valueRow">
              <input
                id="startDateTimeOrder"
                type="checkbox"
                checked={this.state.order === 'asc'}
                onClick={this.onOrderByConsultClick}
              />{' '}
              &nbsp;<label htmlFor="startDateTimeOrder">
                Order by start date (Ascending)
              </label>
            </div>
          </div>
        </div>

        <button className="actionButton updateSearch" onClick={this.onSearch}>
          Update Search
        </button>
      </div>
    );
  }
}
