import { FormControlLabel, Grid, Switch, TextField, Typography, withStyles } from '@material-ui/core';
import SHA512 from "crypto-js/sha512";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  setNotifyHidden,
  setNotifySuccess,
  setNotifyWarning
} from "../../components/notification/NotifyUtils";
import {
  findByNPI,
  findByRegistrationId,
  savePractitioner
} from "../../components/practitioners/PractitionerUtils";
import * as helpers from "../../utils/helpers";
import PractitionerInfo from "./PractitionerInfo";

const styles = ({ palette }) => ({
  labelField: {
    color: palette.primary.light,
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  textField: {
    border: "1px solid #E7E7E7",
    borderRadius: "3px",
    backgroundColor: "#FBFBFB",
    padding: "6px 7px"
  },
  name: {
    color: palette.text.secondary,
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 8,
    marginBottom: 1
  }
});

class PractitionerCreate extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    setNotifyHidden: PropTypes.func.isRequired,
    setNotifySuccess: PropTypes.func.isRequired,
    setNotifyWarning: PropTypes.func.isRequired
  };

  state = {
    practitioner: {
      email: "",
      uid: "",
      location: {
        country: "",
        state: "",
        city: "",
        postalCode: "",
        name: "",
        streetNumber: "",
        street: "",
        additionalInfos: "",
        district: "",
        locality: "",
        googleAddress: "",
        geopoint: "F"
      },
      firstname: "",
      lastname: "",
      country: "us",
      dosespotRegistered: false,
      creationDate: "",
      timeZone: "",
      photoVersion: 0,
      region: "",
      inviteCode: "",
      phone: "",
      birthdate: "",
      demo: false,
      currency: "",
      settings: {
        helpscoutSettings: {}
      },
      role: "practitioner",
      status: "",
      autoTranslate: false,
      specialties: [],
      title: "",
      biography: {
        summary: "",
        welcomeMessage: "",
        education: [],
        experiences: [],
        educationV2: [],
        experiencesV2: []
      },
      available: false,
      canPrescribe: true,
      verifiedPractitioner: true,
      npiNumber: "",
      dosespotStatus: 0,
      pricingModel: {
        amount: 2000,
        type: "ppa",
        currency: "USD"
      },
      idCardSubmitted: false,
      responseTime: {
        unit: "hour",
        time: 2
      },
      isVeterinarian: false,
      prettyName: "",
      selectedEhr: "",
      deaNumber: ""
    }
  };

  handleSave = async newUser => {
    let res = null;

    const password = SHA512(this.state.password).toString();
    const request = {
      password,
      title: newUser.title,
      firstname: newUser.firstname,
      lastname: newUser.lastname,
      email: newUser.email,
      phone: newUser.phone,
      birthdate: newUser.birthdate,
      country: newUser.country,
      location: newUser.location && {
        street: newUser.location.street,
        additionalInfos: newUser.location.additionalInfos,
        country: newUser.location.country,
        state: newUser.location.state,
        city: newUser.location.city,
        postalCode: newUser.location.postalCode,
        geopoint: newUser.location.geopoint
      },
      verifiedPractitioner: this.state.practitioner.verifiedPractitioner,
      language: "en",
      specialties: newUser.specialties,
      npiNumber: newUser.npiNumber,
      deaNumber: newUser.deaNumber,
      registrationId: newUser.registrationId,
      practiceNumber: newUser.practiceNumber
    };

    try {
      switch (newUser.country) {
        case "us":
          if (newUser.npiNumber) {
            res = await findByNPI(newUser.npiNumber);
            if (res) {
              request.srcId = res.srcId;
            }
          }
          break;
        case "za":
          if (newUser.registrationId) {
            res = await findByRegistrationId(newUser.registrationId);
            if (res && res.results.length) {
              request.srcId = res.results[0].srcId;
            }
          }
          break;
        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }

    try {
      const resp = await savePractitioner(request);
      this.props.setNotifySuccess(resp.message);
      this.props.router.push(`/practitioners/${resp.practitionerId}`);
    } catch (error) {
      this.props.setNotifyWarning(helpers.errorToString(error));
    }
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleChangeSwitch = name => event => {
    this.setState({
      practitioner: { ...this.state.practitioner, [name]: event.target.checked },
    });
  };

  render() {
    const { classes } = this.props;
    const { practitioner } = this.state;

    return (
      <Grid container direction="column" justify="center" alignItems="stretch">
        <Typography variant="h6" gutterBottom className={classes.name}>
          New Doctor
        </Typography>
        <Grid
          item
          xs={9}
          style={{ paddingRight: 20, marginBottom: 18, marginTop: 5 }}
        >
          <TextField
            label="Password"
            value={this.state.password}
            onChange={this.handleChange("password")}
            InputLabelProps={{
              shrink: true,
              className: classes.labelField
            }}
            InputProps={{ className: classes.textField }}
            fullWidth
          />
          <FormControlLabel
            control={
              <Switch
                value={this.state.practitioner.verifiedPractitioner}
                checked={this.state.practitioner.verifiedPractitioner}
                onChange={this.handleChangeSwitch("verifiedPractitioner")}
              />
            }
            label="Verified"
          />
        </Grid>
        <PractitionerInfo
          practitioner={practitioner}
          save={this.handleSave}
          isCreate={true}
        />
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setNotifySuccess: title => {
      dispatch(setNotifySuccess(title));
    },
    setNotifyWarning: title => {
      dispatch(setNotifyWarning(title));
    },
    setNotifyHidden: () => {
      dispatch(setNotifyHidden());
    }
  };
}

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(PractitionerCreate));
