import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, Grid, MenuItem, TextField, Typography, Checkbox, FormControlLabel, Button,
} from '@material-ui/core';
import { DatePicker } from 'material-ui-pickers';

const styles = theme => ({
  filterItem: {
    width: '20%',
    marginRight: theme.spacing.unit * 3,
  },
  checkbox: {
    marginRight: theme.spacing.unit * 3,
  },
  container: {
    padding: theme.spacing.unit * 2,
  },
});

class ConsultationFilter extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    specialties: PropTypes.array.isRequired,
    terminated: PropTypes.boolean,
    onFilter: PropTypes.func.isRequired,
  };

  state = {
    dateStartTo: null,
    dateStartFrom: null,
    dateEndTo: null,
    dateEndFrom: null,
    patientCountryCode: '',
    patientUid: '',
    patientIsDemo: '',
    patientReviewStars: '',
    practitionerCountryCode: '',
    practitionerUid: '',
    practitionerIsDemo: '',
    specialtyUid: '',
    paymentStatus: '',
    deleted: false,
    terminated: this.props.terminated,
  };

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  handleChangeCheckbox = name => event => {
    this.setState({
      [name]: event.target.checked
    })
  };

  handleChangeDate = prop => date => {
    this.setState({ [prop]: date ? date.format('YYYY-MM-DD') : null })
  };

  onUpdateFilter = () => {
    const filter = { ...this.state };
    this.props.onFilter(filter);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.terminated !== this.props.terminated) {
      this.setState({ terminated: this.props.terminated });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container direction='column' style={{ margin: '18px 0' }}>
        <Typography variant='h6'>Filters</Typography>
        <Grid container direction='row' justify='space-between'>
          <Grid item xs={9}>
            <Grid container direction='row' className={classes.container}>

              <TextField label='Doctor Country'
                         select
                         className={classes.filterItem}
                         value={this.state.practitionerCountryCode}
                         onChange={this.handleChange('practitionerCountryCode')}
              >
                <MenuItem value=''>Any</MenuItem>
                <MenuItem value='us'>US</MenuItem>
                <MenuItem value='za'>ZA</MenuItem>
                <MenuItem value='na'>NA</MenuItem>
              </TextField>

              <TextField label='Doctor UID'
                         className={classes.filterItem}
                         value={this.state.practitionerUid}
                         onChange={this.handleChange('practitionerUid')}
              />

              <TextField label='Doctor Filter'
                         select
                         className={classes.filterItem}
                         value={this.state.practitionerIsDemo}
                         onChange={this.handleChange('practitionerIsDemo')}
              >
                <MenuItem value=''>All Users</MenuItem>
                <MenuItem value="true">Demo users</MenuItem>
                <MenuItem value="false">Non-demo users</MenuItem>
              </TextField>

              <TextField label='Doctor Specialty'
                         select
                         className={classes.filterItem}
                         value={this.state.specialtyUid}
                         onChange={this.handleChange('specialtyUid')}
              >
                <MenuItem value=''>Any</MenuItem>
                {this.props.specialties.map(item => (
                  <MenuItem key={item.uid} value={item.uid}>{item.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.container}>
              <TextField label='Payment'
                         select
                         fullWidth
                         value={this.state.paymentStatus}
                         onChange={this.handleChange('paymentStatus')}
              >
                <MenuItem value=''>Any</MenuItem>
                <MenuItem value='ppa-requested'>Processing</MenuItem>
                <MenuItem value='ppa-payment-accepted'>Paid</MenuItem>
                <MenuItem value='ppa-paid-with-free-credits'>Paid with free credits</MenuItem>
                <MenuItem value='ppa-free'>Free</MenuItem>
                <MenuItem value='ppa-payment-refused'>Declined</MenuItem>
              </TextField>
            </div>
          </Grid>
        </Grid>
        <Grid container direction='row' justify='space-between'>
          <Grid item xs={9}>
            <Grid container direction='row' className={classes.container}>

              <TextField label='Patient Country'
                         select
                         className={classes.filterItem}
                         value={this.state.patientCountryCode}
                         onChange={this.handleChange('patientCountryCode')}
              >
                <MenuItem value=''>Any</MenuItem>
                <MenuItem value='us'>US</MenuItem>
                <MenuItem value='za'>ZA</MenuItem>
                <MenuItem value='na'>NA</MenuItem>
              </TextField>

              <TextField label='Patient UID'
                         className={classes.filterItem}
                         value={this.state.patientUid}
                         onChange={this.handleChange('patientUid')}
              />

              <TextField label='Patient Filter'
                         select
                         className={classes.filterItem}
                         value={this.state.patientIsDemo}
                         onChange={this.handleChange('patientIsDemo')}
              >
                <MenuItem value=''>All Users</MenuItem>
                <MenuItem value="true">Demo users</MenuItem>
                <MenuItem value="false">Non-demo users</MenuItem>
              </TextField>

              <TextField label='Patient Review'
                         select
                         className={classes.filterItem}
                         value={this.state.patientReviewStars}
                         onChange={this.handleChange('patientReviewStars')}
              >
                <MenuItem value=''>Any</MenuItem>
                <MenuItem value='1'>1 star</MenuItem>
                <MenuItem value='2'>2 star</MenuItem>
                <MenuItem value='3'>3 star</MenuItem>
                <MenuItem value='4'>4 star</MenuItem>
                <MenuItem value='5'>5 star</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.container}>
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox
                    color='primary'
                    checked={this.state.deleted}
                    onChange={this.handleChangeCheckbox('deleted')}
                  />
                }
                label='With deleted'
              />
            </div>
          </Grid>
        </Grid>
        <Grid container direction='row' justify='space-between'>
          <Grid item xs={9}>
            <Grid container direction='row' className={classes.container}>
              <DatePicker label='Start From'
                          className={classes.filterItem}
                          clearable
                          emptyLabel='Any'
                          value={this.state.dateStartFrom}
                          onChange={this.handleChangeDate('dateStartFrom')}
                          format='MM/DD/YYYY' />

              <DatePicker label='Start To'
                          className={classes.filterItem}
                          clearable
                          emptyLabel='Any'
                          value={this.state.dateStartTo}
                          onChange={this.handleChangeDate('dateStartTo')}
                          format='MM/DD/YYYY' />
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.container}>
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox
                    color='primary'
                    checked={this.state.terminated}
                    onChange={this.handleChangeCheckbox('terminated')}
                  />
                }
                label='Only Ended'
              />
            </div>
          </Grid>
        </Grid>
        <Grid container direction='row' justify='space-between'>
          <Grid item xs={9}>
            <Grid container direction='row' className={classes.container}>
              <DatePicker label='End From'
                          className={classes.filterItem}
                          emptyLabel='Any'
                          value={this.state.dateEndFrom}
                          onChange={this.handleChangeDate('dateEndFrom')}
                          format='MM/DD/YYYY' />

              <DatePicker label='End To'
                          className={classes.filterItem}
                          emptyLabel='Any'
                          value={this.state.dateEndTo}
                          onChange={this.handleChangeDate('dateEndTo')}
                          format='MM/DD/YYYY' />
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.container}>
              <Button variant='contained' color='primary' onClick={this.onUpdateFilter}>
                See Results
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(ConsultationFilter);
