import { CircularProgress, Grid } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';


const Loading = ({ size }) => (
  <Grid
    style={{ width: '100%', height: '100%' }}
    container
    direction="row"
    justify="center"
    alignItems="center"
  >
    <CircularProgress color="inherit" size={size} />
  </Grid>
);

Loading.propTypes = {
  size: PropTypes.number,
};

export default Loading;
