export default {
  /**
   * Stores key and value
   * @params key
   * @params value
   */
  set(key, value) {
    if (typeof value === 'string') {
      localStorage.setItem(key, value);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  },
  /**
   * Retrieves value in localStorage
   * @params key
   * @params defaultValue
   */
  get(key, defaultValue) {
    let value;
    defaultValue = defaultValue || null;

    try {
      value = JSON.parse(localStorage.getItem(key));
    } catch (err) {
      value = localStorage.getItem(key);
    }

    return value || defaultValue;
  },
  /**
   * Deletes value in localStorage
   * @params key
   */
  remove(key) {
    localStorage.removeItem(key);
  },
  /**
   * Deletes all values in local storage
   * @returns {Promise.<T>}
   */
  removeAll() {
    localStorage.clear();
  },
};
