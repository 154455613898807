import { getApiUrl, mediciFetch } from './NetworkUtils';

export function getSmsDefaultTemplate(language = 'en') {
  return mediciFetch(`${getApiUrl()}/languages/sms/${language}`, {
    method: 'get'
  })
}

export function cancelSmsSend(practitionerUid, scenarioUid, orderId) {
  return mediciFetch(`${getApiUrl()}/practitioners/${practitionerUid}/patientUploads/${scenarioUid}/smsState/${orderId}/cancel`, {
    method: 'put',
    body: JSON.stringify({ cancelled: true }),
  })
}
