import { mediciFetch, getApiUrl } from './../../components/utils/NetworkUtils';

export function getScenarios(scenarioName = '', state = '', limit = 0, offset = 0) {
  const query = [];
  if (scenarioName) query.push(`scenarioName=${scenarioName}`);
  if (state) query.push(`state=${state}`);
  if (limit) query.push(`limit=${limit}&offset=${offset}`);
  return mediciFetch(`${getApiUrl()}/scenarios?${query.join('&')}`);
}

export function getScenarioByUid(uid) {
  return mediciFetch(`${getApiUrl()}/scenarios/${uid}`);
}

export function resumeScenario(uid) {
  return mediciFetch(`${getApiUrl()}/scenarios/${uid}/resume`, { method: 'post' });
}
