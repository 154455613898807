import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  withStyles
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import Loading from "../../components/Loading";
import {
  setNotifyHidden,
  setNotifySuccess,
  setNotifyWarning
} from "../../components/notification/NotifyUtils";
import { mediciFetch, getApiUrl } from "../../components/utils/NetworkUtils";
import { getSettings } from "../../components/utils/SettingUtils";
import DoctorSelect from "./DoctorSelect";

const styles = theme => ({
  form: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    display: "flex",
    flexDirection: "column"
  },
  input: {
    marginTop: theme.spacing.unit
  },
  typeInputLeft: {
    width: "49%",
    marginRight: "auto"
  },
  typeInputRight: {
    width: "49%"
  },
  button: {
    marginTop: theme.spacing.unit * 2,
    marginLeft: "auto",
    marginRight: "auto"
  }
});

class NetworksForm extends React.Component {
  static propTypes = {
    setNotifySuccess: PropTypes.func.isRequired,
    setNotifyWarning: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string
    })
  };

  state = {
    editing: false,
    loading: true,
    saving: false,
    network: {
      typeCode: "",
      areaCode: "",
      title: "",
      description: "",
      mediciMembers: [],
      externalMembers: []
    },
    types: [],
    areas: []
  };

  async componentDidMount() {
    const { id } = this.props.params || {};
    if (id) {
      const splitedId = id.split("-");
      const network = await mediciFetch(
        `${getApiUrl()}/networks/${splitedId[0]}/${splitedId[1]}`,
        {
          method: "get"
        }
      );

      this.setState({ editing: true, network });
    }

    await this.loadNetworksTypes();
    await this.loadNetworksAreas();
    this.setState({ loading: false });
  }

  loadNetworksTypes = async () => {
    // const response = await mediciFetch(`${getApiUrl()}/networks/types`, {
    //   method: "get"
    // });

    const types = [
      {
        code: "rpn",
        title: "Responsive Providers List"
      }
    ];
    this.setState({ types });
  };

  loadNetworksAreas = async () => {
    const response = await getSettings();
    const areasSetting = response.find(setting => {
      const splitedName = setting.name.split(".");
      if (splitedName[0] === "networks" && splitedName[1] === "areaCodes") {
        return true;
      }

      return false;
    });

    const areas = areasSetting.value.split(",").map(area => ({
      code: area,
      country: "us"
    }));

    this.setState({ areas });
  };

  handleChange = name => event => {
    const { network } = this.state;
    network[name] = event.target.value;
    this.setState({ network });
  };

  handleSave = async () => {
    this.setState({ saving: true });
    const network = Object.assign({}, this.state.network);
    network.mediciMembers = network.mediciMembers.map(user => user.uid);

    try {
      await mediciFetch(`${getApiUrl()}/networks`, {
        method: "post",
        body: JSON.stringify(network)
      });

      this.props.setNotifySuccess("Network Saved");
      this.props.router.push("/networks");
    } catch (error) {
      console.log(error);
      this.props.setNotifyWarning("Network Failed to Saved");
    }

    this.setState({ saving: false });
  };

  addMediciMembers = user => {
    const { network } = this.state;
    network.mediciMembers.push(user);
    this.setState({ network });
  };

  removeMediciMembers = uid => {
    const { network } = this.state;
    network.mediciMembers = network.mediciMembers.filter(
      member => member.uid !== uid
    );
    this.setState({ network });
  };

  render() {
    const { classes } = this.props;
    const { editing, loading, saving, network, areas, types } = this.state;
    const isValid =
      network.typeCode.length > 0 &&
      network.areaCode.length > 0 &&
      network.mediciMembers.length > 0;

    if (loading) {
      return <Loading />;
    }

    return (
      <Grid container direction="column" justify="center" alignItems="stretch">
        <Typography variant="h6" gutterBottom>
          Networks
        </Typography>
        <Paper>
          <Typography variant="h5" gutterBottom align="center">
            {editing ? "Edit" : "Create"}
          </Typography>
          <form className={classes.form} onSubmit={this.submit}>
            <Grid container justify="center" alignItems="stretch">
              <FormControl className={classes.typeInputLeft}>
                <InputLabel htmlFor="typeCode">Type *</InputLabel>
                <Select
                  disabled={editing}
                  value={network.typeCode}
                  onChange={this.handleChange("typeCode")}
                  inputProps={{
                    id: "typeCode",
                    name: "typeCode"
                  }}
                >
                  {types.map(type => (
                    <MenuItem key={type.code} value={type.code}>
                      {type.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.typeInputRight}>
                <InputLabel htmlFor="areaCode">Area *</InputLabel>
                <Select
                  disabled={editing}
                  value={network.areaCode}
                  onChange={this.handleChange("areaCode")}
                  inputProps={{
                    id: "areaCode",
                    name: "areaCode"
                  }}
                >
                  {areas.map(area => (
                    <MenuItem key={area.code} value={area.code}>
                      {area.code}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              container
              justify="center"
              alignItems="stretch"
              className={classes.input}
            >
              <TextField
                required
                label="Title"
                value={network.title}
                onChange={this.handleChange("title")}
                className={classes.typeInputLeft}
                inputProps={{ maxLength: "30" }}
              />

              <TextField
                label="Description"
                value={network.description}
                onChange={this.handleChange("description")}
                className={classes.typeInputRight}
                inputProps={{ maxLength: "50" }}
              />
            </Grid>
            <Grid className={classes.input}>
              <DoctorSelect onChange={this.addMediciMembers} required={true} />
            </Grid>
            <Grid item xs={6}>
              <List>
                {network.mediciMembers.map(user => (
                  <ListItem
                    key={user.uid}
                    style={{ borderBottom: "1px solid #D8D8D8" }}
                  >
                    <ListItemText
                      primary={`${user.firstname ||
                        user.firstName} ${user.lastname || user.lastName}`}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        aria-label="Delete"
                        onClick={() => this.removeMediciMembers(user.uid)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this.handleSave}
              disabled={saving || !isValid}
            >
              {saving ? (
                <Loading size={20} />
              ) : (
                `${editing ? "Edit" : "Create"} Network`
              )}
            </Button>
          </form>
        </Paper>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setNotifySuccess: title => {
      dispatch(setNotifySuccess(title));
    },
    setNotifyWarning: title => {
      dispatch(setNotifyWarning(title));
    },
    setNotifyHidden: () => {
      dispatch(setNotifyHidden());
    }
  };
}

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(NetworksForm));
