import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Grid, TextField, Typography, withStyles } from '@material-ui/core';

import { savePatient } from '../../components/patients/PatientUtils';
import {
  setNotifyHidden,
  setNotifySuccess,
  setNotifyWarning
} from '../../components/notification/NotifyUtils';

import PatientInfo from './PatientInfo';

const styles = theme => ({
  name: {
    color: theme.palette.text.secondary,
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 8,
    marginBottom: 1
  },
  labelField: {
    color: theme.palette.primary.light,
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  textField: {
    border: "1px solid #E7E7E7",
    borderRadius: "3px",
    backgroundColor: "#FBFBFB",
    padding: "6px 7px"
  },
});

class PatientCreate extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    setNotifyHidden: PropTypes.func.isRequired,
    setNotifySuccess: PropTypes.func.isRequired,
    setNotifyWarning: PropTypes.func.isRequired,
  };

  state = {
    password: '',
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleCreate = async (patient) => {
    this.props.setNotifyHidden();
    try {
      const res = await savePatient({ ...patient, password: this.state.password });
      this.props.setNotifySuccess('Patient created');
      this.props.router.push(`/patients/${res.patientId}`);
    } catch (e) {
      if (e.invalidFields) {
        this.props.setNotifyWarning(
          Object.keys(e.invalidFields).map(key => e.invalidFields[key]).join(', ')
        );
      } else {
        this.props.setNotifyWarning(e.message);
      }
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container direction="column" justify="center" alignItems="stretch">
        <Typography variant="h6" gutterBottom className={classes.name}>
          New Patient
        </Typography>
        <Grid
          item
          xs={9}
          style={{ paddingRight: 20, marginBottom: 18, marginTop: 5 }}
        >
          <TextField
            type="password"
            label="Password"
            value={this.state.password}
            onChange={this.handleChange("password")}
            InputLabelProps={{
              shrink: true,
              className: classes.labelField
            }}
            InputProps={{ className: classes.textField }}
            fullWidth
          />
        </Grid>
        <PatientInfo patient={{ location: {} }}
                     isCreate={true}
                     onSave={this.handleCreate} />
      </Grid>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setNotifySuccess: title => {
      dispatch(setNotifySuccess(title));
    },
    setNotifyWarning: title => {
      dispatch(setNotifyWarning(title));
    },
    setNotifyHidden: () => {
      dispatch(setNotifyHidden());
    }
  };
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(withRouter(PatientCreate)));
