import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { MenuItem, TextField } from '@material-ui/core';

import './AutoComplete.css';

export default class AutoComplete extends React.Component {
  static propTypes = {
    onSearch: PropTypes.func,
    onSelect: PropTypes.func,
    onClear: PropTypes.func,
    defaultValue: PropTypes.any,
    label: PropTypes.string,
  };

  state = {
    value: '',
    suggestions: [],
  };

  getSuggestionValue = suggestion => suggestion.title;

  renderSuggestion = (suggestion) => {
    if (suggestion.subTitle) {
      return (
        <MenuItem>
          {suggestion.title}
          <br />
          <span className='subTitle'>{suggestion.subTitle}</span>
        </MenuItem>
      );
    }
    return <MenuItem>{suggestion.title}</MenuItem>;
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.props.onSearch(value).then((list) => {
      this.setState({
        suggestions: list,
      });
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    if (this.props.onSelect) {
      this.props.onSelect(suggestion);
    }
  };

  onChange = (event, { newValue }) => {
    if (newValue === '') {
      this.onClear();
    }

    this.setState({
      value: newValue,
    });
  };

  onClear = () => {
    if (this.props.onClear) {
      this.props.onClear();
    }
  };

  componentWillMount() {
    if (this.props.defaultValue) {
      this.setState({
        value: this.props.defaultValue,
      });
    }
  }

  insertAfter(newNode, referenceNode) { // eslint-disable-line
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.defaultValue &&
      nextProps.defaultValue !== this.props.defaultValue
    ) {
      this.setState({
        value: nextProps.defaultValue,
      });
    }
  }

  renderInputComponent = (inputProps) => (
    <TextField
      label={this.props.label || ''}
      {...inputProps} />
  )

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: '',
      value,
      onChange: this.onChange,
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        onSuggestionSelected={this.onSuggestionSelected}
        inputProps={inputProps}
        renderInputComponent={this.renderInputComponent}
        focusInputOnSuggestionClick={false}
      />
    );
  }
}
