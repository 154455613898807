import React from 'react';
import PropTypes from 'prop-types';
import './searchBox.css';

/**
 * SearchBox
 */
export default class SearchBox extends React.Component {
  static propTypes = {
    address: PropTypes.any,
    onSelect: PropTypes.func.isRequired,
    required: PropTypes.bool,
    className: PropTypes.string,
  };

  state = {
    address: this.props.address,
  };

  getDefaultAddress = () => ({
    city: '',
    country: '',
    name: '',
    postalCode: '',
    state: '',
    street: '',
    streetNumber: '',
  });

  blur = () => {
    if (this.refs.address.value !== '') {
      if (
        !this.searchBox.getPlaces() ||
        this.searchBox.getPlaces().length === 0
      ) {
        this.refs.address.value = this.props.address || '';
      }
    } else {
      // Deleted address, send empty object
      this.props.onSelect(this.getDefaultAddress());
    }
  };

  processAddress = (addressComponents) => {
    const components = {};
    const address = this.getDefaultAddress();

    // Convert googles format into a simple location object
    if (addressComponents) {
      addressComponents.forEach((v1) => {
        v1.types.forEach((v2) => {
          components[v2] = v1.long_name;
        });
      });

      address.city = components.locality || components.sublocality || '';
      address.country = components.country || '';
      address.name = `${components.street_number || ''} ${components.route ||
        ''}`;
      address.postalCode = components.postal_code || '';
      address.state = components.administrative_area_level_1 || '';
      address.street = components.route || '';
      address.streetNumber = components.street_number || '';
    }

    return address;
  };

  placesChanged = () => {
    if (this.searchBox.getPlaces() && this.searchBox.getPlaces().length > 0) {
      const address = Object.assign(
        {},
        this.processAddress(this.searchBox.getPlaces()[0].address_components),
      );
      this.props.onSelect(address);
    }
  };

  componentDidMount = () => {
    setTimeout(() => {
      if (google !== undefined) {
        this.searchBox = new google.maps.places.SearchBox(this.refs.address);
        this.searchBox.addListener('places_changed', this.placesChanged);
      }
    }, 1000);
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.address !== nextProps.address) {
      this.setState({ address: nextProps.address });
    }
  }

  onChange = (event) => {
    this.setState({ address: event.target.value });
  };

  render() {
    return (
      <div className="input_container">
        <input
          ref="address"
          placeholder=" "
          required={this.props.required}
          className={this.props.className}
          onChange={this.onChange}
          onBlur={this.blur}
          value={this.state.address}
        />
        <label>&#9660;</label>
      </div>
    );
  }
}
