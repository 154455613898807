import { mediciFetch, getApiUrl, serialize } from './../utils/NetworkUtils';

/**
 * Search consults
 * @param searchValue value to search
 * @returns {Promise.<T>}
 */
export function searchConsultations(searchValue) {
  return mediciFetch(
    `${getApiUrl()}/consultations/search.v2?q=${searchValue}`,
    {
      method: 'get',
    },
  );
}

/**
 * Search consults filter
 * @param filter object of filter criteria
 * @returns {Promise.<T>}
 */
export function advancedSearchConsultations(filter) {
  filter.withMessages = 0;
  return mediciFetch(
    `${getApiUrl()}/consultations/search.v2?${serialize(filter)}`,
    {
      method: 'get',
    },
  );
}

/**
 * Search calls filter
 * @param filter object of filter criteria
 * @param type
 * @returns {Promise.<T>}
 */
export function searchCalls(filter, type = 'patient') {
  if (type === 'patient') {
    return mediciFetch(
      `${getApiUrl()}/patients/${filter.userUid}/calls?${serialize(filter)}`,
      {
        method: 'get',
      },
    );
  }

  return mediciFetch(
    `${getApiUrl()}/practitioners/${filter.userUid}/calls?${serialize(filter)}`,
    {
      method: 'get',
    },
  );
}

/**
 * Get consult by id
 * @param id consult id
 * @returns {Promise.<T>}
 */
export function getConsultationByID(id) {
  return mediciFetch(`${getApiUrl()}/consultations/${id}`, {
    method: 'get',
  });
}

/**
 * Get call by id
 * @param userUid PR id
 * @param id call id
 * @returns {Promise.<T>}
 */
export function getPRCallByID(userUid, id) {
  return mediciFetch(`${getApiUrl()}/practitioners/${userUid}/calls/${id}`, {
    method: 'get',
  });
}

/**
 * Get call by id
 * @param userUid PA id
 * @param id call id
 * @returns {Promise.<T>}
 */
export function getPACallByID(userUid, id) {
  return mediciFetch(`${getApiUrl()}/patients/${userUid}/calls/${id}`, {
    method: 'get',
  });
}

/**
 * Send receipt by email
 * @param id {String}
 * @param emails {Array}
 * @return {Promise.<T>}
 */
export function sendConsultationReceipt(id, emails) {
  return mediciFetch(`${getApiUrl()}/consultations/${id}/email`, {
    body: JSON.stringify({ recipients: emails }),
    method: 'post',
  });
}

/**
 * Get consult details by id
 * @param id consult id
 * @returns {Promise.<T>}
 */
export function getConsultationDetailsByID(id) {
  return mediciFetch(`${getApiUrl()}/consultations/${id}/details`, {
    method: 'get',
  });
}

