import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import moment from 'moment';
import {
  withStyles, Typography, Grid, Button,
  Table, TableRow, TableCell, TableHead,
} from '@material-ui/core';
import { getSettingHistory } from '../../components/utils/SettingUtils';

const styles = () => ({
  button: {
    marginLeft: 'auto',
  },
});

class SettingsHistory extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    router: PropTypes.object,
    params: PropTypes.shape({
      name: PropTypes.string,
    }),
  };

  state = {
    history: [],
  };

  componentWillMount() {
    getSettingHistory(this.props.params.name)
      .then(history => {
        this.setState({ history });
      })
      .catch(e => console.log(e));
  }

  render() {
    const { router, params, classes } = this.props;
    return (
      <Grid container direction='column'>
        <Grid container direction='row' justify='space-between'>
          <Typography variant='h5'>{params.name} change history</Typography>
          <Button color='primary' className={classes.button}
                  onClick={() => router.push('/settings')}>
            Go Back
          </Button>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date of change</TableCell>
              <TableCell>Previous value</TableCell>
              <TableCell>User</TableCell>
            </TableRow>
          </TableHead>
          {this.state.history.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{moment(row.dateTime).format('YYYY-MM-DD [@] h:mm a')}</TableCell>
              <TableCell>{row.previousValue}</TableCell>
              <TableCell>{row.userName}</TableCell>
            </TableRow>
          ))}
        </Table>
      </Grid>
    )
  }
}

export default withStyles(styles)(withRouter(SettingsHistory));
