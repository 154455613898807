import React from 'react';
import PropTypes from 'prop-types';
import AutoComplete from '../autocomplete/AutoComplete';
import { searchPractices } from '../utils/PracticeUtils';

/**
 * PracticeSelector
 */
export default class PracticeSelector extends React.Component {
  static propTypes = {
    onSelect: PropTypes.func.isRequired,
    defaultValue: PropTypes.string,
    className: PropTypes.string,
  }

  onSearch = e =>
    searchPractices({ q: e.toString().trim(), offset: 0, limit: 25 }).then(response =>
      response.content.map((_practice) => {
        _practice.title = _practice.name;
        return _practice;
      }))

  render() {
    return (
      <AutoComplete
        onSearch={this.onSearch}
        onSelect={this.props.onSelect}
        defaultValue={this.props.defaultValue}
        className={this.props.className}
      />
    );
  }
}
