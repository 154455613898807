import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Switch,
  TextField,
  Typography,
  withStyles
} from "@material-ui/core";
import FileCopyOutlined from "@material-ui/icons/FileCopyOutlined";
import RefreshIcon from "@material-ui/icons/Refresh";
import PropTypes from "prop-types";
import React from "react";
import { getMediciSuccessAdmins } from "../../components/admins/AdminUtils";
import SubscriptionUtils from "../../components/utils/SubscriptionUtils";

const styles = ({ spacing, palette }) => ({
  accountWrap: {
    border: "1px solid #E5E5E5",
    backgroundColor: "#F6F6F6",
    boxShadow: "0 2px 7px 0 rgba(0,0,0,0.5)",
    margin: spacing.unit * 3,
    padding: spacing.unit * 3
  },
  accountLabelField: {
    color: palette.text.secondary,
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  accountTextField: {
    padding: `0 ${spacing.unit}px`,
    border: "1px solid #E7E7E7",
    borderRadius: "3px",
    background: "#FBFBFB!important",
    "&::after": {
      display: "none"
    },
    "&::before": {
      display: "none"
    },
    "&> div > div:focus": {
      background: "#FBFBFB"
    }
  },
  accountRow: {
    marginTop: spacing.unit * 3
  },
  copyIcon: {
    padding: spacing.unit
  },
  copyIconSvg: {
    fontSize: "16px"
  }
});

class PractitionerAccount extends React.Component {
  static propTypes = {
    practitioner: PropTypes.object.isRequired,
    classes: PropTypes.object,
    save: PropTypes.func.isRequired,
    onCopyInviteCode: PropTypes.func.isRequired,
    onSendDosespot: PropTypes.func,
    onRefreshDosespot: PropTypes.func,
  };

  constructor(props) {
    super(props);

    const { practitioner } = this.props;

    this.state = {
      admins: [],
      subscription: {},
      mediciSuccessAdminUid: practitioner.mediciSuccessAdminUid || "",
      available: practitioner.available,
      isTriage: practitioner.isTriage,
      canPrescribe: practitioner.canPrescribe,
      verifiedPractitioner: practitioner.verifiedPractitioner,
      inviteCode: practitioner.inviteCode,
      responseTime:
        practitioner.responseTime.time +
        practitioner.responseTime.unit.charAt(0)
    };
  }

  async componentDidMount() {
    try {
      const subscription = await SubscriptionUtils.getSubscription(
        this.props.practitioner.uid
      );

      const response = await getMediciSuccessAdmins();
      const admins = response.content.map(value => value.user);
      this.setState({ subscription, admins });
    } catch (error) {
      console.log(error);
    }
  }

  handleChangeSwitch = name => event => {
    this.setState({
      [name]: event.target.checked
    });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  onSave = () => {
    const { save } = this.props;
    const { responseTime } = this.state;
    const time = parseInt(responseTime, 10);
    const unit = responseTime.substring(responseTime.length - 1);
    save({
      ...this.state,
      // TODO: 'title' can be removed after GHOST-945 fix
      title: this.props.practitioner.title,
      responseTime: {
        time,
        unit: unit === "h" ? "hour" : "minute"
      }
    });
  };

  render() {
    const { classes, practitioner, onCopyInviteCode } = this.props;
    const { subscription, admins } = this.state;

    return (
      <Grid container className={classes.accountWrap} direction="column">
        <Typography variant="h6">Account</Typography>
        <FormControlLabel
          control={
            <Switch
              checked={this.state.available}
              onChange={this.handleChangeSwitch("available")}
            />
          }
          label="Available"
        />
        <FormControlLabel
          control={
            <Switch
              checked={this.state.verifiedPractitioner}
              onChange={this.handleChangeSwitch("verifiedPractitioner")}
            />
          }
          label="Verified"
        />
        <FormControlLabel
          control={
            <Switch
              checked={this.state.isTriage}
              onChange={this.handleChangeSwitch("isTriage")}
            />
          }
          label="Triage User"
        />
        <TextField
          className={classes.accountRow}
          label="Invite Code"
          value={this.state.inviteCode}
          InputLabelProps={{
            shrink: true,
            className: classes.accountLabelField
          }}
          InputProps={{
            className: classes.accountTextField,
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  className={classes.copyIcon}
                  aria-label="Copy Invite Code"
                  onClick={onCopyInviteCode}
                >
                  <FileCopyOutlined className={classes.copyIconSvg} />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <TextField
          className={classes.accountRow}
          label="Response Time"
          select
          value={this.state.responseTime}
          onChange={this.handleChange("responseTime")}
          InputLabelProps={{
            shrink: true,
            className: classes.accountLabelField
          }}
          InputProps={{ className: classes.accountTextField }}
        >
          <MenuItem value="15m">15 Minutes</MenuItem>
          <MenuItem value="30m">30 Minutes</MenuItem>
          <MenuItem value="60m">60 Minutes</MenuItem>
          <MenuItem value="2h">2 Hours</MenuItem>
          <MenuItem value="4h">4 Hours</MenuItem>
          <MenuItem value="12h">12 Hours</MenuItem>
        </TextField>

        <FormControlLabel
          control={
            <Switch
              checked={this.state.canPrescribe}
              onChange={this.handleChangeSwitch("canPrescribe")}
            />
          }
          label="e-Prescribe"
        />

        {subscription.plan === "premium" && (
          <TextField
            id="assignedTo"
            className={classes.accountRow}
            label="Assigned To"
            select
            value={this.state.mediciSuccessAdminUid}
            onChange={this.handleChange("mediciSuccessAdminUid")}
            InputLabelProps={{
              shrink: true,
              className: classes.accountLabelField
            }}
            InputProps={{ className: classes.accountTextField }}
          >
            <MenuItem value="" />
            {admins.map(admin => (
              <MenuItem key={admin.uid} value={admin.uid}>
                {admin.firstname} {admin.lastname}
              </MenuItem>
            ))}
          </TextField>
        )}

        {practitioner.country === "us" && (
          <div style={{ margin: "16px 0" }}>
            <Typography>DoseSpot Status:</Typography>
            {practitioner.dosespotStatus === 0 && (
              <Typography variant="subtitle2" key="info">
                Has not submitted credentials
              </Typography>
            )}
            <Button
              variant="text"
              color="primary"
              onClick={this.props.onRefreshDosespot}
            >
              <RefreshIcon style={{ marginRight: "4px" }} /> Update
            </Button>
            {practitioner.dosespotStatus === 0 && practitioner.canPrescribe && (
              <Button
                key="btn"
                variant="outlined"
                color="primary"
                onClick={this.props.onSendDosespot}
              >
                Send to DoseSpot
              </Button>
            )}
            {practitioner.dosespotStatus === 1 && (
              <Typography variant="subtitle2">Verification Pending</Typography>
            )}
            {practitioner.dosespotStatus === 2 && (
              <Typography variant="subtitle2">ePrescribe Verified</Typography>
            )}
          </div>
        )}
        <Button
          color="primary"
          variant="contained"
          style={{ marginLeft: "auto" }}
          onClick={this.onSave}
        >
          Save
        </Button>
      </Grid>
    );
  }
}

export default withStyles(styles)(PractitionerAccount);
