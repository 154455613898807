import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, Grid, MenuItem, TextField, Checkbox, FormControlLabel, Button, Typography,
} from '@material-ui/core';

const STATES = {
  us: [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
    'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
    'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan',
    'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
    'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma',
    'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee',
    'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming',
  ],
  za: [
    'Eastern Cape', 'Free State', 'Gauteng', 'KwaZulu-Natal', 'Limpopo',
    'Mpumalanga', 'Northern Cape', 'North West',
  ],
};

const styles = theme => ({
  filterItem: {
    width: '20%',
    marginRight: theme.spacing.unit * 3,
  },
  checkbox: {
    marginRight: theme.spacing.unit * 3,
  },
  container: {
    padding: theme.spacing.unit * 2,
  },
});

const composeFilter = (name, filter) => {
  if (typeof filter[`${name}GE`] !== 'undefined') {
    return `${filter[`${name}GE`]}-${filter[`${name}LE`]}`;
  }

  return '';
};

class DoctorsFilter extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    specialties: PropTypes.array.isRequired,
    updateList: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
  };

  state = {
    createdForDays: this.props.filter.createdForDays || '',
    consultationCount: composeFilter('consultationCount', this.props.filter),
    practitionerContactCount: composeFilter('practitionerContactCount', this.props.filter),
    patientContactCount: composeFilter('patientContactCount', this.props.filter),
    specialtyUid: this.props.filter.specialtyUid || '',
    doseSpotStatus: this.props.filter.doseSpotStatus || '',
    state: this.props.filter.state || '',
    countryCode: this.props.filter.countryCode || '',
    isDemo: this.props.filter.isDemo || '',
    subscriber: !!this.props.filter.subscriber,
    verified: this.props.filter.verified ? this.props.filter.verified !== 'false' : false,
    unverified: this.props.filter.verified === 'false',
  };

  handleChangeSwitch = name => event => {
    this.setState({
      [name]: event.target.checked
    })
  };

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  onUpdateFilter = () => {
    const filter = {};
    if (this.state.createdForDays) filter.createdForDays = this.state.createdForDays;
    if (this.state.consultationCount) {
      filter.consultationCountGE = this.state.consultationCount.split('-')[0];
      filter.consultationCountLE = this.state.consultationCount.split('-')[1];
    }
    if (this.state.practitionerContactCount) {
      filter.practitionerContactCountGE = this.state.practitionerContactCount.split('-')[0];
      filter.practitionerContactCountLE = this.state.practitionerContactCount.split('-')[1];
    }
    if (this.state.specialtyUid) filter.specialtyUid = this.state.specialtyUid;
    if (this.state.patientContactCount) {
      filter.patientContactCountGE = this.state.patientContactCount.split('-')[0];
      filter.patientContactCountLE = this.state.patientContactCount.split('-')[1];
    }
    if (this.state.doseSpotStatus) filter.doseSpotStatus = this.state.doseSpotStatus;
    if (this.state.verified) filter.verified = true;
    if (this.state.unverified) filter.verified = 'false';
    if (this.state.subscriber) filter.subscriber = true;
    if (this.state.countryCode) filter.countryCode = this.state.countryCode;
    if (this.state.state) filter.state = this.state.state;
    if (this.state.isDemo) filter.isDemo = this.state.isDemo;

    this.props.updateList(filter);
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container direction='column' style={{ margin: '18px 0' }}>
        <Typography variant='h6'>Filters</Typography>
        <Grid container direction='row' justify='space-between'>
          <Grid item xs={9}>
            <Grid container direction='row' className={classes.container}>
              <TextField label='Joined Date'
                         select
                         className={classes.filterItem}
                         value={this.state.createdForDays}
                         onChange={this.handleChange('createdForDays')}
              >
                <MenuItem value=''>Any</MenuItem>
                <MenuItem value={7}>Last 7 days</MenuItem>
                <MenuItem value={30}>Last 30 days</MenuItem>
                <MenuItem value={60}>Last 60 days</MenuItem>
                <MenuItem value={90}>Last 90 days</MenuItem>
              </TextField>
              <TextField label='Consult Count'
                         select
                         className={classes.filterItem}
                         value={this.state.consultationCount}
                         onChange={this.handleChange('consultationCount')}
              >
                <MenuItem value='' >Any</MenuItem>
                <MenuItem value='0-0'>0</MenuItem>
                <MenuItem value='1-10'>1-10</MenuItem>
                <MenuItem value='11-25'>11-25</MenuItem>
                <MenuItem value='26-100'>26-100</MenuItem>
                <MenuItem value='101-250'>101-250</MenuItem>
                <MenuItem value='251-500'>251-500</MenuItem>
                <MenuItem value='501-1000'>501-1000</MenuItem>
                <MenuItem value='1001-'>1000+</MenuItem>
              </TextField>
              <TextField label='Colleagues'
                         select
                         className={classes.filterItem}
                         value={this.state.practitionerContactCount}
                         onChange={this.handleChange('practitionerContactCount')}
              >
                <MenuItem value='' >Any</MenuItem>
                <MenuItem value='0-0'>0</MenuItem>
                <MenuItem value='1-10'>1-10</MenuItem>
                <MenuItem value='11-25'>11-25</MenuItem>
                <MenuItem value='26-100'>26-100</MenuItem>
                <MenuItem value='101-250'>101-250</MenuItem>
                <MenuItem value='251-500'>251-500</MenuItem>
                <MenuItem value='501-1000'>501-1000</MenuItem>
                <MenuItem value='1001-'>1000+</MenuItem>
              </TextField>
              <TextField label='Account Type'
                         select
                         className={classes.filterItem}
                         value={this.state.isDemo}
                         onChange={this.handleChange('isDemo')}
              >
                <MenuItem value=''>Any</MenuItem>
                <MenuItem value='true'>Demo Users</MenuItem>
                <MenuItem value='false'>Non Demo Users</MenuItem>
              </TextField>
            </Grid>
            <Grid container direction='row' className={classes.container}>
              <TextField label='Specialty'
                         select
                         className={classes.filterItem}
                         value={this.state.specialtyUid}
                         onChange={this.handleChange('specialtyUid')}
              >
                {this.props.specialties.map(item => (
                  <MenuItem key={item.uid} value={item.uid}>{item.name}</MenuItem>
                ))}
              </TextField>
              <TextField label='Patients'
                         select
                         className={classes.filterItem}
                         value={this.state.patientContactCount}
                         onChange={this.handleChange('patientContactCount')}
              >
                <MenuItem value='' >Any</MenuItem>
                <MenuItem value='0-0'>0</MenuItem>
                <MenuItem value='1-10'>1-10</MenuItem>
                <MenuItem value='11-25'>11-25</MenuItem>
                <MenuItem value='26-100'>26-100</MenuItem>
                <MenuItem value='101-250'>101-250</MenuItem>
                <MenuItem value='251-500'>251-500</MenuItem>
                <MenuItem value='501-1000'>501-1000</MenuItem>
                <MenuItem value='1001-'>1000+</MenuItem>
              </TextField>
              <TextField label='ePrescribe Status'
                         select
                         className={classes.filterItem}
                         value={this.state.doseSpotStatus}
                         onChange={this.handleChange('doseSpotStatus')}
              >
                <MenuItem value='' >Any</MenuItem>
                <MenuItem value='0'>Has not submitted credentials</MenuItem>
                <MenuItem value='1'>Pending</MenuItem>
                <MenuItem value='2'>ePrescribe Verified</MenuItem>
              </TextField>
            </Grid>
            <Grid container direction='row' className={classes.container}>
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox
                    color='primary'
                    checked={this.state.verified}
                    onChange={this.handleChangeSwitch('verified')}
                  />
                }
                label='Verified'
              />
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox
                    color='primary'
                    checked={this.state.unverified}
                    onChange={this.handleChangeSwitch('unverified')}
                  />
                }
                label='Unverified'
              />
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox
                    color='primary'
                    checked={this.state.subscriber}
                    onChange={this.handleChangeSwitch('subscriber')}
                  />
                }
                label='Subscriber'
              />
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.container}>
              <TextField label='State'
                         select
                         fullWidth
                         value={this.state.state}
                         onChange={this.handleChange('state')}
              >
                <MenuItem value=''>Any</MenuItem>
                {STATES[this.state.countryCode] ? STATES[this.state.countryCode].map(state => (
                  <MenuItem key={state} value={state}>{state}</MenuItem>
                )) : null }
              </TextField>
            </div>
            <div className={classes.container}>
              <TextField label='Country'
                         select
                         fullWidth
                         value={this.state.countryCode}
                         onChange={this.handleChange('countryCode')}
              >
                <MenuItem value=''>Any</MenuItem>
                <MenuItem value='us'>US</MenuItem>
                <MenuItem value='za'>ZA</MenuItem>
                <MenuItem value='na'>NA</MenuItem>
              </TextField>
            </div>
            <div className={classes.container}>
              <Button variant='contained' color='primary' onClick={this.onUpdateFilter}>
                See Results
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(DoctorsFilter);
