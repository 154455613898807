import { mediciFetch, getApiUrl } from './../utils/NetworkUtils';

export function getPatientPets(patientUid) {
  return mediciFetch(`${getApiUrl()}/pets?ownerUid=${patientUid}`, {
    method: 'get',
  });
}

export function createPatientPet(patientUid, pet) {
  return mediciFetch(`${getApiUrl()}/pets?ownerUid=${patientUid}`, {
    method: 'post',
    body: JSON.stringify(pet),
  });
}

export function updatePatientPet(petUid, pet) {
  return mediciFetch(`${getApiUrl()}/pets/${petUid}`, {
    method: 'put',
    body: JSON.stringify(pet),
  });
}

export function deletePatientPet(petUid) {
  return mediciFetch(`${getApiUrl()}/pets/${petUid}`, {
    method: 'delete',
  });
}

export function getPictureUrl(petUid) {
  return mediciFetch(`${getApiUrl()}/pets/${petUid}/pictureUrl`, {
    method: 'get',
  });
}

export function uploadPicture(petUid, image) {
  const fd = new FormData();
  fd.append("file", image);

  return mediciFetch(`${getApiUrl()}/pets/${petUid}/picture`, {
    method: 'post',
    body: fd,
  });
}
