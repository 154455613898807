import React from 'react';
import PropTypes from 'prop-types';

import Amount from './Amount';

export default class PricingModel extends React.Component {
  constructor(props) {
    super(props);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.onCurrencyChange = this.onCurrencyChange.bind(this);
    this.onPriceChange = this.onPriceChange.bind(this);
  }

  static propTypes = {
    currency: PropTypes.string,
    defaultCurrency: PropTypes.string,
    price: PropTypes.number,
    type: PropTypes.string,
    lockCurrency: PropTypes.bool,
    pricingModel: PropTypes.object,
    onPricingModelChange: PropTypes.func.isRequired,
  };

  onTypeChange(event) {
    let p = Object.assign({}, this.props.pricingModel);
    p.type = event.target.value;
    if (p.type === 'default') {
      p = {};
    }
    this.props.onPricingModelChange(p);
  }

  onCurrencyChange(currency) {
    const p = Object.assign({}, this.props.pricingModel);
    p.currency = currency;
    this.props.onPricingModelChange(p);
  }

  onPriceChange(amount) {
    const p = Object.assign({}, this.props.pricingModel);
    p.amount = amount;
    if (p.amount === null && p.dollars) {
      p.amount = p.dollars * 100;
    }

    p.currency = this.props.defaultCurrency;

    this.props.onPricingModelChange(p);
  }

  render() {
    let price = this.props.pricingModel.amount;
    if (price === 0 || isNaN(price)) price = '';
    const currency = this.props.pricingModel.currency;
    const type = this.props.pricingModel.type;

    return (
      <div className="form-componentContainer">
        <div className="form-freeFieldset">
          <label htmlFor="pricingType" className="form-label">
            {' '}
            Type{' '}
          </label>
          <select
            className="form-select"
            value={type}
            onChange={this.onTypeChange}
          >
            <option value="default"> Default </option>
            <option value="ppa"> Pay Per Act </option>
            <option value="free"> Free </option>
          </select>
        </div>
        {type === 'ppa' && (
          <Amount
            label="Amount Charged Per Consult"
            amount={price}
            currency={currency}
            onAmountChange={this.onPriceChange}
            onCurrencyChange={this.onCurrencyChange}
            lockCurrency={this.props.lockCurrency}
            defaultCurrency={this.props.defaultCurrency}
          />
        )}
      </div>
    );
  }
}
