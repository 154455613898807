export function setStripeKey(key) {
  Stripe.setPublishableKey(key);
}

export function createCard(card) {
  return new Promise((resolve, reject) => {
    Stripe.card.createToken(card, (status, response) => {
      if (status === 200) {
        resolve(response);
      } else {
        reject(response.error);
      }
    });
  });
}
