export const SET_DOCTORS = 'SET_DOCTORS';
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const SET_PAGINATION = 'SET_PAGINATION';
export const RESET_STATE = 'RESET_STATE';
export const SET_FILTER = 'SET_FILTER';

export const defaultState = {
  doctors: [],
  search: { value: '' },
  pagination: {
    total: 0,
    limit: 10,
    page: 0,
  },
  isShowFilter: false,
  filter: {},
};

export function PractitionerReducer(
  state = defaultState,
  action,
) {
  switch (action.type) {
    case SET_DOCTORS:
      return { ...state, doctors: action.data };
    case SET_SEARCH_VALUE:
      return { ...state, search: { value: action.data } };
    case SET_PAGINATION:
      return { ...state, pagination: action.data };
    case SET_FILTER:
      return { ...state, filter: { ...action.data.filter }, isShowFilter: action.data.isShowFilter };
    case RESET_STATE:
      return defaultState;
    default:
      return state;
  }
}

export function setDoctors(doctors) {
  return {
    type: SET_DOCTORS,
    data: doctors
  }
}

export function setSearchValue(value) {
  return {
    type: SET_SEARCH_VALUE,
    data: value,
  }
}

export function setPagination(pagination) {
  return {
    type: SET_PAGINATION,
    data: pagination,
  }
}

export function setFilter(isShowFilter, filter = {}) {
  return {
    type: SET_FILTER,
    data: { filter, isShowFilter },
  }
}

export function resetState() {
  return {
    type: RESET_STATE,
  }
}
