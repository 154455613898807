import MomentUtils from "@date-io/moment";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import "flag-icon-css/css/flag-icon.min.css";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { hashHistory, Route, Router } from "react-router";
import { syncHistoryWithStore } from "react-router-redux";
import store from "./AppStore";
import AdminForm from "./components/admins/AdminForm";
import ConsultationDetail from "./components/consultations/ConsultationDetail";
import CallDetail from './components/consultations/CallDetail';
import ConsultationForm from "./components/consultations/ConsultationForm";
import Main from "./components/Main";
import InvitePatients, { InviteNew } from "./components/practitioners/InvitePatients";
import PractitionerForm from "./components/practitioners/PractitionerForm";
import UploadAttachments from "./components/practitioners/UploadAttachments";
import "./form.css";
import ActivationCodeForm from "./pages/ActivationCodePage/ActivationCodeForm";
import ActivationCodePage from "./pages/ActivationCodePage/ActivationCodePage";
import AdminsList from "./pages/Admins/AdminsList";
import ConsultationsPage from "./pages/ConsultationsPage";
import DoctorsList from "./pages/Doctors/DoctorsList";
import CreateEHRInstance from "./pages/EHRManagement/CreateEHRInstance";
import EHRInstanceDetails from "./pages/EHRManagement/EHRInstanceDetails";
import EHRManagement from "./pages/EHRManagement/EHRManagement";
import InviteIncentives from "./pages/InviteIncentives";
import CreateInviteIncentives from "./pages/InviteIncentives/CreateInviteIncentives";
import InviteIncentivesReport from "./pages/InviteIncentives/InviteIncentivesReport";
import LoginPage from "./pages/LoginPage";
import Networks from "./pages/Network";
import NetworksForm from "./pages/Network/NetworksForm";
import NotificationsPage from "./pages/NotificationsPage";
import PatientProfile from "./pages/Patients/PatientProfile";
import PatientsList from "./pages/Patients/PatientsList";
import PatientCreate from "./pages/Patients/PatientCreate";
import PracticeForm from "./pages/Practices/PracticeForm";
import PracticesPage from "./pages/Practices/PracticesPage";
import PractitionerProfile from "./pages/PractitionerProfile";
import PractitionerCreate from "./pages/PractitionerProfile/PractitionerCreate";
import PractitionersPage from "./pages/PractitionersPage";
import ScenarioDetails from "./pages/ScenarioManagement/ScenarioDetails";
import ScenarioManagement from "./pages/ScenarioManagement/ScenarioManagement";
import SettingsHistory from "./pages/Settings/SettingsHistory";
import Settings from "./pages/Settings/SettingsList";
import UpgradeVersionPage from "./pages/Settings/UpgradeVersionPage";
import "./table.css";

const mediciTheme = createMuiTheme({
  palette: {
    common: { black: "#000", white: "#fff" },
    background: {
      paper: "rgba(255, 255, 255, 1)",
      default: "rgba(255, 255, 255, 1)"
    },
    primary: {
      light: "#336b9d",
      main: "#004685",
      dark: "#00315d",
      contrastText: "#fff"
    },
    secondary: {
      light: "#17906b",
      main: "#21ce99",
      dark: "#4dd7ad",
      contrastText: "#fff"
    },
    error: {
      light: "#ff7878",
      main: "#ff5757",
      dark: "#b23c3c",
      contrastText: "#fff"
    },
    text: {
      primary: "#333",
      secondary: "#556979",
      disabled: "#8c9dab",
      hint: "#8c9dab"
    }
  },
  status: {
    danger: "#FFA300"
  },
  overrides: {
    MuiTableCell: {
      root: {
        padding: "4px 12px"
      }
    },
    MuiToolbar: {
      regular: {
        background: "#fff",
        boxShadow: "none"
      }
    }
  },
  typography: {
    useNextVariants: true
  }
});

function requireAuth(nextState, replace) {
  const state = store.getState();

  if (!state.login.logged) {
    replace({
      pathname: "/login",
      state: { nextPathname: nextState.location.pathname }
    });
  }
}

function grantAccessFor(roles = []) {
  const { user } = store.getState().login;
  return (nextState, replace) => {
    if (user && user.subRole && !roles.includes(user.subRole)) {
      replace({ pathname: "/practitioners" });
    }
  }
}

const history = syncHistoryWithStore(hashHistory, store);

ReactDOM.render(
  <MuiThemeProvider theme={mediciTheme}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Provider store={store}>
        <Router history={history}>
          <Route path="/login"
                 component={LoginPage}
                 name="Login" />
          <Route path="/"
                 component={Main}
                 onEnter={requireAuth}>
            <Route path="/patients"
                   component={PatientsList}
                   onEnter={grantAccessFor(['admin'])} />
            <Route path="/patients/create"
                   component={PatientCreate}
                   onEnter={grantAccessFor(['admin'])} />
            <Route path="/patients/:id(/:tab)"
                   component={PatientProfile}
                   onEnter={grantAccessFor(['admin'])} />
            <Route path="/patients/:userUid/calls/:id"
              component={CallDetail}
              role={'patient'}
              onEnter={grantAccessFor(['admin'])} />
            <Route path={"/practitioners"}
                   component={DoctorsList}
                   onEnter={grantAccessFor(['admin', 'sales'])} />
            <Route path="/practitioners/create"
                   component={PractitionerCreate}
                   onEnter={grantAccessFor(['admin', 'sales'])}
            />
            <Route path="/practitioners/:id"
                   component={PractitionerProfile}
                   onEnter={grantAccessFor(['admin', 'sales'])} />
            <Route path="/practitioners/:userUid/calls/:id"
              component={CallDetail}
              onEnter={grantAccessFor(['admin'])} />
            <Route component={PractitionersPage} path="/practitioners-old">
              <Route component={InvitePatients} path="invite/:id" />
              <Route component={InviteNew} path="invite-new/:id" />
              <Route component={UploadAttachments} path="upload/:id" />
              <Route component={PractitionerForm} path=":id" />
            </Route>
            <Route path="/admins"
                   component={AdminsList}
                   onEnter={grantAccessFor(['admin'])} />
            <Route path="/admins/:id"
                   component={AdminForm}
                   onEnter={grantAccessFor(['admin'])} />
            <Route path="/practices"
                   component={PracticesPage}
                   onEnter={grantAccessFor(['admin', 'sales'])} />
            <Route path="/practices/create"
                   component={PracticeForm}
                   onEnter={grantAccessFor(['admin', 'sales'])} />
            <Route path="/practices/:id"
                   component={PracticeForm}
                   onEnter={grantAccessFor(['admin', 'sales'])} />
            <Route path="/consultations"
                   component={ConsultationsPage}
                   onEnter={grantAccessFor(['admin'])} />
            <Route path="/consultations/:id"
                   component={ConsultationDetail}
                   onEnter={grantAccessFor(['admin'])} />
            <Route path="/consultations-old/:id"
                   component={ConsultationForm}
                   onEnter={grantAccessFor(['admin'])} />
            <Route path="/activation-code"
                   component={ActivationCodePage}
                   onEnter={grantAccessFor(['admin', 'sales'])} />
            <Route path="/activation-code/:id"
                   component={ActivationCodeForm}
                   onEnter={grantAccessFor(['admin', 'sales'])} />
            <Route path="/invite-incentives"
                   component={InviteIncentives}
                   onEnter={grantAccessFor(['admin'])} />
            <Route path="/invite-incentives/create"
                   component={CreateInviteIncentives}
                   onEnter={grantAccessFor(['admin'])} />
            <Route path="/invite-incentives/:id/report"
                   component={InviteIncentivesReport}
                   onEnter={grantAccessFor(['admin'])} />
            <Route path="/ehr-management"
                   component={EHRManagement}
                   onEnter={grantAccessFor(['admin'])} />
            <Route path="/ehr-management/create"
                   component={CreateEHRInstance}
                   onEnter={grantAccessFor(['admin'])} />
            <Route path="/ehr-management/details/:sourceKey"
                   component={EHRInstanceDetails}
                   onEnter={grantAccessFor(['admin'])} />
            <Route path="/notifications"
                   component={NotificationsPage}
                   onEnter={grantAccessFor(['admin'])} />
            <Route path="/networks"
                   component={Networks}
                   onEnter={grantAccessFor(['admin'])} />
            <Route path="/networks/create"
                   component={NetworksForm}
                   onEnter={grantAccessFor(['admin'])} />
            <Route path="/networks/:id"
                   component={NetworksForm}
                   onEnter={grantAccessFor(['admin'])} />
            <Route path="/settings"
                   component={Settings}
                   onEnter={grantAccessFor(['admin'])} />
            <Route path="/settings/upgrade-version"
                   component={UpgradeVersionPage}
                   onEnter={grantAccessFor(['admin'])} />
            <Route path="/settings/history/:name"
                   component={SettingsHistory}
                   onEnter={grantAccessFor(['admin'])} />
            <Route path="/scenarios"
                   component={ScenarioManagement}
                   onEnter={grantAccessFor(['admin'])} />
            <Route path="/scenarios/:uid"
                   component={ScenarioDetails}
                   onEnter={grantAccessFor(['admin'])} />
          </Route>
        </Router>
      </Provider>
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>,
  document.getElementById("main")
);
