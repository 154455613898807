import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
  withStyles
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React from "react";
import Loading from "../../../components/Loading";
import {
  deletePractitionerFromPractice,
  savePractitionertoPractice
} from "../../../components/utils/PracticeUtils";
import { errorToString } from "../../../utils/helpers";
import DoctorSelect from "../../Network/DoctorSelect";
import styles from "./styles";

class PracticeMembers extends React.Component {
  static propTypes = {
    setNotifyWarning: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    practiceUid: PropTypes.string.isRequired,
    members: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      newMembers: [...this.props.members]
    };
  }

  addMember = async member => {
    const { newMembers } = this.state;
    this.setState({ newMembers: newMembers.concat([member]) });
    this.props.onChange();
  };

  removeMember = async uid => {
    const { newMembers } = this.state;
    this.setState({
      newMembers: newMembers.filter(member => member.uid !== uid)
    });
    this.props.onChange();
  };

  handleSubmit = async () => {
    const { practiceUid, members } = this.props;
    const { newMembers } = this.state;

    this.setState({ saving: true });

    try {
      // Remove
      const membersUids = newMembers.map(member => member.uid);
      const membersToRemove = members
        .filter(member => !membersUids.includes(member.uid))
        .map(member => member.uid);

      if (membersToRemove.length > 0) {
        await deletePractitionerFromPractice(practiceUid, membersToRemove);
      }

      // Add
      const originalMembersUids = members.map(member => member.uid);
      const membersToAdd = newMembers
        .filter(member => !originalMembersUids.includes(member.uid))
        .map(member => member.uid);

      if (membersToAdd.length > 0) {
        await savePractitionertoPractice(practiceUid, membersToAdd);
      }

      this.props.onSave(newMembers);
    } catch (error) {
      this.props.setNotifyWarning(errorToString(error));
    }

    this.setState({ saving: false });
  };

  render() {
    const { classes } = this.props;
    const { newMembers, saving } = this.state;
    return (
      <Paper style={{ marginTop: "1em" }}>
        <Typography variant="h5" gutterBottom align="center">
          Members
        </Typography>
        <Grid className={classes.form}>
          <DoctorSelect
            onChange={this.addMember}
            label="Search for practice members..."
          />
          <List>
            {newMembers.map(user => (
              <ListItem
                key={user.uid}
                style={{ borderBottom: "1px solid #D8D8D8" }}
              >
                <ListItemText
                  primary={`${user.firstname ||
                    user.firstName} ${user.lastname || user.lastName}`}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    aria-label="Delete"
                    onClick={() => this.removeMember(user.uid)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>

          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={saving || this.props.disabled}
            onClick={this.handleSubmit}
          >
            {saving ? <Loading size={20} /> : "Save members"}
          </Button>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(PracticeMembers);
