import {
  Button,
  Grid,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles
} from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";
import PropTypes from "prop-types";
import React from "react";
import ContactsDialog from "../../components/ContactsDialog";
import Loading from "../../components/Loading";
import {
  getPractitionerContacts,
  deletePractitionerContact
} from "../../components/practitioners/PractitionerUtils";
import UserAvatar from "../../components/user-avatar/UserAvatar";
import * as helpers from "../../utils/helpers";

const styles = ({ palette }) => ({
  title: {
    color: palette.primary.light
  },
  name: {
    fontSize: 13,
    fontWeight: "bold",
    marginLeft: 8
  },
  addButton: {
    marginTop: 20
  },
  inviteButton: {
    marginTop: 20,
    marginLeft: 16
  }
});
class PractitionerColleagues extends React.Component {
  static propTypes = {
    setNotifyWarning: PropTypes.func.isRequired,
    practitioner: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    role: PropTypes.string,
    classes: PropTypes.object.isRequired
  };

  static defaultProps = {
    role: "practitioner"
  };

  state = {
    loading: true,
    contacts: [],
    selectedContact: null,
    anchorEl: null,
    openContactsDialog: false
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    try {
      const response = await getPractitionerContacts(
        this.props.practitioner.uid
      );
      this.setState({ contacts: response.contacts });
    } catch (error) {
      this.props.setNotifyWarning(helpers.errorToString(error));
    }

    this.setState({ loading: false });
  };

  handleClick = (event, selectedContact) => {
    this.setState({ anchorEl: event.currentTarget, selectedContact });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, selectedContact: null });
  };

  handleRemove = async contactToRemove => {
    const { practitioner } = this.props;

    try {
      await deletePractitionerContact(practitioner.uid, contactToRemove.id);

      const filteredContacts = this.state.contacts.filter(contact => {
        if (contact.userUid === contactToRemove.userUid) {
          return false;
        }

        return true;
      });

      this.setState({ contacts: filteredContacts });
    } catch (error) {
      this.props.setNotifyWarning(helpers.errorToString(error));
    }
  };

  handleView = contact => {
    const { role } = this.props;
    this.props.router.push(`/${role}s/${contact.userUid}`);
  };

  toggleContactsDialog = () =>
    this.setState({ openContactsDialog: !this.state.openContactsDialog });

  renderContactRow = contact => {
    const { selectedContact, anchorEl } = this.state;
    const { classes } = this.props;
    const uid = helpers.getUidFromString(contact.id);

    return (
      <TableRow key={uid}>
        <TableCell>
          <Grid container direction="row" alignItems="center">
            <UserAvatar
              uid={uid}
              photoVersion={contact.photoVersion}
              role={`${contact.role}s`}
              size={27}
            />
            <span className={classes.name}>{contact.prettyName}</span>
          </Grid>
        </TableCell>
        <TableCell>{contact.birthdate}</TableCell>
        <TableCell>{contact.phone}</TableCell>
        <TableCell>{helpers.shortUid(uid)}</TableCell>
        <TableCell>
          <Button color="secondary" onClick={() => this.handleView(contact)}>
            View
          </Button>
          <Button
            onClick={e => this.handleClick(e, contact)}
            aria-owns={selectedContact ? `simple-menu${uid}` : undefined}
            aria-haspopup="true"
          >
            <MoreHoriz />
          </Button>
          <Menu
            id={`simple-menu${uid}`}
            open={Boolean(selectedContact && selectedContact.id === contact.id)}
            onClose={this.handleClose}
            anchorEl={anchorEl}
          >
            <MenuItem onClick={() => this.handleRemove(contact)}>
              Remove
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
    );
  };

  render() {
    const { classes, practitioner, role } = this.props;
    const { loading, contacts, openContactsDialog } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <Grid container>
        <Typography variant="h6" className={classes.title}>
          {role === "practitioner" ? "Colleagues" : "Patients"}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>DOB</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Medici ID</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {contacts
              .filter(contact => contact.status === "linked")
              .filter(contact => contact.role === role)
              .map(contact => this.renderContactRow(contact))}
          </TableBody>
        </Table>
        <Button
          variant="contained"
          color="primary"
          onClick={this.toggleContactsDialog}
          className={classes.addButton}
        >
          Add {role === "practitioner" ? "Colleague" : "Patient"}
        </Button>
        {role === "patient" && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() =>
              this.props.router.push(
                `/practitioners-old/invite/${practitioner.uid}`
              )
            }
            className={classes.inviteButton}
          >
            Invite Patients
          </Button>
        )}
        {role === "patient" && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() =>
              this.props.router.push(
                `/practitioners-old/invite-new/${practitioner.uid}`
              )
            }
            className={classes.inviteButton}
          >
            Invite Patients (NEW)
          </Button>
        )}
        <ContactsDialog
          uid={openContactsDialog && practitioner.uid}
          handleClose={this.toggleContactsDialog}
          isPractitioner={true}
        />
      </Grid>
    );
  }
}

export default withStyles(styles)(PractitionerColleagues);
