import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
  withStyles
} from "@material-ui/core";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router";
import Loading from "../../components/Loading";
import { mediciFetch, getApiUrl } from "../../components/utils/NetworkUtils";

const styles = theme => ({
  form: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    display: "flex",
    flexDirection: "column"
  },
  input: {
    marginTop: theme.spacing.unit
  },
  typeInputLeft: {
    width: "49%",
    marginRight: "auto"
  },
  typeInputRight: {
    width: "49%"
  },
  button: {
    marginTop: theme.spacing.unit * 2,
    marginLeft: "auto",
    marginRight: "auto",
    width: "157px",
    height: "36px"
  }
});

const TypeSelect = ({ className, id, label, value, onChange }) => (
  <FormControl className={className}>
    <InputLabel htmlFor={id}>{label}</InputLabel>
    <Select
      value={value}
      onChange={onChange}
      inputProps={{
        id,
        name: label
      }}
    >
      <MenuItem value="pr">Doctor</MenuItem>
    </Select>
  </FormControl>
);

TypeSelect.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

class CreateInviteIncentives extends React.Component {
  state = {
    loading: false,
    messageOpened: false,
    message: "",
    incentive: {
      startDate: moment().format("YYYY-MM-DD"),
      country: "US",
      typeFrom: "pr",
      typeTo: "pr",
      currency: "USD",
      amount: 0
    }
  };

  submit = async e => {
    e.preventDefault();
    this.setState({ loading: true });

    const { incentive } = this.state;

    try {
      await mediciFetch(`${getApiUrl()}/admins/incentive`, {
        method: "POST",
        body: JSON.stringify({
          startDate: moment(incentive.startDate).toDate(),
          type: `${incentive.typeFrom}-${incentive.typeTo}`,
          amount: Number(incentive.amount * 100),
          currency: incentive.currency,
          country: incentive.country
        })
      });

      this.props.router.push("/invite-incentives");
    } catch (error) {
      this.setState({
        messageOpened: true,
        message: error.message
      });
    }

    this.setState({ loading: false });
  };

  handleChangeIncentive = (event, key) => {
    const { incentive } = this.state;
    const value = event.target.value;
    incentive[key] = value;

    if (key === "amount" && Number(incentive.amount) < 0) {
      incentive[key] = 0;
    }

    if (key === "currency") {
      if (value === "USD") {
        incentive.country = "US";
      } else if (value === "NAD") {
        incentive.country = "NA";
      } else {
        incentive.country = "ZA";
      }
    }

    if (key === "country") {
      if (value === "US") {
        incentive.currency = "USD";
      } else if (value === "NA") {
        incentive.currency = "NAD";
      } else {
        incentive.currency = "ZAR";
      }
    }

    this.setState({ incentive });
  };

  render() {
    const { classes } = this.props;
    const { loading, incentive } = this.state;

    return (
      <Grid container direction="column" justify="center" alignItems="stretch">
        <Typography variant="h6" gutterBottom>
          Invite Incentive
        </Typography>
        <Paper>
          <Typography variant="h5" gutterBottom align="center">
            Create
          </Typography>
          <form className={classes.form} onSubmit={this.submit}>
            <Grid container justify="center" alignItems="stretch">
              <TypeSelect
                id="from"
                label="From"
                className={classes.typeInputLeft}
                value={incentive.typeFrom}
                onChange={event =>
                  this.handleChangeIncentive(event, "typeFrom")
                }
              />
              <TypeSelect
                id="to"
                label="To"
                className={classes.typeInputRight}
                value={incentive.typeTo}
                onChange={event => this.handleChangeIncentive(event, "typeTo")}
              />
            </Grid>

            <FormControl className={classes.input}>
              <InputLabel htmlFor="country">Country</InputLabel>
              <Select
                value={incentive.country}
                onChange={event => this.handleChangeIncentive(event, "country")}
                inputProps={{
                  name: "Country",
                  id: "country"
                }}
              >
                <MenuItem value="US">USA</MenuItem>
                <MenuItem value="NA">Namibia</MenuItem>
                <MenuItem value="ZA">South Africa</MenuItem>
              </Select>
            </FormControl>

            <Grid
              container
              justify="center"
              alignItems="stretch"
              className={classes.input}
            >
              <TextField
                className={classes.typeInputLeft}
                id="amount"
                label="Amount"
                type="number"
                value={incentive.amount}
                onChange={event => this.handleChangeIncentive(event, "amount")}
              />

              <FormControl className={classes.typeInputRight}>
                <InputLabel htmlFor="currency">Currency</InputLabel>
                <Select
                  value={incentive.currency}
                  onChange={event =>
                    this.handleChangeIncentive(event, "currency")
                  }
                  inputProps={{
                    name: "Currency",
                    id: "currency"
                  }}
                >
                  <MenuItem value="USD">USD</MenuItem>
                  <MenuItem value="ZAR">ZAR</MenuItem>
                  <MenuItem value="NAD">NAD</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <TextField
              className={classes.input}
              id="startDate"
              label="Start Date"
              type="date"
              value={incentive.startDate}
              onChange={event => this.handleChangeIncentive(event, "startDate")}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
            />

            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              type="submit"
            >
              {loading ? <Loading size={20} /> : "Create Incentive"}
            </Button>
          </form>
        </Paper>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={this.state.messageOpened}
          autoHideDuration={6000}
          onClose={() => this.setState({ messageOpened: false })}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.message}</span>}
        />
      </Grid>
    );
  }
}

CreateInviteIncentives.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

const StyledCreateInviteIncentives = withStyles(styles)(CreateInviteIncentives);

export default withRouter(StyledCreateInviteIncentives);
