import { withStyles, CircularProgress } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import React from "react";
import { mediciFetch, getApiUrl } from "../utils/NetworkUtils";

const styles = () => ({
  avatar: {
    borderRadius: "50%"
  },
  icon: {
    position: "absolute",
    bottom: 2,
    right: 18,
    cursor: "pointer"
  }
});

// Changed from PureComponent, need performace improvement?
class UserAvatar extends React.Component {
  static propTypes = {
    uid: PropTypes.string.isRequired,
    photoVersion: PropTypes.number,
    role: PropTypes.string,
    size: PropTypes.number,
    classes: PropTypes.object,
    className: PropTypes.string,
    enableUpload: PropTypes.bool
  };

  static defaultProps = {
    role: "practitioners",
    size: 40
  };

  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.fileInput = React.createRef();
    this.state = { uploadedImage: null, loading: false };
  }

  handleEditPicture = () => {
    const { enableUpload } = this.props;

    if (enableUpload) {
      this.fileInput.current.click();
    }
  };

  handleUploadPicture = async event => {
    const { uid, role } = this.props;
    this.setState({ loading: true });

    try {
      const file = event.target.files[0];
      const fd = new FormData();
      fd.append("picture", file);

      await mediciFetch(`${getApiUrl()}/${role}/${uid}/picture`, {
        method: "post",
        body: fd
      });

      const reader = new FileReader();
      reader.onload = e => {
        this.setState({
          uploadedImage: e.target.result
        });
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.log(error);
    }

    this.setState({ loading: false });
  };

  render() {
    const { uploadedImage, loading } = this.state;
    const {
      uid,
      photoVersion,
      role,
      classes,
      size,
      className,
      enableUpload
    } = this.props;

    const params = ["w=100"];
    if (photoVersion) params.push(`photoVersion=${photoVersion}`);
    const url = `${getApiUrl()}/${role}/${uid}/picture?${params.join("&")}`;
    return (
      <div style={{ position: "relative", height: "100%" }}>
        <img
          src={uploadedImage || url}
          className={className || classes.avatar}
          style={size ? { width: size, height: size } : null}
        />

        {enableUpload &&
          (loading ? (
            <CircularProgress color="inherit" className={classes.icon} size={25} />
          ) : (
            <EditIcon
              className={classes.icon}
              onClick={this.handleEditPicture}
            />
          ))}
        {enableUpload && (
          <input
            ref={this.fileInput}
            style={{ display: "none" }}
            type="file"
            name="file"
            accept=".jpg,.jpeg,.png,.gif"
            onChange={this.handleUploadPicture}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(UserAvatar);
