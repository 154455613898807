import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  withStyles, Button, Grid, Typography,
  Table, TableHead, TableBody, TableRow, TableCell,
  Dialog, DialogTitle, DialogContent, DialogActions,
  TextField,
} from '@material-ui/core';

import {
  setNotifyHidden,
  setNotifySuccess,
  setNotifyWarning
} from '../../components/notification/NotifyUtils';

import { deleteSetting, getSettings, saveSetting } from '../../components/utils/SettingUtils';

const styles = theme => ({
  container: {
    margin: `${theme.spacing.unit * 3}px 0`,
  },
  cell: {
    wordBreak: 'break-word',
  },
});

const EXCLUDE = ['app.ios.min.version', 'app.android.min.version', 'app.web.min.version'];

class SettingsList extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    setNotifyHidden: PropTypes.func.isRequired,
    setNotifyWarning: PropTypes.func.isRequired,
    setNotifySuccess: PropTypes.func.isRequired,
    router: PropTypes.object,
  };

  state = {
    items: [],
    item: {},
    isOpen: false,
  };


  loadSettings = () => {
    getSettings()
      .then((settings) => {
        this.props.setNotifyHidden();
        this.setState({
          items: settings.filter(item => !EXCLUDE.includes(item.name)),
        });
      })
      .catch((ex) => {
        this.props.setNotifyWarning(ex.message);
      });
  };

  onRowDelete = (index) => {
    if (confirm('Delete setting?')) {
      deleteSetting(this.state.items[index])
        .then(() => {
          this.props.setNotifySuccess('Setting deleted');
          this.setState({ items: [ ...this.state.items.splice(index, 1) ]})
        })
        .catch((e) => {
          this.props.setNotifyWarning(e.message);
        });
    }
  };

  onRowEdit = (index) => {
    this.setState({ item: this.state.items[index], isOpen: true });
  };

  handleClose = () => {
    this.setState({ item: {}, isOpen: false });
  };

  handleChange = (prop) => (e) => {
    const { value } = e.target;
    this.setState({ item: { ...this.state.item, [prop]: value }});
  };

  handleSave = () => {
    saveSetting(this.state.item)
      .then(() => {
        this.props.setNotifySuccess('Setting saved');
        this.setState({ isOpen: false });
        this.loadSettings();
      })
      .catch((e) => {
        this.props.setNotifyWarning(e.message());
      });
  };

  onClickAdd = () => {
    this.setState({ isOpen: true })
  };

  componentWillMount() {
    this.loadSettings();
  }

  render() {
    const { classes, router } = this.props;
    return (
      <Grid container direction='column'>
        <Typography variant='h5'>Settings</Typography>
        <Grid container direction='row' justify='flex-end' className={classes.container}>
          <Grid item>
            <Button variant='outlined'
                    color='primary'
                    style={{ marginRight: 12 }}
                    onClick={this.onClickAdd}>
              Add Setting
            </Button>
            <Button variant='contained'
                    color='secondary'
                    onClick={() => router.push('/settings/upgrade-version')}>
              Edit Upgrade Version
            </Button>
          </Grid>
        </Grid>
        <Table style={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Value</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.items.map( (item, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{item.name}</TableCell>
                <TableCell className={classes.cell}>{item.value}</TableCell>
                <TableCell numeric style={{ whiteSpace: 'nowrap' }}>
                  <Button color='secondary'
                          onClick={() => this.onRowEdit(index)}
                  >Edit</Button>
                  <Button color='secondary'
                          onClick={() => this.onRowDelete(index)}
                  >Delete</Button>
                  <Button color='secondary'
                          onClick={() => router.push(`/settings/history/${item.name}`)}
                  >History</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Dialog open={this.state.isOpen}
                onClose={this.handleClose}
                fullWidth
        >
          <DialogTitle>{this.state.item.creationDate ? 'Edit' : 'Add'} Setting</DialogTitle>
          <DialogContent>
            <TextField label='Name'
                       className={classes.container}
                       value={this.state.item.name}
                       inputProps={{ readOnly: this.state.item.creationDate }}
                       onChange={this.handleChange('name')}
                       fullWidth
            />
            <TextField label='Value'
                       className={classes.container}
                       value={this.state.item.value}
                       onChange={this.handleChange('value')}
                       fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button color='primary'
                    onClick={this.handleClose}
            >Cancel</Button>
            <Button variant='contained'
                    color='primary'
                    onClick={this.handleSave}
            >{this.state.item.creationDate ? 'Save' : 'Add'}</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setNotifySuccess: (title) => {
      dispatch(setNotifySuccess(title));
    },
    setNotifyWarning: (title) => {
      dispatch(setNotifyWarning(title));
    },
    setNotifyHidden: () => {
      dispatch(setNotifyHidden());
    },
  };
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(withRouter(SettingsList)));
