import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { debounce } from 'underscore';
import {
  withStyles, Grid, InputAdornment, TextField, Typography,
  Table, TableHead, TableBody, TableRow, TableCell, Button, TablePagination,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { setNotifyWarning } from '../../components/notification/NotifyUtils';
import { searchAdmins } from '../../components/admins/AdminUtils';

import Loading from '../../components/Loading';

const styles = theme => ({
  loadingWrap: {
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    background: 'rgba(255, 255, 255, 0.75)',
    zIndex: 1,
  },
  action: {
    marginLeft: 'auto',
    marginBottom: 'auto',
  },
  container: {
    margin: `${theme.spacing.unit * 2}px 0`,
  },
});

class AdminsList extends React.Component {
  static propTypes = {
    router: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    setNotifyWarning: PropTypes.func,
  };

  state = {
    admins: [],
    searchValue: '',
    pagination: {
      total: 0,
      limit: 10,
      page: 0,
    },
    loading: false,
  };

  search = debounce(() => {
    this.retrieveAdmins();
  }, 500);

  onSearch = e => {
    const { value } = e.target;
    this.setState({ searchValue: value }, this.search);
  };

  retrieveAdmins = async () => {
    const { limit, page } = this.state.pagination;
    this.setState({ loading: true });
    try {
      const res = await searchAdmins(this.state.searchValue, limit, limit * page);
      this.setState({
        admins: res.content,
        pagination: {
          ...this.state.pagination,
          total: res.pagination.total,
        },
        loading: false,
      })
    } catch (e) {
      this.setState({ loading: false });
      this.props.setNotifyWarning(e.message);
    }
  };

  handleChangeRowsPerPage = (e) => {
    const { pagination } = this.state;
    this.setState({ pagination: { ...pagination, limit: e.target.value } }, this.retrieveAdmins);
  };

  handleChangePage = (e, page) => {
    const { pagination } = this.state;
    this.setState({ pagination: { ...pagination, page } }, this.retrieveAdmins);
  };

  componentWillMount() {
    this.retrieveAdmins()
  }

  render() {
    const { pagination } = this.state;
    const { classes, router } = this.props;
    return (
      <Grid container direction='column'>
        {this.state.loading && (
          <div className={classes.loadingWrap}>
            <Loading/>
          </div>
        )}
        <Typography variant='h5'>Admins</Typography>
        <Grid container direction='row' style={{ margin: '24px 0' }} justify='space-between'>
          <TextField
            style={{ width: '275px' }}
            label='Search'
            value={this.state.searchValue}
            onChange={this.onSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.admins.map(admin => (
              <TableRow key={admin.user.uid}>
                <TableCell>{`${admin.user.firstname} ${admin.user.lastname}`}</TableCell>
                <TableCell>{admin.user.email}</TableCell>
                <TableCell numeric>
                  <Button color='primary'
                          onClick={() => router.push(`/admins/${admin.user.uid}`)}>
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          count={pagination.total}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          page={pagination.page}
          rowsPerPage={pagination.limit}
          rowsPerPageOptions={[10, 25, 50, 100]}
        />
        <Grid direction='row' container className={classes.container}>
          <Button
            className={classes.action}
            variant='outlined'
            color='primary'
            onClick={() => router.push('/admins/create')}>New Admin</Button>
        </Grid>
      </Grid>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setNotifyWarning: (title) => {
      dispatch(setNotifyWarning(title));
    },
  };
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(withRouter(AdminsList)));
