import PropTypes from "prop-types";
import React from "react";
import PatientDialog from "./PatientDialog";
import PractitionerDialog from "./PractitionerDialog";

const ProfileDialog = props => {
  if (props.isPractitioner) {
    return (
      <PractitionerDialog
        uid={props.uid}
        handleClose={props.handleClose}
        handleOpenContacts={props.handleOpenContacts}
      />
    );
  }

  return (
    <PatientDialog
      uid={props.uid}
      handleClose={props.handleClose}
      handleOpenContacts={props.handleOpenContacts}
    />
  );
};

ProfileDialog.propTypes = {
  isPractitioner: PropTypes.bool,
  uid: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleOpenContacts: PropTypes.func.isRequired
};

export default ProfileDialog;
