import React from 'react';
import PropTypes from 'prop-types';

export default class CountrySelector extends React.Component {
  static propTypes = {
    onCountryChanged: PropTypes.func.isRequired,
    country: PropTypes.string,
    id: PropTypes.string,
  }

  onChange = (event) => {
    const value = event.target.value;

    this.props.onCountryChanged(value);
  }

  render() {
    return (
      <select
        className="titleSelector"
        onChange={this.onChange}
        value={this.props.country}
        id={this.props.id}
      >
        <option value="">Default</option>
        <option value="us">USA</option>
        <option value="na">Namibia</option>
        <option value="za">South Africa</option>
      </select>
    );
  }
}
