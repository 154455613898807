import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Button,
  Grid, IconButton, Menu, MenuItem, Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Avatar,
  withStyles
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreHoriz';

import {
  getPatientPets,
  createPatientPet,
  updatePatientPet,
  deletePatientPet,
  getPictureUrl,
  uploadPicture,
} from '../../components/utils/PetsUtils';
import PetDialog from '../../components/PetDialog/PetDialog';

const styles = (theme) => ({
  title: {
    color: theme.palette.primary.light,
  },
  container: {
    margin: `${theme.spacing.unit * 2}px 0`,
  },
  avatar: {
    width: 24,
    height: 24,
    marginRight: theme.spacing.unit,
  },
});

class PatientPets extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    patient: PropTypes.object.isRequired,
    setNotifySuccess: PropTypes.func.isRequired,
    setNotifyWarning: PropTypes.func.isRequired,
  };

  state = {
    pets: [],
    activePet: null,
    isOpen: false,
    anchorEl: null,
    openPetDialog: false,
    isLoading: false,
  };

  fetchPets = async () => {
    this.setState({ isLoading: true });
    try {
      const res = await getPatientPets(this.props.patient.uid);
      const pictures = await Promise.all(res.pets.map(pet => getPictureUrl(pet.uid)));
      this.setState({
        pets: res.pets.map((pet, index) => ({ ...pet, imageUrl: pictures[index].pictureUrl })),
        isLoading: false,
      });
    } catch (e) {
      this.setState({ isLoading: false });
      this.props.setNotifyWarning(e.message);
    }
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      isOpen: false,
    });
  };

  handleMenu = (pet) => (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      isOpen: true,
      activePet: pet,
    });
  };

  handleAdd = () => {
    this.setState({ openPetDialog: true });
  };

  handleEdit = () => {
    this.setState({
      openPetDialog: true,
      isOpen: false,
    });
  };

  handleRemove = async () => {
    try {
      await deletePatientPet(this.state.activePet.uid);
      this.setState({ isOpen: false, activePet: null }, this.fetchPets);
      this.props.setNotifySuccess('Pet is removed!');
    } catch (e) {
      this.props.setNotifyWarning(e.message);
    }
  };

  handleClosePetDialog = async (pet, image) => {
    if (pet) {
      this.setState({ isLoading: true });
      if (pet.uid) {
        try {
          await updatePatientPet(pet.uid, pet);
          if (image) {
            await uploadPicture(pet.uid, image);
          }
          this.setState({ openPetDialog: false, activePet: null, isLoading: false }, this.fetchPets);
        } catch (e) {
          this.setState({ isLoading: false });
          this.props.setNotifyWarning(e.message);
        }
      } else {
        try {
          const res = await createPatientPet(this.props.patient.uid, { ...pet, uid: null });
          if (image) {
            await uploadPicture(res.uid, image);
          }
          this.setState({ openPetDialog: false, activePet: null, isLoading: false }, this.fetchPets);
        } catch (e) {
          this.setState({ isLoading: false });
          this.props.setNotifyWarning(e.message);
        }
      }
    } else {
      this.setState({ openPetDialog: false, activePet: null, isLoading: false });
    }
  };

  componentDidMount() {
    this.fetchPets();
  }

  render() {
    const { classes } = this.props;
    const { isOpen, anchorEl, openPetDialog } = this.state;

    return (
      <Grid container direction='row'>
        <Grid container direction='row' justify='space-between' alignItems='center'>
          <Typography variant="h6" className={classes.title}>
            Pet Profiles
          </Typography>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Breed</TableCell>
              <TableCell>DoB</TableCell>
              <TableCell>Sex</TableCell>
              <TableCell>Status</TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            { this.state.pets.map(pet => (
              <TableRow key={pet.uid}>
                <TableCell>
                  <Grid container alignItems='center'>
                    <Avatar src={pet.imageUrl} className={classes.avatar} />
                    {pet.name}
                  </Grid>
                </TableCell>
                <TableCell>{pet.typeName}</TableCell>
                <TableCell>{pet.breed}</TableCell>
                <TableCell>{moment(pet.dateOfBirth).format('MMMM DD, YYYY')}</TableCell>
                <TableCell>{pet.genderName}</TableCell>
                <TableCell>{pet.owners.length ? pet.owners[0].linkStatus : ''}</TableCell>
                <TableCell>
                  <IconButton aria-haspopup="true"
                              onClick={this.handleMenu(pet)}
                              color="inherit">
                    <MoreIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={classes.container}>
          <Button variant='contained'
                  color='primary'
                  onClick={this.handleAdd}>
            Add pet
          </Button>
        </div>
        <Menu
          id="menu-table"
          anchorReference='anchorPosition'
          anchorPosition={{
            top: anchorEl ? anchorEl.getBoundingClientRect().bottom - 18 : 0,
            left: anchorEl ? anchorEl.getBoundingClientRect().left : 0
          }}
          open={isOpen}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleEdit}>Edit</MenuItem>
          <MenuItem onClick={this.handleRemove}>Remove</MenuItem>
        </Menu>
        <PetDialog
          isOpen={openPetDialog}
          pet={this.state.activePet}
          isLoading={this.state.isLoading}
          handleClose={this.handleClosePetDialog}
        />
      </Grid>
    );
  }
}

export default withStyles(styles)(PatientPets);
