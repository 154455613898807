import {
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableRow
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import * as helpers from "../../utils/helpers";
import { getApiUrl } from '../utils/NetworkUtils';
import {
  getPatientByID,
  getPatientInsuranceByID
} from "../patients/PatientUtils";
import Loading from "../Loading";

const styles = {
  appBar: {
    position: "relative",
    minWidth: '500px'
  },
  flex: {
    flex: 1
  }
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

function renderDate(dateString) {
  return moment(dateString).format("YYYY-MM-DD [@] h:mm a");
}

class PatientDialog extends React.Component {
  static propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleOpenContacts: PropTypes.func.isRequired,
    uid: PropTypes.string
  };

  state = {
    loading: true,
    open: false,
    patient: null,
    error: null
  };

  loadData = async uid => {
    try {
      const patient = await getPatientByID(uid);
      const insurance = await getPatientInsuranceByID(uid);
      this.setState({ patient: Object.assign({}, patient, insurance) });
    } catch (error) {
      this.setState({ error });
    }

    this.setState({ loading: false });
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.uid) {
      this.setState({ open: false });
      return;
    }

    if (nextProps.uid !== this.props.uid) {
      this.setState({ loading: true, open: true });
      this.loadData(nextProps.uid);
    }
  }

  renderRow = (label, value) => {
    if (!value && typeof value !== "boolean") {
      return null;
    }

    return (
      <TableBody>
        <TableRow>
          <TableCell>{label}</TableCell>
          <TableCell>{value.toString()}</TableCell>
        </TableRow>
      </TableBody>
    );
  };

  renderPhoto = () => {
    const url = `${getApiUrl()}/patients/${
      this.state.patient.uid
    }/picture?w=50`;

    return (
      <TableBody>
        <TableRow>
          <TableCell>Photo</TableCell>
          <TableCell>
            <img src={url} />
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  renderTypography = (value, type = "h6") => (
    <TableBody>
      <TableRow>
        <TableCell colSpan="2">
          <Typography variant={type}>{value}</Typography>
        </TableCell>
      </TableRow>
    </TableBody>
  );

  render() {
    const { classes } = this.props;
    const { patient } = this.state;

    let content = <Loading />;

    if (!this.state.loading) {
      const location = helpers.locationToString(patient.location);
      content = (
        <div>
          <Table>
            {this.renderPhoto()}
            {this.renderRow("Date Created", renderDate(patient.creationDate))}
            {this.renderRow("Country", patient.country)}
            {this.renderRow("Language", patient.language)}
            {this.renderRow("Title", patient.title)}

            {this.renderRow("First Name", patient.firstname)}
            {this.renderRow("Last Name", patient.lastname)}
            {typeof patient.gender !== "undefined"
              ? this.renderRow(
                  "Gender",
                  patient.gender === 1 ? "Female" : "Male"
                )
              : this.renderRow("Gender", "Undefined")}
            {this.renderRow("Medici ID", patient.uid)}
            {this.renderRow("Date of Birth", patient.birthdate)}
            {this.renderRow("Phone", patient.phone)}
            {this.renderRow("Email", patient.email)}
            {this.renderRow(
              "Payment Card",
              patient.paymentMethods.length ? "True" : "False"
            )}
            {this.renderRow(
              patient.country === "za" ? "Medical Aid Card" : "Insurance Card",
              patient.insuranceList.length ? "True" : "False"
            )}

            {this.renderRow("Invite Code", patient.inviteCode)}
            {this.renderRow("Is Demo/Sales account", patient.demo)}

            {this.renderRow("Default currency", patient.currency)}
            {this.renderRow(
              "Free credits",
              patient.freeCredits &&
                patient.freeCredits[patient.currency] &&
                patient.freeCredits[patient.currency].amount / 100
            )}

            {location && (
              <React.Fragment>
                {this.renderRow("Address", location)}
                {this.renderRow(
                  "Additional Info",
                  patient.location.additionalInfos
                )}
              </React.Fragment>
            )}
          </Table>
        </div>
      );
    }

    return (
      <Dialog
        open={this.state.open}
        onClose={() => this.props.handleClose(false)}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar} position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={() => this.props.handleClose(false)}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              Patient
            </Typography>

            <Button color="inherit" onClick={() => this.props.handleOpenContacts()}>
              Contacts
            </Button>
            <Button
              color="inherit"
              onClick={() => this.props.handleClose(true)}
            >
              Edit
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>{content}</DialogContent>
      </Dialog>
    );
  }
}

PatientDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PatientDialog);
