import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Typography, withStyles,
  Table, TableRow, TableCell, TableHead, TableBody, Button,
  TablePagination,
} from '@material-ui/core';
import moment from 'moment';
import CallIcon from '@material-ui/icons/Call';

import PillSvg from './../../images/icons/pill.svg';

import { advancedSearchConsultations } from '../../components/consultations/ConsultationUtils';
import { getCurrencySymbol } from '../../utils/helpers';
import UserAvatar from '../../components/user-avatar/UserAvatar';

const styles = theme => ({
  container: {
    paddingTop: theme.spacing.unit * 2,
  },
  title: {
    color: theme.palette.primary.light
  },
  opacity: {
    opacity: 0.2,
  }
});

class UserConsults extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    uid: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    onViewConsult: PropTypes.func.isRequired,
    setNotifyWarning: PropTypes.func.isRequired,
  };

  state = {
    activeConsults: [],
    pastConsults: [],
    activePagination: {
      total: 0,
      limit: 10,
      page: 0,
    },
    pastPagination: {
      total: 0,
      limit: 10,
      page: 0,
    },
  };

  handleChangeRowsPerPageActive = (e) => {
    const { activePagination } = this.state;
    this.setState({ activePagination: { ...activePagination, limit: e.target.value } }, this.retrieveConsults);
  };

  handleChangePageActive = (e, page) => {
    const { activePagination } = this.state;
    this.setState({ activePagination: { ...activePagination, page } }, this.retrieveConsults);
  };

  handleChangeRowsPerPagePast = (e) => {
    const { pastPagination } = this.state;
    this.setState({ pastPagination: { ...pastPagination, limit: e.target.value } }, () => this.retrieveConsults(false));
  };

  handleChangePagePast = (e, page) => {
    const { pastPagination } = this.state;
    this.setState({ pastPagination: { ...pastPagination, page } }, () => this.retrieveConsults(false));
  };

  retrieveConsults = (isActive = true) => {
    const { page, limit } = this.state[`${isActive ? 'active' : 'past' }Pagination`];

    const params = {
      terminated: isActive ? 'false' : 'true',
      size: limit,
      offset: limit * page,
    };

    if (this.props.role === "patient") {
      params.patientUid = this.props.uid;
    } else {
      params.practitionerUid = this.props.uid;
    }

    advancedSearchConsultations(params)
      .then(result => {
        this.setState({
          [`${isActive ? 'active' : 'past'}Consults`]: result.content,
          [`${isActive ? 'active' : 'past' }Pagination`]: {
            page,
            limit,
            total: result.pagination.total,
          }
        })
      })
      .catch(e => this.props.setNotifyWarning(e.message));
  };

  componentWillMount() {
    this.retrieveConsults();
    this.retrieveConsults(false);
  }

  render() {
    const { classes, role } = this.props;
    const { activePagination, pastPagination } = this.state;
    return (
      <Grid container direction='column' className={classes.container}>
        <Typography variant="h6" className={classes.title}>
          Active Consults
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Started</TableCell>
              <TableCell>{role === "patient" ? "Provider" : "Patient"}</TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.activeConsults.map(consult => (
              <TableRow key={consult.uid}>
                <TableCell>{moment(consult.dateStart).format('YYYY-MM-DD [@] h:mm a')}</TableCell>
                <TableCell>
                  <Grid container direction='row' alignItems='center'>
                    <UserAvatar role={role === "patient" ? "practitioners" : "patients"} uid={role === "patient" ? consult.practitionerUid : consult.patientUid} size={25} />
                    <Typography style={{ marginLeft: 10 }} variant='subtitle2'>{role === "patient" ? consult.practitionerPrettyName : consult.patientPrettyName}</Typography>
                  </Grid>
                </TableCell>
                <TableCell numeric>
                  <Button color='secondary'
                          onClick={() => this.props.onViewConsult(consult.uid)}>
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          count={activePagination.total}
          onChangePage={this.handleChangePageActive}
          onChangeRowsPerPage={this.handleChangeRowsPerPageActive}
          page={activePagination.page}
          rowsPerPage={activePagination.limit}
          rowsPerPageOptions={[10, 25, 50]}
        />
        <Typography variant="h6" className={classes.title} style={{ marginTop: 16 }}>
          Past Consults
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Started</TableCell>
              <TableCell>Ended</TableCell>
              <TableCell>{role === "patient" ? "Provider" : "Patient"}</TableCell>
              <TableCell numeric>Messages</TableCell>
              <TableCell numeric>Charge</TableCell>
              <TableCell/>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.pastConsults.map(consult => (
              <TableRow key={consult.uid}>
                <TableCell>{moment(consult.dateStart).format('YYYY-MM-DD [@] h:mm a')}</TableCell>
                <TableCell>{moment(consult.dateEnd).format('YYYY-MM-DD [@] h:mm a')}</TableCell>
                <TableCell>
                  <Grid container direction='row' alignItems='center'>
                    <UserAvatar role={role === "patient" ? "practitioners" : "patients"} uid={role === "patient" ? consult.practitionerUid : consult.patientUid}  size={25} />
                    <Typography style={{ marginLeft: 10 }} variant='subtitle2'>{role === "patient" ? consult.practitionerPrettyName : consult.patientPrettyName}</Typography>
                  </Grid>
                </TableCell>
                <TableCell numeric>{consult.messageCount}</TableCell>
                <TableCell numeric>
                  {consult.consultationChargeSummary ? (
                    <div>{getCurrencySymbol(consult.consultationChargeSummary.currency)} {consult.consultationChargeSummary.amount / 100}</div>
                  ) : (
                    <span>Free</span>
                  )}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  <CallIcon className={consult.hasAudioCalls ? '' : classes.opacity} style={{ marginRight: 6 }}/>
                  <img src={PillSvg} className={consult.hasPrescriptions ? '' : classes.opacity} />
                </TableCell>
                <TableCell numeric>
                  <Button color='secondary'
                          onClick={() => this.props.onViewConsult(consult.uid)}>
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          count={pastPagination.total}
          onChangePage={this.handleChangePagePast}
          onChangeRowsPerPage={this.handleChangeRowsPerPagePast}
          page={pastPagination.page}
          rowsPerPage={pastPagination.limit}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </Grid>
    );
  }
}

export default withStyles(styles)(UserConsults);
