import SHA512 from 'crypto-js/sha512';
import { mediciFetch, getApiUrl, mediciFetchBinaryData } from './../utils/NetworkUtils';

const ACTION_LOGIN_OK = 'ACTION_LOGIN_OK';
const ACTION_GET_USER = 'ACTION_GET_USER';
const ACTION_LOGIN_KO = 'ACTION_LOGIN_KO';
const ACTION_LOGOUT_OK = 'ACTION_LOGOUT_OK';

/**
 * Reducer that manages the states linked to the login
 */
export function LoginReducer(state = { logged: false, user: {} }, action) {
  switch (action.type) {
    case ACTION_LOGIN_OK:
      return Object.assign({}, state, { logged: true });
    case ACTION_GET_USER:
      return Object.assign({}, state, { user: action.user });
    case ACTION_LOGIN_KO:
    case ACTION_LOGOUT_OK:
      return Object.assign({}, state, { logged: false, user: {} });
    default:
      return state;
  }
}

/**
 * Create a "login ok" action
 */
export function loginOk(credentials) {
  return {
    type: ACTION_LOGIN_OK,
    credentials,
  };
}

/**
 * Create a "get user" action
 */
export function getUser(user) {
  return {
    type: ACTION_GET_USER,
    user,
  };
}

/**
 * Create a "login failed" action
 */
export function loginKo() {
  return {
    type: ACTION_LOGIN_KO,
  };
}

/**
 * Create a "logout OK" action
 */
export function logoutOk() {
  return {
    type: ACTION_LOGOUT_OK,
  };
}

/**
 * Try to login with the user credentials
 * @param login The user login
 * @param password The user password
 * @returns {Promise.<T>}
 */
export function loginUser(login, password, code) {
  return mediciFetch(
    `${getApiUrl()}/admins/login`,
    {
      method: 'post',
      body: JSON.stringify({
        email: login,
        password: SHA512(password).toString(),
        verificationCode: code,
      }),
      headers: {
        'X-Auth-Client-Id': '74bb3d27dfee4d9d895d80dd0a5107b8',
      },
    },
    true,
  );
}

/**
 * Log out user
 * @returns {Promise.<T>}
 */
export function logoutUser() {
  return mediciFetch(`${getApiUrl()}/admins/logout`, {
    method: 'post',
  });
}

/**
 * Check user session
 * @returns {Promise.<T>}
 */
export function checkSession() {
  return mediciFetch(`${getApiUrl()}/admins/current`, {
    method: 'get',
  });
}

/**
 * Get QR code for user 2 step authentication
 * @returns {Promise.<T>}
 */
export function getQRCode(token) {
  return mediciFetchBinaryData(`${getApiUrl()}/admins/current/totp/qrcode?jwtToken=${token}`, {
    method: 'get',
  });
}

/**
 * Reset TOTP, used for testing only
 * @returns {Promise.<T>}
 */
export function resetTOTP(uid) {
  return mediciFetch(`${getApiUrl()}/admins/${uid}/totp/reset`, {
    method: 'get',
  });
}
