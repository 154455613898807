import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { AppBar, Tab, Tabs, Typography, withStyles } from '@material-ui/core';
import Settings from "@material-ui/icons/Settings";
import { connect } from 'react-redux';

import Loading from '../../components/Loading';
import PatientHeader from './PatientHeader';
import PatientInfo from './PatientInfo';
import PatientProviders from './PatientProviders';
import UserConsults from './UserConsults';
import UserCalls from './UserCalls';
import PatientPayment from './PatientPayment';
import PatientInsurance from './PatientInsurance';
import PatientSettings from './PatientSettings';
import PatientPets from './PatientPets';

import {
  getPatientByID,
  getPatientContacts,
  savePatient,
  deletePatient,
  removePatientContact,
  removePatientPayment,
  setPaymentAsDefault,
  addPaymentMethod,
} from '../../components/patients/PatientUtils';
import { advancedSearchConsultations } from '../../components/consultations/ConsultationUtils';
import { setNotifyHidden, setNotifyWarning, setNotifySuccess } from '../../components/notification/NotifyUtils';
import { forceLogoff } from '../../components/utils/NetworkUtils';

const styles = ({ palette }) => ({
  loadingWrap: {
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    background: 'rgba(255, 255, 255, 0.75)',
    zIndex: 1,
  },
  appBar: {
    backgroundColor: '#556979',
  },
  settings: {
    color: palette.common.white,
  }
});

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class PatientProfile extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    router: PropTypes.object,
    params: PropTypes.shape({
      id: PropTypes.string,
      tab: PropTypes.string,
    }),
    setNotifyHidden: PropTypes.func.isRequired,
    setNotifyWarning: PropTypes.func.isRequired,
    setNotifySuccess: PropTypes.func.isRequired,
  };

  state = {
    value: parseInt(this.props.params.tab, 10) || 0,
    loading: false,
    patient: null,
    providers: [],
    consultCount: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
    this.props.router.replace(`/patients/${this.props.params.id}/${value}`);
  };

  onCopyInviteCode = async () => {
    if ("clipboard" in window.navigator) {
      try {
        await window.navigator.clipboard.writeText(
          this.state.patient.inviteCode
        );
        this.props.setNotifySuccess("Copied!");
      } catch (e) {
        this.props.setNotifyWarning(e.message);
      }
    }
  };

  save = (data) => {
    this.setState({ loading: true });
    this.props.setNotifyHidden();
    savePatient({ ...data, uid: this.props.params.id })
      .then((patient) => {
        this.setState({ patient, loading: false });
        this.props.setNotifySuccess('Patient Saved!')
      })
      .catch((e) => {
        this.setState({ loading: false });
        if (e.invalidFieldsV2) {
          const message = Object.keys(e.invalidFieldsV2)
            .map(key => `${key}: ${e.invalidFieldsV2[key].message}`)
            .join(' ');
          this.props.setNotifyWarning(message);
        } else {
          this.props.setNotifyWarning(e.message);
        }
      });
  };

  removeContact = (contactId) => {
    removePatientContact(this.state.patient.uid, contactId)
      .then(() => {
        this.setState({
          providers: this.state.providers.filter(contact => contact.id !== contactId),
        })
      })
      .catch(e => this.props.setNotifyWarning(e.message));
  };

  handleProviderUpdate = () => {
    this.props.setNotifyHidden();
    this.setState({ loading: true });
    getPatientContacts(this.props.params.id)
      .then(result => {
        const providers = result.contacts.filter(contact => contact.status === 'linked' && contact.role === 'practitioner');
        this.setState({
          providers,
          loading: false,
        })
      })
      .catch(e => {
        this.props.setNotifyWarning(e.message);
        this.setState({ loading: false });
      });
  };

  removePaymentMethod = (methodUid) => {
    removePatientPayment(this.props.params.id, methodUid)
      .then(() => {
        this.setState({
          patient: {
            ...this.state.patient,
            paymentMethods: this.state.patient.paymentMethods.filter(method => method.uid !== methodUid),
          }
        })
      })
      .catch(e => this.props.setNotifyWarning(e.message));
  };

  setPaymentMethodAsDefault = (methodUid) => {
    setPaymentAsDefault(this.props.params.id, methodUid)
      .then(() => {
        this.setState({
          patient: {
            ...this.state.patient,
            paymentMethods: this.state.patient.paymentMethods.map(method => {
              if (method.uid === methodUid) {
                return { ...method, favorite: true };
              }
              return { ...method, favorite: false};
            })
          }
        })
      })
      .catch(e => this.props.setNotifyWarning(e.message));
  };

  addPaymentMethod = (body) => {
    addPaymentMethod(this.props.params.id, body)
      .then((result) => {
        this.setState({
          patient: {
            ...this.state.patient,
            paymentMethods: [
              ...this.state.patient.paymentMethods.map(n => ({ ...n, favorite: false})),
              result
            ],
          },
        });
      })
      .catch(e => this.props.setNotifyWarning(e.message));
  };

  deletePatient = () => {
    if(confirm("Are you sure you want to delete the Patient?")) {
      deletePatient({ uid: this.state.patient.uid })
        .then(() => {
          this.props.router.replace('/patients');
        })
        .catch(e => this.props.setNotifyWarning(e.message));
    }
  };

  logoffPatient = async () => {
    if (confirm('Are you sure you want to force logoff?')) {
      try {
        await forceLogoff(this.state.patient.uid, 'pa');
        this.props.setNotifySuccess(
          "Force logoff successful."
        );
      } catch (error) {
        console.log(error);
        this.props.setNotifyWarning('Error forcing logoff.');
      }
    }
  }

  componentWillMount() {
    this.props.setNotifyHidden();
    this.setState({ loading: true });
    Promise.all([
      getPatientByID(this.props.params.id),
      getPatientContacts(this.props.params.id),
      advancedSearchConsultations({ patientUid: this.props.params.id, size: 1 }),
    ])
      .then(results => {
        const patient = results[0];
        const providers = results[1].contacts.filter(contact => contact.status === 'linked' && contact.role === 'practitioner');
        const consultCount = results[2].pagination.total;
        this.setState({
          patient,
          providers,
          consultCount,
          loading: false,
        })
      })
      .catch(e => {
        this.props.setNotifyWarning(e.message);
        this.setState({ loading: false });
      });
  }

  render() {
    const { classes, router } = this.props;
    const { value, patient, providers } = this.state;
    return (
      <div>
        {this.state.loading && (
          <div className={classes.loadingWrap}>
            <Loading/>
          </div>
        )}
        {patient && <PatientHeader
          patient={patient}
          providersCount={this.state.providers.length}
          consultCount={this.state.consultCount}
        />}
        <AppBar
          position="static"
          style={{ marginTop: 15 }}
          className={classes.appBar}
        >
            <Tabs value={value} onChange={this.handleChange}>
              <Tab style={{ minWidth: 125 }} label="Info" />
              <Tab style={{ minWidth: 125 }} label="Pet Profiles" />
              <Tab style={{ minWidth: 125 }} label="Consults"/>
              <Tab style={{ minWidth: 125 }} label="Calls"/>
              <Tab style={{ minWidth: 125 }} label="Providers" />
              <Tab style={{ minWidth: 125 }} label="Payment" />
              <Tab style={{ minWidth: 125 }} label="Insurance" />
              <Tab
                style={{ minWidth: 92, marginLeft: 'auto' }}
                icon={<Settings className={classes.settings} />}
              />
            </Tabs>
        </AppBar>
        {value === 0 && (
          <TabContainer>
            {patient && <PatientInfo
              patient={patient}
              onSave={this.save}
              onCopyInviteCode={this.onCopyInviteCode}
              onError={this.props.setNotifyWarning}
            />}
          </TabContainer>
        )}
        {value === 1 && (
          <TabContainer>
            {patient && <PatientPets
              patient={patient}
              setNotifySuccess={this.props.setNotifySuccess}
              setNotifyWarning={this.props.setNotifyWarning}
            />}
          </TabContainer>
        )}
        {value === 2 && (
          <TabContainer>
            {patient && <UserConsults
              role="patient"
              uid={patient.uid}
              onViewConsult={(uid) => { this.props.router.push(`/consultations/${uid}`); }}
              setNotifyWarning={this.props.setNotifyWarning}
            />}
          </TabContainer>
        )}
        {value === 3 && (
          <TabContainer>
            {patient && <UserCalls
              role="patient"
              uid={patient.uid}
              onViewCall={uid => router.push(`/patients/${patient.uid}/calls/${uid}`)}
              setNotifyWarning={this.props.setNotifyWarning}
            />}
          </TabContainer>
        )}
        {value === 4 && (
          <TabContainer>
            {patient && <PatientProviders
              providers={providers}
              uid={patient.uid}
              onProviderRemove={this.removeContact}
              onProviderUpdate={this.handleProviderUpdate}
              setNotifySuccess={this.props.setNotifySuccess}
              setNotifyWarning={this.props.setNotifyWarning}
              router={router}
            />}
          </TabContainer>
        )}
        {value === 5 && (
          <TabContainer>
            {patient && <PatientPayment
              patient={patient}
              onAddPaymentMethod={this.addPaymentMethod}
              onRemovePaymentMethod={this.removePaymentMethod}
              onSetPaymentMethodAsDefault={this.setPaymentMethodAsDefault}
              onSave={this.save}
              setNotifySuccess={this.props.setNotifySuccess}
              setNotifyWarning={this.props.setNotifyWarning}
            />}
          </TabContainer>
        )}
        {value === 6 && (
          <TabContainer>
            {patient && <PatientInsurance
              uid={patient.uid}
              birthdate={patient.birthdate}
              setNotifyWarning={this.props.setNotifyWarning}
            />}
          </TabContainer>
        )}
        {value === 7 && (
          <TabContainer>
            {patient && <PatientSettings
              patient={patient}
              onSave={this.save}
              onDelete={this.deletePatient}
              onLogoff={this.logoffPatient}
            />}
          </TabContainer>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setNotifySuccess: title => {
      dispatch(setNotifySuccess(title));
    },
    setNotifyWarning: title => {
      dispatch(setNotifyWarning(title));
    },
    setNotifyHidden: () => {
      dispatch(setNotifyHidden());
    },
  };
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(withRouter(PatientProfile)));

