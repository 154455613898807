import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  IconButton, Menu,
  MenuItem,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment';
import MoreIcon from '@material-ui/icons/MoreHoriz';

import {
  getPatientInsuranceByID,
  addPatientInsurance,
  getInsurancePlans,
  removePatientInsurance,
} from '../../components/patients/PatientUtils';
import Loading from '../../components/Loading';

function getDefaultInsurance(birthdate) {
  return {
    insurancePlanId: '',
    name: '',
    insurancePolicyId: '',
    birthdate: moment(birthdate).format('YYYY-MM-DD'),
  }
}

const styles = theme => ({
  title: {
    color: theme.palette.primary.light,
  },
  accountWrap: {
    border: '1px solid #E5E5E5',
    backgroundColor: '#F6F6F6',
    boxShadow: '0 2px 7px 0 rgba(0,0,0,0.5)',
    padding: theme.spacing.unit * 3,
  },
  line: {
    backgroundColor: theme.palette.primary.light,
    height: 2,
    width: "100%",
    marginBottom: theme.spacing.unit * 4,
  },
  thinLine: {
    backgroundColor: '#333',
    height: 1,
    width: "100%",
    marginBottom: theme.spacing.unit * 3,
  },
  container: {
    marginBottom: theme.spacing.unit * 3,
  },
  labelField: {
    color: theme.palette.primary.light,
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  textField: {
    border: "1px solid #E7E7E7",
    borderRadius: "3px",
    backgroundColor: "#FBFBFB",
    padding: "6px 7px"
  },
  cardWrap: {
    boxShadow: '0 1px 4px 0 rgba(0,0,0,0.5)',
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 4,
  },
  loadingWrap: {
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    background: 'rgba(255, 255, 255, 0.75)',
    zIndex: 1,
  },
});

class PatientInsurance extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    uid: PropTypes.string.isRequired,
    birthdate: PropTypes.string.isRequired,
    setNotifyWarning: PropTypes.func.isRequired,
  };

  state = {
    insuranceList: [],
    plans: [],
    insurance: getDefaultInsurance(this.props.birthdate),
    insuranceUid: '',
    isLoading: false,
    anchorEl: null,
    isOpen: false,
  };

  handleChangeInsurance = name => (event) => {
    this.setState({
      insurance: { ...this.state.insurance, [name]: event.target.value },
    });
  };

  addInsurance = () => {
    const { insurance } = this.state;
    this.setState({ isLoading: true });
    addPatientInsurance(this.props.uid, {
      firstname: insurance.name.split(' ')[0],
      lastname: insurance.name.split(' ')[1],
      insurancePlanId: insurance.insurancePlanId,
      insurancePlanName: this.state.plans.find(p => p.id === insurance.insurancePlanId).name,
      insurancePolicyId: insurance.insurancePolicyId,
      birthdate: insurance.birthdate,
    })
      .then(() => {
        getPatientInsuranceByID(this.props.uid)
          .then(result => {
            this.setState({
              insuranceList: result.insuranceList,
              insurance: getDefaultInsurance(this.props.birthdate),
              isLoading: false,
            });
          })
          .catch(e => {
            this.setState({ isLoading: false });
            this.props.setNotifyWarning(e.message);
          });
      })
      .catch(e => {
        this.setState({ isLoading: false });
        this.props.setNotifyWarning(e.message)
      });
  };

  handleMenu = uid => (event) => {
    this.setState({
      insuranceUid: uid,
      anchorEl: event.currentTarget,
      isOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      isOpen: false,
    });
  };

  removeInsurance = () => {
    this.handleClose();
    this.setState({ isLoading: true });
    removePatientInsurance(this.props.uid, this.state.insuranceUid)
      .then(() => {
        getPatientInsuranceByID(this.props.uid)
          .then(result => {
            this.setState({
              insuranceList: result.insuranceList,
              isLoading: false,
            });
          })
          .catch(e => {
            this.setState({ isLoading: false });
            this.props.setNotifyWarning(e.message)
          });
      })
      .catch(e => {
        this.setState({ isLoading: false });
        this.props.setNotifyWarning(e.message);
      });
  };

  componentWillMount() {
    this.setState({ isLoading: true });
    Promise.all([
      getPatientInsuranceByID(this.props.uid),
      getInsurancePlans()
    ])
      .then((result) => {
        this.setState({
          insuranceList: result[0].insuranceList,
          plans: result[1].plans,
          isLoading: false,
        })
      })
      .catch(e => {
        this.setState({ isLoading: false });
        this.props.setNotifyWarning(e.message)
      });
  }

  render() {
    const { classes } = this.props;
    const { isOpen, anchorEl } = this.state;
    return (
      <Grid container direction='row'>
        {this.state.isLoading && (
          <div className={classes.loadingWrap}>
            <Loading/>
          </div>
        )}
        <Grid container direction='row' justify='space-between'>
          <Grid item xs={5}>
            <Typography variant='h6' className={classes.title}>Insurance Cards</Typography>
            <div className={classes.line} />
            {this.state.insuranceList.map(insurance => (
              <div key={insurance.uid} className={classes.cardWrap}>
                <Grid container direction='row' justify='space-between' alignItems='center'>
                  <Typography variant='h6'>{insurance.insurancePlanName}</Typography>
                  <IconButton aria-haspopup="true"
                              onClick={this.handleMenu(insurance.uid)}
                              color="inherit">
                    <MoreIcon />
                  </IconButton>
                </Grid>
                <div className={classes.thinLine} />
                <Typography variant='body2'>Member Name</Typography>
                <Typography variant='body1' style={{ marginBottom: 12 }}>{insurance.firstname} {insurance.lastname}</Typography>
                <Typography variant='body2'>Member Number</Typography>
                <Typography variant='body1' style={{ marginBottom: 12 }}>{insurance.insurancePolicyId}</Typography>
                <Typography variant='body2'>DOB</Typography>
                <Typography variant='body1'>{moment(insurance.birthdate).format('YYYY-MM-DD')}</Typography>
              </div>
            ))}
          </Grid>
          <Grid item xs={5}>
            <Grid container className={classes.accountWrap} direction='column'>
              <Typography variant='h6' style={{ marginBottom: 18 }}>Add Insurance Card</Typography>
              <Grid container className={classes.container}>
                <TextField
                  label='Payer'
                  value={this.state.insurance.insurancePlanId}
                  onChange={this.handleChangeInsurance('insurancePlanId')}
                  select
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                >
                  {this.state.plans.map(plan => (
                    <MenuItem key={plan.id} value={plan.id}>{plan.name}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid container className={classes.container}>
                <TextField
                  label='Member Name'
                  value={this.state.insurance.name}
                  onChange={this.handleChangeInsurance('name')}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                />
              </Grid>
              <Grid container
                    className={classes.container}
                    direction="row"
                    justify="space-between">
                <TextField
                  label='Member Number'
                  value={this.state.insurance.insurancePolicyId}
                  onChange={this.handleChangeInsurance('insurancePolicyId')}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ width: '45%' }}
                />
                <DatePicker
                  label='DOB'
                  format="YYYY-MM-DD"
                  value={this.state.insurance.birthdate}
                  onChange={date =>
                    this.handleChangeInsurance('birthdate')({
                      target: { value: date.format('YYYY-MM-DD') }
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                    className: classes.labelField
                  }}
                  InputProps={{ className: classes.textField }}
                  style={{ width: '45%' }}
                />
              </Grid>
              <Button variant='contained'
                      color='primary'
                      style={{ marginLeft: 'auto' }}
                      onClick={this.addInsurance}>
                ADD
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Menu
          id="menu-method"
          anchorReference='anchorPosition'
          anchorPosition={{
            top: anchorEl ? anchorEl.getBoundingClientRect().bottom - 18 : 0,
            left: anchorEl ? anchorEl.getBoundingClientRect().left : 0
          }}
          open={isOpen}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.removeInsurance}>Remove</MenuItem>
        </Menu>
      </Grid>
    );
  }
}

export default withStyles(styles)(PatientInsurance);
