import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  withStyles,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl, MenuItem
} from '@material-ui/core';

import { getAdminByID, saveAdmin, deleteAdmin, resetTOTP } from "./AdminUtils";

import {
  setNotifyHidden,
  setNotifyWarning,
  setNotifySuccess
} from "../notification/NotifyUtils";

const styles = theme => ({
  container: {
    padding: theme.spacing.unit * 2
  },
  btn: {
    margin: `0 ${theme.spacing.unit}px`
  },
  deleteBtn: {
    margin: `0 ${theme.spacing.unit}px`,
    background: theme.palette.error.main,
    color: "#fff",
    "&:hover": {
      background: "rgba(255,87,87,0.7)"
    }
  }
});

class AdminForm extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    router: PropTypes.object,
    params: PropTypes.shape({
      id: PropTypes.string
    }),
    setNotifyWarning: PropTypes.func,
    setNotifySuccess: PropTypes.func,
    setNotifyHidden: PropTypes.func
  };

  getDefaultAdmin = () => ({
    email: "",
    uid: "",
    firstname: "",
    lastname: "",
    mediciSuccess: false,
    subRole: "",
  });

  state = {
    password: "",
    admin: this.getDefaultAdmin()
  };

  save = async e => {
    if (this.refs.formAdmin.checkValidity()) {
      e.preventDefault();
      const data = Object.assign({}, this.state.admin);

      if (this.state.password !== "") {
        data.password = this.state.password;
      }

      try {
        await saveAdmin(data);
        this.props.setNotifySuccess("Admin saved");
        this.props.router.goBack();
      } catch (error) {
        this.props.setNotifyWarning(error.message);
      }
    }
  };

  cancel = e => {
    e.preventDefault();
    this.props.router.replace("/admins");
  };

  deleteItem = e => {
    e.preventDefault();

    if (confirm("Delete admin?")) {
      deleteAdmin(this.state.admin)
        .then(() => {
          this.props.setNotifySuccess("Admin deleted");
          this.props.router.goBack();
        })
        .catch(error => {
          this.props.setNotifyWarning(error.message);
        });
    }
  };

  resetTOTP = async () => {
    if (this.state.admin.uid && confirm("Are you sure to reset TOTP?")) {
      this.props.setNotifyHidden();
      try {
        await resetTOTP(this.state.admin.uid);
        this.props.setNotifySuccess(
          "The google authenticator has been reset for this user."
        );
      } catch (e) {
        this.props.setNotifyWarning(e.message);
      }
    }
  };

  onChange(propName, event) {
    this.setState({
      [propName]: event.target.value
    });
  }

  onAdminChange(propName, event) {
    const p = this.state.admin;
    p[propName] = event.target.value;
    if (propName === 'subRole' && event.target.value === 'admin') {
      p[propName] = '';
    }
    this.setState({ admin: { ...p } });
  }

  onCheckChange = event => {
    const { admin } = this.state;
    admin.mediciSuccess = event.target.checked;
    this.setState({ admin });
  };

  async componentDidMount() {
    this.props.setNotifyHidden();
    if (this.props.params.id === "create") {
      return;
    }

    try {
      const response = await getAdminByID(this.props.params.id);

      const admin = Object.assign(this.state.admin, response);
      this.setState({ admin });
    } catch (error) {
      this.props.setNotifyWarning("Error retrieving Admin");
      this.props.router.replace("/admins");
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <form id="formAdmin" ref="formAdmin" className="form">
        <h2>{this.state.admin.uid ? "Edit" : "Create"}</h2>
        <TextField
          label="User Role"
          select
          value={this.state.admin.subRole || 'admin'}
          onChange={this.onAdminChange.bind(this, "subRole")}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          displayEmpty={true}
        >
          <MenuItem value="admin">Admin</MenuItem>
          <MenuItem value="sales">Sales</MenuItem>
        </TextField>
        <TextField
          id="name"
          label="First Name"
          value={this.state.admin.firstname}
          onChange={this.onAdminChange.bind(this, "firstname")}
          fullWidth
        />
        <TextField
          id="lastname"
          label="Last Name"
          value={this.state.admin.lastname}
          onChange={this.onAdminChange.bind(this, "lastname")}
          fullWidth
        />
        <TextField
          id="email"
          label="Email"
          pattern=".+\@.+\..+"
          value={this.state.admin.email}
          onChange={this.onAdminChange.bind(this, "email")}
          fullWidth
          required
        />
        <TextField
          id="password"
          label="Password"
          placeholder="Only change to reset password"
          value={this.state.password}
          onChange={this.onChange.bind(this, "password")}
          fullWidth
        />
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.admin.mediciSuccess}
                onChange={this.onCheckChange}
                value={this.state.admin.mediciSuccess}
              />
            }
            label="Is Medici Success?"
          />
        </FormControl>
        <div className={classes.container}>
          <Button
            className={classes.btn}
            variant="contained"
            color="secondary"
            onClick={this.save}
          >
            Save
          </Button>
          <Button
            className={classes.btn}
            variant="contained"
            onClick={this.cancel}
          >
            Cancel
          </Button>
          {this.state.admin.uid && (
            <Button
              className={classes.deleteBtn}
              variant="contained"
              onClick={this.deleteItem}
            >
              Delete
            </Button>
          )}
          {this.state.admin.uid && (
            <Button
              className={classes.btn}
              variant="contained"
              color="primary"
              onClick={this.resetTOTP}
            >
              Reset google token
            </Button>
          )}
        </div>
      </form>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setNotifyWarning: title => {
      dispatch(setNotifyWarning(title));
    },
    setNotifySuccess: title => {
      dispatch(setNotifySuccess(title));
    },
    setNotifyHidden: () => {
      dispatch(setNotifyHidden());
    }
  };
}

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(withRouter(AdminForm)));
