import {
  Button,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withStyles
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";
import React from "react";
import Loading from "../../components/Loading";
import { mediciFetch, getApiUrl } from "../../components/utils/NetworkUtils";

const styles = () => ({
  button: {
    marginLeft: "auto"
  }
});

class Networks extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired
  };

  state = {
    loading: true,
    search: "",
    networks: []
  };

  async componentDidMount() {
    try {
      const { networks } = await mediciFetch(`${getApiUrl()}/networks`, {
        method: "get"
      });

      this.setState({ networks });
    } catch (error) {
      console.log(error);
    }

    this.setState({ loading: false });
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  fileAttachmentHandler = (event, network) => {
    const { networks } = this.state;
    const newNetworks = networks.map(n => {
      if (n.typeCode === network.typeCode && n.areaCode === network.areaCode) {
        n.file = event.target.files[0];
      }

      return n;
    });

    this.setState({ networks: newNetworks });
  };

  saveNetwork = network => {
    const { networks } = this.state;

    const newNetworks = networks.map(n => {
      if (n.typeCode === network.typeCode && n.areaCode === network.areaCode) {
        return network;
      }

      return n;
    });

    this.setState({ networks: newNetworks });
  };

  fileSaveHandler = async network => {
    const fd = new FormData();
    fd.append("file", network.file);

    try {
      this.saveNetwork(Object.assign({}, network, { loading: true }));

      await mediciFetch(
        `${getApiUrl()}/networks/${network.typeCode}/${network.areaCode}/batch`,
        {
          method: "post",
          body: fd
        }
      );

      this.saveNetwork(
        Object.assign({}, network, {
          fileSaved: network.file,
          file: null
        })
      );
    } catch (error) {
      this.saveNetwork(Object.assign({}, network, { loading: false }));
      console.log(error);
    }
  };

  render() {
    const { classes, router } = this.props;
    const { loading, search, networks } = this.state;

    if (loading) {
      return <Loading />;
    }

    const filteredNetworks = networks.filter(
      network =>
        (network.typeCode &&
          network.typeCode.toLowerCase().includes(search.toLowerCase())) ||
        (network.areaCode &&
          network.areaCode.toLowerCase().includes(search.toLowerCase())) ||
        (network.title &&
          network.title.toLowerCase().includes(search.toLowerCase())) ||
        (network.description &&
          network.description.toLowerCase().includes(search.toLowerCase()))
    );

    return (
      <Grid container direction="column">
        <Typography variant="h5" gutterBottom>
          Networks
        </Typography>
        <Grid container direction="row" alignItems="center">
          <TextField
            style={{ width: "275px" }}
            label="Search"
            value={search}
            onChange={this.handleChange("search")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />

          <Grid className={classes.button}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => router.push("/networks/create")}
            >
              Create Network
            </Button>
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredNetworks
              .filter(n => n.typeCode.includes("shp"))
              .map(network => (
                <TableRow key={`${network.typeCode}-${network.areaCode}`}>
                  <TableCell>{network.typeCode}</TableCell>
                  <TableCell>{network.areaCode}</TableCell>
                  <TableCell>{network.title}</TableCell>
                  <TableCell>{network.description}</TableCell>
                  <TableCell>
                    <input
                      type="file"
                      accept=".csv"
                      onChange={e => this.fileAttachmentHandler(e, network)}
                    />
                    <Button
                      disabled={!network.file || network.loading}
                      onClick={() => this.fileSaveHandler(network)}
                    >
                      SAVE
                    </Button>
                    {network.fileSaved && !network.loading && (
                      <span>{`"${network.fileSaved.name}"`} Saved</span>
                    )}
                    {network.loading && <span>Saving...</span>}
                  </TableCell>
                </TableRow>
              ))}
            {filteredNetworks
              .filter(n => !n.typeCode.includes("shp"))
              .map(network => (
                <TableRow
                  key={`${network.typeCode}-${network.areaCode}`}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    router.push(
                      `/networks/${network.typeCode}-${network.areaCode}`
                    )
                  }
                >
                  <TableCell>{network.typeCode}</TableCell>
                  <TableCell>{network.areaCode}</TableCell>
                  <TableCell>{network.title}</TableCell>
                  <TableCell>{network.description}</TableCell>
                  <TableCell />
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Grid>
    );
  }
}

export default withStyles(styles)(Networks);
