import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import { Paper, withStyles, TextField, MenuItem } from "@material-ui/core";
import { searchPractitioners } from "../../components/practitioners/PractitionerUtils";
import Loading from "../../components/Loading";

const TIMEOUT = 300;

const styles = theme => ({
  root: {
    height: 250,
    flexGrow: 1
  },
  container: {
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  suggestion: {
    display: "block"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  },
  divider: {
    height: theme.spacing.unit * 2
  }
});

function renderInputComponent(inputProps) {
  const {
    classes,
    label,
    required,
    inputRef = () => {},
    ref,
    ...other
  } = inputProps;

  return (
    <TextField
      label={label}
      required={required}
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input
        }
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { isHighlighted }) {
  return (
    <MenuItem selected={isHighlighted} component="div">
      {suggestion.user.firstname} {suggestion.user.lastname}
    </MenuItem>
  );
}

async function getSuggestions(value) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  if (inputLength === 0) {
    return [];
  }

  let suggestions = { content: [] };
  try {
    suggestions = await searchPractitioners(inputValue, 0, 10);
  } catch (error) {
    console.log(error);
  }

  return suggestions.content;
}

function getSuggestionValue(suggestion) {
  return `${suggestion.user.firstname} ${suggestion.user.lastname}`;
}

class DoctorSelect extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool
  };

  static defaultProps = {
    label: "Members",
    required: false
  };

  state = {
    single: "",
    suggestions: [],
    loading: false
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(async () => {
      this.setState({ loading: true });
      const suggestions = await getSuggestions(value);
      this.setState({ suggestions });
      this.setState({ loading: false });
    }, TIMEOUT);
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleChange = name => (event, { newValue }) => {
    this.setState({
      [name]: newValue
    });
  };

  handleSuggestionSelected = (event, { suggestion }) => {
    this.props.onChange(suggestion.user);
    this.setState({
      single: "",
      suggestions: []
    });
  };

  render() {
    const { classes, label, required } = this.props;
    const { loading } = this.state;

    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      onSuggestionSelected: this.handleSuggestionSelected,
      getSuggestionValue,
      renderSuggestion
    };

    return (
      <Fragment>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            label,
            required,
            classes,
            value: this.state.single,
            onChange: this.handleChange("single")
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion
          }}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
        {loading && (
          <div style={{ marginTop: 5 }}>
            <Loading />
          </div>
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(DoctorSelect);
