import React from 'react';
import PropTypes from 'prop-types';
import './titleSelector.css';

export default class TitleSelector extends React.Component {
  static propTypes = {
    onTitleChanged: PropTypes.func.isRequired,
    title: PropTypes.string,
    id: PropTypes.string,
  }

  onChangeTitle = (event) => {
    const value = event.target.value;

    let title = '';
    if (value === 'none') {
      title = '_';
    } else if (value === 'doctor') {
      title = 'Dr.';
    }

    this.props.onTitleChanged(title);
  }

  static getSelectValue(value) {
    if (value === undefined || value === null) {
      return 'default';
    } else if (value === '_' || value === '') {
      return 'none';
    }
    return 'doctor';
  }

  render() {
    return (
      <select
        className="titleSelector"
        onChange={this.onChangeTitle}
        value={TitleSelector.getSelectValue(this.props.title)}
        id={this.props.id}
      >
        <option value="default">Default</option>
        <option value="doctor">Doctor</option>
        <option value="none">None</option>
      </select>
    );
  }
}
