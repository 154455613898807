import { getApiUrl, mediciFetch } from "./NetworkUtils";

/**
 * Add SMS phone number for PR
 * @param uid: string
 * @param areaCode: string
 * @returns {Promise.<{ phoneNumber: string, }>}
 */
async function createSmsNumber(uid, areaCode= '') {
  return mediciFetch(
    `${getApiUrl()}/sms/${uid}/numbers`,
    {
      method: "post",
      body: JSON.stringify({
        areaCode: parseInt(areaCode, 10),
      })
    }
  );
}

/**
 * Remove SMS phone number for PR
 * @param uid
 */
async function removeSmsNumber(uid) {
  return mediciFetch(
    `${getApiUrl()}/sms/${uid}/numbers`,
    {
      method: "delete"
    }
  );
}

/**
 * Get SMS phone number for PR
 * @param uid
 * @returns {Promise.<{ phoneNumber: string, }>}
 */
async function getSmsNumber(uid) {
  return mediciFetch(
    `${getApiUrl()}/sms/${uid}/numbers`,
    {
      method: "get"
    }
  );
}

export default {
  createSmsNumber,
  removeSmsNumber,
  getSmsNumber
};
