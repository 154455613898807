import React from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, withStyles } from '@material-ui/core';

import NotifyPanel from '../notification/NotifyPanel';
import { NOTIFY_GRAY } from '../notification/NotifyUtils';
import LocalStorage from '../utils/LocalStorage';
import { getQRCode } from './LoginUtils';

import appStoreImg from '../../images/appStore.png';
import googlePlayImg from '../../images/googlePlay.png';

const styles = theme => ({
  loginForm: {
    maxWidth: '450px',
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    padding: theme.spacing.unit * 3,
    margin: theme.spacing.unit * 2,
    borderRadius: 10,
  },
  title: {
    textAlign: 'center',
  },
  actions: {
    textAlign: 'center',
    marginTop: theme.spacing.unit * 2
  },
  googleAuthLinks: {
    display: 'flex',
    flexDirection: 'row',
    width : '100%',
    margin: '20px 0 20px 0',
  },
  googleAuthLink: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    width: '50%',
  },
  appStore: {
    height : '50px',
    background: `url(${appStoreImg}) no-repeat`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  playStore: {
    height : '50px',
    background: `url(${googlePlayImg}) no-repeat`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  qrImage: {
    width : '200px',
    height : '200px',
    margin: '10px 0 10px 0',
  },
  qrCode: {
    margin: '20px 0 20px 0',
    textAlign: 'center',
  },
});

class AuthenticateCodeForm extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onVerificationLogin: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
  };

  state = {
    step: 1,
    qrImage: undefined,
    qrCode: '',
    notificationMessage: undefined,
  };

  onChange(propName, event) {
    this.setState({
      [propName]: event.target.value,
      notificationMessage: '',
    });
  }

  componentDidMount() {
    this.retrieveQRCode().then((qrCodeURL) => {
      this.setState({
        qrImage: qrCodeURL,
      });
    });
  }

  advance = (e) => {
    e.preventDefault();

    const step = this.state.step + 1;

    this.setState({
      step,
    });
  };

  retrieveQRCode = () =>
    getQRCode(this.props.token)
      .then((response) => {
        LocalStorage.set('useTOTPAuth', true);
        return URL.createObjectURL(response);
      })
      .catch((ex) => {
        console.log(ex);
      });

  login = (e) => {
    e.preventDefault();

    if (this.state.qrCode && this.state.qrCode.length === 6) {
      this.props.onVerificationLogin(this.state.qrCode);
    } else {
      this.setState({
        notificationMessage: 'Please enter a valid verification code',
      });
    }
  };

  render() {
    const { classes } = this.props;
    let notifyPanel = null;
    if (this.state.notificationMessage) {
      notifyPanel = (
        <NotifyPanel
          status={NOTIFY_GRAY}
          title={this.state.notificationMessage}
        />
      );
    }

    if (this.state.step === 1) {
      return (
        <form className={classes.loginForm}>
          <h3 className={classes.title}>
            Before continuing,<br />we need to secure your account!
          </h3>
          <p>Step 1: Download Google Authenticator for your device.</p>
          <div className={classes.googleAuthLinks}>
            <div className={classes.googleAuthLink}>
              <a
                className={classes.appStore}
                target="_blank"
                rel="noopener noreferrer"
                href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"
              />
            </div>
            <div className={classes.googleAuthLink}>
              <a
                className={classes.playStore}
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
              />
            </div>
          </div>
          <div className={classes.actions}>
            <Button variant='raised' color='secondary' onClick={this.advance}>
              Next
            </Button>
          </div>
        </form>
      );
    } else if (this.state.step === 2) {
      return (
        <div>
          {notifyPanel}
          <form className={classes.loginForm}>
            <h3 className={classes.title}>Verification Code</h3>
            <p>Step 2: Scan the QR barcode using Google Authenticator</p>
            <div className={classes.qrCode}>
              <img className={classes.qrImage} src={this.state.qrImage} />
            </div>
            <p>
              Step 3: Enter the 6 digit verification code from Google
              Authenticator and login!
            </p>
            <TextField
              className={classes.formItem}
              fullWidth
              label='Verification Code'
              value={this.state.qrCode}
              onChange={this.onChange.bind(this, 'qrCode')}
            />
            <div className={classes.actions}>
              <Button variant='raised' color='secondary' onClick={this.login}>
                Login
              </Button>
            </div>
          </form>
        </div>
      );
    }
  }
}

export default withStyles(styles)(AuthenticateCodeForm);
