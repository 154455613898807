import {
  withStyles,
  Button,
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TablePagination,
  InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { debounce } from "underscore";
import {
  setNotifyHidden,
  setNotifySuccess,
  setNotifyWarning,
  setNotifyDanger,
} from "../../components/notification/NotifyUtils";
import {
  deletePractice,
  deletePracticePicture,
  // getPracticeByID,
  savePractice,
  savePracticeImage,
  searchPractices
} from "../../components/utils/PracticeUtils";

import Loading from './../../components/Loading';

const styles = theme => ({
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto"
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 250
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  },
  actionsCell: {
    textAlign: "right"
  },
  loadingWrap: {
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    background: 'rgba(255, 255, 255, 0.75)',
    zIndex: 1,
  },
});

class PracticesPage extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    setNotifyHidden: PropTypes.func.isRequired,
    setNotifyWarning: PropTypes.func.isRequired,
    setNotifySuccess: PropTypes.func.isRequired,
    setNotifyDanger: PropTypes.func,
    router: PropTypes.object.isRequired,
    classes: PropTypes.object
  };

  state = {
    searchValue: "",
    items: [],
    practiceID: undefined,
    pagination: {
      total: 0,
      limit: 10,
      page: 0,
    },
    loading: false,
  };

  lastSearchResultTs = null;

  debouncedSearch = debounce(() => {
    this.retrievePractices();
  }, 500);

  onSearch = e => {
    const { value } = e.target;
    this.setState({ searchValue: value }, this.debouncedSearch);
  };

  retrievePractices() {
    const { limit, page } = this.state.pagination;
    this.setState({ loading: true });
    this.props.setNotifyHidden();
    searchPractices({ q: this.state.searchValue, limit, offset: limit * page })
      .then(res => {
        const practices = res.content.map(practice => {
          practice.location = practice.location || {};

          return {
            ...practice,
            address: this.mapFriendlyAddress(practice.location),
            defaultPrice: practice.defaultPrice || {},
          };
        });
        this.setState({
          items: practices,
          pagination: {
            ...this.state.pagination,
            total: res.pagination.total,
          },
          loading: false,
        });
      })
      .catch(() => {
        this.props.setNotifyWarning("Error loading search results");
        this.setState({ loading: false });
      });

  }

  mapFriendlyAddress = location => {
    if (!location) return "";
    const {
      streetNumber = "",
      street = "",
      city = "",
      state = "",
      postalCode = ""
    } = location;
    return `${streetNumber} ${street} ${city} ${state} ${postalCode}`.trim();
  };

  onEdit = uid => {
    this.props.router.push(`/practices/${uid}`);
  };

  onDelete = e => {
    if (confirm("Delete practice?")) {
      deletePractice(e)
        .then(() => {
          this.props.setNotifySuccess("Practice deleted");
          this.onSearch({
            target: { value: this.state.searchValue }
          });
        })
        .catch(error => {
          console.log(error);
          this.props.setNotifyDanger(error.message);
        });
    }
  };

  onSave = e => {
    this.setState({ loaded: false });

    let practiceID;
    let saveChain = savePractice(e.practice);

    if (e.practiceImage.uploaded) {
      saveChain = saveChain.then(practice => {
        practiceID = practice.practiceId || practice.uid || e.practice.uid;
        e.practiceImage.uid = practiceID;
        return savePracticeImage(e.practiceImage);
      });
    }

    saveChain
      .then(() => {
        this.props.setNotifySuccess("Practice saved");
        this.props.router.replace("/practices");
      })
      .catch(ex => {
        this.setState({
          practice: e.practice
        });
        this.props.setNotifyWarning(ex.message);
      });
  };

  onDeletePracticePicture = practiceID =>
    deletePracticePicture(practiceID)
      .then(() => {
        this.props.setNotifySuccess("Practice Picture Deleted");
      })
      .catch(ex => {
        this.props.setNotifyWarning(ex.message);
      });

  onCancel = () => {
    this.props.router.replace("/practices");
  };

  createPractice = () => {
    this.setState({
      practice: {}
    });
    this.props.router.push("/practices/create");
  };

  handleChangeRowsPerPage = (e) => {
    const { pagination } = this.state;
    this.setState({ pagination: { ...pagination, limit: e.target.value, page: 0 } }, this.retrievePractices);
  };

  handleChangePage = (e, page) => {
    const { pagination } = this.state;
    this.setState({ pagination: { ...pagination, page } }, this.retrievePractices);
  };

  componentWillMount() {
    this.retrievePractices();
  }

  render() {
    const { classes } = this.props;
    const { pagination } = this.state;

    return (
      <Grid container direction="column">
        <Typography variant="h5">Practices</Typography>
        {!this.props.children && (
          <Grid container direction='row' justify='space-between' alignItems='center' style={{ margin: '24px 0' }}>
            <TextField
              id="search"
              label="Search"
              value={this.state.searchValue}
              onChange={this.onSearch}
              style={{ width: '275px' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              id="create"
              variant="contained"
              color="secondary"
              title="Add Practice"
              onClick={this.createPractice}
            >
              Add Practice
            </Button>
          </Grid>
        )}
        {!this.props.children && (
          <Grid container direction="column">
            {this.state.loading && (
              <div className={classes.loadingWrap}>
                <Loading/>
              </div>
            )}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Default Price / Duration</TableCell>
                  <TableCell>Direct Billing</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.items.length ? (
                  this.state.items.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.address}</TableCell>
                      <TableCell>
                        {item.defaultPrice.dollars}/{item.defaultPrice.minutes}
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          color="secondary"
                          checked={!item.managerReceivesMoney}
                          readOnly
                        />
                      </TableCell>
                      <TableCell className={classes.actionsCell}>
                        <Button
                          color="secondary"
                          onClick={() => this.onEdit(item.uid, item.managerId)}
                        >
                          Edit
                        </Button>
                        <Button onClick={() => this.onDelete(item.uid)}>
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan="5"
                      variant="footer"
                      style={{ textAlign: "center" }}
                    >
                      Start searching to see results
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              count={pagination.total}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              page={pagination.page}
              rowsPerPage={pagination.limit}
              rowsPerPageOptions={[10, 25, 50, 100]}
            />
          </Grid>
        )}
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setNotifySuccess: title => {
      dispatch(setNotifySuccess(title));
    },
    setNotifyWarning: title => {
      dispatch(setNotifyWarning(title));
    },
    setNotifyHidden: () => {
      dispatch(setNotifyHidden());
    },
    setNotifyDanger: (title) => {
      dispatch(setNotifyDanger(title));
    },
  };
}

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(withRouter(PracticesPage)));
