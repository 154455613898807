import PropTypes from 'prop-types';

const Practitioner = PropTypes.shape({
  available: PropTypes.bool,
  biography: PropTypes.shape({
    baseline: PropTypes.string,
    education: PropTypes.arrayOf(PropTypes.string),
    experiences: PropTypes.arrayOf(PropTypes.string),
    summary: PropTypes.string,
    welcomeMessage: PropTypes.string,
  }),
  birthdate: PropTypes.string,
  phone: PropTypes.string,
  canPrescribe: PropTypes.bool,
  deaNumber: PropTypes.string,
  demo: PropTypes.bool,
  dosespotRegistered: PropTypes.bool,
  dosespotStatus: PropTypes.number,
  email: PropTypes.string,
  firstname: PropTypes.string,
  inviteCode: PropTypes.string,
  lastname: PropTypes.string,
  location: PropTypes.shape({
    additionalInfos: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    name: PropTypes.string,
    postalCode: PropTypes.string,
    state: PropTypes.string,
    street: PropTypes.string,
    streetNumber: PropTypes.string,
  }),
  nbiConsultations: PropTypes.number,
  npiNumber: PropTypes.string,
  prettyName: PropTypes.string,
  pricingModel: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
    dollars: PropTypes.number,
    type: PropTypes.string,
  }),
  score: PropTypes.number,
  specialties: PropTypes.arrayOf(PropTypes.string),
  stats: PropTypes.shape({
    nbInviteSentPatients: PropTypes.number,
    nbInviteSentPractitioners: PropTypes.number,
    nbInviteSent: PropTypes.number,
    nbPatientsMain: PropTypes.number,
  }),
  title: PropTypes.string,
  uid: PropTypes.string,
  verifiedPractitioner: PropTypes.bool,
  currency: PropTypes.string,
  registrationId: PropTypes.string,
});

export default {
  Practitioner,
};
