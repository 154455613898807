import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

import CustomRow from './CustomRow';
import AddRow from './AddRow';

/**
 * Dictionary manager
 * @param title Title header for dictionary
 * @param values Object data {id, title}
 */
class DictionaryManager extends React.Component { // eslint-disable-line
  static propTypes = {
    onDeleteProperty: PropTypes.func.isRequired,
    onSaveProperty: PropTypes.func.isRequired,
    onEdit: PropTypes.func,
    values: PropTypes.object.isRequired,
    title: PropTypes.string,
  };

  doDelete = (dictionaryItemID) => {
    if (dictionaryItemID) {
      this.props.onDeleteProperty(dictionaryItemID);
    }
  };

  doAdd = (e) => {
    e.preventDefault();

    this.setState({
      addMode: true,
    });
  };

  doSave = (item) => {
    if (item) {
      this.props.onSaveProperty(item);
    }

    // reset form
    this.doCancel();
  };

  doCancel = () => {
    this.setState({
      addMode: false,
    });
  };

  componentWillMount() {
    this.setState({
      values: this.props.values,
      addMode: Object.keys(this.props.values).length === 0,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      values: nextProps.values,
      addMode: Object.keys(nextProps.values).length === 0,
    });
  }

  render() {
    const rows = [];
    Object.keys(this.state.values).forEach((key, index) => {
      rows.push(<CustomRow
        key={`row${index}`}
        keyIndex={index + 1}
        _key={key}
        _value={this.state.values[key]}
        doEdit={this.props.onEdit ? this.doEdit : null}
        doDelete={this.doDelete}
      />);
    });

    if (this.state.addMode) {
      const i = new Date().getTime();
      rows.push(<AddRow
        key={`row${i}`}
        keyIndex={i + 1}
        doSave={this.doSave}
        doCancel={this.doCancel}
      />);
    }

    return (
      <Grid container>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Value</TableCell>
              <TableCell style={{ width: 185 }}>
                <Button
                  color="secondary"
                  onClick={this.doAdd}
                >
                  Add
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      </Grid>
    );
  }
}
DictionaryManager.defaultProps = { values: {}, addMode: false };

export default DictionaryManager;
