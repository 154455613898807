import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';

import CurrencySelector from './CurrencySelector';

export default class Amount extends React.Component {
  static propTypes = {
    currency: PropTypes.string,
    defaultCurrency: PropTypes.string,
    amount: PropTypes.number,
    onAmountChange: PropTypes.func,
    onCurrencyChange: PropTypes.func,
    lockCurrency: PropTypes.bool,
    label: PropTypes.string,
  };

  onCurrencyChange = (event) => {
    this.props.onCurrencyChange(event.target.value);
  };

  onAmountChange = (event) => {
    const amount = event.target.value * 100;
    this.props.onAmountChange(amount);
  };

  render() {
    return (
      <Grid direction="row" container >
        <TextField
          id="chargeAmount"
          label={this.props.label}
          type="number"
          min="0"
          step="1"
          value={this.props.amount / 100}
          onChange={this.onAmountChange}
        />
        <CurrencySelector
          currency={this.props.currency || this.props.defaultCurrency}
          onCurrencyChange={this.onCurrencyChange}
          disabled={this.props.lockCurrency}
        />
      </Grid>
    );
  }
}
