import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';

export default class CurrencySelector extends React.Component {
  static propTypes = {
    currency: PropTypes.string,
    onCurrencyChange: PropTypes.func,
    disabled: PropTypes.bool,
  };

  render() {
    return (
      <FormControl disabled={this.props.disabled}>
        <InputLabel>Currency</InputLabel>
        <Select value={this.props.currency} onChange={this.props.onCurrencyChange}>
          <MenuItem value='USD'>USD</MenuItem>
          <MenuItem value='ZAR'>Rand</MenuItem>
          <MenuItem value='NAD'>NAD</MenuItem>
        </Select>
      </FormControl>
    );
  }
}
