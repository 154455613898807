import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { getActivationCodesStats } from '../../components/activationCode/ActivationCodeUtils';

export default class Patient extends React.Component {
  static propTypes = {
    codeUid: PropTypes.string.isRequired,
  };

  state = {
    display: false,
    value: 'Loading...',
  };

  show = async () => {
    this.setState({ display: true });

    try {
      const response = await getActivationCodesStats(this.props.codeUid);
      this.setState({
        value: response.numberOfPatients,
      });
    } catch (error) {
      console.log(error);
      this.setState({ display: false });
    }
  };

  render() {
    const { display, value } = this.state;

    if (display) {
      return (<span>{value}</span>);
    }

    return (
      <Button onClick={this.show}>Show</Button>
    );
  }
}
