import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, TableRow, TableCell, Button } from '@material-ui/core';

const styles = theme => ({
  deleteBtn: {
    margin: `0 ${theme.spacing.unit}px`,
    color: theme.palette.error.main,
    "&:hover": {
      background: "rgba(255,87,87,0.08)"
    }
  }
});

class CustomRow extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    keyIndex: PropTypes.number,
    _key: PropTypes.any,
    _value: PropTypes.any,
    doDelete: PropTypes.func.isRequired,
  };

  render() {
    return (
      <TableRow key={`td${this.props.keyIndex}`} className="dictionary-entry">
        <TableCell>{this.props._key}</TableCell>
        <TableCell>{this.props._value}</TableCell>
        <TableCell>
          <Button
            className={this.props.classes.deleteBtn}
            onClick={this.props.doDelete.bind(this, this.props._key)}
          >
            delete
          </Button>
        </TableCell>
      </TableRow>
    );
  }
}

export default withStyles(styles)(CustomRow);
