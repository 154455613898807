import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  withStyles
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import React from "react";
import Loading from "../../components/Loading";
import { saveSetting } from "../../components/utils/SettingUtils";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class NotificationDialog extends React.Component {
  state = {
    open: false,
    editing: false,
    loading: false,
    notification: null
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.notification) {
      this.setState({ open: false, editing: false, notification: null });
      return;
    }

    if (
      nextProps.notification.name !==
      (this.notification && this.notification.name)
    ) {
      this.setState({
        open: true,
        editing: false,
        notification: Object.assign({}, nextProps.notification)
      });
    }
  }

  handleChange = name => event => {
    const { notification } = this.state;
    notification[name] = event.target.value;

    this.setState({ notification });
  };

  handleSave = async () => {
    this.setState({ loading: true });
    const { notification } = this.state;

    try {
      if (notification.notification_time) {
        await saveSetting({
          name: `task.schedule.${notification.name}.notification_time`,
          value: notification.notification_time
        });
      }

      if (notification.delay_days) {
        await saveSetting({
          name: `task.schedule.${notification.name}.delay_days`,
          value: notification.delay_days
        });
      }

      if (notification.delay_hours) {
        await saveSetting({
          name: `task.schedule.${notification.name}.delay_hours`,
          value: notification.delay_hours
        });
      }

      setTimeout(() => this.props.handleClose(true));
    } catch (error) {
      console.log(error);
    }

    this.setState({ loading: false });
  };

  renderRow = (label, value, canEdit = false, type = "text") => {
    const { editing } = this.state;
    const { classes } = this.props;

    if (!this.props.notification[label]) {
      return null;
    }

    return (
      <TableBody>
        <TableRow>
          <TableCell>{label}</TableCell>
          <TableCell>
            {editing && canEdit ? (
              <TextField
                className={classes.textField}
                type={type}
                value={value}
                onChange={this.handleChange(label)}
                inputProps={{ min: 0 }}
              />
            ) : (
              value.toString()
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  render() {
    const { classes } = this.props;
    const { notification, open, editing, loading } = this.state;

    return (
      <Dialog
        open={open}
        onClose={() => this.props.handleClose()}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar} position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={() => this.props.handleClose()}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              Notification
            </Typography>
            {editing ? (
              <Button
                color="inherit"
                onClick={this.handleSave}
                disabled={loading}
              >
                {loading ? <Loading size={20} /> : "Save"}
              </Button>
            ) : (
              <IconButton
                color="inherit"
                onClick={() => this.setState({ editing: true })}
              >
                <EditIcon />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
        <DialogContent>
          {notification && (
            <Table>
              {this.renderRow("name", notification.name)}
              {this.renderRow(
                "notification_time",
                notification.notification_time,
                true,
                "time"
              )}
              {this.renderRow(
                "delay_days",
                notification.delay_days,
                true,
                "number"
              )}
              {this.renderRow(
                "delay_hours",
                notification.delay_hours,
                true,
                "number"
              )}
            </Table>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

NotificationDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  notification: PropTypes.shape({
    name: PropTypes.string.isRequired,
    notification_time: PropTypes.string,
    delay_days: PropTypes.string,
    delay_hours: PropTypes.string
  })
};

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  }
});

export default withStyles(styles)(NotificationDialog);
