import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles
} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import Loading from "../../components/Loading";
import { getSettings } from "../../components/utils/SettingUtils";
import NotificationDialog from "./NotificationDialog";

const styles = () => ({
  button: {
    marginLeft: "auto"
  },
  tableCell: {
    padding: "4px 8px 4px 8px"
  },
  actionsCell: {
    padding: "4px 8px 4px 8px",
    textAlign: 'right',
  }
});

class NotificationsPage extends React.Component {
  state = {
    loading: true,
    notifications: {},
    selectedNotification: null
  };

  async componentDidMount() {
    await this.loadData();
  }

  async loadData() {
    this.setState({ loading: true });

    try {
      const response = await getSettings();
      const notifications = {};

      response.forEach(setting => {
        const splitedName = setting.name.split(".");
        if (splitedName[0] === "task" && splitedName[1] === "schedule") {
          if (!notifications[splitedName[2]]) {
            notifications[splitedName[2]] = {};
          }

          notifications[splitedName[2]][splitedName[3]] = setting.value;
        }
      });

      this.setState({ notifications });
    } catch (error) {
      console.log(error);
    }

    this.setState({ loading: false });
  }

  setNotification = notificationKey => {
    const notification = this.state.notifications[notificationKey];
    this.setState({
      selectedNotification: {
        name: notificationKey,
        notification_time: notification.notification_time,
        delay_days: notification.delay_days,
        delay_hours: notification.delay_hours
      }
    });
  };

  handleClose = async reload => {
    if (reload) {
      await this.loadData();
    }

    this.setState({ selectedNotification: null });
  };

  render() {
    const { classes } = this.props;
    const { loading, notifications } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <Grid container direction="column" className="pageContainer">
        <Typography variant="h5" gutterBottom>
          Notifications
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>Name</TableCell>
              <TableCell className={classes.tableCell}>
                Notification Time
              </TableCell>
              <TableCell className={classes.tableCell}>Delay Days</TableCell>
              <TableCell className={classes.tableCell}>Delay Hours</TableCell>
              <TableCell className={classes.tableCell} />
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.keys(notifications).map(notificationKey => (
              <TableRow key={notificationKey}>
                <TableCell className={classes.tableCell}>
                  {notificationKey}
                </TableCell>

                <TableCell className={classes.tableCell}>
                  {notifications[notificationKey].notification_time}
                </TableCell>

                <TableCell className={classes.tableCell}>
                  {notifications[notificationKey].delay_days}
                </TableCell>

                <TableCell className={classes.tableCell}>
                  {notifications[notificationKey].delay_hours}
                </TableCell>

                <TableCell className={classes.actionsCell}>
                  <Button
                    color="secondary"
                    onClick={() => this.setNotification(notificationKey)}
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <NotificationDialog
          notification={this.state.selectedNotification}
          handleClose={this.handleClose}
        />
      </Grid>
    );
  }
}

NotificationsPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NotificationsPage);
