import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, withStyles } from '@material-ui/core';
import { getAttachments, uploadAttachment } from '../../components/practitioners/PractitionerUtils';

const styles = theme => ({
  paper: {
    width: '230px',
    height: '230px',
    margin: theme.spacing.unit,
    position: 'relative',
  },
  media: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  info: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
    backgroundColor: theme.palette.text.secondary,
    opacity: '0.8',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  addFileWrap: {
    width: '230px',
    height: '230px',
    margin: theme.spacing.unit,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px dashed #D8D8D8',
    color: '#D8D8D8',
    cursor: 'pointer',
  },
});

class PractitionerFiles extends React.Component {
  static propTypes = {
    prUid: PropTypes.string.isRequired,
    classes: PropTypes.object,
  };

  state = {
    files: []
  };

  handleAddFileClick = () => {
    this.inputEl.click();
  };

  handleUploadFile = (e) => {
    if (e.target.files.length) {
      uploadAttachment(this.props.prUid, e.target.files[0])
        .then(files => {
          this.setState({ files: [...this.state.files, ...files] });
        })
        .catch(err => console.log(err));
    }
  };

  componentWillMount() {
    getAttachments(this.props.prUid)
      .then(files => {
        this.setState({ files })
      })
      .catch(e => console.log(e));
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container direction='row' justify='flex-start'>
        {this.state.files.map(file => (
          <Paper key={file.uid} className={classes.paper}>
            <div className={classes.media} style={{ backgroundImage: `url(${file.thumbnailLink})`}} />
            <div className={classes.info}>{file.name}</div>
          </Paper>
        ))}
        <div className={classes.addFileWrap} onClick={this.handleAddFileClick} id='addFile'>
          <span>+ Add File</span>
        </div>
        <input type='file' ref={(r) => {this.inputEl = r}} hidden onChange={this.handleUploadFile}/>
      </Grid>
    );
  }
}

export default withStyles(styles)(PractitionerFiles);
