import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './SearchForm.css';

export default class SearchAdvancedFilter extends React.Component {
  static propTypes = {
    onUpdate: PropTypes.func.isRequired,
  };

  state = {
    showDeleted: false,
    filterByCreationDate: false,
    creationDateFrom: '',
    creationDateTo: '',
  };

  onChange = (propName, e) => {
    this.setState({
      [propName]: e.target.value,
    });
  };

  onCreationDateChange = (e) => {
    this.setState({
      filterByCreationDate: e.target.checked,
    });
  };

  showDeleted = (e) => {
    this.setState({
      showDeleted: e.target.checked,
    });
  };

  onSearch = (e) => {
    if (e) { e.preventDefault(); }

    const searchData = {
      deleted: this.state.showDeleted,
    };

    if (this.state.filterByCreationDate) {
      searchData.creationDateFrom = this.state.creationDateFrom
        ? `${this.state.creationDateFrom}T00:00:00`
        : '';
      if (this.state.creationDateTo) {
        const toDate = moment(this.state.creationDateTo)
          .add(1, 'days')
          .format('YYYY-MM-DD');
        searchData.creationDateTo = `${toDate}T00:00:00`;
      }
    }

    this.props.onUpdate(searchData);
  };

  render() {
    return (
      <div className="searchForm-fieldset searchForm-advanced">
        <div className="searchForm-fieldset searchForm-advanced-container">
          <label className="searchForm-advanced-caption">Show Deleted</label>
          <div className="searchForm-advanced-valueContainer">
            <div className="searchForm-advanced-valueRow showDeleted">
              <input
                id="showDeleted"
                type="checkbox"
                title="Show Deleted"
                className="checkboxFilterDeleted"
                onClick={this.showDeleted}
                defaultChecked={this.state.showDeleted}
              />
              <label htmlFor="showDeleted" className="lblFilterDeleted">
                Show Deleted Results
              </label>
            </div>
          </div>
        </div>

        <div className="searchForm-fieldset searchForm-advanced-container">
          <label className="searchForm-advanced-caption">Creation date</label>
          <div className="searchForm-advanced-valueContainer">
            <div className="searchForm-advanced-valueRow">
              <input
                id="filterByCreationDate"
                type="checkbox"
                checked={this.state.filterByCreationDate}
                onClick={this.onCreationDateChange}
              />{' '}
              &nbsp;<label htmlFor="filterByCreationDate">
                Filter by creation date
              </label>
            </div>
            <div
              className={
                this.state.filterByCreationDate
                  ? 'searchForm-advanced-valueRow'
                  : 'searchForm-advanced-valueRow disabled'
              }
            >
              <input
                type="date"
                value={this.state.creationDateFrom}
                onChange={this.onChange.bind(this, 'creationDateFrom')}
              />{' '}
              <span>&nbsp;to&nbsp;</span>{' '}
              <input
                type="date"
                value={this.state.creationDateTo}
                onChange={this.onChange.bind(this, 'creationDateTo')}
              />
            </div>
          </div>
        </div>

        <button className="actionButton updateSearch" onClick={this.onSearch}>
          Update Search
        </button>
      </div>
    );
  }
}
