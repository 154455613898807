import { mediciFetch, getApiUrl, serialize, trimObjectValues } from './../utils/NetworkUtils';

/**
 * Search patients free text
 * @param searchValue value to search
 * @returns {Promise.<T>}
 */
export function searchPatients(searchValue) {
  return mediciFetch(`${getApiUrl()}/patients/search?q=${searchValue}`, {
    method: 'get',
  });
}

/**
 * Search patients between dates
 * @returns {Promise.<T>}
 */
export function searchPatientsBetweenDates(fromDate, toDate) {
  return mediciFetch(
    `${getApiUrl()}/patients/search?creationDateFrom=${fromDate}&creationDateTo=${toDate}`,
    {
      method: 'get',
    },
  );
}

/**
 * Search patients filter
 * @param filter object of filter criteria
 * @returns {Promise.<T>}
 */
export function advancedSearchPatients(filter) {
  return mediciFetch(`${getApiUrl()}/patients/search?${serialize(filter)}`, {
    method: 'get',
  });
}

/**
 * Get patients by id
 * @param id patient id
 * @returns {Promise.<T>}
 */
export function getPatientByID(id) {
  return mediciFetch(`${getApiUrl()}/patients/${id}`, {
    method: 'get',
  });
}

/**
 * Get patient insurance list
 * @param uid
 * @returns {Promise<*|void>}
 */
export function getPatientInsuranceByID(uid) {
  return mediciFetch(`${getApiUrl()}/patients/${uid}/insurance`, {
    method: 'get',
  });
}

export function getInsurancePlans() {
  return mediciFetch(`${getApiUrl()}/insurance/plans`);
}

export function addPatientInsurance(uid, insurance) {
  return mediciFetch(`${getApiUrl()}/patients/${uid}/insurance`, {
    body: JSON.stringify(insurance),
    method: 'post',
  });
}

export function removePatientInsurance(patientUid, insuranceUid) {
  return mediciFetch(`${getApiUrl()}/patients/${patientUid}/insurance/${insuranceUid}`, {
    method: 'delete',
  });
}

/**
 * Loop patients and find by UID
 * @param patients data array to loop through
 * @param uid ID of practictioner to find
 * @returns {Promise.<T>}
 */
export function findByUID(patients, uid) {
  return patients.find(r => r.uid === uid);
}

/**
 * Save patient
 * @param patient object to save
 * @returns {Promise.<T>}
 */
export function savePatient(patient, forceUpdate) {
  let queryParams = '';
  trimObjectValues(patient);

  if (forceUpdate) {
    queryParams = '?force=true';
  }

  if (patient.uid) {
    // update
    return mediciFetch(
      `${getApiUrl()}/patients/${patient.uid}${queryParams}`,
      {
        method: 'post',
        body: JSON.stringify(patient),
      },
    );
  }
  // insert
  return mediciFetch(`${getApiUrl()}/patients/register${queryParams}`, {
    method: 'post',
    body: JSON.stringify(patient),
  });
}

/**
 * Save patient profile picture
 * @param patientImage patient data
 * @returns {Promise.<T>}
 */
export function savePatientImage(patientImage) {
  const fd = new FormData();
  fd.append('file', patientImage.file);

  return mediciFetch(`${getApiUrl()}/patients/${patientImage.uid}/picture`, {
    method: 'post',
    body: fd,
  });
}

/**
 * Delete patient
 * @param patient object to delete
 * @returns {Promise.<T>}
 */
export function deletePatient(patient) {
  return mediciFetch(`${getApiUrl()}/patients/${patient.uid}`, {
    method: 'delete',
  });
}

/**
 * Save csv bulk file
 * @param File csv file
 * @returns {Promise.<T>}
 */
export function saveBulkCSVFile(patientUID, bulkCSVFile) {
  const fd = new FormData();
  fd.append('file', bulkCSVFile);

  return mediciFetch(
    `${getApiUrl()}/patients/${patientUID}/inviteByFile?format=csv`,
    {
      method: 'post',
      body: fd,
    },
  );
}

/**
 * Get PA contacts by uid
 * @param uid
 * @return {Promise}
 */
export function getPatientContacts(uid) {
  return mediciFetch(`${getApiUrl()}/patients/${uid}/contacts?showAll=true`);
}

export function removePatientContact(patientUid, contactId) {
  return mediciFetch(`${getApiUrl()}/patients/${patientUid}/contacts/${contactId}`, {
    method: 'delete',
  });
}

export function removePatientPayment(patientUid, paymentUid) {
  return mediciFetch(`${getApiUrl()}/patients/${patientUid}/payments/${paymentUid}`, {
    method: 'delete',
  });
}

export function setPaymentAsDefault(patientUid, paymentUid) {
  return mediciFetch(`${getApiUrl()}/patients/${patientUid}/payments/${paymentUid}`, {
    body: JSON.stringify({ favorite: true }),
    method: 'post',
  })
}

export function addPaymentMethod(patientUid, body) {
  return mediciFetch(`${getApiUrl()}/patients/${patientUid}/payments`, {
    body: JSON.stringify(body),
    method: 'post',
  });
}
