import moment from 'moment';

export function convertToUTC(datetime) {
  const format = 'YYYY-MM-DD[T]HH:mm:SS[.000Z]';
  const momentDTLocal = moment(datetime);
  return momentDTLocal.utc().format(format);
}

export function convertToLocal(datetime) {
  const format = 'YYYY-MM-DD HH:mm:ss';
  return moment(datetime).format(format);
}

export function formatDateForPicker(datetime) {
  return moment(datetime).format('YYYY-MM-DD[T]HH:mm');
}

export function convertToFriendlyDateTime(datetime) {
  return moment(datetime).format('YYYY-MM-DD[ ]hh:mm A');
}

export function getLocalTimeZone() {
  const split = new Date().toString().split(' ');
  const timeZoneFormatted = `${split[split.length - 2]} ${split[split.length - 1]}`;
  return timeZoneFormatted;
}
