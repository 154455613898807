/* eslint-disable class-methods-use-this */
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles
} from "@material-ui/core";
import { Launch } from '@material-ui/icons';
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Line as LineChart } from 'react-chartjs-2';
import Loading from "../../Loading";
import {
  getPACallByID,
  getPRCallByID,
} from '../ConsultationUtils';
import UserAvatar from '../../user-avatar/UserAvatar';
import PractitionerHeader from '../../../pages/PractitionerProfile/PractitionerHeader';
import { getPractitionerByID } from '../../practitioners/PractitionerUtils';
import { getPatientByID } from '../../patients/PatientUtils';

const styles = ({ palette, spacing }) => ({
  container: {
    border: "1px solid #E5E5E5",
    backgroundColor: "#F6F6F6",
    padding: 24
  },
  labelField: {
    color: palette.primary.light,
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  textField: {
    border: "1px solid #E7E7E7",
    borderRadius: "3px",
    backgroundColor: "#FBFBFB",
    padding: "6px 7px"
  },
  title: {
    color: palette.primary.light
  },
  line: {
    backgroundColor: palette.primary.light,
    height: 2,
    width: "100%",
    marginBottom: 23
  },
  subscriptionPrice: {
    color: palette.primary.light,
    textTransform: "capitalize",
    fontSize: 18,
    fontWeight: 500
  },
  subscriptionDate: {
    color: palette.primary.light,
    fontSize: 14,
    marginTop: 6
  },
  subscriptionStatus: {
    fontSize: 18,
    fontWeight: 500,
    marginLeft: "auto",
    textTransform: "capitalize",
    color: palette.text.secondary,
    "&> span.active": {
      color: palette.secondary.main
    }
  },
  cancelButton: {
    color: palette.error.main,
    borderColor: palette.error.main,
    marginTop: spacing.unit,
    marginLeft: "auto"
  },
  roleLabel: {
    height: 39,
    width: "100%",
    backgroundColor: "#556979",
    color: palette.common.white,
    paddingLeft: 21,
    paddingRight: 16,
    fontSize: 22,
    fontWeight: 600
  },
  viewProfile: {
    cursor: "pointer",
    width: 125,
    color: "#21CE99",
    fontSize: 18,
    fontWeight: "normal"
  },
  paymentTitle: {
    color: "#6D7278",
    fontSize: 27,
    fontWeight: 500
  },
  paymentStatus: {
    color: "#20CE9A",
    fontSize: "18px",
    fontWeight: "bold",
    marginLeft: "auto"
  },
  divider: {
    width: 1,
    backgroundColor: "#F3F3F3"
  },
  wrap: {
    padding: 16
  },
  statsTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: palette.text.secondary,
    textTransform: "uppercase",
    marginBottom: spacing.unit
  },
  statsData: {
    color: palette.text.secondary,
    fontSize: 21,
    letterSpacing: 0.5,
    lineHeight: "32px"
  },
  statsHint: {
    color: palette.text.secondary,
    opacity: 0.6,
    fontSize: 10,
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: 0.5
  },
});

class CallDetail extends React.Component {
  static propTypes = {
    params: PropTypes.shape({ id: PropTypes.string, userUid: PropTypes.string }),
    classes: PropTypes.object,
    onCancel: PropTypes.func,
    setNotifySuccess: PropTypes.func.isRequired,
    setNotifyWarning: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
    route: PropTypes.object,
  };

  state = {
    participants: [],
    loading: true,
  };

  async componentDidMount() {
    const { params } = this.props;

    try {
      let call;
      if (this.props.route.role === 'patient') {
        call = await getPACallByID(params.userUid, params.id);
      } else {
        call = await getPRCallByID(params.userUid, params.id);
      }

      const participants = [];
      await Promise.all(call.participants.map(async p => {
        let participant;
        if (p.role === 'practitioner') {
          participant = await getPractitionerByID(p.userUid);
        }
        if (p.role === 'patient') {
          participant = await getPatientByID(p.userUid);
        }
        if (participant) participants.push({...participant, appPlatform: p.appPlatform, appVersion: p.appVersion, averageLevel: p.networkQuality.averageLevel, averageLevelDesc: p.networkQuality.averageLevelDesc, networkQualityLevels: p.networkQuality.levels});
      }));

      this.setState({
        call,
        participants,
        loading: false
      });
    } catch (error) {
      console.log("Error retrieving call details", error);
      this.props.setNotifyWarning("Error retrieving Call");
    }
  }

  renderRoleLabel = user => {
    if (!user) return;
    const { classes } = this.props;
    const role = user.role === "practitioner" ? "Doctor" : "Patient";

    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.roleLabel}
      >
        <Grid style={{ marginRight: 16 }}>{role}</Grid>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.viewProfile}
          onClick={() => window.open(`/#/${user.role}s/${user.uid}`, "_blank")}
        >
          <div>View Profile</div> <Launch />
        </Grid>
      </Grid>
    );
  };

  getChartData(levels) {
    levels.unshift(0);
    levels.push(0);
    const timeLabels = [];
    for (let i = 0; i < levels.length; i += 1 ) {
      timeLabels.push(moment.utc(moment.duration(5 * i, 'seconds').asMilliseconds()).format('mm:ss'));
    }

    const data = {
      labels: timeLabels,
      datasets: [{
        data: levels,
        backgroundColor: ['rgba(54, 162, 235, 0.2)'],
        borderColor: ['rgba(54, 162, 235, 1)'],
        borderWidth: 3,
        lineTension: 0,
      }],
    };

    return data;
  }

  render() {
    const { loading, call, participants } = this.state;
    const { classes } = this.props;

    const options = {
      maintainAspectRatio: false,
      scales: {
        xAxes: [{ display: false }],
        yAxes: [{
          display: true,
          position: 'right',
          ticks: {
            beginAtZero: true,
            max: 6,
            min: 0,
            stepSize: 1,
            callback(value, index, values) {
              if (value !== 6) {
                return values[index]
              }
            }
          }
        }]
      },
      layout: { padding: { left: 5, right: 5 }},
      tooltips: {
        enabled: true,
        displayColors: false,
        bodyAlign: 'center',
        bodyFontSize: '24',
      },
      legend: { display: false },
    };

    if (loading) {
      return (
        <Grid container direction="column">
          <Typography variant="h5">Call Details</Typography>
          <div style={{ height: "50vh" }}>
            <Loading size={50} />
          </div>
        </Grid>
      );
    }

    return (
      <Grid container direction="column">
        <Typography variant="h5" style={{ marginBottom: 16 }}>
          Call Details
        </Typography>

        <Grid container direction="row" alignItems="baseline">
          <Typography
            variant="h6"
            className={classes.title}
            style={{ marginTop: 16 }}
          >
            Overview
          </Typography>
          <Grid style={{ marginLeft: "auto", color: "#556979", fontSize: 16 }}>
            ID: {call.roomSid}
          </Grid>
        </Grid>
        <div className={classes.line} />

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Started</TableCell>
              <TableCell>Ended</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Duration (h:m:s)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={call.uid}>
              <TableCell>
                {moment(call.callStartedAt).format("YYYY-MM-DD [@] h:mm a")}
              </TableCell>
              {call.callEndedAt && (
                <React.Fragment>
                  <TableCell>
                    {moment(call.callEndedAt).format(
                      "h:mm a"
                    )}
                  </TableCell>
                </React.Fragment>
              )}
              <TableCell>{call.callType}</TableCell>
              <TableCell style={{ width: 80, textAlign: 'right'}}>{call.duration}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Grid container direction="row" alignItems="baseline">
          <Typography
            variant="h6"
            className={classes.title}
            style={{ marginTop: 16 }}
          >
            Participants
          </Typography>
        </Grid>
        <div className={classes.line} />

        {participants.map((participant, index) => (
          <div key={index} style={{ minHeight: 240 }}>
            {this.renderRoleLabel(participant)}
            <Grid container direction="row">
              <Grid item xs={6}>
                <PractitionerHeader
                  practitioner={participant}
                  actions={false}
                />
              </Grid>

              <Grid item className={classes.wrap}>
                <div className={classes.statsTitle} style={{ color: '#999999' }}>Quality</div>
                <div className={classes.statsData} style={{ fontSize: 48, lineHeight: '48px' }}>
                  {participant.averageLevel}
                </div>
                <div style={{ fontSize: 12, color: '#999999' }}>{participant.averageLevelDesc}</div>
              </Grid>

              <Grid item className={classes.wrap}>
                <Grid container style={{ height: "100%" }}>
                  <div className={classes.divider} style={{ height: "100%" }} />
                </Grid>
              </Grid>

              {participant.appPlatform && (
                <Grid item className={classes.wrap}>
                  <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    style={{ height: "100%" }}
                  >
                    <div className={classes.statsTitle}>Version</div>
                    <div>
                      <div className={classes.statsData} style={{ fontSize: 32 }}>
                        {participant.appPlatform}
                      </div>
                      <div className={classes.statsHint}>
                        {participant.appVersion || "----"}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )}
            </Grid>

            {participant.networkQualityLevels && (
              <Grid container direction="column">
                <LineChart
                  data={this.getChartData(participant.networkQualityLevels)}
                  options={options}
                />
              </Grid>
            )}

            <Grid container direction="column" style={{ marginBottom: '60px' }}>
              <Typography
                variant="h6"
                className={classes.title}
                style={{ marginTop: 16 }}
              >
                Events
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Event Type</TableCell>
                    <TableCell>Timestamp (h:m:s)</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {call.events.filter(e => e.userUid === participant.uid).map((event, idx) => (
                    <TableRow key={idx}>
                      <TableCell>
                        {event.typeDesc}
                      </TableCell>
                      <TableCell style={{ width: 70, textAlign: 'right'}}>{event.timeFromCallStart}</TableCell>
                      <TableCell>{event.message}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </div>
        ))}

        <Grid container direction="row" alignItems="baseline">
          <Typography
            variant="h6"
            className={classes.title}
            style={{ marginTop: 16 }}
          >
            All Events
          </Typography>
        </Grid>
        <div className={classes.line} />

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Event Type</TableCell>
              <TableCell>Timestamp (h:m:s)</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Event Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {call.events.map((event, index) => (
              <TableRow key={index}>
                <TableCell>
                  {event.typeDesc}
                </TableCell>
                <TableCell style={{ width: 70, textAlign: 'right'}}>{event.timeFromCallStart}</TableCell>
                <TableCell>
                  {event.userUid && (
                    <Grid container direction='row' alignItems='center'>
                      <UserAvatar role={call.participants.find(p => p.userUid === event.userUid).role === "practitioner" ? "practitioners" : "patients"} uid={event.userUid}  size={25} />
                      <Typography style={{ marginLeft: 10 }} variant='subtitle2'>
                        {event.userName}
                      </Typography>
                    </Grid>
                    )}
                </TableCell>
                <TableCell>{event.message}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    );
  }
}

export default withStyles(styles)(CallDetail);
