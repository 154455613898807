export const NOTIFY_SUCCESS = 'NOTIFY_SUCCESS';
export const NOTIFY_INFO = 'NOTIFY_INFO';
export const NOTIFY_WARNING = 'NOTIFY_WARNING';
export const NOTIFY_DANGER = 'NOTIFY_DANGER';
export const NOTIFY_HIDDEN = 'NOTIFY_HIDDEN';
export const NOTIFY_GRAY = 'NOTIFY_GRAY';
export const NOTIFY_CLEAR = 'NOTIFY_CLEAR';

/**
 * Reducer that manages the states for specialist data
 */
export function NotificationReducer(
  state = { status: NOTIFY_HIDDEN, title: '' },
  action,
) {
  switch (action.type) {
    case NOTIFY_SUCCESS:
    case NOTIFY_INFO:
    case NOTIFY_WARNING:
    case NOTIFY_DANGER:
      return Object.assign({}, state, {
        status: action.type,
        title: action.title,
      });
    case NOTIFY_HIDDEN:
      return Object.assign({}, state, { status: action.type });
    case NOTIFY_CLEAR:
      return { status: NOTIFY_HIDDEN, title: '' };
    default:
      return state;
  }
}

/**
 * Clear a notification
 * @returns {{type: *}}
 */
export function clearNotify() {
  return {
    type: NOTIFY_CLEAR,
  };
}

/**
 * Create a "success" action
 */
export function setNotifySuccess(title) {
  return {
    type: NOTIFY_SUCCESS,
    title,
  };
}

/**
 * Create a "info" action
 */
export function setNotifyInfo(title) {
  return {
    type: NOTIFY_INFO,
    title,
  };
}

/**
 * Create a "warning" action
 */
export function setNotifyWarning(title) {
  return {
    type: NOTIFY_WARNING,
    title,
  };
}

/**
 * Create a "info" action
 */
export function setNotifyDanger(title) {
  return {
    type: NOTIFY_DANGER,
    title,
  };
}

/**
 * Create a "info" action
 */
export function setNotifyHidden() {
  return {
    type: NOTIFY_HIDDEN,
  };
}
