import PropTypes from "prop-types";
import React from "react";

/**
 * UploadAttachments
 */
export default class UploadAttachments extends React.Component {
  static propTypes = {
    uploadAttachmentsSave: PropTypes.func.isRequired,
    cancelUploadAttachments: PropTypes.func.isRequired,
    params: PropTypes.shape({ id: PropTypes.string })
  };

  fileAttachment = undefined;

  fileAttachmentImporterHandler = e => {
    e.preventDefault();
    this.fileAttachment = e.target.files[0];
  };

  save = e => {
    e.preventDefault();
    this.props.uploadAttachmentsSave(this.fileAttachment, this.props.params.id);
  };

  render() {
    return (
      <form
        id="formUploadAttachments"
        ref="formUploadAttachments"
        className="form"
      >
        <h2>Upload Document</h2>

        <div className="form-freeFieldset">
          <label htmlFor="firstname" className="form-label">
            Document
          </label>

          <div className="form-flexColFieldset">
            <label className="tip">File ext: .pdf,.jpg,.jpeg,.png</label>
            <input
              type="file"
              name="bulkCSVFileImporter"
              accept=".pdf,.jpg,.jpeg,.png"
              onChange={this.fileAttachmentImporterHandler}
            />
          </div>
        </div>

        <div className="form-options">
          <button className="form-button actionButton" onClick={this.save}>
            Upload
          </button>
          <button
            className="form-button actionButton"
            onClick={this.props.cancelUploadAttachments}
          >
            Cancel
          </button>
        </div>
      </form>
    );
  }
}
