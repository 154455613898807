import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, IconButton, withStyles, Avatar } from '@material-ui/core';

const styles = theme => ({
  avatar: {
    color: '#fff',
    backgroundColor: theme.palette.secondary.main,
  },
});

class ProfileMenu extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    router: PropTypes.object,
    user: PropTypes.object,
    onLogout: PropTypes.func,
  };

  state = {
    isOpen: false,
    anchorEl: null,
    admin: null,
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      isOpen: false
    });
  };

  handleMenu = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      isOpen: true
    });
  };

  onClickProfile = () => {
    this.handleClose();
    this.props.router.push(`/admins/${this.props.user.uid}`);
  };

  onClickLogout = () => {
    this.handleClose();
    this.props.onLogout();
  };

  render() {
    const { isOpen, anchorEl } = this.state;
    const { user } = this.props;
    if (!user) return null;
    return (
      <div className={this.props.className}>
        <IconButton
          aria-owns={isOpen ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={this.handleMenu}
          color="inherit"
        >
          <Avatar className={this.props.classes.avatar}>
            {`${user.firstname ? user.firstname.charAt(0) : ''}${user.lastname ? user.lastname.charAt(0): ''}`}
          </Avatar>
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorReference='anchorPosition'
          anchorPosition={{
            top: anchorEl ? anchorEl.getBoundingClientRect().bottom : 0,
            left: anchorEl ? anchorEl.getBoundingClientRect().left : 0
          }}
          open={isOpen}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.onClickProfile}>Profile</MenuItem>
          <MenuItem onClick={this.onClickLogout}>Logout</MenuItem>
        </Menu>
      </div>
    )
  }
}

export default withStyles(styles)(ProfileMenu);
