import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import AutoComplete from '../autocomplete/AutoComplete';

/**
 * Group Selector - Used for both companyGroups and brokerGroups
 */
export default class GroupSelector extends React.Component {
  static propTypes = {
    values: PropTypes.array,
    onSelect: PropTypes.func,
    onSave: PropTypes.func,
    className: PropTypes.string,
    defaultValue: PropTypes.string,
  }

  state = {
    searchValue: '',
    showAddButton: false,
  }

  savingItem = false

  onSearch(searchValue = '') {
    this.setState({ searchValue });

    return new Promise((resolve) => {
      const results = this.props.values.filter((item) => {
        item.title = item.name;
        return item.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
      });

      const searchValueExists = results.find(val =>
        val.name.toLowerCase() === searchValue.toLowerCase());

      if (searchValue && !searchValueExists) {
        // No results, allow user to add
        this.setState({ showAddButton: true });
      } else {
        this.setState({ showAddButton: false });
      }

      resolve(results);
    });
  }

  onClear() {
    this.setState({ showAddButton: false });
  }

  onSave(e) {
    this.savingItem = true;

    e.preventDefault();
    this.props.onSave(this.state.searchValue);
  }

  componentWillUpdate(nextProps) {
    if (!this.savingItem) {
      return;
    }

    this.savingItem = false;
    const newlyCreatedItem = nextProps.values.find(val => val.name === this.state.searchValue);

    // confirm search has been added
    if (this.state.searchValue && newlyCreatedItem) {
      setTimeout(() => {
        this.props.onSelect(newlyCreatedItem);
      }, 500);

      this.setState({
        showAddButton: false,
      });
    }
  }

  render() {
    const classNames = `groupSelector-AutoSuggest ${this.props.className}`;
    return (
      <Grid container alignItems="center" direction="row">
        <AutoComplete
          onSearch={this.onSearch.bind(this)}
          onClear={this.onClear.bind(this)}
          onSelect={this.props.onSelect}
          defaultValue={this.props.defaultValue}
          className={classNames}
        />
        <Button
          disabled={!this.state.showAddButton}
          color="secondary"
          onClick={this.onSave.bind(this)}
        >
          Create
        </Button>
      </Grid>
    );
  }
}
