import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  IconButton,
  Radio,
  withStyles, FormControlLabel, Checkbox, FormControl
} from '@material-ui/core';
import PaymentIcon from "@material-ui/icons/Payment";
import RefreshIcon from "@material-ui/icons/Refresh";
import PropTypes from "prop-types";
import React from "react";
import AddressAutoComplete from "../../../components/google/AddressAutoComplete";
import Loading from "../../../components/Loading";
import { getPractitionerByID, getSubscriptionStatus } from '../../../components/practitioners/PractitionerUtils';
import {
  getPracticeByID,
  getPRsForPractice,
  savePractice,
  enforcePracticePayments
} from "../../../components/utils/PracticeUtils";
import SubscriptionUtils from "../../../components/utils/SubscriptionUtils";
import {
  errorToString,
  locationToString,
  validateLocation,
  renderDate
} from "../../../utils/helpers";
import DoctorSelect from "../../Network/DoctorSelect";
import PracticeMembers from "./PracticeMembers";
import styles from "./styles";

const emptyState = () => ({
  editing: false,
  loading: true,
  saving: false,
  name: "",
  hasInbox: false,
  manager: null,
  defaultPrice: {},
  location: {
    country: "",
    state: "",
    city: "",
    postalCode: "",
    streetNumber: "",
    street: ""
  },
  members: [],
  dirtyPractice: true,
  dirtyMembers: false,
  subscription: {},
  enforcePayment: false,
});

class PracticeForm extends React.Component {
  static propTypes = {
    setNotifyWarning: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string
    })
  };

  state = emptyState();

  async componentDidMount() {
    const { id } = this.props.params || {};
    await this.loadData(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.params.id !== this.props.params.id) {
      this.loadData(nextProps.params.id);
    }
  }

  loadData = async id => {
    this.setState({ loading: true });

    if (id) {
      const practice = await getPracticeByID(id);

      let manager;
      let subscription;
      if (practice.managerUid) {
        manager = await getPractitionerByID(practice.managerUid);
        subscription = await getSubscriptionStatus(practice.managerUid);
      }

      const membersResponse = await getPRsForPractice(id);
      const members = membersResponse.content.map(
        content => content.practitioner
      );

      this.setState({
        editing: true,
        manager,
        hasInbox: practice.hasInbox,
        subscription,
        enforcePayment: practice.enforcePayment,
        name: practice.name,
        defaultPrice: practice.defaultPrice,
        location: practice.location,
        members,
        dirtyPractice: false
      });
    } else {
      this.setState(emptyState());
    }

    this.setState({ loading: false });

    setTimeout(async () => {
      SubscriptionUtils.setRecurly();
    }, 1000);
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });

    const { id } = this.props.params || {};

    if (id) {
      this.setState({ dirtyPractice: true });
    }
  };

  onCheckChange = event => {
    this.setState({ hasInbox: event.target.checked });

    const { id } = this.props.params || {};

    if (id) {
      this.setState({ dirtyPractice: true });
    }
  }

  refreshData = async () => {
    await this.loadData(this.props.params.id);
  }

  openBillingInfo = async () => {
    const { subscription, manager } = this.state;

    if (!manager || !subscription) {
      this.props.setNotifyWarning('Practice Manager is not set');
      return;
    }

    try {
      if (!subscription.recurlyAccountUrl) {
        const recurlyProfile = await SubscriptionUtils.createRecurlyAccount(manager.uid);
        subscription.recurlyAccountUrl = recurlyProfile.recurlyAccountUrl;
        await this.setState({ ...subscription });
      }
      const win = window.open(subscription.recurlyAccountUrl, '_blank');
      if (win !== null) {
        win.focus();
      }
    } catch (error) {
      this.props.setNotifyWarning(errorToString(error));
    }
  };

  addManager = manager =>
    this.setState({ manager, location: manager.location, dirtyPractice: true });
  removeManager = () => this.setState({ manager: null, dirtyPractice: true });

  addressSelected = location =>
    this.setState({ location, dirtyPractice: true });

  handleSave = async () => {
    this.setState({ saving: true });
    const { name, manager, defaultPrice, location, enforcePayment, hasInbox } = this.state;
    const { id } = this.props.params || {};

    try {
      const response = await savePractice({
        name,
        defaultPrice,
        location,
        hasInbox,
        managerUid: manager && manager.uid,
        uid: id
      });

      if (!id) {
        this.props.router.push(`/practices/${response.details.practiceUid}`);
      }
      this.setState({ dirtyPractice: false });

      if (response.managerUid) {
        this.setState({ subscription: await getSubscriptionStatus(response.managerUid) });
      }

      if (id) {
        await enforcePracticePayments(id, enforcePayment);
      }
    } catch (error) {
      console.log(error);
      this.props.setNotifyWarning(errorToString(error));
    }

    this.setState({ saving: false });
  };

  renderSubscription = () => {
    const { classes } = this.props;
    const { subscription } = this.state;

    if (subscription.plan === "basic") {
      return (
        <Paper style={{ padding: 24, marginBottom: 32, marginTop: 16 }} id="subscription">
          <Typography variant="h5" gutterBottom align="center">Subscription</Typography>
          <div className={classes.subscriptionPrice}>Basic</div>
        </Paper>
      );
    }

    return (
      <Paper style={{ padding: 24, marginBottom: 32, marginTop: 16 }} id="subscription">
        <Typography variant="h5" gutterBottom align="center">Subscription</Typography>
        <Grid container direction="row">
          <div className={classes.subscriptionPrice}>
            {subscription.plan} - ${subscription.price / 100}/mo.
          </div>
          <div className={classes.subscriptionStatus}>
            {subscription.subscriptionCancelRequested ? (
              "Cancellation Pending"
            ) : (
              <span className={subscription.status}>{subscription.status}</span>
            )}
          </div>
        </Grid>
        <Grid container direction="row">
          <div className={classes.subscriptionQuantity}>
            Number of seats - {subscription.quantity}
          </div>
        </Grid>
        <Grid container direction="row" alignItems="center">
          <div className={classes.subscriptionDate}>
            Started {renderDate(subscription.activatedAt, "MM/DD/YYYY")}
          </div>
        </Grid>
      </Paper>
    );
  };

  render() {
    const { classes } = this.props;
    const { id } = this.props.params || {};
    const {
      editing,
      loading,
      saving,
      name,
      location,
      manager,
      subscription,
      members,
      dirtyPractice,
      dirtyMembers,
      hasInbox,
    } = this.state;
    const isValid = name.length > 0 && validateLocation(location);

    if (loading) {
      return <Loading />;
    }

    return (
      <Grid container direction="column" justify="center" alignItems="stretch">
        <Typography variant="h6" gutterBottom>
          Practice
        </Typography>
        <Paper>
          <Typography variant="h5" gutterBottom align="center">
            {editing ? "Edit" : "Create"}
          </Typography>
          <IconButton
            className={classes.actionIcon}
            onClick={this.openBillingInfo}
          >
            <PaymentIcon />
          </IconButton>
          <IconButton
            style={{ marginLeft: "0" }}
            className={classes.actionIcon}
            onClick={this.refreshData}
          >
            <RefreshIcon />
          </IconButton>
          {subscription && subscription.plan === "premium" && (
            <span className={classes.label}>Subscribed</span>
          )}
          <form className={classes.form} onSubmit={this.submit}>
            <div className={classes.input}>
              <TextField
                id="name"
                label="Practice Name"
                value={name}
                onChange={this.handleChange("name")}
                fullWidth
                required
              />
            </div>

            <div className={classes.input}>
              {manager ? (
                <TextField
                  id="manager"
                  label="Practice Manager"
                  value={`${manager.firstname ||
                    manager.firstName} ${manager.lastname || manager.lastName}`}
                  onChange={this.removeManager}
                  fullWidth
                />
              ) : (
                <DoctorSelect onChange={this.addManager} label="Manager" />
              )}
            </div>

            <div className={classes.input}>
              <AddressAutoComplete
                value={locationToString(location)}
                label="Practice Address"
                onChange={this.addressSelected}
                required
              />
            </div>

            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasInbox}
                    onChange={this.onCheckChange}
                    value={hasInbox}
                  />
                }
                label="Has Practice Inbox"
              />
            </FormControl>

            { editing && (<div className={classes.payouts}>
              <label style={{ fontWeight: 500, fontSize: 18, lineHeight: '22px' }}>Payout Management</label>
              <div>
                <Radio
                  disabled={!(subscription && subscription.plan === "premium")}
                  checked={!this.state.enforcePayment}
                  onChange={() => this.setState({ enforcePayment: false, dirtyPractice: true })}
                />
                <label htmlFor="ehr_csv">Providers in this practice manage their payouts independently</label>
              </div>
              <div>
                <Radio
                  disabled={!(subscription && subscription.plan === "premium")}
                  checked={this.state.enforcePayment}
                  onChange={() => this.setState({ enforcePayment: true, dirtyPractice: true })}
                />
                <label htmlFor="sms_csv">Practice Manager manages all payouts (Premium only)</label>
              </div>
            </div>)}

            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this.handleSave}
              disabled={saving || !isValid || !dirtyPractice}
            >
              {saving ? (
                <Loading size={20} />
              ) : (
                editing ? "Save" : "Create"
              )}
            </Button>
          </form>
        </Paper>
        {id && manager && (
          <PracticeMembers
            practiceUid={id}
            members={members}
            onChange={() => this.setState({ dirtyMembers: true })}
            onSave={newMembers =>
              this.setState({ dirtyMembers: false, members: newMembers })
            }
            disabled={!dirtyMembers}
            setNotifyWarning={this.props.setNotifyWarning}
          />
        )}
        {id && manager && this.renderSubscription()}
      </Grid>
    );
  }
}

export default withStyles(styles)(PracticeForm);
