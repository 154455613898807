import { withStyles } from "@material-ui/core";
import InvitePatients from "./InvitePatients";
import InvitePatientsNew from "./InvitePatientsNew";

const styles = ({ palette }) => ({
  appBar: {
    backgroundColor: palette.primary.main,
    color: palette.common.white
  },
  deleteButton: {
    color: palette.error.main,
    borderColor: palette.error.main
  }
});

export default withStyles(styles)(InvitePatients);

const InviteNew = withStyles(styles)(InvitePatientsNew);
export { InviteNew };
