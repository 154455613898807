import { mediciFetch, getApiUrl } from './../utils/NetworkUtils';

const ACTION_GET_LANGUAGES_SUCCESS = 'ACTION_GET_LANGUAGES_SUCCESS';
const ACTION_GET_LANGUAGES_FAILURE = 'ACTION_GET_LANGUAGES_FAILURE';

/**
 * Reducer that manages the states for specialist data
 */
export function LanguageReducer(state = { languageList: [] }, action) {
  switch (action.type) {
    case ACTION_GET_LANGUAGES_SUCCESS:
      return Object.assign({}, state, { languageList: action.languageList });
    case ACTION_GET_LANGUAGES_FAILURE:
      return Object.assign({}, state, { languageList: [] });
    default:
      return state;
  }
}

/**
 * Create a "success" action
 */
export function fetchLanguageSuccess(languageList) {
  return {
    type: ACTION_GET_LANGUAGES_SUCCESS,
    languageList,
  };
}

/**
 * Create a "failed" action
 */
export function fetchLanguageFailure() {
  return {
    type: ACTION_GET_LANGUAGES_FAILURE,
  };
}

/**
 * Get list of langauges for practitioner
 * @returns {Promise.<T>}
 */
export function getLanguages() {
  return mediciFetch(`${getApiUrl()}/practitioners/languages`, {
    method: 'get',
  });
}
