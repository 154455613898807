import { mediciFetch, getApiUrl, serialize } from './../utils/NetworkUtils';

/**
 * get all activationcodes
 * @returns {Promise.<T>}
 */
export function getActivationCodes(filters = {}, limit = 10, offset = 0) {
  return mediciFetch(`${getApiUrl()}/activationCodes?${serialize(filters)}&limit=${limit}&offset=${offset}`, {
    method: 'get',
  });
}

export function getActivationCodesStats(uid) {
  return mediciFetch(`${getApiUrl()}/activationCodes/stats`, {
    method: 'POST',
    body: JSON.stringify({
      activationCodes: [uid],
    }),
  });
}

/**
 * Get activationCode by code
 * @param code activationCode
 * @returns {Promise.<T>}
 */
export function getActivationCodeByCode(uid) {
  return mediciFetch(`${getApiUrl()}/activationCodes/${uid}`, {
    method: 'get',
  });
}

/**
 * Get activationCode users by id
 * @param id activationCode id
 * @returns {Promise.<T>}
 */
export function getActivationCodeUsersByCode(code) {
  return mediciFetch(`${getApiUrl()}/activationCodes/${code}/consumedBy`, {
    method: 'get',
  });
}

/**
 * Save activationCode
 * @param activationCode object to save
 * @returns {Promise.<T>}
 */
export function saveActivationCode(activationCode) {
  // insert
  return mediciFetch(`${getApiUrl()}/activationCodes`, {
    method: 'post',
    body: JSON.stringify(activationCode),
  });
}

/**
 * Update activationCode
 * @param activationCode object to save
 * @returns {Promise.<T>}
 */
export function updateActivationCode(activationCode) {
  // update
  return mediciFetch(
    `${getApiUrl()}/activationCodes/${activationCode.code}`,
    {
      method: 'post',
      body: JSON.stringify(activationCode),
    },
  );
}

/**
 * Delete activationCode
 * @param code string to delete
 * @returns {Promise.<T>}
 */
export function deleteActivationCode(code) {
  return mediciFetch(
    `${getApiUrl()}/activationCodes/${code}`,
    {
      method: 'delete',
    },
  );
}

// Company Groups

/**
 * get all companygroups
 * @returns {Promise.<T>}
 */
export function getCompanyGroups() {
  return mediciFetch(`${getApiUrl()}/companyGroups`, {
    method: 'get',
  });
}

/**
 * save company group
 * @param companyGroup
 * @returns {Promise.<T>}
 */
export function saveCompanyGroup(companyGroup) {
  return mediciFetch(`${getApiUrl()}/companyGroups`, {
    method: 'post',
    body: JSON.stringify({ name: companyGroup }),
  });
}

// Broker Groups

/**
 * get all brokergroups
 * @returns {Promise.<T>}
 */
export function getBrokerGroups() {
  return mediciFetch(`${getApiUrl()}/brokerGroups`, {
    method: 'get',
  });
}

/**
 * save broker group
 * @param brokerGroup
 * @returns {Promise.<T>}
 */
export function saveBrokerGroup(brokerGroup) {
  return mediciFetch(`${getApiUrl()}/brokerGroups`, {
    method: 'post',
    body: JSON.stringify({ name: brokerGroup }),
  });
}
