import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, withStyles } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import Loading from '../../components/Loading';
import { mediciFetch, getApiUrl } from '../../components/utils/NetworkUtils';
import * as helpers from '../../utils/helpers';

const styles = () => ({
  button: {
    marginLeft: 'auto',
  },
  tableCell: {
    padding: '4px 8px 4px 8px',
  },
  actionsCell: {
    padding: "4px 8px 4px 8px",
    textAlign: 'right',
  },
});

class IncentivesTable extends React.Component {
  state = {
    loading: true,
    incentives: [],
  };

  async componentDidMount() {
    try {
      const response = await mediciFetch(`${getApiUrl()}/admins/incentive`, { method: 'get' });
      this.setState({ incentives: helpers.sortByKey(response.incentives, 'startDate') });
    } catch (error) {
      console.log(error);
    }

    this.setState({ loading: false });
  }

  render() {
    const { classes, router } = this.props;
    const { loading, incentives } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell}>Incentive</TableCell>
            <TableCell className={classes.tableCell}>Start</TableCell>
            <TableCell className={classes.tableCell}>End</TableCell>
            <TableCell className={classes.tableCell}>Type</TableCell>
            <TableCell className={classes.tableCell}>Currency</TableCell>
            <TableCell className={classes.tableCell}>Amount</TableCell>
            <TableCell className={classes.tableCell}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {incentives.map((incentive) => {
            const now = moment();
            const startDate = moment(incentive.startDate);
            const endDate = moment(incentive.endDate);
            let endDateString = moment(incentive.endDate).format('YYYY-MM-DD [@] h:mm a');

            if (endDate >= now) {
              endDateString = 'active';

              if (startDate >= now) {
                endDateString = 'start pending';
              }
            }

            return (
              <TableRow key={incentive.uid}>
                <TableCell className={classes.tableCell}>{incentive.uid}</TableCell>
                <TableCell className={classes.tableCell}>{moment(incentive.startDate).format('YYYY-MM-DD [@] h:mm a')}</TableCell>
                <TableCell className={classes.tableCell}>{endDateString}</TableCell>
                <TableCell className={classes.tableCell}>{incentive.type.replace(/pr/g, 'doctor').replace(/pa/g, 'patient')}</TableCell>
                <TableCell className={classes.tableCell}>{incentive.currency}</TableCell>
                <TableCell className={classes.tableCell}>{incentive.amount / 100}</TableCell>
                <TableCell className={classes.actionsCell}>
                  <Button
                    color='secondary'
                    onClick={() => router.push(`/invite-incentives/${incentive.uid}/report`)}
                  >Report</Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

IncentivesTable.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

const InviteIncentives = ({ classes, router }) => (
  <Grid container direction='column' className='pageContainer'>
    <Typography variant='h5' gutterBottom>
      Invite Incentives
    </Typography>
    <Button variant='contained' color='secondary' className={classes.button} onClick={() => router.push('/invite-incentives/create')}>
      Create Incentive
    </Button>
    <IncentivesTable classes={classes} router={router} />
  </Grid>
);

const StyledInviteIncentives = withStyles(styles)(InviteIncentives);

InviteIncentives.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

export default withRouter(StyledInviteIncentives);
