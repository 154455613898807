import React from 'react';
import PropTypes from 'prop-types';
import './Notify.css';
import { NOTIFY_HIDDEN } from './NotifyUtils';

export default class NotifyPanel extends React.Component {
  static propTypes = {
    status: PropTypes.string,
  };

  state = {
    status: NOTIFY_HIDDEN,
    title: '',
  };

  componentWillMount() {
    this.setState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
    if (nextProps.status !== NOTIFY_HIDDEN) {
      this.closeNotification(4000);
    }
  }

  timeoutID = 0;

  closeNotification(timeout) {
    timeout = timeout || 0;
    if (this.timeoutID) {
      clearTimeout(this.timeoutID);
      this.timeoutID = 0;
    }
    this.timeoutID = setTimeout(() => {
      this.setState({
        status: NOTIFY_HIDDEN,
        title: '',
      });
    }, timeout);
  }

  render() {
    return (
      <div id="notifyPanel" className={this.state.status}>
        <label className="notifyTitle">{this.state.title}</label>
        <label
          className="notifyClose"
          onClick={this.closeNotification.bind(this)}
        >
          x
        </label>
      </div>
    );
  }
}
