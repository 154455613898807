/* eslint-disable class-methods-use-this */
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withStyles
} from "@material-ui/core";
import { Launch, Star, VideoCall } from "@material-ui/icons";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import PillSvg from "../../../images/icons/pill.svg";
import PractitionerHeader from "../../../pages/PractitionerProfile/PractitionerHeader";
import { getCurrencySymbol, renderPaymentStatus } from "../../../utils/helpers";
import Loading from "../../Loading";
import { setPractitionerSpecialties } from "../../specialties/SpecialtyUtils";
import {
  getConsultationByID,
  sendConsultationReceipt
} from "../ConsultationUtils";

const styles = ({ palette, spacing }) => ({
  container: {
    border: "1px solid #E5E5E5",
    backgroundColor: "#F6F6F6",
    padding: 24
  },
  labelField: {
    color: palette.primary.light,
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  textField: {
    border: "1px solid #E7E7E7",
    borderRadius: "3px",
    backgroundColor: "#FBFBFB",
    padding: "6px 7px"
  },
  title: {
    color: palette.primary.light
  },
  line: {
    backgroundColor: palette.primary.light,
    height: 2,
    width: "100%",
    marginBottom: 23
  },
  subscriptionPrice: {
    color: palette.primary.light,
    textTransform: "capitalize",
    fontSize: 18,
    fontWeight: 500
  },
  subscriptionDate: {
    color: palette.primary.light,
    fontSize: 14,
    marginTop: 6
  },
  subscriptionStatus: {
    fontSize: 18,
    fontWeight: 500,
    marginLeft: "auto",
    textTransform: "capitalize",
    color: palette.text.secondary,
    "&> span.active": {
      color: palette.secondary.main
    }
  },
  cancelButton: {
    color: palette.error.main,
    borderColor: palette.error.main,
    marginTop: spacing.unit,
    marginLeft: "auto"
  },
  roleLabel: {
    height: 39,
    width: "100%",
    backgroundColor: "#556979",
    color: palette.common.white,
    paddingLeft: 21,
    paddingRight: 16,
    fontSize: 22,
    fontWeight: 600
  },
  viewProfile: {
    cursor: "pointer",
    width: 125,
    color: "#21CE99",
    fontSize: 18,
    fontWeight: "normal"
  },
  paymentTitle: {
    color: "#6D7278",
    fontSize: 27,
    fontWeight: 500
  },
  paymentStatus: {
    color: "#20CE9A",
    fontSize: "18px",
    fontWeight: "bold",
    marginLeft: "auto"
  }
});

class ConsultationDetail extends React.Component {
  static propTypes = {
    params: PropTypes.shape({ id: PropTypes.string }),
    classes: PropTypes.object,
    onCancel: PropTypes.func,
    setNotifySuccess: PropTypes.func.isRequired,
    setNotifyWarning: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired
  };

  state = {
    loading: true,
    sendTo: ""
  };

  async componentDidMount() {
    const { params } = this.props;

    try {
      const consult = await getConsultationByID(params.id);

      const consultation = Object.assign(
        {
          totalNbOfMessages: 0,
          practitionerNbMessages: 0,
          callsTotalDuration: 0
        },
        consult
      );

      const practitioner = await setPractitionerSpecialties(
        consultation.practitioner
      );

      this.setState({
        consultation,
        practitioner,
        patient: consultation.patient,
        loading: false
      });
    } catch (error) {
      console.log("Error retrieving consultation details", error);
      this.props.setNotifyWarning("Error retrieving Consultation");
      this.props.router.replace("/consultations");
    }
  }

  sendReceipt = async () => {
    const { consultation, sendTo } = this.state;
    try {
      const response = await sendConsultationReceipt(consultation.uid, [
        sendTo
      ]);

      this.props.setNotifySuccess(
        `You have emailed an invoice to [${response.recipients.join(",")}]`
      );

      this.setState({ sendTo: "" });
    } catch (error) {
      console.log("Error sending receipt: ", error);
      this.props.setNotifyWarning("Email failed to send");
    }
  };

  renderRoleLabel = user => {
    const { classes } = this.props;
    const role = user.role === "practitioner" ? "Doctor" : "Patient";

    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.roleLabel}
      >
        <Grid style={{ marginRight: 16 }}>{role}</Grid>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.viewProfile}
          onClick={() => window.open(`/#/${user.role}s/${user.uid}`, "_blank")}
        >
          <div>View Profile</div> <Launch />
        </Grid>
      </Grid>
    );
  };

  renderPaymentDetails = () => {
    const { classes } = this.props;
    const { consultation } = this.state;

    if (consultation.payment) {
      return (
        <Paper style={{ padding: 24, marginBottom: 32 }} id="subscription">
          <Grid container direction="row">
            <Grid className={classes.paymentTitle}>
              {getCurrencySymbol(consultation.payment.currency)}{" "}
              {consultation.payment.price / 100}
            </Grid>
            <Grid className={classes.paymentStatus}>
              {renderPaymentStatus(consultation.paymentStatus)}
            </Grid>
          </Grid>
        </Paper>
      );
    }

    return null;
  };

  renderPaymentTypes = () => {
    const { classes } = this.props;
    const { consultation } = this.state;

    const content = [];

    if (
      consultation.payment &&
      consultation.payment.amount &&
      consultation.payment.card
    ) {
      const { card } = consultation.payment;

      content.push(
        <Paper key={1} style={{ padding: 24, marginBottom: 32 }}>
          <Grid container direction="row">
            <Grid className={classes.paymentTitle} style={{ fontSize: 18 }}>
              {card.brand}
            </Grid>
            <Grid className={classes.paymentStatus}>
              {getCurrencySymbol(consultation.payment.currency)}{" "}
              {consultation.payment.amount / 100}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ marginTop: 8 }}
          >
            <div>**** **** **** {card.last4}</div>
            <div>
              {card.expMonth}/{card.expYear}
            </div>
          </Grid>
        </Paper>
      );
    }

    if (consultation.payment && consultation.payment.amountPaidWithFreeCredit) {
      content.push(
        <Paper key={2} style={{ padding: 24, marginBottom: 32 }}>
          <Grid container direction="row">
            <Grid className={classes.paymentTitle} style={{ fontSize: 18 }}>
              Medici Credit
            </Grid>
            <Grid className={classes.paymentStatus}>
              {getCurrencySymbol(consultation.payment.currency)}{" "}
              {consultation.payment.amountPaidWithFreeCredit / 100}
            </Grid>
          </Grid>
        </Paper>
      );
    }

    return content;
  };

  render() {
    const { loading, practitioner, patient, consultation } = this.state;
    const { classes } = this.props;

    if (loading) {
      return (
        <Grid container direction="column">
          <Typography variant="h5">Consultation Detail</Typography>
          <div style={{ height: "50vh" }}>
            <Loading size={50} />
          </div>
        </Grid>
      );
    }

    return (
      <Grid container direction="column">
        <Typography variant="h5" style={{ marginBottom: 16 }}>
          Consultation Detail
        </Typography>

        <Grid container direction="row">
          <Grid item xs={6} style={{ paddingRight: 15 }}>
            {this.renderRoleLabel(patient)}
            <PractitionerHeader
              practitioner={patient}
              actions={false}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: 15 }}>
            {this.renderRoleLabel(practitioner)}
            <PractitionerHeader
              practitioner={practitioner}
              actions={false}
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="baseline">
          <Typography
            variant="h6"
            className={classes.title}
            style={{ marginTop: 16 }}
          >
            Consult Detail
          </Typography>
          <Grid style={{ marginLeft: "auto", color: "#556979", fontSize: 16 }}>
            ID: {consultation.uid}
          </Grid>
        </Grid>
        <div className={classes.line} />

        {/* Reuse from UserConsults?? */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Started</TableCell>
              <TableCell>Ended</TableCell>
              <TableCell>Patient Sent Messages</TableCell>
              <TableCell>Provider Sent Messages</TableCell>
              <TableCell>Calls</TableCell>
              <TableCell>Call Duration (s) </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={consultation.uid}>
              <TableCell>
                {moment(consultation.dateTime).format("YYYY-MM-DD [@] h:mm a")}
              </TableCell>
              {consultation.dateTimeEnd && (
                <React.Fragment>
                  <TableCell>
                    {moment(consultation.dateTimeEnd).format(
                      "YYYY-MM-DD [@] h:mm a"
                    )}
                  </TableCell>
                  <TableCell>
                    {consultation.totalNbOfMessages -
                      consultation.practitionerNbMessages}
                  </TableCell>
                  <TableCell>{consultation.practitionerNbMessages}</TableCell>
                  <TableCell>{consultation.audioCalls.length}</TableCell>
                  <TableCell>{consultation.callsTotalDuration}</TableCell>
                  <TableCell>
                    <Grid container direction="row" justify="center">
                      <VideoCall
                        style={{
                          opacity: consultation.audioCalls.length ? 1 : 0.5
                        }}
                      />
                      <img
                        src={PillSvg}
                        style={{
                          width: 20,
                          marginLeft: 8,
                          opacity: consultation.medications.length ? 1 : 0.5
                        }}
                      />
                      {consultation.reviews.length > 0 && (
                        <div style={{ marginLeft: 16 }}>
                          {[0, 1, 2, 3, 4].map(startIndex => (
                            <Star
                              key={startIndex}
                              style={{
                                color:
                                  consultation.reviews[0].stars <= startIndex
                                    ? "#D8D8D8"
                                    : "#FFA300"
                              }}
                            />
                          ))}
                        </div>
                      )}
                    </Grid>
                  </TableCell>
                </React.Fragment>
              )}
              {!consultation.dateTimeEnd && (
                <React.Fragment>
                  <TableCell>Active</TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      style={{ opacity: 0.5 }}
                    >
                      <VideoCall />
                      <img src={PillSvg} style={{ width: 20, marginLeft: 8 }} />
                    </Grid>
                  </TableCell>
                </React.Fragment>
              )}
            </TableRow>
          </TableBody>
        </Table>

        <Grid container direction="row" style={{ marginTop: 16 }}>
          <Grid item xs={6} style={{ marginRight: "auto" }}>
            <Typography variant="h6" className={classes.title}>
              Payment Details
            </Typography>
            <div className={classes.line} />
            {this.renderPaymentDetails()}

            <Typography variant="h6" className={classes.title}>
              Payment Types
            </Typography>
            <div className={classes.line} />
            {this.renderPaymentTypes()}
          </Grid>

          <Grid item xs={5} style={{ marginTop: 8 }}>
            {consultation.charges.map(charge => (
              <div className={classes.container} key={charge.uid}>
                <Typography variant="h6" className={classes.title}>
                  Invoice
                </Typography>
                <Grid container direction="column" style={{ marginTop: 20 }}>
                  <TextField
                    value={charge.invoice}
                    label="Invoice Number"
                    InputLabelProps={{
                      shrink: true,
                      className: classes.labelField
                    }}
                    InputProps={{ className: classes.textField }}
                    onChange={() => {}}
                  />
                  <TextField
                    placeholder="email@email.com"
                    value={this.state.sendTo}
                    onChange={e => this.setState({ sendTo: e.target.value })}
                    label="Sent To:"
                    InputLabelProps={{
                      shrink: true,
                      className: classes.labelField
                    }}
                    InputProps={{ className: classes.textField }}
                    style={{ marginTop: 20 }}
                  />
                </Grid>
                <Grid container direction="row" style={{ marginTop: 20 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginLeft: "auto", marginTop: 16 }}
                    disabled={loading}
                    onClick={this.sendReceipt}
                  >
                    Send
                  </Button>
                </Grid>
              </div>
            ))}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ConsultationDetail);
