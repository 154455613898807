import React from 'react';
import PropTypes from 'prop-types';

export default class LanguageSelector extends React.Component {
  static propTypes = {
    onLanguageChanged: PropTypes.func.isRequired,
    language: PropTypes.string,
    id: PropTypes.string,
  }

  onChange = (event) => {
    const value = event.target.value;

    this.props.onLanguageChanged(value);
  }

  render() {
    return (
      <select
        className="titleSelector"
        onChange={this.onChange}
        value={this.props.language}
        id={this.props.id}
      >
        <option value="en">English</option>
        <option value="af">Afrikaans</option>
      </select>
    );
  }
}
