import React from 'react';
import PropTypes from 'prop-types';

export class PageContent extends React.Component { // eslint-disable-line
  static propTypes = {
    children: PropTypes.node,
  }

  render() {
    const content = React.Children.map(
      this.props.children,
      child => React.cloneElement(child),
      // return React.cloneElement(child, {
      //     openSidePanel: this.props.openSidePanel,
      //     closeSidePanel: this.props.closeSidePanel
      // });
    );

    return <div className="pageContent">{content}</div>;
  }
}
