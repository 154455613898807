import { mediciFetch, getApiUrl } from './../utils/NetworkUtils';

/**
 * Get list of settings
 * @returns {Promise.<Array>}
 */
export function getSettings() {
  return mediciFetch(`${getApiUrl()}/mgmt/settings`, {
    method: 'get',
  });
}

/**
 * Save setting
 * @param setting object to save
 * @returns {Promise.<T>}
 */
export function saveSetting(setting) {
  return mediciFetch(`${getApiUrl()}/mgmt/settings/${setting.name}`, {
    method: 'post',
    body: setting.value,
  });
}

/**
 * Delete setting
 * @param setting to delete
 * @returns {Promise.<T>}
 */
export function deleteSetting(setting) {
  return mediciFetch(`${getApiUrl()}/mgmt/settings/${setting.name}`, {
    method: 'delete',
  });
}

export function getSettingHistory(name) {
  return mediciFetch(`${getApiUrl()}/mgmt/settings/${name}/history`, {
    method: 'get',
  });
}

export function getUserParams(uid, role) {
  return mediciFetch(`${getApiUrl()}/${role}/${uid}/params`, {
    method: 'get',
  })
}
