import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  withStyles
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PropTypes from "prop-types";
import React from "react";
import Loading from "../Loading";
import { getPatientByID, searchPatients } from "../patients/PatientUtils";
import {
  getPractitionerByID,
  searchPractitioners
} from "../practitioners/PractitionerUtils";
import { getSpecialties } from "../specialties/SpecialtyUtils";
import { mediciFetch, getApiUrl } from "../utils/NetworkUtils";

const styles = theme => ({
  appBar: {
    position: "relative",
    minWidth: "500px"
  },
  addButton: {
    marginLeft: "20px"
  },
  searchButton: {
    marginTop: theme.spacing.unit,
    marginLeft: "auto"
  },
  flex: {
    flex: 1
  },
  textField: {
    margin: theme.spacing.unit
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

async function handleSpecialties(contact) {
  const practitioner = await getPractitionerByID(contact.uid);

  const specialties = await getSpecialties();
  const specialtyValues = [];

  practitioner.specialties.forEach(specialtyUid => {
    specialties.forEach(specialty => {
      if (specialty.uid === specialtyUid) {
        specialtyValues.push(specialty.name);
      }
    });
  });

  practitioner.specialties = specialtyValues.join(", ");

  return practitioner;
}

class ContactsDialog extends React.Component {
  static propTypes = {
    handleClose: PropTypes.func.isRequired,
    isPractitioner: PropTypes.bool,
    uid: PropTypes.string
  };

  state = {
    open: false,
    patients: [],
    practitioners: [],
    error: null,
    search: "",
    loading: false,
    loadingAdd: false,
    contactsToConnect: [],
    contactsToInvite: []
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.uid) {
      this.setState({ open: false });
      return;
    }

    if (nextProps.uid !== this.props.uid) {
      this.setState({ open: true });
    }
  }

  handleBack(isHasChanges) {
    this.setState({
      search: "",
      loadingAdd: false,
      contactsToConnect: [],
      contactsToInvite: [],
      patients: [],
      practitioners: []
    });

    this.props.handleClose(isHasChanges);
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleSearch = async () => {
    if (!this.state.search || this.state.loading) {
      return;
    }

    this.setState({ loading: true, patients: [], practitioners: [] });
    // Try Patient and than contact

    try {
      const response = await searchPatients(this.state.search);
      this.setState({ patients: response.content.map(contact => contact.user) });
    } catch (error) {
      console.log(error);
    }

    try {
      const response = await searchPractitioners(this.state.search);
      this.setState({ practitioners: response.content.map(contact => contact.user) });
    } catch (error) {
      console.log(error);
    }

    this.setState({ loading: false });
  };

  handleConnect = contact => {
    const { contactsToConnect } = this.state;

    contactsToConnect.push(contact);

    this.setState({ contactsToConnect });
  };

  handleInvite = contact => {
    const { contactsToInvite } = this.state;

    contactsToInvite.push(contact);

    this.setState({ contactsToInvite });
  };

  handleSpecialty = async contact => {
    try {
      const practitioner = await handleSpecialties(contact);
      practitioner.showSpecialty = true;

      const practitioners = this.state.practitioners.map(pr => {
        if (practitioner.uid === pr.uid) {
          return practitioner;
        }

        return pr;
      });

      this.setState({ practitioners });
    } catch (error) {
      console.log(error);
    }
  };

  handleSave = async () => {
    const { contactsToConnect, contactsToInvite } = this.state;

    this.setState({ loadingAdd: true });

    let currentUser;
    try {
      currentUser = await getPatientByID(this.props.uid);
    } catch (error) {
      console.log(error);
    }

    try {
      if (!currentUser) {
        currentUser = await getPractitionerByID(this.props.uid);
      }

      const contactsToConnectPromises = contactsToConnect.map(contact =>
        mediciFetch(
          `${getApiUrl()}/${currentUser.role}s/${this.props.uid}/contacts`,
          { method: "POST", body: JSON.stringify({ userId: contact.uid }) }
        )
      );

      const contactsToInvitePromises = contactsToInvite.map(contact =>
        mediciFetch(
          `${getApiUrl()}/${currentUser.role}s/${
            this.props.uid
          }/requestLinkToUser`,
          { method: "POST", body: JSON.stringify({ userId: contact.uid }) }
        )
      );

      await Promise.all(contactsToConnectPromises);
      await Promise.all(contactsToInvitePromises);
    } catch (error) {
      console.log(error);
    }

    this.handleBack(true);
  };

  isAddDisabled = contact => {
    const { contactsToConnect, contactsToInvite, loadingAdd } = this.state;

    return (
      contactsToConnect.includes(contact) ||
      contactsToInvite.includes(contact) ||
      loadingAdd
    );
  };

  render() {
    const { classes } = this.props;

    const {
      patients,
      practitioners,
      contactsToConnect,
      contactsToInvite,
      loadingAdd
    } = this.state;

    const content = (
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={9}>
          <TextField
            fullWidth
            id="search"
            label="Contact Name or UUID"
            className={classes.textField}
            value={this.state.search}
            onChange={this.handleChange("search")}
            disabled={this.state.loading}
          />
        </Grid>
        <Grid container item xs={3}>
          <Button
            id="searchBtn"
            color="primary"
            className={classes.searchButton}
            onClick={this.handleSearch}
            disabled={this.state.loading}
          >
            Search
          </Button>
        </Grid>

        {this.state.loading && <Loading />}

        <Grid item xs={12}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <h4>Patients</h4>
                </TableCell>
              </TableRow>

              {patients.map(patient => (
                <TableRow key={patient.uid} className="patient-row">
                  <TableCell>
                    {patient.firstname} {patient.lastname}
                  </TableCell>
                  <TableCell>{patient.uid}</TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      onClick={() => this.handleInvite(patient)}
                      disabled={this.isAddDisabled(patient)}
                    >
                      Send Invite
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      onClick={() => this.handleConnect(patient)}
                      disabled={this.isAddDisabled(patient)}
                    >
                      Automatically Connect
                    </Button>
                  </TableCell>
                </TableRow>
              ))}

              <TableRow>
                <TableCell>
                  <h4>Practitioners</h4>
                </TableCell>
              </TableRow>
              {practitioners.map(practitioner => (
                <TableRow key={practitioner.uid} className="practitioner-row">
                  <TableCell>
                    {practitioner.firstname} {practitioner.lastname}
                  </TableCell>
                  <TableCell>{practitioner.uid}</TableCell>
                  <TableCell>
                    {practitioner.showSpecialty ? (
                      practitioner.specialties
                    ) : (
                      <Button
                        color="primary"
                        onClick={() => this.handleSpecialty(practitioner)}
                      >
                        Show Specialties
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      onClick={() => this.handleInvite(practitioner)}
                      disabled={this.isAddDisabled(practitioner)}
                    >
                      Send Invite
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      onClick={() => this.handleConnect(practitioner)}
                      disabled={this.isAddDisabled(practitioner)}
                    >
                      Automatically Connect
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>

        <Button
          variant="contained"
          fullWidth={true}
          color="primary"
          style={{ marginTop: 10 }}
          disabled={
            (contactsToConnect.length === 0 && contactsToInvite.length === 0) ||
            loadingAdd
          }
          onClick={this.handleSave}
        >
          Save
        </Button>
      </Grid>
    );

    return (
      <Dialog
        open={this.state.open}
        onClose={() => this.handleBack(false)}
        TransitionComponent={Transition}
        maxWidth="lg"
      >
        <AppBar className={classes.appBar} position="fixed">
          <Toolbar className={classes.toolbar}>
            <IconButton
              id="backHeader"
              color="inherit"
              onClick={() => this.handleBack(false)}
              aria-label="Close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              Contacts - {this.props.uid}
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent>{content}</DialogContent>
      </Dialog>
    );
  }
}

ContactsDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ContactsDialog);
