import { mediciFetch, getApiUrl } from "./../utils/NetworkUtils";

/**
 * Search admins free text
 * @param searchValue value to search
 * @returns {Promise.<T>}
 */
export function searchAdmins(searchValue, limit = 10, offset = 0) {
  const params = [`size=${limit}`, `offset=${offset}`];
  if (searchValue) {
    params.push(`q=${searchValue}`);
  }
  return mediciFetch(`${getApiUrl()}/admins/search?${params.join("&")}`, {
    method: "get"
  });
}

/**
 * Get admins by id
 * @param id admin id
 * @returns {Promise.<T>}
 */
export function getAdminByID(id) {
  return mediciFetch(`${getApiUrl()}/admins/${id}`, {
    method: "get"
  });
}

/**
 * Save admin
 * @param admin object to save
 * @returns {Promise.<T>}
 */
export function saveAdmin(admin) {
  if (admin.uid) {
    // update
    return mediciFetch(`${getApiUrl()}/admins/${admin.uid}`, {
      method: "post",
      body: JSON.stringify(admin)
    });
  }
  // insert
  return mediciFetch(`${getApiUrl()}/admins`, {
    method: "post",
    body: JSON.stringify(admin)
  });
}

/**
 * Delete admin
 * @param admin object to delete
 * @returns {Promise.<T>}
 */
export function deleteAdmin(admin) {
  return mediciFetch(`${getApiUrl()}/admins/${admin.uid}`, {
    method: "delete"
  });
}

export function resetTOTP(uid) {
  return mediciFetch(`${getApiUrl()}/admins/${uid}/totp/reset`, {
    method: "get"
  });
}

export function getMediciSuccessAdmins() {
  return mediciFetch(`${getApiUrl()}/admins/search?mediciSuccess=true`, {
    method: "get"
  });
}
