import React from 'react';
import PropTypes from 'prop-types';

import './SearchResults.css';

export default class SearchResults extends React.Component {
  static propTypes = {
    searchResults: PropTypes.arrayOf(PropTypes.object),
    searchHeaders: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
    onEdit: PropTypes.func,
    editTitle: PropTypes.string,
    showEdit: PropTypes.bool,
    onView: PropTypes.func,
  }

  static defaultProps = {
    searchResults: [],
    searchHeaders: [],
    onEdit: () => { },
    editTitle: '',
    showEdit: true,
    onView: null,
  }

  render() {
    let items = null;
    if (this.props.searchResults.length === 0) {
      items = (
        <tr key="noResults">
          <td
            colSpan={this.props.searchHeaders.length + 1}
            className="noResults"
          >
            <span>Start searching to see results</span>
          </td>
        </tr>
      );
    } else {
      items = this.props.searchResults.map((result, index) => {
        const tableDataList = this.props.searchHeaders.map((header) => {
          if (typeof header === 'object') {
            return <td key={`searchHeaders${header.name}`}
              style={{ textAlign: header.align }}>{result[header.name]}</td>;
          }

          return <td key={`searchHeaders${header}`}>{result[header]}</td>;
        });

        return (
          <tr className="active" key={index + 1}>
            {tableDataList}
            {this.props.showEdit && (
              <td key={index} className="textRight">
                {this.props.onView &&
                  <a
                    className="link edit"
                    onClick={this.props.onView.bind(this, result.uid)}
                    title={result.uid}
                  >
                    {'view'}
                  </a>
                }
                <a
                  className="link edit"
                  onClick={this.props.onEdit.bind(this, result.uid)}
                  title={result.uid}
                >
                  {this.props.editTitle || 'edit'}
                </a>
              </td>
            )}
          </tr>
        );
      });
    }

    return (
      <table className="table search">
        <tbody>
          <tr>
            {this.props.searchHeaders.map((header, index) => {
              if (typeof header === 'object') {
                return <th key={`header${index}`}>{header.name}</th>;
              }
              return <th key={`header${index}`}>{header}</th>;
            })}
            <th />
          </tr>
          {items}
        </tbody>
      </table>
    );
  }
}
