import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, TextField, Checkbox } from '@material-ui/core';

import './BiographyManager.css';

export default class BiographyManager extends React.Component {
  static propTypes = {
    entries: PropTypes.arrayOf(PropTypes.shape({
      summary: PropTypes.string,
      body: PropTypes.string,
      current: PropTypes.bool,
      start: PropTypes.string,
      end: PropTypes.string,
    })),
    onAdd: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    bioType: PropTypes.string,
  };

  state = {
    editIndex: null,
    isNew: true,
    summary: '',
    body: '',
    start: '',
    end: '',
    current: false,
    showError: false,
  };

  form;

  onChange = (event) => {
    const { id, value } = event.target;
    this.setState({
      [id]: value,
      showError: false,
    });
  };

  onCheckboxChange = (event) => {
    const { id, checked } = event.target;
    this.setState({
      [id]: checked,
    });
  };

  resetForm = () => {
    this.setState({
      editIndex: null,
      isNew: true,
      summary: '',
      body: '',
      start: '',
      end: '',
      current: false,
      showError: false,
    });
  };

  onEdit = (index) => {
    this.setState({
      editIndex: index,
      isNew: false,
      summary: this.props.entries[index].summary || '',
      body: this.props.entries[index].body || '',
      current: this.props.entries[index].current,
      start: moment(this.props.entries[index].start).format('YYYY-MM-DD'),
      end: moment(this.props.entries[index].end).format('YYYY-MM-DD'),
    });
  };

  onAdd = (event) => {
    event.preventDefault();

    if (this.form.checkValidity()) {
      if (this.state.isNew) {
        this.props.onAdd({
          summary: this.state.summary,
          body: this.state.body,
          current: this.state.current,
          start: moment(this.state.start).format('YYYY-MM-DD'),
          end: this.state.current ? '' : moment(this.state.end).format('YYYY-MM-DD'),
        });
      } else {
        this.props.onEdit({
          index: this.state.editIndex,
          summary: this.state.summary,
          body: this.state.body,
          current: this.state.current,
          start: moment(this.state.start).format('YYYY-MM-DD'),
          end: this.state.current ? '' : moment(this.state.end).format('YYYY-MM-DD'),
        });
      }
      this.resetForm();
    } else {
      this.setState({ showError: true });
    }
  };

  onDelete = (index) => {
    this.props.onDelete(index);
  };

  onCancel = (event) => {
    event.preventDefault();
    this.resetForm();
  };

  render() {
    return (
      <div className="biography-manager">
        {this.props.entries && this.props.entries.map((item, index) => (
          <div className={`item-container ${this.state.editIndex === index ? 'active' : ''}`} key={index}>
            <div className="info">
              <div><b>{item.summary}</b></div>
              <div>{item.body}</div>
              <div className="dates">
                <span>{moment(item.start).format('MMMM YYYY')}</span>
                <span>&mdash;</span>
                <span>{item.current ? 'Present' : moment(item.end).format('MMMM YYYY')}</span>
              </div>
            </div>
            <div className="actions">
              <Button disabled={this.state.editIndex === index} color="primary"
                      onClick={() => this.onEdit(index)}>Edit</Button>
              <Button disabled={this.state.editIndex === index}
                      onClick={() => this.onDelete(index)}>Delete</Button>
            </div>
          </div>
        ))}
        <form className="form-container" ref={(r) => { this.form = r; }}>
          <div className="row">
            <TextField
              id="summary"
              label={this.props.bioType === 'experience' ? 'Company/Organization' : 'School/University'}
              value={this.state.summary}
              onChange={this.onChange}
              error={this.state.showError && !this.state.summary.trim()}
              required
              fullWidth
            />
            <TextField
              id="body"
              label={this.props.bioType === 'experience' ? 'Job Title' : 'Degree'}
              value={this.state.body}
              onChange={this.onChange}
              error={this.state.showError && !this.state.body.trim()}
              required
              fullWidth
            />
          </div>
          <div className="row">
            <TextField
              id="start"
              label="From"
              type="date"
              value={this.state.start}
              onChange={this.onChange}
              error={this.state.showError && !this.state.start}
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
            />
            {this.state.current ? (
              <TextField
                label="To"
                value="Present"
                readOnly
                fullWidth
              />
            ) : (
              <TextField
                id="end"
                label="To"
                type="date"
                value={this.state.end}
                onChange={this.onChange}
                InputLabelProps={{
                  shrink: true,
                }}
                error={this.state.showError && !this.state.end}
                required
                fullWidth
              />
            )}
          </div>
          {this.props.bioType === 'experience' ? (
            <div className="row">
              <div>
                <Checkbox
                  id="current"
                  color="primary"
                  checked={this.state.current}
                  onChange={this.onCheckboxChange}
                />
                <label htmlFor="current" className="form-label">Is currently work/volunteer here</label>
              </div>
            </div>
          ) : null}
          <div className="actions">
            {!this.state.isNew ? (
              <Button onClick={this.onCancel}>
                Cancel
              </Button>
            ) : null}
            <Button color="primary" variant="contained" onClick={this.onAdd}>
              {this.state.isNew ? 'Add' : 'Apply'}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}
