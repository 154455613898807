import moment from "moment";

export function locationToString(location) {
  if (!location) {
    return "";
  }
  if (location.formatted_address) {
    return location.formatted_address;
  }

  // tslint:disable-next-line:max-line-length
  let addrStr = `${location.streetNumber || ""} ${location.street ||
    ""} ${location.additionalInfos || ""}, ${location.city ||
    ""}, ${location.state || ""} ${location.postalCode || ""}`;
  // remove commas from the beginning and end of line
  addrStr = addrStr.trim().replace(/^,|,$/g, "");
  addrStr = addrStr.replace(/ +,/g, ",");
  return addrStr.trim();
}

export function locationToAddressLine(location) {
  return [location.streetNumber, location.street, location.additionalInfos].join(' ');
}

export function renderUserName(user) {
  if (user.prettyName) {
    return user.prettyName;
  }

  return `${user.firstname || user.firstName} ${user.lastname ||
    user.lastName}`;
}

export function renderPaymentStatus(paymentStatus) {
  switch (paymentStatus) {
    case "ppa-free":
      return "Free";
    case "ppa-paid-with-free-credits":
      return "Paid with Credits";
    case "ppa-payment-accepted":
      return "Paid";
    case "ppa-requested":
      return "Pending";
    case "ppa-payment-refused":
      return "Declined";
    default:
      return "Free";
  }
}

export function validateLocation(location) {
  if (!location) {
    return false;
  }

  if (
    !location.country &&
    !location.state &&
    !location.city &&
    !location.postalCode &&
    !location.streetNumber &&
    !location.street
  ) {
    return false;
  }

  return true;
}

export function sortByKey(list, key) {
  const sortedList = list.sort((a, b) => {
    if (a[key] < b[key]) {
      return 1;
    }

    if (a[key] > b[key]) {
      return -1;
    }

    return 0;
  });

  return [...sortedList];
}

export function validateEmail(email) {
  const EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

  return EMAIL_REGEXP.test(email);
}

export function parsePhone(phoneString) {
  return `+${phoneString.replace(/\D/g, '')}`;
}

export function validatePhone(phone) {
  const PHONE_REGEXP = /^(\+)?([0-9]){10,16}$/;

  return PHONE_REGEXP.test(parsePhone(phone));
}

export function renderDate(dateString, format = "YYYY-MM-DD [@] h:mm a") {
  return moment(dateString).format(format);
}

export function shortUid(uid) {
  return uid && `${uid.slice(0, 4)}...${uid.slice(uid.length - 4, uid.length)}`;
}

export function getUidFromString(uid) {
  if (uid) {
    const uidRegexp = new RegExp(
      "(.+)?([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(.+)?"
    );
    return uidRegexp.test(uid.toLowerCase())
      ? uid.toLowerCase().replace(uidRegexp, "$2")
      : "";
  }
  return "";
}

export function errorToString(error) {
  if (error.message) {
    return error.message;
  } else if (error.invalidFields) {
    const message = Object.keys(error.invalidFields)
      .map(field => error.invalidFields[field])
      .join("\n");
    return message;
  }

  return "";
}

export function getCurrencySymbol(currency) {
  switch (currency) {
    case "USD":
      return "$";
    case "ZAR":
      return "R";
    default:
      return "$";
  }
}

export function getGeopoint(addressString) {
  const geocoder = new google.maps.Geocoder();
  return new Promise((resolve, reject) => {
    geocoder.geocode({ address: addressString }, (results, status) => {
      if (status === "OK") {
        const address = results[0];
        const location = {};

        if (!address || address.partial_match) {
          return reject(new Error("ZERO_RESULTS"));
        }

        location.lat = `${address.geometry.location.lat()}`;
        location.lng = `${address.geometry.location.lng()}`;

        resolve(`${location.lat},${location.lng}`);
      } else {
        reject(
          new Error(
            `Geocode was not successful for the following reason: ${status}`
          )
        );
      }
    });
  });
}
