import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from "@material-ui/core";
import { DateTimePicker } from "material-ui-pickers";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { cancelSmsSend, getSmsDefaultTemplate } from "../../utils/InviteUtils";
import {
  getDeliveryReport,
  getUploadsHistory,
  getUploadStats,
  saveBulkCSVFile,
  getPractitionerByID
} from "../PractitionerUtils";
import "./InvitePatients.css";
import InvitePatientsHistory from "./InvitePatientsHistory";

/**
 * InvitePatients
 */
export default class InvitePatients extends React.Component {
  static propTypes = {
    router: PropTypes.object,
    params: PropTypes.shape({ id: PropTypes.string }),
    setNotifyWarning: PropTypes.func,
    setNotifySuccess: PropTypes.func,
    classes: PropTypes.object
  };

  state = {
    error: null,
    history: [],
    stats: {},
    isDialogOpen: false,
    type: "ehr_csv",
    smsSetups: [
      {
        orderId: 1,
        overriddenTemplate: null,
        sendingDateTime: moment()
          .set("hours", 10)
          .set("minutes", 0)
          .set("seconds", 0),
        error: null
      },
      {
        orderId: 2,
        overriddenTemplate: null,
        sendingDateTime: moment()
          .set("hours", 10)
          .set("minutes", 0)
          .set("seconds", 0)
          .add(7, "days"),
        error: null
      },
      {
        orderId: 3,
        overriddenTemplate: null,
        sendingDateTime: moment()
          .set("hours", 10)
          .set("minutes", 0)
          .set("seconds", 0)
          .add(14, "days"),
        error: null
      }
    ],
    defaultMessages: ["", "", ""],
  };

  validate = () => {
    const smsSetups = this.state.smsSetups.map((item, index) => {
      if (item.sendingDateTime.hour() < 8 || item.sendingDateTime.hour() > 17) {
        return { ...item, error: "Please select time between 8AM and 6PM" };
      }

      if (
        index > 0 &&
        item.sendingDateTime < this.state.smsSetups[index - 1].sendingDateTime
      ) {
        return {
          ...item,
          error: `${index +
            1}th message should not be scheduled early ${index}th message`
        };
      }

      return { ...item, error: null };
    });

    this.setState({ smsSetups });
  };

  isSmsSetupValid = () => !this.state.smsSetups.some(item => item.error);

  handleChange = (item, prop, value) => {
    const { smsSetups } = this.state;

    if (item.orderId === 1 && prop === 'sendingDateTime') {
      smsSetups[1].sendingDateTime = moment(value).add(7, "days");
      smsSetups[2].sendingDateTime = moment(value).add(14, "days");
    }

    const newSmsSetups = smsSetups.map(sms => {
      if (sms.orderId === item.orderId) {
        return Object.assign({}, sms, { [prop]: value });
      }

      return sms;
    });

    this.setState({ smsSetups: newSmsSetups }, this.validate);
  };

  onChange(propName, event) {
    this.setState({
      [propName]: event.target.value
    });
  }

  bulkCSVFile = null;

  bulkCSVFileImporterHandler = e => {
    e.preventDefault();
    this.bulkCSVFile = e.target.files[0];
    this.setState({ error: null });
  };

  save = async e => {
    // TODO: Add a loading component and clear SMS form
    e.preventDefault();

    if (this.bulkCSVFile && confirm("Send SMS to all users linked to PR?")) {
      try {
        const res = await saveBulkCSVFile(
          this.props.params.id,
          this.bulkCSVFile,
          this.state.type,
          this.state.smsSetups.map(sms => ({
            orderId: sms.orderId,
            overriddenTemplate: sms.overriddenTemplate,
            sendingDateTime: sms.sendingDateTime.format("YYYY-MM-DDTHH:mm:ss")
          }))
        );

        this.setState({
          history: res.uploadHistory
        });
        this.props.setNotifySuccess("Invitations sending");
      } catch (error) {
        if (error.status === 504) {
          this.props.setNotifySuccess("Validation Report Pending");
        } else {
          this.props.setNotifyWarning(
            "Error sending invites. Please try again."
          );
        }
      }
    } else if (!this.bulkCSVFile) {
      this.setState({ error: "Select a file" });
    }
  };

  onCancel = e => {
    e.preventDefault();
    this.props.router.goBack();
  };

  getReport = async uid => {
    const newWindow = window.open("", "_blank");
    newWindow.document.write("Loading file...");

    try {
      const res = await getDeliveryReport(this.props.params.id, uid);
      const file = new Blob([res], { type: "text/csv" });
      newWindow.location.href = URL.createObjectURL(file);
    } catch (error) {
      newWindow.close();
      console.error(error);
    }
  };

  onDialogClose = () => {
    this.setState({
      isDialogOpen: false
    });
  };

  getStats = async uuid => {
    try {
      const stats = await getUploadStats(this.props.params.id, uuid);

      this.setState({
        stats,
        isDialogOpen: true,
        currentUid: uuid
      });
    } catch (error) {
      this.props.setNotifyWarning(error.message);
    }
  };

  async componentDidMount() {
    try {
      const sms = await getSmsDefaultTemplate();
      const res = await getUploadsHistory(this.props.params.id);
      const currentUser = await getPractitionerByID(this.props.params.id);

      this.setState({
        currentUser,
        history: res.uploadHistory,
        defaultMessages: [
          sms.translations && sms.translations.template_invite_pr_to_pa_sms_1,
          sms.translations && sms.translations.template_invite_pr_to_pa_sms_2,
          sms.translations && sms.translations.template_invite_pr_to_pa_sms_3,
        ],
      });
    } catch (error) {
      this.props.setNotifyWarning(error.message);
    }
  }

  handleCancel = async item => {
    const { currentUid } = this.state;
    const { id } = this.props.params;

    try {
      await cancelSmsSend(id, currentUid, item.orderId);
      const history = await getUploadsHistory(id);
      this.setState({ history: history.uploadHistory });
      this.props.setNotifySuccess(
        "The scheduled SMS invitation send has been canceled"
      );
    } catch (error) {
      this.props.setNotifyWarning(error.message);
    }
  };

  render() {
    const {
      currentUid,
      history,
      stats,
      isDialogOpen,
      currentUser
    } = this.state;
    const { classes } = this.props;

    return (
      <div>
        <InvitePatientsHistory
          classes={classes}
          currentUid={currentUid}
          history={history}
          stats={stats}
          isDialogOpen={isDialogOpen}
          onDialogClose={this.onDialogClose}
          handleCancel={this.handleCancel}
        />

        <div className="form-pod">
          <form
            id="formInvitePatients"
            ref="formInvitePatients"
            className="form"
          >
            <div className="form-pod-title">
              <h2>
                Invite Patients for {currentUser ? currentUser.prettyName : '...'}
              </h2>
            </div>

            <div className="form-freeFieldset">
              <label htmlFor="firstname" className="form-label">
                CSV File *
              </label>

              <div className="form-flexColFieldset">
                <div>
                  <label className="tip">File ext: .csv,.txt</label>
                </div>
                <input
                  type="file"
                  name="bulkCSVFileImporter"
                  accept=".csv,.txt"
                  onChange={this.bulkCSVFileImporterHandler}
                />
                {this.state.error && (
                  <div className="errorMessage"> {this.state.error}</div>
                )}
              </div>
            </div>

            <div className="form-freeFieldset">
              <label className="form-label">Type</label>
              <div className="form-freeContainer">
                <input
                  id="ehr_csv"
                  className="form-type"
                  type="radio"
                  value="ehr_csv"
                  checked={this.state.type === "ehr_csv"}
                  onChange={e => this.onChange("type", e)}
                />
                <label htmlFor="ehr_csv">EHR import</label>
                <input
                  id="sms_csv"
                  className="form-type"
                  type="radio"
                  value="sms_csv"
                  checked={this.state.type === "sms_csv"}
                  onChange={e => this.onChange("type", e)}
                />
                <label htmlFor="sms_csv">SMS import</label>
              </div>
            </div>

            <div
              style={{
                borderBottom: "1px solid #cdcdcd",
                width: "100%",
                margin: "20px 0px"
              }}
            />

            {this.state.smsSetups.map((item, index) => (
              <Grid
                key={item.orderId}
                container
                direction="row"
                alignItems="flex-start"
                style={{ marginBottom: 20 }}
              >
                <label className="form-label">SMS Invite #{item.orderId}</label>
                <TextField
                  label="Content"
                  InputLabelProps={{
                    shrink: true
                  }}
                  style={{ flex: "1 0 auto", marginRight: 20 }}
                  value={item.overriddenTemplate || this.state.defaultMessages[index]}
                  onChange={event =>
                    this.handleChange(
                      item,
                      "overriddenTemplate",
                      event.target.value
                    )
                  }
                />
                <DateTimePicker
                  error={!!item.error}
                  helperText={item.error}
                  label="Send Date/Time"
                  clearable
                  emptyLabel="Any"
                  value={item.sendingDateTime}
                  onChange={date =>
                    this.handleChange(item, "sendingDateTime", date)
                  }
                  format="MM/DD/YYYY - HH:mm:ss"
                  style={{ width: "330px" }}
                />
              </Grid>
            ))}

            <div className="form-options">
              <Button
                style={{ minWidth: 90, marginRight: 12 }}
                variant="contained"
                color="primary"
                onClick={this.save}
                disabled={!this.isSmsSetupValid()}
              >
                Schedule
              </Button>
              <Button
                style={{ minWidth: 90 }}
                variant="outlined"
                className={classes.deleteButton}
                onClick={this.onCancel}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>

        {this.state.history.length ? (
          <div>
            <h2>Reports</h2>
            <Table className="reports">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Scenario UID</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Links</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.history.map((report, index) => (
                  <TableRow key={index}>
                    <TableCell className="no-wrap">
                      {moment(report.date).format("YYYY-MM-DD [@] h:mm a")}
                    </TableCell>
                    <TableCell>{report.scenarioUid}</TableCell>
                    <TableCell>
                      <div className="no-wrap">
                        Completed: {report.completed ? "True" : "False"}
                      </div>
                      <div className="no-wrap">
                        Manual Upload: {report.manualUpload ? "True" : "False"}
                      </div>
                      <div className="no-wrap">
                        Validation Success:{" "}
                        {report.validationSuccess ? "True" : "False"}
                      </div>
                      <div className="no-wrap">
                        Records Uploaded: {report.nbRecordsUploaded}
                      </div>
                      <div className="no-wrap">
                        User Passed Validation: {report.nbUsersPassValidation}
                      </div>
                    </TableCell>
                    <TableCell>
                      {report.originalFileUrl ? (
                        <div className="no-wrap">
                          Original file:{" "}
                          <a
                            href={report.originalFileUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            link
                          </a>
                        </div>
                      ) : null}
                      {report.validationReportUrl ? (
                        <div className="no-wrap">
                          Validation report:{" "}
                          <a
                            href={report.validationReportUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            link
                          </a>
                        </div>
                      ) : null}
                    </TableCell>
                    <TableCell className="textCenter">
                      <Button
                        color="primary"
                        onClick={() => this.getStats(report.uuid)}
                      >
                        Stats
                      </Button>
                      <Button
                        color="primary"
                        onClick={() => this.getReport(report.uuid)}
                      >
                        Report
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : null}
      </div>
    );
  }
}
