import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Typography, withStyles,
  Table, TableRow, TableCell, TableHead, TableBody, Button,
  TablePagination,
} from '@material-ui/core';
import moment from 'moment';

import { searchCalls } from '../../components/consultations/ConsultationUtils';
import UserAvatar from '../../components/user-avatar/UserAvatar';

const styles = theme => ({
  container: {
    paddingTop: theme.spacing.unit * 2,
  },
  title: {
    color: theme.palette.primary.light
  },
  opacity: {
    opacity: 0.2,
  }
});

class UserCalls extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    uid: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    onViewCall: PropTypes.func.isRequired,
    setNotifyWarning: PropTypes.func.isRequired,
  };

  state = {
    calls: [],
    callsPagination: {
      total: 0,
      limit: 10,
      page: 0,
    },
  };

  handleChangeRowsPerPageCalls = (e) => {
    const { callsPagination } = this.state;
    this.setState({ callsPagination: { ...callsPagination, limit: e.target.value } }, this.retrieveCalls);
  };

  handleChangePageCalls = (e, page) => {
    const { callsPagination } = this.state;
    this.setState({ callsPagination: { ...callsPagination, page } }, this.retrieveCalls);
  };

  retrieveCalls = () => {
    const { page, limit } = this.state.callsPagination;

    const params = {
      limit,
      offset: limit * page,
    };

    params.userUid = this.props.uid;

    searchCalls(params, this.props.role)
      .then(result => {
        result.content.forEach((call, index) => {
          result.content[index].filteredParticipants = call.participants.filter(p => p.userUid !== this.props.uid && p.role !== 'system');
        });

        this.setState({
          calls: result.content,
          callsPagination: {
            page,
            limit,
            total: result.pagination.total,
          }
        })
      })
      .catch(e => this.props.setNotifyWarning(e.message));
  };

  componentWillMount() {
    this.retrieveCalls();
  }

  render() {
    const { classes } = this.props;
    const { callsPagination } = this.state;
    return (
      <Grid container direction='column' className={classes.container}>
        <Typography variant="h6" className={classes.title} style={{ marginTop: 16 }}>
          Calls
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Started</TableCell>
              <TableCell>Participants</TableCell>
              <TableCell>Duration (h:m:s)</TableCell>
              <TableCell>Type</TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.calls.map(call => (
              <TableRow key={call.roomSid}>
                <TableCell>{moment(call.callStartedAt).format('YYYY-MM-DD [@] h:mm a')}</TableCell>
                <TableCell>
                  {call.filteredParticipants.length > 0 && (<Grid container direction='row' alignItems='center'>
                    <UserAvatar role={call.filteredParticipants[0].role === "practitioner" ? "practitioners" : "patients"} uid={call.filteredParticipants[0].userUid}  size={25} />
                    <Typography style={{ marginLeft: 10 }} variant='subtitle2'>
                      {call.filteredParticipants[0].prettyName}
                      {call.filteredParticipants.length > 1 && (<span> & {call.filteredParticipants.length} others&hellip;</span>)}
                    </Typography>
                  </Grid>)}
                </TableCell>
                <TableCell>
                  <div style={{ width: 80, textAlign: 'right'}}>
                    {call.duration}
                  </div>
                </TableCell>
                <TableCell>
                  {call.callType}
                </TableCell>
                <TableCell numeric>
                  <Button color='secondary'
                          onClick={() => this.props.onViewCall(call.roomSid)}>
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          count={callsPagination.total}
          onChangePage={this.handleChangePageCalls}
          onChangeRowsPerPage={this.handleChangeRowsPerPageCalls}
          page={callsPagination.page}
          rowsPerPage={callsPagination.limit}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </Grid>
    );
  }
}

export default withStyles(styles)(UserCalls);
